import styled from "styled-components";
import { color, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const SearchElementContainer = styled.div`
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 45px;
    column-gap: 10px;
    width: 100%;
    max-width: 100%;
    padding-left: 10px;
    user-select: none;
    cursor: ${props => props.attributes ? "default" : "pointer"};
    
    &:hover {
        background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    }
`

export const SearchElementIconContainer = styled.div`
    margin: 0 10px 0 auto;
    display: flex;
    align-items: center;
    height: inherit;
`

export const SearchElementIcon = styled(Icon)`
    height: auto;
    justify-content: center;
    flex-shrink: 0;
    margin-right: ${props => props.type === "close" ? "10px" : "0"};
    transform: ${props => props.type === "close" ? "rotateZ(45deg)" : "none"};
  
    ${
        props => props.type === "close" ? `
            &:hover {
                border-radius: 50px;
                background-color: ${props.darkmode ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.1)"};
                
            }
            &:active {
                transform: scale(0.95) rotateZ(45deg);
            }
        ` : null
    }
`

export const SearchElementQuery = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 20px;
    color: ${props => props.hover ? color.blue : props.attributes ? props.darkmode ? color.gray3 : color.gray4 : props.darkmode ? color.gray4 : color.gray2};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    flex-shrink: ${props => props.attributes ? "0" : "1"};
    -webkit-box-orient: vertical;
    overflow: hidden;
`

export const SearchElementAttribute = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    color: ${props => props.darkmode ? color.gray3 : color.gray4};
    margin-left: 5px;
    align-items: center;
    flex-shrink: 0;
    display: flex;
  
    &::before {
        content: "";
        width: 25px;
        height: 2px;
        display: inline-flex;
        border-radius: 4px;
        background-color: ${props => props.darkmode ? color.gray3 : color.gray4};
        margin-right: 5px;
    }
`

export const SearchAttributeWrapper = styled.div`
    display: inline-block;
    overflow: hidden;
    padding-right: 10px;
    min-width: 0;
    flex-grow: 0;
`

export const SearchAttributeContainer = styled.div`
    height: max-content;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    overflow-x: scroll;
    border-radius: 5px;
    min-width: 0;
`

export const SearchAttribute = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    height: 28px;
    padding: 4px 10px;
    border-radius: inherit;
    cursor: pointer;
    flex-shrink: 0;
    display: table-cell;
    white-space: nowrap;
    color: ${props => props.active ? color.white : props.darkmode ? color.gray4 : color.gray2};
    background-color: ${props => props.active ? color.blue : props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
  
    &:hover {
        background-color: ${props => props.active ? color.blue : props.darkmode ? color.gray3 : color.gray4};
        opacity: ${props => props.active ? "0.7" : "1"};
    }
`