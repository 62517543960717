import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { timeStamp } from "../general.utils";
import { color } from "../../design/theme";

import {
    BreakingCardContainer,
    BreakingCardDetailsContainer,
    BreakingCardButtonContainer,
    BreakingCardIcon,
    BreakingCardTimestamp,
    BreakingCardNewsContainer
} from "./breakingCard.styles";
import ArticleText from "../articleContent/articleText/articleText.content";

const BreakingCard = ({ breakingArticle, lang, darkmode, highlightUpdate }) => {
    const [showLink, setShowLink] = useState(false);
    const { metadata, content, id } = breakingArticle || {};
    const { title, text } = content || {};
    const { publishedAt, timestamp, articleUrl, requestUrl } = metadata || {};

    const handleHover = (event, state) => {
        event.stopPropagation();
        setShowLink(state)
    }

    return (
        <BreakingCardContainer
            id={id}
            onMouseEnter={(event => handleHover(event, true))}
            onMouseLeave={(event => handleHover(event, false))}
            darkmode={darkmode}
        >
            <BreakingCardDetailsContainer>
                <BreakingCardTimestamp darkmode={darkmode}>
                    <span>{timeStamp(timestamp || publishedAt, lang)}</span>
                </BreakingCardTimestamp>
                {
                    (articleUrl || requestUrl) && showLink ? (
                        <BreakingCardButtonContainer
                            href={articleUrl || requestUrl}
                            referrerPolicy={"no-referer"}
                            target={"_blank"}
                            darkmode={darkmode}
                        >
                            <BreakingCardIcon
                                name={"external"}
                                size={20}
                                color={darkmode ? color.gray4 : color.gray2}
                            />
                        </BreakingCardButtonContainer>
                    ) : null
                }
            </BreakingCardDetailsContainer>
            <BreakingCardNewsContainer darkmode={darkmode} highlight={highlightUpdate}>
                <ArticleText text={title} small bold contrast narrow />
                <ArticleText text={text} small narrow />
            </BreakingCardNewsContainer>
        </BreakingCardContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(BreakingCard);