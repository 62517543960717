import styled from "styled-components";
import { color } from "../../design/theme";
import checkmark from "../../design/assets/Checkmark Icon @25x.svg";

export const CheckboxOptIn = styled.input`
    width: 22px;
    height: 22px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: inset 0 0 0 2px ${color.gray3};
    border-radius: 7px;
    cursor: pointer;
    flex-shrink: 0;
    
    &:focus {
        outline: none;
    }    
    
    &::before {
        content: "";
        width: 22px;
        height: 22px;
        border-radius:7px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }
    
    &:checked {
        content: ${checkmark};
        z-index: 5;
        width: 22px;
        height: 22px;
        border-radius: 7px;
        background-color: ${color.blue};
        box-shadow: 0 0 0 2px transparent !important;
        background-image: url("${checkmark}");
        top: 0;
        left: 0;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
`;