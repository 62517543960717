import { takeLatest, call, put, all } from "redux-saga/effects";
import { onValue, ref, update, set } from "firebase/database";
import UserSettingsActionTypes from "./userSettings.types";

import { store } from "../store";
import { database } from "../../config/firebase.config";
import {
    setUserLanguageSuccess, setUserLanguageFailure,
    setUserEmbedConsentSuccess, setUserEmbedConsentFailure
} from "./userSettings.actions";
import { validateLang } from "../../components/general.utils";


export function* setUserLanguageStartAsync(action) {
    try {
        const { userId, embedValue } = action.payload;
        const lang = validateLang(embedValue);
        let userLanguage = yield ref(database, "users/" + userId + "/general/language");
        let userGeneral = yield ref(database, "users/" + userId + "/general");
        yield onValue(userLanguage, async snapshot => {
            let snapshotData = await snapshot.val()
            if (snapshotData !== lang) {
                await update(userGeneral, { language: lang });
            }
            store.dispatch(setUserLanguageSuccess());
        }, { onlyOnce: true })
    } catch (error) {
        yield put(setUserLanguageFailure(error.message))
    }
}

export function* setUserLanguageStart() {
    yield takeLatest(
        UserSettingsActionTypes.SET_USER_LANGUAGE_START,
        setUserLanguageStartAsync
    )
}


export function* setUserEmbedConsentStartAsync(action) {
    try {
        const { userId, embedVariant, embedValue } = action.payload;
        let userNewsGeneral = yield ref(database, "users/" + userId + "/news/general");
        let userNewsGeneralEmbed = yield ref(database, "users/" + userId + "/news/general/allowEmbeds/" + embedVariant);

        if(embedVariant === "all") yield update(userNewsGeneral, { allowAllEmbeds: embedValue })
        else yield set(userNewsGeneralEmbed, embedValue || null)

        yield put(setUserEmbedConsentSuccess())
    } catch (error) {
        yield put(setUserEmbedConsentFailure(error.message))
    }
}

export function* setUserEmbedConsentStart() {
    yield takeLatest(
        UserSettingsActionTypes.SET_USER_EMBED_CONSENT_START,
        setUserEmbedConsentStartAsync
    )
}


export function* userSettingsSagas() {
    yield all([
        call(setUserLanguageStart),
        call(setUserEmbedConsentStart)
    ])
}