import styled from "styled-components";
import { categoryColor, categoryContentColor, textColor } from "../general.utils";
import { color, font, radius } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const ChannelCardWrapper = styled.div`
    width: 100%;
    height: 90px;
    background-color: ${props => categoryColor(props.categoryId, props.darkmode, props.active)};
    // background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    border-radius: ${radius.smallRadius};
    user-select: none;
    color: ${props => categoryContentColor(props.categoryId, props.darkmode)};
    cursor: pointer;
    z-index: 10;
    position: relative;
    transform: translate3d(0,0,0); // forces tap into webGPU
    will-change: scroll-position;
    
    &:active {
        transform: scale(0.98);
        // transition: 0.1s;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &::-moz-focus-inner {
        border: 0;
    }
    ${
        props => props.categoryId !== "special" ?
            `&:hover {outline: none; box-shadow: 0 0 0 2px ${textColor(props.darkmode)};}`
            : null
    } 
    ${
        props => props.categoryId === "special" ?
            `&::before {content: '';position: absolute;top: 0; right: 0; bottom: 0; left: 0; z-index: -1; margin: -2px; border-radius: 16px; ${props.hover ? "background: linear-gradient(90deg, rgba(56,115,247,1) 0%, rgba(255,17,87,1) 33%, rgba(253,180,0,1) 66%, rgba(22,255,190,1) 100%);" : "background-color:transparent;"}}
             &::after {content: '';position: absolute;top: 0; right: 0; bottom: 0; left: 0; z-index: -1; border-radius: inherit; background-color:${categoryColor(props.categoryId, props.darkmode, props.active)};}`
            : null
    }  
`;

const filterCategories = ["society", "sports", "science"];
const filterFunction = (categoryId, darkmode) => {
    if(categoryId === "special") return false;
    return darkmode ? !filterCategories.includes(categoryId) : filterCategories.includes(categoryId)
}
export const ChannelCardContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 15px;
    border-radius: inherit;
    // background-color: ${props => categoryColor(props.categoryId, props.darkmode)};
    filter: ${props => props.active && filterFunction(props.categoryId, props.darkmode) ? "opacity(0.5)" : "none"};
    // filter: ${props => props.active ? "opacity(0.5)" : "none"};
    display: flex;
    position: relative;
`

export const ChannelCardActionIconContainer = styled.div`
    height: auto;
    width: auto;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: center;
    flex-direction: column;
    margin: 5px 5px 0 0;
    cursor: pointer;
`;

export const ChannelCardContent = styled.div`
    margin: 0;
`;

export const ChannelCardTopicIcon = styled(Icon)`
    display: block;
    margin-top: 0;
`;

export const ChannelCardTopicContent = styled.span`
    display: block;
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    line-height: 18px;
    max-height: 36px;
    justify-content: center;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    text-align: left;
    letter-spacing: 0.045em;
    margin-top: 5px;
`;