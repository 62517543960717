import { timeData, greetingData, timePassedData, geoData, shareData } from "./lang.data";
import { color } from "../design/theme";
import { cloneDeep, isEmpty } from "lodash";
import { detect } from "detect-browser";

export const capitalize = (string = "", matchAll) => {
    if(matchAll) return string.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ")
    else return (string.charAt(0).toUpperCase() + string.slice(1))
}

export const copyToClipboard = (string) => {
    navigator?.clipboard?.writeText(string) || (() => {
        const el = document.createElement("textarea");
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "1000vw";
        el.style.opacity = "0";
        el.value = string;
        document.body.appendChild(el);
        el.select();
        document?.execCommand("copy")
        document.body.removeChild(el);
    })()
}

export const date = (lang, isoDate) => {
    const time = isoDate ? new Date(isoDate) : new Date();
    const intlLang = getLangCode(lang);
    // const year = time.getFullYear();
    // const month = time.getMonth();
    // const day = time.getDate();
    // const weekday = time.getDay();

    // const addString = isoDate ? ` ${year}` : "";
    if(isoDate && compareDates(new Date(), time)) return timeData.today[lang];
    if(isoDate && compareDates(new Date(), time, 1)) return timeData.yesterday[lang];
    return new Intl.DateTimeFormat(intlLang, {
        weekday: "long",
        month: "long",
        day: "2-digit",
        year: isoDate ? "numeric" : undefined,
        localeMatcher: "lookup"
    }).format(time)
    // return `${timeData.weekday[weekday][lang]}, ${day}. ${timeData.month[month][lang]}` + addString;
}

export const compactArticleData = (article, articleRef) => {
    const { articleId, source, content, metadata } = article || {};
    const { timestamp } = articleRef || {};
    return Object.assign({
        articleId: articleId,
        newspaper: source?.newspaper,
        title: content?.title,
        catchline: content?.catchline,
        image: content?.teaserImage,
        category: metadata?.category,
        publishedAt: metadata?.publishedAt,
        updatedAt: metadata?.updatedAt,
        similarArticles: metadata?.similarArticles,
        readingTime: metadata?.readingTime,
        type: metadata?.type,
        timestamp: timestamp ?? new Date().toISOString()
    }, articleRef)
}

export const time = (dateObj, lang) => {
    const intlLang = getLangCode(lang);
    return new Intl.DateTimeFormat(intlLang, {
        hour: "2-digit",
        minute: "2-digit",
        localeMatcher: "lookup"
    }).format(dateObj)
}

export const timeStamp = (isoDate, lang, mode) => {
    const userISOTime = new Date().toISOString();
    const articleTime = new Date(isoDate);

    // const articleHour = ("0" + articleTime.getHours()).slice(-2);
    // const articleMinutes = ("0" + articleTime.getMinutes()).slice(-2);

    const userTime = new Date(userISOTime);
    const difference = userTime - articleTime;

    if (mode === "simple" || difference < 86400e3) {
        return time(articleTime, lang)
        // return `${articleHour}:${articleMinutes}`
    } else {
        return timeSince(isoDate, lang)
    }
}

export const isValidDate = (date) => {
    return date && (Object.prototype.toString.call(date) === "[object Date]") && !isNaN(date);
}

export const isValidUrl = (url) => {
    const pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
    return !!pattern.test(url);
}

export const calcDayDiff = (date, dateToCompare) => {
    if(!(isValidDate(date) && isValidDate(dateToCompare))) return 0;
    let validDateDiff = date.setHours(0,0,0,0) - dateToCompare.setHours(0,0,0,0);
    return Math.floor(validDateDiff / (1000 * 60 * 60 * 24));
}

export const compareDates = (date, dateToCompare, dayDiff = 0, comparison = "equal") => {
    if(!date || !dateToCompare) return false;
    let validDate = isValidDate(date) ? date : new Date(date);
    let validDateToCompare = isValidDate(dateToCompare) ? dateToCompare : new Date(dateToCompare);
    let validDateDiffInDays = calcDayDiff(validDate, validDateToCompare);

    if(comparison === "smaller") return validDateDiffInDays < dayDiff;
    if(comparison === "greater") return validDateDiffInDays > dayDiff;
    else return validDateDiffInDays === dayDiff;
}

export const textColor = (darkmode) => {
    return darkmode ? color.white : color.black
}

export const categoryColor = (category, darkmode, active) => {
    const categoryColors = {
        "economy": active ? darkmode ? color.purpleActiveDark : color.purpleActiveLight : color.purple,
        "society": active ? darkmode ? color.orangeActiveDark : color.orangeActiveLight : color.orange,
        "sports": active ? darkmode ? color.yellowActiveDark : color.yellowActiveLight : color.yellow,
        "politics": active ? darkmode ? color.redActiveDark : color.redActiveLight : color.red,
        "technology": active ? darkmode ? color.blueActiveDark : color.blueActiveLight : color.blue,
        "science": active ? darkmode ? color.greenActiveDark : color.greenActiveLight : color.green,
        "special": active ? darkmode ? color.whiteActive: color.blackActive : darkmode ? color.white : color.black
    }

    if(!category || typeof category !== "string") return textColor(darkmode)
    else return categoryColors[category.toLowerCase()];
}

export const categoryContentColor = (category, darkmode) => {
    const categoryColors = {
        "economy": color.white,
        "society": color.black,
        "sports": color.black,
        "politics": color.white,
        "technology": color.white,
        "science": color.black,
        "special": darkmode ? color.black : color.white
    }
    return categoryColors[category.toLowerCase()];
}

export const getUserPlatform = () => {
    const { os } = detect();
    return os || window.navigator?.platform || window.navigator?.userAgentData?.platform || "unknown";
}

export const getUserAgent = (includeVersion) => {
    const { type, name, version } = detect();
    return (type === "browser" ? name + (includeVersion ? version : "") : type) || "unknown";
    // return window.navigator?.userAgentData?.brands?.[2]?.brand || (window.navigator?.userAgent?.includes("Firefox") ? "Firefox" : "") || (window.navigator?.userAgent?.includes("Safari") ? "Safari" : "") || "Other";
}

export const getEnvPermissionsHelpUrl = (lang, darkmode) => {
    const { name } = detect();
    if(name === "firefox") return `https://support.mozilla.org/${getLangCode(lang)}/kb/site-permissions-panel`
    else if(name === "safari") return `https://support.apple.com/${getLangCode(lang)}/guide/safari/ibrw7f78f7fe`
    else return `https://support.google.com/chrome/answer/114662?hl=${lang}&dark=${darkmode ? 1 : 0}`
}

const testImage = (url) => {
    return new Promise(function imgPromise(resolve, reject) {
        const imgElement = new Image();
        imgElement.addEventListener("load", () => resolve(this));
        imgElement.addEventListener("error", () => reject());
        imgElement.src = url;
    });
}

export const isValidImage = (testUrl, callback, fallbackUrl = "") => {
    const returnValidUrl = async () => {
        let returnUrl = await validUrl;
        callback(returnUrl);
    }
    const validUrl = testImage(testUrl).then(() => {
        return testUrl;
    }).catch(() => { return fallbackUrl; })
    returnValidUrl()
}

export const greeting = (lang, extended) => {
    const time = new Date();
    const hour = time.getHours();
    const weekday = time.getDay();

    if (( weekday === 0) ||
        ( weekday === 6) ||
        ((weekday === 5) && (hour >= 17)) ||
        ((weekday === 1) && (hour <= 1)))
    {
        return greetingData["weekend"][lang] + (extended ? " 🏖" : "");
    } else if (hour <= 5) {
        return greetingData["night"][lang] + (extended ? " 😴" : "");
    } else if (hour < 12) {
        return greetingData["morning"][lang] + (extended ? " 👋" : "");
    } else if (hour < 15) {
        return greetingData["noon"][lang] + (extended ? " 🍀" : "");
    } else if (hour < 18) {
        return greetingData["afternoon"][lang] + (extended ? " 🚀" : "");
    } else if (hour < 23) {
        return greetingData["evening"][lang] + (extended ? " 💫" : "");
    } else {
        return greetingData["night"][lang] + (extended ? " 😴" : "");
    }
}

export const cleanStringToDbKey = (string = "") => {
    // FirebaseError :: Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"
    return string.replace(/[.#$/\[\]]/g,"")
}

export const timeSince = (isoDate, lang) => {
    const leftLangs = ["de", "fr"];

    const langPlacement = (leftLang, lang, timePassed, type) => {
        if (leftLang.indexOf(lang) > -1) {
            return `${timePassedData.passed[lang]} ${timePassed} ${timePassedData[type].plural[lang]}`;
        } else {
            return `${timePassed} ${timePassedData[type].plural[lang]} ${timePassedData.passed[lang]}`;
        }
    }

    const userISOTime = new Date().toISOString()
    const articleTime = new Date(isoDate);
    const userTime = new Date(userISOTime);
    const difference = userTime - articleTime;

    if (difference < 2e3) {
        return timePassedData.second.singular[lang] // 1 second ago
    } else if (difference < 60e3) {
        const timePassed = Math.floor(difference / 1e3); // seconds ago
        const type = "second";
        return langPlacement(leftLangs, lang, timePassed, type);
    } else if (difference < 120e3) {
        return timePassedData.minute.singular[lang] // 1 minute ago
    } else if (difference < 3600e3) {
        const timePassed = Math.floor(difference / 60e3); // minutes ago
        const type = "minute";
        return langPlacement(leftLangs, lang, timePassed, type);
    } else if (difference < 7200e3) {
        return timePassedData.hour.singular[lang] // 1 hour ago
    } else if (difference < 86400e3) {
        const timePassed = Math.floor(difference / 3600e3); // hours ago
        const type = "hour";
        return langPlacement(leftLangs, lang, timePassed, type);
    } else if (difference < 172800e3) {
        return timePassedData.day.singular[lang] // 1 day ago
    } else if (difference < 2592e6) {
        const timePassed = Math.floor(difference / 86400e3); // days ago
        const type = "day";
        return langPlacement(leftLangs, lang, timePassed, type);
    } else if (difference < 5184e6) {
        return timePassedData.month.singular[lang] // 1 month ago
    } else if (difference < 31536e6) {
        const timePassed = Math.floor(difference / 2592e6); // months ago
        const type = "month";
        return langPlacement(leftLangs, lang, timePassed, type);
    } else if (difference < 63072e6) {
        return timePassedData.year.singular[lang] // 1 year ago
    } else {
        const timePassed = Math.floor(difference / 31536e6); // years ago
        const type = "year";
        return langPlacement(leftLangs, lang, timePassed, type);
    }
}

export const publishedDate = (isoDate, lang) => {
    const localDate = new Date(isoDate);
    const intlLang = getLangCode(lang);
    const date = new Intl.DateTimeFormat(intlLang, {
        day: '2-digit',
        month: "2-digit",
        year: "numeric",
        localeMatcher: "lookup"
    }).format(localDate)
    // const day = ("0" + localDate.getDate()).slice(-2);
    // const month = ("0" + (localDate.getMonth() + 1)).slice(-2);
    // const year = localDate.getFullYear();
    // const hour = ("0" + localDate.getHours()).slice(-2);
    // const minute = ("0" + localDate.getMinutes()).slice(-2);

    return `${date} ${timeData.at[lang]} ${time(localDate)}`;
    // return `${day}.${month}.${year} ${timeData.at[lang]} ${hour}:${minute}`;
}

export const validateLang = (browserLang) => {
    const ISOLangCode = browserLang.slice(0,2);
    if (geoData.language[ISOLangCode]) {
        return ISOLangCode;
    } else {
        return "en";
    }
}

export const defaultLanguage = () => {
    const browserLang = window.navigator.languages[0] || [window.navigator.language || window.navigator.userLanguage];
    console.log("DETECTED DEFAULT LANGUAGE", browserLang)
    console.log("VALIDATED DEFAULT LANGUAGE", validateLang(browserLang))
    return validateLang(browserLang);
}

export const getLangCode = (userLang, sdk) => {
    if(!userLang && typeof userLang !== "string") return;

    let concatChar = sdk ? "_" : "-";
    if (userLang !== "en") {
        return `${userLang}${concatChar}${userLang.toUpperCase()}`
    } else {
        return `${userLang}${concatChar}GB`
    }
}

/*window.matchMedia("(prefers-color-scheme: dark)").addEventListener(
    "change", e => e.matches && defaultTheme()
);*/
/*export const defaultThemeListener = (state) => {
    defaultThemeListener.called = true;
    console.log("DEFAULT THEME LISTENER INITIALIZED")
    return window.matchMedia("(prefers-color-scheme: dark)").addEventListener(
        "change", e => {
            console.log("DEFAULT THEME LISTENER TRIGGER")
            e.matches ? Object.assign(state, { darkmode: 1 }) : Object.assign(state, { darkmode: 0 })
        }
    )
}*/

/*export const loadWebWorker = (worker) => {
    const code = worker.toString();
    const blob = new Blob(["(" + code + ")()"], { type: "application/javascript" });
    return new Worker(URL.createObjectURL(blob));
}*/

export const defaultTheme = () => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 1
    } else {
        return 0
    }
}

export const shareContent = (site, lang, id, title, type, desc) => {
    const urlBase = (type === "article") ? "reavi.de/" : "reavi.de/c/";
    const url = encodeURI(`${urlBase + id}`)

    console.log("SHARE CONTENT TYPE", type)
    console.log("SHARE CONTENT URL BASE", urlBase)
    console.log("SHARE CONTENT URL DEF", url)

    const shareCases = {
        twitter: `https://twitter.com/intent/tweet?text="${title}"${shareData.content[type][lang]}: &url=${url}\n\n&via=#reavideNews`,
        reddit: `https://www.reddit.com/submit?url=${url}&title=${title}`,
        facebook: `https://www.facebook.com/dialog/share?app_id=272522984360685&display=page&href=${url}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
        whatsapp: `whatsapp://send/?text=${title}%20${url}`,
        email: `mailto:?subject=${shareData.content.subject[lang]} 📬&body=${shareData.content.greeting[lang]}\n\n📯 "${title}" \n✏️ "${desc}" \n${shareData.content[type][lang]} → ${url}.\n©${(new Date()).getFullYear()} reavide KlG.`
    }

    return encodeURI(shareCases[site])
}

export const resizeApp = (parameterVal, appSize) => {
    if(typeof parameterVal === "object" && !parameterVal.target["window"]) return appSize;
    let innerWidth = (typeof parameterVal === "object") ? parameterVal.target.innerWidth : parameterVal;

    if(innerWidth <= 1100) { // 1300
        return 3;
    } else if(innerWidth <= 1360) { // 1400
        return 2;
    } else if(innerWidth <= 1540) {
        return 1;
    } else {
        return 0;
    }
}

export const calcBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const isMobile = () => {
    const agentData = navigator.userAgent || navigator.vendor || window?.opera || "";
    const firstRegex = new RegExp(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i);
    const secondRegex = new RegExp(/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br[ev]w|bumb|bw-[nu]|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do[cp]o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly[-_]|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-[mpt]|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c[- _agpst]|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac[ -\/]|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja[tv]a|jbro|jemu|jigs|kddi|keji|kgt[ \/]|klon|kpt |kwc-|kyo[ck]|le(no|xi)|lg( g|\/[klu]|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t[- ov]|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30[02]|n50[025]|n7(0[01]|10)|ne([cm]-|on|tf|wf|wg|wt)|nok[6i]|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan[adt]|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c[-01]|47|mc|nd|ri)|sgh-|shar|sie[-m]|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel[im]|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c[- ]|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i);

    return window.navigator?.userAgentData?.mobile || firstRegex.test(agentData.substr(0)) || secondRegex.test(agentData.substr(0,4));
};

export const isValidEmail = (string) => {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/.test(string)
}

export const isDataURL = (string) => {
    if(typeof string !== "string") return false;
    isDataURL.regex = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i;
    return !!string.trim().match(isDataURL.regex);
}

export const flattenString = (string = "", basic, lowerCase) => {
    let resultString = (lowerCase ? string.toLowerCase() : string).replaceAll("–", "-").trim();
    if(basic) return resultString.replace(/['"“”‘`´«»¶ﬁ^<>‹≤≥°\[\]{}()*+•,;=≠±∞.~:|\\/⁄¿?¡!]/g, "")
    return resultString.replace(/[^a-z0-9\-_#%&$£€?]/g,"")
}

export const containsString = (string, query) => {
    const cleanString = flattenString(string, false, true);
    const cleanQuery = flattenString(query, false, true);

    return cleanString.includes(cleanQuery);
}

export const canUseWebP = () => {
    let elem = document.createElement("canvas");
    CSS.supports("webp")
    if (!!(elem.getContext && elem.getContext("2d"))) {
        return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
    return false;
}

export const generateCdnUrl = (path) => {
    return `https://storage.googleapis.com/reavide/${path}`
}

export const generateImageUrl = (mediaId, type, fallbackUrl = "", callback) => {
    if(!mediaId) return fallbackUrl;

    let returnUrl;
    if(type === "list") {
        if(!canUseWebP()) {
            returnUrl = generateCdnUrl(`news/lists/${mediaId}.png`)
        } else returnUrl = generateCdnUrl(`news/lists/${mediaId}.webp`)
    } else if(type === "image") {
        if(!canUseWebP() || !mediaId) {
            returnUrl = `${fallbackUrl}`
        } else returnUrl = generateCdnUrl(`news/articles/images/${mediaId}.webp`)
    }

    if(!callback) return returnUrl;
    return isValidImage(returnUrl, (url) => callback(url), fallbackUrl)
}

export const sanitizeUrl = (url, rmParams) => {
    if(rmParams === "all") {
        if(url.includes("?")) return url.split("?")[0]
        if(url.includes("&")) return url.split("&")[0]
    }
    if(url.includes("?utm_")) return url.split("?utm_")[0]
    if(url.includes("&utm_")) return url.split("&utm_")[0]
    return url;
}

export const sanitizeStringToUrl = (string) => {
    let resultString = flattenString(string, true, true)
        .replace(/\u00a0/g, " ")
        .replace(/\s/g,"_")
        .replace(/[äæꬱ]/g,"ae")
        .replace(/[öœ]/g,"oe")
        .replace(/[ü]/g,"ue")
        .replace(/[₠]/g,"ce")
        .replace(/[ß]/g,"ss")
        .replace(/[àáåâǻḁẚăặắằẳẵȃậấầẫẩảǎⱥȧǡạǟȁāãąaᶏᶐ𝔞𐌀ᚨ𐌰𐤀աÅ∀ɐɒ]/g,"a")
        .replace(/[Ƀƀḃḅḇɓᵬᶀβꞗƃвⲃ𐤁𝔟]/g,"b")
        .replace(/[ćçĉčċḉƈc̈ȼↄ𝇍ℭ𝖈ɕȝγꜿ℃]/g,"c")
        .replace(/[đꟈɗḋḍḑḓďḏᵭᶁᶑɖðꝺƌȡ∂𐌃]/g,"d")
        .replace(/[èéëêĕḝȇềếểễệẻḙěɇėẹȅēḕḗẽḛęȩe̩ᶒɛᶓɜəǝɘɝε℮𝑒ꬲє𐌄ꬴ𐌴∃]/g,"e")
        .replace(/[Ꞙꞙꜰⅎϝ𐌅𐤅℉₣ꟻꝼƒḟᵮᶂ]/g,"f")
        .replace(/[ǵǥĝǧğģɠġḡꞡᶃցꬶɢʛꝿꝽᵹɣȝгᵷγ𐤂ᴳᵍ]/g,"g")
        .replace(/[ĥȟħḩⱨẖḥḣḧḫꞕʜꟸɦʰʱɥᶣₕʮʯƕⱶꟶ𐤇𐌇ᚻһhℏ]/g,"h")
        .replace(/[ìíïîịĭǐɨḯȉįĮ́ĪīᶖỉȋĨĩḭᶤi̇̀ɪᵻᶦᶧꟾꟷ𐤉ιⲓ𐌹ᛁ]/g,"i")
        .replace(/[ĵǰɉj̇̃𐤉ȷᶡᶨʝɟʄځ]/g,"j")
        .replace(/[ƙꝁḱǩḳķᶄⱪḵκϰ𐤊кꞣʞₖꝃꝅᴷᵏᴋ]/g,"k")
        .replace(/[ĺłľḹl̃ļŀḷḻḽƚⱠⱡꬷꬸꬹꭝꭞꝇᶅᶩᶪᶫꞁȴₗʟɫɬɭɺɮꞎˡ𐤋ℓꝉ𐌋ᛚלلܠࠋ𐡋ለ]/g,"l")
        .replace(/[ḿṁṃm̃ᵯɱɰⱮᴍᴟᴹᵐᵚꟺₘɯꟽꟿ𐌼ᛗ𐌌ⲙм𐤌МӍ𐌼מםمﬦⰏࠌ]/g,"m")
        .replace(/[ńñňǹṅṇņṉṋꞥᵰᶇŋɲƞɳɴᴎᴺᴻᵑꬻȵꞑ𐌽𐌍ⲛ𐤍ܢނՆնմࠍነᚾᎻᏁᏃ]/g,"n")
        .replace(/[øǿᶱöȫóòôốồổỗộǒőŏȏȯȱọɵơớờỡợởỏōṓօṑõȭṍṏǫȍo̩ܥࠏዐࡘჺ𐤏ǭꝍⱺɔꬿꬽ𐌏ꬾꝋ∅ᱳᱜᱣ]/g,"o")
        .replace(/[p𐤐ṕṗᵽƥᵱᶈպп𐌐πꝑꝓꝕꟼ℘♇]/g,"p")
        .replace(/[ʠɋq̃ƣꞯꝗꝙ𐤒ϙ𐌒ԛ℺]/g,"q")
        .replace(/[ℛŕɍřŗṙȑȓṛṝṟꞧɽr̃ᵲꭨᵳᶉɹɺɾɻʀʁʶ˞ʴɼɿ𐍂ᴙᵣꭅꭆꭇꭈꭉꭊꭋꭌⱹꝛꞃ𐤓ρ𐌓ᚱ𐍂р]/g,"r")
        .replace(/[ſẝₛƨꜱˢʃɧʂꞅśṡẛṩṥs̩ꞩꟉꟊŝṧšşșᶊȿᵴᶳՍ𐤔Σϲⲥ𐌔ᛊᛋᛌ𐍃ṣ]/g,"s")
        .replace(/[ⶊꞇʇẗᴛₜȶ𐤕τⲧт𐍄𐌕ᛏፐተťṫţŢꞱṭʈțƫṱṯŧⱦƬƭᵵᶵ]/g,"t")
        .replace(/[ùúû∪∩ᴝꞿᶸꭟꭑꭏꭎᵙᴞᵤᵁ𐤅ʊυɥŭʉᵾᶶꞸꞹụüǜǘǚǖṳṷǔȗűưứừửỰựỮủū̀́ṻ̃ũṹṵᶙųȕů]/g,"u")
        .replace(/[ỽⱱʋᶹʌᶺⱴᴠᵥᵛυ∨уѵṽṿᶌ℣ꝟ]/g,"v")
        .replace(/[ⱳꝡꟃװʷᴡʍɯɰẃẁŵẅẇẉẘ]/g,"w")
        .replace(/[χꭖꭗꭘꭙˣₓхⲭ𐍇𐌗ᚷξẍẋx̂ᶍ]/g,"x")
        .replace(/[ʎʏꭚʸỿⲩуѵүұⓨýỳŷÿỹẏỵẙỷȳɏƴ]/g,"y")
        .replace(/[ʒźẑžżẓẕƶᵶꟆᶎⱬζ𐤆з𐌶𐌆ⲍᶻᶼᶽʑʐɮꝣɀȥℤzℨ]/g,"z")
        .replace(/(_)\1+/g, "_") // .replace(/[^\w\s_-]|(_)(?=\1)/gi,"")) | .replace(/[^\w\s]|(_)(?=\1)/g,"_")
        .replace(/[^a-z0-9\-_#%&$£₤€₱฿₢₵₲₸₷₳₮₭₩ℳ₥¥™©®℠℗₡₽₿₦∫]/g,"")
    return encodeURI(flattenString(resultString, false, false))
}

export const addScript = (src, addAttr, addVal, callback) => {
    let scriptElement = document.createElement("script");
    scriptElement.setAttribute("defer", "");
    scriptElement.setAttribute("async", "");
    scriptElement.setAttribute("src", src);
    if(addAttr) scriptElement.setAttribute(addAttr, addVal);
    if(callback) scriptElement.onload = callback;
    document.body.appendChild(scriptElement);
}

export const scriptPresent = (scriptRef) => {
    let scriptTags = document.getElementsByTagName("script");

    if(scriptRef) {
        for (let scriptTag of scriptTags) {
            let scriptSrc = scriptTag.getAttribute("src");
            if(scriptSrc.includes(scriptRef)) return true;
        }
    }
    return false;
}

export const loadTopBar = (elongated, darkmode) => {
    let el = document.createElement("div");
    let defaultWidth = Math.floor(Math.random() * (40 - 10 + 1) + 10);
    el.style.width = `${defaultWidth}vw`;
    el.style.height = darkmode ? "2px" : "3px";
    el.style.position = "fixed";
    let transitionTrigger = (Math.random() * (2.5 - 1) + 1) / 10;
    let transitionSteps = Math.floor(Math.random() * (3 - 2 + 1) + 2);
    el.style.transition = `${transitionTrigger}s steps(${transitionSteps}, jump-both)`;
    el.style.zIndex = "1111";
    el.style.backgroundColor = color.blue;
    el.style.top = "0";
    el.style.left = "0";
    document.body.appendChild(el);
    let timeoutTrigger = Math.floor(((Math.random() * (10 - 7) + 7)) * 100) / 10;
    setTimeout(() => el.style.width = "100vw", timeoutTrigger)
    let removeTrigger = (transitionTrigger * 1000) + timeoutTrigger + (elongated ? 450 : 250);
    setTimeout(() => document.body.removeChild(el), removeTrigger)
}

export const removeScript = (scriptRef, windowMethod) => {
    let scriptTags = document.getElementsByTagName("script");

    if(scriptRef) {
        for (let scriptTag of scriptTags) {
            let scriptSrc = scriptTag.getAttribute("src");
            if(scriptSrc.indexOf(scriptRef) !== -1) scriptTag.remove();
        }
    }
    if(windowMethod) delete window[windowMethod]
}

export const removeStyle = (styleRef) => {
    let styleTags = document.getElementsByTagName("style");

    if(styleRef) {
        for (let styleTag of styleTags) {
            let scriptHTML = styleTag.innerHTML;
            if (scriptHTML.indexOf(styleRef) !== -1) styleTag.remove();
        }
    }
}

export const removeIframe = (iframeRef) => {
    let iframeTags = document.getElementsByTagName("iframe");

    if(iframeRef) {
        for (let iframeTag of iframeTags) {
            let scriptTitle = iframeTag.getAttribute("title");
            if(scriptTitle.indexOf(iframeRef) !== -1) iframeTag.remove();
        }
    }
}

export const getUrlQueries = (url) => {
    if(!url) return {}

    let query = url.substr(1);
    let result = {};
    query.split("&").forEach((part) => {
        let item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
}

export const encodeURIQuery = (query, type) => {
    if(!query) return ""
    if(type === "reverse") {
        return query.toString().replace(/(\+)(?=\1)/g, "").replaceAll("+", " ").trim()
    } else return query.toString().trim().replace(/(\s)(?=\1)/g, "").replaceAll(" ", "+")
}

export const getURIQueryResultKey = (query, attribute) => {
    return "q=" + encodeURIQuery(query) + (attribute ? `&a=${attribute}` : "");
}

export const validateSearchAttribute = (attribute, lang = "", algoliaSpec) => {
    const acceptAttributes = {
        topic: "category",
        flair: `${lang}.content.flair`,
        author: "author",
        tag: `${lang}.content.tags`
    }
    if(!attribute || !acceptAttributes[attribute]) return "";
    else {
        if(algoliaSpec) return acceptAttributes[attribute];
        else return attribute;
    }
}

export const truncateArray = (loopArray) => {
    let resultArr = [];
    for(let i = 0; i < loopArray?.length ?? 0; i++) {
        if(i < 2) resultArr.push(loopArray[i])
        else {
            resultArr.push({ remainingNodes: loopArray.length - 2 })
            break;
        }
    }
    return resultArr;
}

export const sanitizeForTraceLog = (action) => {
    const clonedAction = cloneDeep(action);
    const returnArray = [];

    switch(action.type) {
        case "persist/REHYDRATE":
            let progress = clonedAction?.payload?.persistData?.articleProgress;
            if(!isEmpty(progress)) {
                let truncObj = {};
                let passArr = Object.entries(progress).map(entryArr => ({ [entryArr[0]]: entryArr[1] }));
                truncateArray(passArr).forEach(item => Object.assign(truncObj, item));
                Object.assign(clonedAction?.payload?.persistData, { articleProgress: truncObj });
            }
            delete clonedAction?.payload?.userLogin?.userAuth?.providerData
            return clonedAction;
        case "FETCH_BREAKING_DATA_SUCCESS":
            if(Array.isArray(action.payload)) {
                clonedAction.payload.forEach((breakingObj) => {
                    delete breakingObj.content;
                    delete breakingObj.metadata;
                })
            } else if(!isEmpty(action.payload)) {
                delete clonedAction.content;
                delete clonedAction.metadata;
            }
            return clonedAction;
        case "CHECK_USER_SESSION_SUCCESS":
            const { uid, providerId, accessToken, auth } = clonedAction.payload || {};
            return Object.assign(clonedAction, {
                payload: { uid, providerId, accessToken, auth: auth?.config }
            })
        case "FETCH_USER_DATA_SUCCESS":
            const { userId, studEligible, loginEnabled, subscriptionType } = clonedAction.payload || {};
            return Object.assign(clonedAction, {
                payload: { userId, studEligible, loginEnabled, subscriptionType }
            })
        case "FETCH_ARTICLE_DATA_SUCCESS":
            clonedAction.payload?.forEach(({ articleId, contentLang, metadata }) => {
                returnArray.push({ articleId, contentLang, type: metadata.type })
            })
            return Object.assign(clonedAction, { payload: returnArray })
        case "FETCH_USER_HISTORY_SUCCESS":
            if(Array.isArray(action.payload)) {
                Object.assign(clonedAction, { payload: truncateArray(clonedAction.payload) })
            }
            return clonedAction
        case "UPLOAD_IMAGE_SUCCESS":
            const { payload, fallback } = clonedAction || {};
            return Object.assign(clonedAction, { payload: payload?.split(",")[0], fallback: fallback.split(",")[0] })
        case "FETCH_USER_BOOKMARKS_SUCCESS":
        case "FETCH_USER_CHANNELS_SUCCESS":
            if(clonedAction.fetchKey === "general") Object.assign(clonedAction, { payload: "truncated" })
            return clonedAction
        case "FETCH_USER_LISTS_SUCCESS":
        case "FETCH_USER_COLLECTIONS_SUCCESS":
            if(typeof clonedAction.addData !== "string") Object.assign(clonedAction, { payload: "truncated" })
            return clonedAction
        case "EMAIL_SIGN_UP_START":
        case "EMAIL_SIGN_IN_START":
            Object.assign(clonedAction, { payload: "truncated" })
            return clonedAction
        case "SEND_FEEDBACK":
            Object.assign(clonedAction, { feedback: "truncated" })
            return clonedAction
        default:
            return clonedAction;
    }
}

export const getCurrentPath = ({ pathname, search }) => {
    if(!pathname) return "/";
    if(pathname === "/search") {
        const params = new URLSearchParams(search);
        const query = params.get("q");
        const attribute = params.get("a");
        return pathname + (query ? `?q=${query}` : "") + (attribute ? `&a=${attribute}` : "");
    } else return pathname;
}

// ±“#Ç[]|{}≠œ∑€®†Ω°¡øπ§‘åß∂ƒ@ªº∆¬¢æ¶≤¥≈©√∫~~«…–ﬁ

/*const preventDoubleClickSelection = (ref) => {
    if(!ref.current) return;
    ref.current.addEventListener('mousedown', function (event) {
        if (event.detail > 1) event.preventDefault();
    }, false);
}*/

//////// [LATER] GENERATE DYNAMIC BLOBS PROJECT

/*const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType}),
        url = URL.createObjectURL(blob),
        img = new Image();
    console.log("FUNCTION BLOB: ", blob, "BLOB URL", url, "BLOB IMG", img)
    return url;
    // return new Blob(byteArrays, { type: contentType });
};

export const generateBlob = (imgUrl, contentType) => {
    const base64Img = Buffer.from(imgUrl, "binary").toString("base64")
    const blob = b64toBlob(base64Img, contentType)
    console.log("BLOB EXPERIMENT", blob)
    return blob;
}*/

/*const blob = new Blob([encodeURI(imgUrl)], {type: "image/png"}),
      url = URL.createObjectURL(blob),
      img = new Image();

img.onload = function() {
    URL.revokeObjectURL(this.src);     // clean-up memory
    document.getElementById("modal").appendChild(this);   // add image to DOM
}

img.src = url;*/

/*const isCanvasBlank = (canvas) => {
    const contextX = canvas.getContext('2d');
    setContext(contextX)
    const pixelBuffer = new Uint32Array(
        contextX.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some(color => color !== 0);*/