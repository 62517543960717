import styled from "styled-components";
import { color, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";
import { Link } from "react-router-dom";

export const IndicatorContainer = styled.section`
    width: auto;
    height: auto;
    text-align: left;
    justify-content: left;
    margin: ${props => props.type === "timeline" ? "0 0 50px 60px" : "0 0 30px 0"};
    position: relative;

    @media only screen and (max-width: 750px) {
        margin: ${props => props.type === "timeline" ? "0 0 30px 60px" : "0 0 30px 0"};
    }
`;

export const IndicatorTitleContainer = styled.h1`
    font-size: 60px;
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    padding-top: 70px;
    padding-bottom: ${props => (props.icon && !props.link) ? "17px" : "10px"};
    white-space: pre-wrap;

    @media only screen and (max-width: 750px) {
        font-size: 40px;
        padding-bottom: 5px;
    }
`

export const IndicatorSubNav = styled(Link)`
    position: relative;
    margin-right: 25px;
    color: ${props => props.active ? textColor(props.darkmode) : props.darkmode ? color.gray3 : color.gray4};
    cursor: pointer;

    @media only screen and (max-width: 750px) {
        margin-right: 15px;
    }
`

export const IndicatorSubTitleContainer = styled.div`
    font-size: 16px;
    font-family: ${font.pulpDisplayBold};
    letter-spacing: 0.02em;
    width: max-content;
    color: ${props => props.darkmode ? color.gray3 : color.gray2};
`

export const IndicatorIconContainer = styled(Icon)`
    height: ${props => props.link ? "65px" : "60px"};
    width: ${props => props.link ? "65px" : "auto"};
    justify-content: center;
    margin: ${props => props.link ? props.icon2 ? "10px 80px 0 0 " : "10px 2px 0 0" : "0 10px 0 0"};
    user-select: ${props => props.link ? "auto" : "none"};
    display: block;
    cursor: ${props => props.link ? "pointer" : "auto"};

    &:hover {
        border-radius: ${props => props.link ? "50%" : "initial"};
        background-color: ${props => props.link ? props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)" : "transparent"};
        box-shadow: 0 0 0 2px ${props => props.link ? props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)" : "transparent"};
    }
    ${props => props.link ? "right: 0; position: absolute;" : null};

    @media only screen and (max-width: 750px) {
        height: 45px;
        width: 45px;
        margin: ${props => props.link ? props.icon2 ? "5px 50px 0 0 " : "5px 2px 0 0" : "0 10px 0 0"};
    }
`;