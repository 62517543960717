import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { textColor } from "../general.utils";

export const ChannelCategoryContainer = styled.section`
    width: 100%;
    height: auto;
    display: table;
    padding: 25px ${props => props.type ? "25px" : "2px"} 0 ${props => props.type ? "25px" : "40px"};
    margin-bottom: 60px;
    text-align: left;
    flex-wrap: wrap-reverse;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 750px) {
        padding: 20px ${props => props.type ? "20px" : "2px"} 0 ${props => props.type ? "20px" : "30px"};
    }
    
    &::after {
        content: "";
        position: absolute;
        top: 0;
        margin-left: ${props => props.type ? "-25px" : "-40px"};
        z-index: -1;
        width: ${props => props.width ?? "100%"};
        height: ${props => props.height};
        background-color: ${props => props.darkmode ? color.gray2 : color.white};
        border-radius: ${radius.largeRadius};

        @media only screen and (max-width: 750px) {
            margin-left: ${props => props.type ? "-20px" : "-30px"};
        }
    }
`;

export const ChannelCategoryContainerTitle = styled.h1`
    color: ${props => textColor(props.darkmode)};
    font-size: ${props => props.type ? "34px" : "40px"};
    font-family: ${font.pulpDisplayBold};
    height: 100%;
    display: block;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 ${props => props.type ? "25px" : "30px"} ${props => props.type ? "15px" : 0};

    @media only screen and (max-width: 750px) {
        font-size: 30px;
        margin: 0 0 ${props => props.type ? "20px" : "25px"} ${props => props.type ? "10px" : 0};
    }
`;

export const ChannelCategoryCardsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${props => props.numColumns}, minmax(0, 1fr));
    row-gap: 10px;
    column-gap: 10px;
`;

export const ChannelCategoryListContainer = styled.div`
    width: 100%;
    display: grid;
    row-gap: 15px;
`;