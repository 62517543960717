import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory, useLocation } from "react-router-dom";

import { LayoutContext } from "../../App";
import { date, greeting, textColor, categoryColor } from "../general.utils";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";

import {
    IndicatorContainer,
    IndicatorTitleContainer,
    IndicatorSubNav,
    IndicatorSubTitleContainer,
    IndicatorIconContainer
} from "./indicator.styles";

const Indicator = ({ pageLabel, pageLabelEmoji, subLabel, lang, darkmode, icon, icon2, link, type, subNav, category }) => {
    const { mainWidth } = useContext(LayoutContext);
    const extended = mainWidth > 600;

    const [greetingPhrase, setGreetingPhrase] = useState(greeting(lang, extended))
    const [currentDate, setCurrentDate] = useState(date(lang).toUpperCase())
    const history = useHistory();
    const location = useLocation().pathname;

    const handleIconClick = (event, path) => {
        event.stopPropagation();
        if(link) history.push(`/${path}`);
    }

    useEffect(() => {
        let greetingTimeout;
        const adjustGreeting = () => {
            if (!pageLabel) setGreetingPhrase(greeting(lang, extended))
            if (!(!icon && link)) setCurrentDate(date(lang).toUpperCase())
            perpetualGreeting();
        }
        const perpetualGreeting = () => {
            greetingTimeout = setTimeout(() => {
                if(window.requestIdleCallback) {
                    window?.requestIdleCallback(() => adjustGreeting())
                } else adjustGreeting();
            }, 60000)
        }
        if(!pageLabel || !(icon && !link)) perpetualGreeting()

        return () => clearTimeout(greetingTimeout)
    }, [])

    return (
        <IndicatorContainer type={type}>
            <IndicatorTitleContainer
                aria-label={"Greetings"}
                darkmode={darkmode}
                link={link}
                icon={icon}
            >
                {
                    icon ? (
                        <IndicatorIconContainer
                            name={icon}
                            size={extended ? 60 : 45}
                            link={link}
                            color={category ? categoryColor(category, darkmode) : textColor(darkmode)}
                            darkmode={darkmode}
                            onClick={event => handleIconClick(event, icon)}
                        />
                    ) : null
                }
                {
                    icon2 ? (
                        <IndicatorIconContainer
                            name={icon2}
                            size={extended ? 60 : 45}
                            link={link}
                            color={category ? categoryColor(category, darkmode) : textColor(darkmode)}
                            darkmode={darkmode}
                            icon2={icon2}
                            onClick={event => handleIconClick(event, icon2)}
                        />
                    ) : null
                }
                {
                    subNav ? Object.values(pageLabel).map(navPoint => (
                        <IndicatorSubNav
                            active={location.includes(navPoint.url, 0) ? 1 : 0}
                            darkmode={darkmode}
                            to={`/${navPoint.url}`}
                            key={navPoint.url}
                        >
                            <span>{ navPoint.desc[lang] }</span>
                        </IndicatorSubNav>
                    )) : <span>{ pageLabel ? pageLabel + " " + (extended ? (pageLabelEmoji || "") : "") : greetingPhrase }</span>
                }
            </IndicatorTitleContainer>
            <IndicatorSubTitleContainer
                aria-label={"Current Date"}
                darkmode={darkmode}
            >
                <span>{subLabel || currentDate}</span>
            </IndicatorSubTitleContainer>
        </IndicatorContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(Indicator);