import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { RecentContext } from "../../context/recent.context";
import { selectArticleList } from "../../redux/articleData/articleData.selectors";
import { compactArticleData } from "../general.utils";

import { RecentlyAccessedContainer } from "./recentlyAccessed.styles";
import PreviewCard from "../previewCard/previewCard.component";
import ListCard from "../listCard/listCard.component";

const RecentlyAccessed = ({ articleList }) => {
    const { recentList } = useContext(RecentContext)
    const { mainWidth } = useContext(LayoutContext)


    const recentComponents = {
        list: ListCard,
        article: PreviewCard
    }

    return (
        <RecentlyAccessedContainer numColumns={mainWidth > 600 ? 2 : 1}>
            {
                recentList && recentList.map(recentItem => {
                    let RecentComponent = recentComponents[recentItem?.type]
                    if(recentItem.type === "list") {
                        return (
                            <RecentComponent
                                key={recentItem.listId}
                                list={recentItem}
                                preview
                            />
                        )
                    } else if(recentItem.type === "article") {
                        return (
                            <RecentComponent
                                key={recentItem.articleId}
                                articleLog={recentItem}
                                article={compactArticleData(articleList[recentItem.articleId])}
                                minimal={"true"}
                            />
                        )
                    }
                })
            }
        </RecentlyAccessedContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    articleList: selectArticleList
})

export default connect(mapStateToProps)(RecentlyAccessed);