import styled from "styled-components";
import { color, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";
import { Link } from "react-router-dom"

export const ArticleInfoWrapper = styled.div`
    margin: 30px 0;
    width: inherit;
    height: auto;
  
    &>div:first-child {
        margin-bottom: 10px;
    }
`;

export const ArticleInfoContainer = styled.div`
    width: inherit;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 2px;
`;

export const ArticleInfoIconContainer = styled.div`
    height: auto;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    & > a {
        height: 25px;
    }
`
export const ArticleInfoIcon = styled(Icon)`
    height: auto;
    width: auto;
    border-radius: 3px;
    flex-shrink: 0;
    
    ${props => props.link ? `&:hover {
        border-radius: 50px;
        box-shadow: 0 0 0 2px ${color.blue};
    }` : null}
`;

export const ArticleInfoTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    width: auto;
    margin-left: 10px;
    flex-grow: 1;
`

export const ArticleInfoTextContainer = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    word-break: break-word;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: ${props => textColor(props.darkmode)};
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
    row-gap: 5px;
    height: inherit;
    width: 100%;
  
    &>span:first-child {
        margin-right: 10px;
    }
    &>span:nth-child(2) {
        background-color: ${color.blue};
        box-shadow: 0 0 0 1px ${color.blue};
        letter-spacing: 0.02em;
        vertical-align: bottom;
        justify-content: center;
        flex-direction: column;
        color: ${color.white};
        width: max-content;
        display: inline-block;
        border-radius: 4px;
        padding: 0 6px;
    }
    &>span:nth-child(2):first-letter {
        font-family: ${props => props.round ? "inherit" : font.interMedium};
    }
`

export const ArticleInfoTextAuthor = styled(Link)`
    text-decoration: underline;
    text-underline-offset: 2px;
    text-underline-position: below;
    text-decoration-color: ${color.blue};
    text-decoration-thickness: 1.5px;
    color: ${props => textColor(props.darkmode)};
    cursor: pointer;
  
    &:hover {
        /*color: ${color.gray4};
        text-decoration-color: ${color.blue + "99"};*/
        text-decoration-thickness: 2px;
        opacity: 0.75;
    }
`

export const ArticleInfoHighlight = styled.span` 
    color: ${color.blue};
    font-family: ${font.pulpDisplayBold};
`