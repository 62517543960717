import { takeLatest, call, put, all, select } from "redux-saga/effects";

import ArticleRefActionTypes from "./articleRef.types";

import { selectArticleRefPage } from "./articleRef.selectors";
import { fetchArticleRefSuccess, fetchArticleRefFailure } from "./articleRef.actions";
import { fetchArticleDataStart } from "../articleData/articleData.actions";
import { newsAPI } from "../backendAPI";

export function* fetchArticleRefStartAsync(action) {
    const refKey = action.refKey;
    const { userId, lang } = action.payload;
    const refPage = yield select(selectArticleRefPage(refKey));
    const page = (refPage || refPage === 0) ? refPage + 1 : 0;

    try {
        const response = yield newsAPI.get(`/articles/${refKey}`, {
            headers: {
                user: userId,
                lang: lang,
                page: page,
                limit: "10"
            }
        }).catch((err) => console.log("BACKEND FETCH FAILED", err))
        const articleRefList = yield response.data.content;
        yield put(fetchArticleDataStart("", articleRefList))
        yield put(fetchArticleRefSuccess(articleRefList, page, refKey))
    } catch (error) {
        yield put(fetchArticleRefFailure(error.message, refKey))
    }
}

export function* fetchArticleRefStart() {
    yield takeLatest(
        ArticleRefActionTypes.FETCH_ARTICLE_REF_START,
        fetchArticleRefStartAsync
    )
}

export function* articleRefSagas() {
    yield all([
        call(fetchArticleRefStart)
    ])
}
