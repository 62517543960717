import React, { Fragment, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { reducedAccView } from "../../pages/main/accountPage/account.utils";

import {
    AccountHighlightContainer,
    AccountHighlightTitleContainer,
    AccountHighlightNumContainer,
    AccountHighlightNumStandard,
    AccountHighlightNumTrend,
    AccountHighlightNumExtra
} from "./accountHighlight.styles";

const AccountHighlight = ({ darkmode, title, metric, color, type, prefix = "", suffix = "" }) => {
    const { mainWidth } = useContext(LayoutContext);
    const reduced = reducedAccView(mainWidth);

    return(
        <AccountHighlightContainer darkmode={darkmode} reduced={reduced}>
            <AccountHighlightTitleContainer darkmode={darkmode} reduced={reduced}>
                <span>{title}</span>
            </AccountHighlightTitleContainer>
            <AccountHighlightNumContainer color={color}>
                {
                    type === "trend" ? (
                        <Fragment>
                            <p>
                                <AccountHighlightNumExtra
                                    length={metric.toString().length}
                                    reduced={reduced}
                                >
                                    {prefix}
                                </AccountHighlightNumExtra>
                                <AccountHighlightNumTrend
                                    length={metric.toString().length}
                                    reduced={reduced}
                                >
                                    {metric}
                                </AccountHighlightNumTrend>
                                <AccountHighlightNumExtra>
                                    {suffix}
                                </AccountHighlightNumExtra>
                            </p>
                        </Fragment>
                    ) : (
                        <AccountHighlightNumStandard
                            length={metric.toString().replace('.','').length}
                            reduced={reduced}
                        >
                            {metric}
                        </AccountHighlightNumStandard>
                    )
                }
            </AccountHighlightNumContainer>
        </AccountHighlightContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(AccountHighlight);