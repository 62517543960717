import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const ListSelectionContainer = styled.div`
    width: 100%;
    height: ${props => props.minimal ? "50px" : "60px"};
    border-radius: ${/*props => props.mapped ? "0" : */radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${props => props.mapped ? "0" : "20px"};
    padding: 0 15px;
    position: relative;
    cursor: ${props => props.mapped ? "pointer" : "default"};

    ${props => props.mapped ? `&:hover { background-color: ${props.darkmode ? color.blueActiveDark : color.blueActiveLight}; }` : null}
`

export const ListSelectionPictureContainer = styled.div`
    height: ${props => props.minimal ? "34px" : "40px"};
    width: ${props => props.minimal ? "34px" : "40px"};
    border-radius: ${props => props.minimal ? "8px" : "50%"};
    margin-right: 15px;
`

export const ListSelectionContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${font.pulpDisplayBold};
    align-items: flex-start;
    height: max-content;
    width: auto;
`

export const ListSelectionTitleContainer = styled.div`
    font-size: 20px;
    color: ${props => textColor(props.darkmode)}
`

export const ListSelectionDescContainer = styled.div`
    font-size: 16px;
    line-height: 22px;
    color: ${color.gray3};
    letter-spacing: 0.025em;

    &>span:nth-child(0n + 2) {
        color: ${color.blue};
    }
    &>span:nth-child(0n + 3) {
        padding: 0 2px;
    }
`

export const ListSelectionDescIcon = styled(Icon)`
    height: 16px;
    margin: 0 -2px -2px 0;
`

export const ListSelectionIcon = styled(Icon)`
    height: inherit;
    justify-content: center;
    margin: 0 15px;
    position: absolute;
    right: 0;
`