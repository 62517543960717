import { createSelector } from "reselect";
import { defaultTheme } from "../../components/general.utils";

const selectPersistData = state => state.persistData;

export const selectDarkmode = createSelector(
    [selectPersistData],
    persistData => (persistData.darkmode !== null) ? persistData.darkmode : defaultTheme()
)

export const selectTheme = createSelector(
    [selectPersistData],
    persistData => persistData.theme
)

export const selectArticleProgress = createSelector(
    [selectPersistData],
    persistData => persistData.articleProgress
)

export const selectArticleReadingProgress = (articleId) =>
    createSelector(
        [selectArticleProgress],
        articleProgress => articleProgress ? articleProgress[articleId] : 0
    )
