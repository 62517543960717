import { createSelector } from "reselect";
import { compactArticleData } from "../../components/general.utils";

const selectArticleData = state => state.articleData;

export const selectArticleList = createSelector(
    [selectArticleData],
    articleData => articleData.articleList
)

export const selectArticleFetching = createSelector(
    [selectArticleData],
    articleData => articleData.articleDataFetching
)

export const selectArticleError = createSelector(
    [selectArticleData],
    articleData => articleData.articleDataError
)

export const selectArticlePreview = createSelector(
    [selectArticleData],
    articleData => articleData.articlePreview
)

export const selectArticleArrayForPreview = (articleRefArray) =>
    createSelector(
        [selectArticleList],
        articleList => (articleList && articleRefArray) ? previewArray(articleList, articleRefArray) : []
    )

export const selectArticle = (articleId) =>
    createSelector(
        [selectArticleList],
        articleList => (articleList && (Object.keys(articleList).length !== 0)) ?
            articleList[articleId] : undefined
    )

const previewArray = (articleList, articleRefArray) => {
    let resultArr = []
    let omittedTypes = ["deleted", "undefined"];
    articleRefArray.forEach(articleRef => {
        if(articleList[articleRef.articleId] && !omittedTypes.includes(articleList[articleRef.articleId]?.metadata.type)) {
            resultArr.push(compactArticleData(articleList[articleRef.articleId], articleRef))
        }
    })
    return resultArr;
}

/*
const articleForPreview = (articleList, refKey, articleRefArray) => {
    console.log("ArticleList for preview", articleList)
    console.log("ArticleRefKey for preview", refKey)
    console.log("ArticleRefArray for preview", articleRefArray)
    console.log("ARTICLE LIST REF KEY TEST", articleList)

    articleRefArray.map(articleRef => {
        console.log("ARTICLE REF IN PREVIEW", articleRef.articleId)
        console.log("ARTICLE LIST WITH KEY", articleList[articleRef.articleId])
        const { articleId, source, content, metadata } = articleList[articleRef.articleId]
        console.log("DESTRUCTURED ARTICLE ID", articleId)
        console.log("DESTRUCTURED SOURCE", source)
        console.log("DESTRUCTURED CONTENT", content)
        console.log("DESTRUCTURED METADATA", metadata)
        return {
            articleId: articleId,
            newspaper: source.newspaper,
            title: content.title,
            catchline: content.catchline,
            image: content.images[0],
            category: metadata.category,
            publishedAt: metadata.publishedAt,
            similarArticles: metadata.similarArticles,
            live: metadata.live
        }
    })
}*/
