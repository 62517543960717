import UserSettingsActionTypes from "./userSettings.types";

export const setUserLanguageStart = (reqHeaders) => ({
    type: UserSettingsActionTypes.SET_USER_LANGUAGE_START,
    payload: reqHeaders,
    refKey: "langData"
})
export const setUserLanguageSuccess = () => ({
    type: UserSettingsActionTypes.SET_USER_LANGUAGE_SUCCESS,
    refKey: "langData"
})
export const setUserLanguageFailure = (errorMessage) => ({
    type: UserSettingsActionTypes.SET_USER_LANGUAGE_FAILURE,
    payload: errorMessage,
    refKey: "langData"
})

export const setUserEmbedConsentStart = (userId, embedVariant, embedValue) => ({
    type: UserSettingsActionTypes.SET_USER_EMBED_CONSENT_START,
    payload: { userId, embedVariant, embedValue },
    refKey: "consentData"
})
export const setUserEmbedConsentSuccess = () => ({
    type: UserSettingsActionTypes.SET_USER_EMBED_CONSENT_SUCCESS,
    refKey: "consentData"
})
export const setUserEmbedConsentFailure = (errorMessage) => ({
    type: UserSettingsActionTypes.SET_USER_EMBED_CONSENT_FAILURE,
    payload: errorMessage,
    refKey: "consentData"
})