import ArticleDataActionTypes from "./articleData.types";

export const fetchArticleDataStart = (collectionKey, payloadRef = "") => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_DATA_START,
    payload: collectionKey,
    payloadRef: payloadRef
})
export const fetchArticleDataSuccess = (articleListMap) => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_DATA_SUCCESS,
    payload: articleListMap
})
export const fetchArticleDataFailure = (errorMessage) => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_DATA_FAILURE,
    payload: errorMessage
})

export const fetchArticlePreviewStart = (articleId, lang) => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_START,
    payload: articleId,
    lang: lang
})
export const fetchArticlePreviewSuccess = (preview) => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_SUCCESS,
    payload: preview
})
export const fetchArticlePreviewFailure = (errorMessage) => ({
    type: ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_FAILURE,
    payload: errorMessage
})