import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, DoughnutController, ArcElement } from "chart.js";
import { max } from "lodash";

import { calcBarChart, calcPieChart, calcDailyReads, calcXAxisLabels } from "../../pages/main/accountPage/account.utils";
import { categoryColor, textColor } from "../general.utils";
import { color, font } from "../../design/theme";
import { accountData } from "../lang.data";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, DoughnutController, ArcElement);

Tooltip.positioners.custom = function(elements, eventPosition) {
    return {
        x: eventPosition.x,
        y: eventPosition.y
    };
}

export const generateChart = (type, historyData, loading, chartNode, option, darkmode, lang, reducedSize) => {
    if(type === "bar") {
        let chartData = calcBarChart(option, historyData, loading);
        let maxYScale = historyData?.length ? max(calcDailyReads(option, historyData)) : 20;
        let borderRadius = reducedSize ? option === "week" ? 5 : 2 : option === "week" ? 8 : 4;
        let barThickness = reducedSize ? option === "week" ? "flex" : 8 : option === "week" ? 50 : 15;

        return new Chart(chartNode, {
            type: "bar",
            data: {
                labels: option === "week" ? accountData.barChart.labels.short[lang] : calcXAxisLabels("30days", 1),
                datasets: [
                    {
                        label: accountData.pieChart.labels[lang][0],
                        data: chartData[0],
                        backgroundColor: historyData?.length ? categoryColor("politics", darkmode) : darkmode ? color.gray3 : color.gray4,
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][1],
                        data: chartData[1],
                        backgroundColor: categoryColor("society", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][2],
                        data: chartData[2],
                        backgroundColor: categoryColor("sports", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][3],
                        data: chartData[3],
                        backgroundColor: categoryColor("science", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][4],
                        data: chartData[4],
                        backgroundColor: categoryColor("technology", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][5],
                        data: chartData[5],
                        backgroundColor: categoryColor("economy", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }, {
                        label: accountData.pieChart.labels[lang][6],
                        data: chartData[6],
                        backgroundColor: categoryColor("special", darkmode),
                        borderWidth: 0,
                        borderRadius: borderRadius,
                        barThickness: barThickness
                    }
                ]
            },
            options: {
                normalized: true,
                segmentShowStroke: false,
                devicePixelRatio: 2,
                maintainAspectRatio: false,
                font: {
                    family: font.interBold
                },
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                plugins: {
                    tooltip: {
                        enabled: !!historyData?.length,
                        position: "custom",
                        bodySpacing: 4,
                        //xPadding: 14,
                        //yPadding: 12,
                        padding: {
                            left: 16,
                            right: 16,
                            top: 12,
                            bottom: 12
                        },
                        cornerRadius: 12,
                        displayColors: true,
                        multiKeyBackground: darkmode ? color.black : color.white,
                        caretPadding: 7,
                        caretSize: 7,
                        backgroundColor: darkmode ? color.white : color.gray2,
                        bodyColor: darkmode ? color.black : color.white,
                        titleColor: darkmode ? color.black : color.white,
                        footerColor: darkmode ? color.black : color.white,
                        titleFont: {
                            family: font.interBold,
                            size: 14
                        },
                        bodyFont: {
                            family: font.interMedium,
                            size: 14
                        },
                        footerFont: {
                            family: font.interBold,
                            size: 14
                        },
                        itemSort: function (a, b) {
                            return b.datasetIndex - a.datasetIndex;
                        },
                        callbacks: {
                            label: function(context) {
                                let label = " " + context.dataset.label || '';
                                if (label) {
                                    label += ': ' + context.parsed.y // context.dataPoint.y;
                                }
                                return label;
                            },
                            labelColor: function(context) {
                                return {
                                    borderColor: textColor(!darkmode),
                                    backgroundColor: context.dataset.backgroundColor,
                                    borderRadius: 3,
                                };
                            },
                            footer: function(tooltipItems) {
                                let sum = 0;
                                tooltipItems.forEach(function(tooltipItem) {
                                    sum += tooltipItem.parsed.y // tooltipItem.dataPoint.y;
                                });
                                return '∑ Total: ' + sum;
                            },
                            title: function(context) {
                                let index = context[0].dataIndex // context[0].dataPoint.x
                                return option === "week" ? accountData.barChart.labels.long[lang][index] : (calcXAxisLabels("30days", 2))[index];
                            },
                            footerFontStyle: 'normal'
                        }
                    },
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        grid: {
                            borderColor: darkmode ? color.gray3 : color.gray4,
                            color: darkmode ? color.gray3 : color.gray4,
                            lineWidth: option === "week" ? 0.5 : 0.2
                        },
                        ticks: {
                            color: darkmode ? color.gray4 : color.gray3,
                            font: {
                                size: 14
                            },
                            autoSkip: true,
                            maxTicksLimit: reducedSize ? 12 : 20
                        }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        min: 0,
                        max: historyData ? (maxYScale && maxYScale > 3) ? /*(maxYScale % 2 !== 0) ? (maxYScale - (maxYScale % 2) + 2) :*/ (maxYScale - (maxYScale % 2) + 4) : 5 : maxYScale, // maxYScale ? (maxYScale % 5 === 0) ? (maxYScale + 2) : (maxYScale - (maxYScale % 5) + 5) : 5
                        grid: {
                            borderColor: darkmode ? color.gray3 : color.gray4,
                            color: darkmode ? color.gray3 : color.gray4,
                            tickBorderDashOffset: 1,
                            drawTicks: true,
                            lineWidth: option === "week" ? 0.4 : 0.5,
                            tickLength: 5,
                        },
                        ticks: {
                            color: darkmode ? color.gray4 : color.gray3,
                            // stepSize: 3,
                            font: {
                                size: 14
                            },
                            autoSkip: true,
                            maxTicksLimit: reducedSize ? 8 : 10,
                            padding: 5
                        }
                    }
                }
            }
        })
    } else if(type === "pie") {
        let chartData = calcPieChart("", historyData);

        return new Chart(chartNode, {
            type: "doughnut",
            data: {
                labels: accountData.pieChart.labels[lang],
                datasets: [{
                    label: "",
                    data: chartData,
                    backgroundColor: [
                        (historyData?.length ? categoryColor("politics", darkmode) : darkmode ? color.gray3 : color.gray4),
                        categoryColor("society", darkmode),
                        categoryColor("sports", darkmode),
                        categoryColor("science", darkmode),
                        categoryColor("technology", darkmode),
                        categoryColor("economy", darkmode),
                        categoryColor("special", darkmode)
                    ],
                    hoverOffset: -15,
                    hoverBorderWidth: 3,
                    hoverBorderColor: textColor(darkmode),
                    weight: 10
                }]
            },
            options: {
                normalized: true,
                segmentShowStroke: false,
                devicePixelRatio: 2,
                cutout: "50%",
                // cutoutPercentage: 50,
                font: {
                    family: font.interBold
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                plugins: {
                    tooltip: {
                        mode: "nearest",
                        position: "custom",
                        enabled: !!historyData?.length,
                        bodySpacing: 4,
                        //xPadding: 14,
                        //yPadding: 12,
                        padding: {
                            left: 14,
                            right: 14,
                            top: 12,
                            bottom: 12
                        },
                        cornerRadius: 12,
                        displayColors: true,
                        multiKeyBackground: darkmode ? color.black : color.white,
                        caretPadding: 7,
                        caretSize: 7,
                        backgroundColor: darkmode ? color.white : color.gray2,
                        bodyColor: darkmode ? color.black : color.white,
                        bodyFont: {
                            family: font.interBold,
                            size: 14
                        },
                        callbacks: {
                            label: function(context) {
                                let label = " " + context.label || '';
                                if (label) {
                                    label += ': ' + context.parsed // context.dataPoint;
                                }
                                return label;
                            },
                            labelColor: function(context) {
                                return {
                                    borderColor: textColor(!darkmode),
                                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                                    borderRadius: 3,
                                };
                            }
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            }
        })
    }
}