import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

const darkGradient = "rgba(42, 42, 42, 1) 10%, rgba(42, 42, 42, 0.98) 25%, rgba(42, 42, 42, 0.95) 50%, rgba(42, 42, 42, 0.80) 70%, rgba(42, 42, 42, 0)";
const lightGradient = "rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.98) 25%, rgba(255, 255, 255, 0.90) 50%, rgba(255, 255, 255, 0.80) 70%, rgba(255, 255, 255, 0)";

export const AccountProgressContainer = styled.div`
    width: inherit;
    height: 50px;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    margin-bottom: 20px;
    padding: 10px 25px;
    justify-content: left;
    text-align: left;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
`;

export const AccountProgressTitleContainer = styled.div`
    color: ${props => textColor(props.darkmode)};
    font-size: 25px;
    font-family: ${font.interBold};
    display: inline-block;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        height: 50px;
        width: ${props => props.long ? "185px" : "110px"};
        margin-left: -25px;
        margin-top: -10px;
        left: 0;
        z-index: -1;
        background-color: ${props => props.darkmode ? color.gray2 : color.white};
    }
    &::after {
        content: "";
        position: absolute;
        height: ${props => !props.gradient ? "50px" : "0"};
        width: ${props => !props.gradient ? "60px" : "0"};
        margin-right: ${props => props.long ? "-65px" : "-60px"};
        margin-top: -10px;
        right: 0;
        z-index: 0;
        background-image: linear-gradient(to right, ${props => props.darkmode ? darkGradient : lightGradient});
    }
`

export const AccountProgressTotalContainer = styled.div`
    color: ${props => textColor(props.darkmode)};
    font-size: 25px;
    font-family: ${font.interBold};
    display: inline-flex;
    justify-content: center;
    vertical-align: center;
    position: absolute;
    right: 0;
    margin-right: 25px;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        height: ${props => !props.gradient /*&& (props.target !== 11 || props.long)*/ ? "50px" : "0"};
        width: ${props => !props.gradient /*&& (props.target !== 11 || props.long)*/ ? "60px" : "0"};
        margin-right: 75px;
        margin-top: -10px;
        right: 0;
        z-index: 0;
        background-image: linear-gradient(to left, ${props => props.darkmode ? darkGradient : lightGradient});
    }
    &::after {
        content: "";
        position: absolute;
        height: 50px;
        width: 100px;
        margin-right: -25px;
        margin-top: -10px;
        right: 0;
        z-index: -1;
        background-color: ${props => props.darkmode ? color.gray2 : color.white};
    }
`

export const AccountProgressIconBarContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-left: ${props => props.long ? "195px" : "130px"};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-block;
    position: absolute;
    overflow-x: scroll;
    justify-content: left;
`

export const AccountProgressIconBar = styled.div`
    width: auto;
    height: inherit;
    padding-right: 110px;
    display: inline-flex;
    position: absolute;
    float: left;
`

export const AccountProgressIconContainer = styled(Icon)`
    height: 100%;
    display: inline-table;
    justify-content: center;
    margin: 0 22px 0 0;
    float: left;
  
    &:last-of-type {
        margin-right: 10px;
    }
`