import React, { createContext, useState } from "react";
import { validateSearchAttribute } from "../components/general.utils";

export const SearchContext = createContext(null);

const SearchContextProvider = ({ children }) => {
    const [value, setValue] = useState("");
    const [attribute, setAttribute] = useState("");
    const [clearInputAndFocus, setClearInputAndFocus] = useState({});

    const handleAttribute = (newAttribute) => {
        console.log("HANDLE ATTRIBUTE CONTEXT", newAttribute, validateSearchAttribute(newAttribute), attribute)
        if((!validateSearchAttribute(newAttribute) && newAttribute !== "articleId") || newAttribute === attribute) {
            setAttribute("");
        } else setAttribute(newAttribute);
    }

    return (
        <SearchContext.Provider value={{ value, setValue, attribute, currentAttribute: attribute, handleAttribute, clearInputAndFocus, setClearInputAndFocus }}>
            { children }
        </SearchContext.Provider>
    )
}

export default SearchContextProvider;