import PersistDataActionTypes from "./persistData.types";

export const setTheme = (mode) => ({
    type: PersistDataActionTypes.SET_THEME,
    payload: mode
})

export const setArticleProgress = (data) => ({
    type: PersistDataActionTypes.SET_ARTICLE_PROGRESS,
    payload: data
})