import SessionDataActionTypes from "./sessionData.types";
import { omit } from "lodash";

const INITIAL_STATE = {
    scrollState: {},
    imageRefObj: {},
    embedConsent: {},
    feedRefObj: {},
    traceLog: []
}

const sessionDataSetter = (action, stateObj) => {
    const { refKey, payload, addData } = action || {};

    if(refKey === "embedConsent") {
        let stateData = stateObj.embedConsent;
        if(addData === "remove") stateData = omit(stateObj.embedConsent, [payload],)
        else stateData = Object.assign({}, stateData, { [payload]: addData })

        return stateData;
    } else if(refKey === "feedRef") {
        let stateData = stateObj.feedRefObj;

        if(stateData[payload]) stateData[payload].unshift(addData)
        else Object.assign(stateData, { [payload]: [addData] })

        return Object.assign({}, stateData);
    }
}

const sessionDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SessionDataActionTypes.SET_SCROLL_STATE:
            return {
                ...state,
                scrollState: Object.assign({}, state.scrollState, { [action.pathName]: action.payload })
            }
        case SessionDataActionTypes.SET_IMAGE_REF:
            return {
                ...state,
                imageRefObj: Object.assign({}, state.imageRefObj, { [action.refKey]: action.payload })
            }
        case SessionDataActionTypes.SET_EMBED_CONSENT:
            return {
                ...state,
                embedConsent: sessionDataSetter(action, state)
            }
        case SessionDataActionTypes.SET_FEED_REF:
            return {
                ...state,
                feedRefObj: sessionDataSetter(action, state)
            }
        case SessionDataActionTypes.ADD_TO_TRACE_LOG:
            state.traceLog.push(action.payload)
            return {
                ...state,
                traceLog: state.traceLog
            }
        default:
            return state;
    }
}

export default sessionDataReducer;