import styled from "styled-components";
import { color, radius } from "../../design/theme";

export const HeaderContainer = styled.header`
    width: 800px;
    height: 50px;
    top: 20px;
    text-align: left;
    justify-content: left;
    position: fixed;
    z-index: 100;
    display: flex;

    @media only screen and (max-width: 1300px) {
        width: ${props => props.mainWidth + "px"};
    }
    
    &::after {
        content: "";
        width: 102%;
        height: 90px;
        position: absolute;
        z-index: -1;
        margin: -20px 0 0 -1%;
        background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    }
`;

export const HeaderContentContainer = styled.div`
    width: inherit;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    &> div + div {
        margin-left: 10px;
    }
`;

export const HeaderMainContainer = styled.div`
    width: fit-content;
    flex-grow: 1;
    height: inherit;
    min-width: 0;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    position: relative;
    border-radius: ${radius.smallRadius};
    display: flex;
`;