import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const SearchbarWrapper = styled.div`
    width: 100%;
    height: ${props => (props.main && props.focused) ? "max-content" : "100%"};
    max-height: calc(100vh - 40px);
    min-width: ${props => !props.main ? props.width + "px" : "100%"};
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: ${props => props.main ? radius.smallRadius : "7px"};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    box-shadow: ${props => props.main && props.focused ? `inset 0 0 0 2.5px ${props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"}, 0 1px 5px 0 ${props.darkmode ? color.black : color.gray3}` : null};
    z-index: 3;

    &:focus-within {
        outline: none;
        box-shadow: 0 0 0 2px ${color.blue} ${props => props.main ? `, 0 1px 5px 0 ${props.darkmode ? color.black : color.gray3}` : null} !important;
    }
    &:hover {
        box-shadow: inset 0 0 0 2.5px ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"} ${props => props.main && props.focused ? `, 0 1px 5px 0 ${props.darkmode ? color.black : color.gray3}` : null};
    }

    @media only screen and (max-width: 600px) {
        min-width: 100%;
    }
    @media only screen and (min-width: 1250px) {
        min-width: 100%;
    }
`

export const SearchbarBackdrop = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: ${props => props.darkmode ? "rgba(0, 0, 0, 0.4)" : "rgba(255, 255, 255, 0.45)"};
`

export const SearchbarContainer = styled.div`
    width: 100%;
    min-width: max-content;
    max-width: 100%;
    height: ${props => props.main ? "50px" : "34px"}; 
    padding: 4px 5px;
    flex: 1;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media only screen and (max-width: 700px)  {
        min-width: 0;
    }
    @media only screen and (min-width: 1250px) {
        min-width: 0;
    }
`

export const SearchbarContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: inherit;
    justify-content: center;
    position: relative;
    z-index: 10;
    display: flex;
    min-width: 0;
    flex: 0;
    flex-direction: ${props => props.main ? "row" : "unset"};
    align-items: center;
    padding: ${props => props.main ? "0 10px" : "0 5px"};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
  
    ${
        props => props.focused ? `
            &::after {
                content: "";
                width: calc(100% - 30px);
                margin-top: 48px;
                top: 0;
                height: 1px;
                background-color: ${props.darkmode ? color.gray3 : color.gray4};
                position: absolute;
                border-radius: 5px;
                z-index: 3;
            }` 
        : null
    }
`

export const SearchbarInputContainer = styled.div`
    width: 100%;
    margin: 0 ${props => props.main ? "10px" : "2px"} 0 ${props => props.main ? "10px" : "5px"};
    min-width: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 100;
    position: relative;
    z-index: 0;
`

export const SearchbarInput = styled.input`
    width: 100%;
    height: 100%;
    font-size: ${props => props.main ? "20px" : "16px"};
    line-height: 100%;
    font-family: ${font.pulpDisplayMedium};
    background-color: transparent;
    color: ${props => textColor(props.darkmode)};
    letter-spacing: 0.045em;
    border: none;
    text-indent: 5px;
    flex-grow: 100;
    -webkit-appearance: none; // be wary of potential side effects
    
    &:focus {
        outline: none;
        box-shadow: none !important;
    }

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
        -webkit-appearance:none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
`;

export const SearchbarInputCancelContainer = styled.div`
    // position: absolute;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: ${props => props.main ? "10px" : "8px"};
    width: ${props => props.main ? "32px" : "22px"};
`

export const SearchbarInputCancelIcon = styled(Icon)`
    height: auto;
    width: inherit;
    cursor: pointer;
`

export const SearchbarIconContainer = styled.div`
    height: ${props => props.main ? "40px" : "26px"};
    width: ${props => props.main ? "40px" : "26px"};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    z-index: 2;
    
    ${
        props => props.active ? (
            `
                &:before {
                    content: "";
                    position: absolute;
                    width: 140%;
                    height: 140%;
                    border-radius: 50px;
                    background-color: ${color.blue};
                    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
                    z-index: -1;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    width: ${props.main ? "95%" : "100%"};
                    height: ${props.main ? "95%" : "100%"};
                    background-color: ${color.blue};
                    border-radius: 50px;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
                    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
                    z-index: -1;
                }
                
                @keyframes pulse-ring {
                    0% {
                        transform: scale(.33);
                    }
                    95%, 100% {
                        opacity: 0;
                    }
                }
                
                @keyframes pulse-dot {
                    0% {
                        transform: scale(.95);
                    }
                    50% {
                        transform: scale(1);
                    }
                    100% {
                        transform: scale(.95);
                    }
                }
            `
        ) : null
    }
`

export const SearchbarIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    cursor: ${props => props.focused && props.name === "search" ? "text" : "pointer"};
    flex-shrink: 0;
`;

export const SearchbarMethodGroup = styled.div`
    display: inline-flex;
    height: 100%;
    margin-left: auto;
    position: relative;
    align-items: center;
    padding: 5px 0 5px 0;
  
    & > svg:nth-child(2) {
        margin-left: 10px;
    }
  
    &::before {
        content: "";
        width: 2px;
        height: ${props => props.main ? "95%" : "120%"};
        background-color: ${props => props.darkmode ? color.gray3 : color.gray4};
        border-radius: 5px;
        margin-right: ${props => props.main ? "15px" : "10px"};
    }
`

export const SearchbarAutocompleteWrapper = styled.div`
    margin-top: 60px;
    width: 100%;
    height: ${props => props.height ? props.height + "px" : "max-content"};
    max-height: inherit;
    position: relative;
    overflow-y: scroll;
    padding: 0 10px 10px 10px;
`

export const SearchbarAutocompleteContainer = styled.div`
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SearchbarAttribute = styled.div`
    font-family: ${font.pulpDisplayMedium};
    color: ${props => props.darkmode ? color.gray2 : color.gray5};
    padding: 0 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    user-select: none;
    height: 28px;
    cursor: pointer;
    background-color: ${props => props.darkmode ? color.gray4 : color.gray3};
    border-radius: 6px;
    margin-left: 15px;
  
    &:hover {
        opacity: 0.8;
    }
`