import { takeLatest, call, put, all, select } from "redux-saga/effects";
import {
    onValue,
    onChildChanged,
    ref,
    orderByChild,
    query,
    limitToLast,
    onChildAdded,
    onChildRemoved,
    get,
    off,
    orderByKey, endBefore
} from "firebase/database";
import { sortBy, values, isEqual } from "lodash";
import UserDataActionTypes from "./userData.types";

import { store } from "../store";
import { newsAPI } from "../backendAPI";
import { database } from "../../config/firebase.config";
import {
    fetchUserDataSuccess, fetchUserDataFailure,
    fetchUserBookmarksSuccess, fetchUserBookmarksFailure,
    fetchUserChannelsSuccess, fetchUserChannelsFailure,
    fetchUserHistorySuccess, fetchUserHistoryFailure,
    fetchUserListsSuccess, fetchUserListsFailure,
    fetchUserCollectionsSuccess, fetchUserCollectionsFailure,
    fetchUserAccessedSuccess, fetchUserAccessedFailure,
    fetchUserSearchesSuccess, fetchUserSearchesFailure
} from "./userData.actions";
import { selectHistoryDataFetchKey, selectCollectionsDataRaw } from "./userData.selectors";


export function* fetchUserDataStartAsync(action) {
    try {
        const userId = action.payload;
        let userGeneral = yield ref(database, "users/" + userId + "/general");
        let userNewsGeneral = yield ref(database, "users/" + userId + "/news/general");
        let userNewsDetails = yield ref(database, "users/" + userId + "/news/details");

        yield onValue(userGeneral, async snapshot => {
            let general = await snapshot.val();
            onValue(userNewsGeneral, async snapshot => {
                let newsGeneral = await snapshot.val();
                onValue(userNewsDetails, async snapshot => {
                    let newsDetails = await snapshot.val();
                    store.dispatch(
                        fetchUserDataSuccess(Object.assign({}, general, newsGeneral, newsDetails))
                    );
                }, { onlyOnce: true });
            }, { onlyOnce: true });
        }, { onlyOnce: true });

        yield onChildChanged(userGeneral, async snapshot => {
            let generalChange = await snapshot.val();
            store.dispatch(fetchUserDataSuccess({ [snapshot.key]: generalChange }));
        })
        yield onChildChanged(userNewsGeneral, async snapshot => {
            let newsGeneralChange = await snapshot.val();
            store.dispatch(fetchUserDataSuccess({ [snapshot.key]: newsGeneralChange }));
        })
        yield onChildChanged(userNewsDetails, async snapshot => {
            let newsDetailsChange = await snapshot.val();
            store.dispatch(fetchUserDataSuccess({ [snapshot.key]: newsDetailsChange }));
        })

        yield onChildAdded(query(userNewsGeneral), async snapshot => {
            let newsGeneralChange = await snapshot.val();
            let storedUser = await store.getState()?.["userData"]?.["user"]?.["userId"];
            if(storedUser) store.dispatch(fetchUserDataSuccess({ [snapshot.key]: newsGeneralChange }));
        })
        yield onChildRemoved(userNewsGeneral, async snapshot => {
            let storedUser = await store.getState()?.["userData"]?.["user"]?.["userId"];
            if(storedUser) store.dispatch(fetchUserDataSuccess({ [snapshot.key]: null }));
        })
    } catch (error) {
        yield put(fetchUserDataFailure(error.message))
    }
}

export function* fetchUserDataStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_DATA_START,
        fetchUserDataStartAsync
    )
}


export function* fetchUserBookmarksStartAsync(action) {
    try {
        const userId = action.payload;
        let userBookmarks = yield ref(database, "users/" + userId + "/news/bookmarks");
        let lastKey = "";

        yield onValue(query(userBookmarks, orderByChild("timestamp")), async snapshot => {
            if (snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let snapshotArr = sortBy(values(snapshotData), "timestamp").reverse();
                lastKey = snapshotArr[0]?.articleId;
                store.dispatch(fetchUserBookmarksSuccess(snapshotData, snapshotArr, "general"))
            } else {
                store.dispatch(fetchUserBookmarksSuccess({}, [], "general"))
            }
        }, { onlyOnce: true })

        yield onChildAdded(query(userBookmarks, orderByChild("timestamp"), limitToLast(1)), async snapshot => {
            if (snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let bookmarkData = await store.getState()?.["userData"]?.["bookmarkData"]?.["data"];
                if((snapshot.key !== lastKey) && !bookmarkData?.[snapshot.key]) {
                    store.dispatch(fetchUserBookmarksSuccess(
                        { [snapshot.key]: snapshotData },
                        "add",
                        `added_${snapshot.key}`)
                    )
                } else console.log("BOOKMARK UPDATE PREVENTED")
            }
        })
        yield onChildRemoved(userBookmarks, async snapshot => {
            let snapshotData = await snapshot.val();
            if(lastKey) lastKey = "";
            store.dispatch(
                fetchUserBookmarksSuccess(snapshotData, "delete", `removed_${snapshot.key}`)
            )
        })
    } catch (error) {
        yield put(fetchUserBookmarksFailure(error.message))
    }
}

export function* fetchUserBookmarksStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_BOOKMARKS_START,
        fetchUserBookmarksStartAsync
    )
}


export function* fetchUserChannelsStartAsync(action) {
    try {
        const { userId } = action.payload;
        const { lang } = action.payload;
        let lastKey = "";
        let userChannels = yield ref(database, "users/" + userId + "/news/channels");

        const fetchChannelApi = (snapshotData, channelArr, fetchKey) => {
            newsAPI.post(`/channels`, { channels: channelArr }, {
                headers: {
                    user: userId,
                    lang: lang
                }
            }).then(response => {
                console.log("API_RESPONSE SUCCESS ::", response)
                store.dispatch(fetchUserChannelsSuccess(snapshotData, response.data.content, fetchKey))
            }).catch((err) => {
                console.log("API_RESPONSE FAILED ::", err)
                store.dispatch(fetchUserChannelsFailure(err.message))
            })
        }

        yield onValue(userChannels, async snapshot => {
            if(snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let channelArr = snapshotData ? Object.keys(snapshotData).map(key => key) : [];
                let snapshotArr = sortBy(values(snapshotData), "timestamp").reverse();
                lastKey = snapshotArr[0]?.channelId;
                fetchChannelApi(snapshotData, channelArr, "general");
            } else {
                store.dispatch(fetchUserChannelsSuccess({}, {}, "general"))
            }
        }, { onlyOnce: true })

        yield onChildAdded(query(userChannels, orderByChild("timestamp"), limitToLast(1)), async snapshot => {
            let snapshotData = await snapshot.val();
            let channelData = await store.getState()?.["userData"]?.["channelData"]?.["data"];
            if((snapshot.key !== lastKey) && !channelData?.[snapshot.key]) {
                fetchChannelApi(
                    { [snapshot.key]: snapshotData },
                    [snapshot.key],
                    `added_${snapshot.key}`);
            } else console.log("CHANNEL UPDATE PREVENTED");
        })
        yield onChildRemoved(userChannels, async snapshot => {
            let snapshotData = await snapshot.val();
            lastKey = "";
            store.dispatch(
                fetchUserChannelsSuccess(snapshotData, "delete", `removed_${snapshot.key}`)
            )
        })
    } catch (error) {
        yield put(fetchUserChannelsFailure(error.message))
    }
}

export function* fetchUserChannelsStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_CHANNELS_START,
        fetchUserChannelsStartAsync
    )
}


export function* fetchUserHistoryStartAsync(action) {
    try {
        const userId = action.payload;
        const fetchKey = action.fetchKey;
        let userHistory = ref(database, "users/" + userId + "/news/history");
        let historyFetchKey = yield select(selectHistoryDataFetchKey);
        let lastKey = "";

        const userHistoryFinal = fetchKey === "recent" ? query(userHistory, limitToLast(6)) : userHistory;
        yield onValue(userHistoryFinal, async snapshot => {
            if(snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let snapshotArr = [];
                Object.values(snapshotData).forEach(key => snapshotArr.unshift(key)); // push, _.sortBy(snapshotArr, "timestamp").reverse()
                lastKey = snapshotArr[0].timestamp;
                store.dispatch(fetchUserHistorySuccess(snapshotArr, null, fetchKey))
            } else {
                lastKey = "undefined";
                store.dispatch(fetchUserHistorySuccess(null, null, fetchKey))
            }
        }, { onlyOnce: true })

        if(!historyFetchKey) {
            yield onChildAdded(query(userHistory, limitToLast(1)), async snapshot => {
                if (snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    if(lastKey && (lastKey !== snapshotData.timestamp)) {
                        store.dispatch(fetchUserHistorySuccess(snapshotData, "add"))
                    } else console.log("HISTORY UPDATE PREVENTED")
                }
            });
            yield onChildRemoved(userHistory, async snapshot => {
                let snapshotData = await snapshot.val();
                store.dispatch(fetchUserHistorySuccess(snapshotData, "delete"))
            });
        }
    } catch (error) {
        yield put(fetchUserHistoryFailure(error.message))
    }
}

export function* fetchUserHistoryStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_HISTORY_START,
        fetchUserHistoryStartAsync
    )
}


export function* fetchUserListsStartAsync(action) {
    try {
        const userId = action.payload;
        const listId = action.listId;
        const fetchKey = action.fetchKey;
        let userListRef = (listId = "") => ref(database, "users/" + userId + "/news/lists/details" + `/${listId}`);
        let userListRefTotal = ref(database, "users/" + userId + "/news/lists/details");
        off(userListRefTotal);
        let lastKey = "";

        if(fetchKey !== "general") {
            let listIdsToFetch = Array.isArray(listId) ? listId : [listId]
            let listRefs = yield listIdsToFetch.map(async listId => {
                return await get(userListRef(listId))
            })
            Promise.all(listRefs).then(snapshots => {
                let listRefsArr = snapshots.map(snapshot => snapshot.val())
                let resultObj = {};
                listRefsArr.forEach((listRef, index) => {
                    Object.assign(resultObj, { [(listRef?.listId ?? listIdsToFetch[index])]: listRef || "undefined" })
                });
                let resultArr = sortBy(values(resultObj), "timestamp").reverse();

                store.dispatch(fetchUserListsSuccess(resultObj, resultArr, fetchKey))
            })
        } else {
            yield onValue(userListRefTotal, async snapshot => {
                if (snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    let snapshotArr = sortBy(values(snapshotData), "timestamp").reverse();

                    lastKey = snapshotArr[0]?.listId;
                    store.dispatch(fetchUserListsSuccess(snapshotData, snapshotArr, fetchKey));
                } else {
                    lastKey = "undefined";
                    store.dispatch(fetchUserListsSuccess({}, [], fetchKey));
                }
            }, { onlyOnce: true })

            yield onChildAdded(query(userListRefTotal, orderByChild("timestamp"), limitToLast(1)), async snapshot => {
                if(snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    let listsData = await store.getState()?.["userData"]?.["listsData"]?.["data"];
                    if(lastKey && (snapshot.key !== lastKey) && !listsData?.[snapshot.key]) {
                        store.dispatch(fetchUserListsSuccess(
                            { [snapshot.key]: snapshotData },
                            "add"
                        ))
                    } else console.log("LISTS UPDATE PREVENTED")
                }
            })
        }

        yield onChildRemoved(userListRefTotal, async snapshot => {
            let snapshotData = await snapshot.val();
            store.dispatch(fetchUserListsSuccess(snapshotData, "delete"))
        })
        yield onChildChanged(userListRefTotal, async snapshot => {
            let snapshotData = await snapshot.val();
            store.dispatch(fetchUserListsSuccess({ [snapshot.key]: snapshotData }, "change"))
        })
    } catch (error) {
        yield put(fetchUserListsFailure(error.message))
    }
}

export function* fetchUserListsStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_LISTS_START,
        fetchUserListsStartAsync
    )
}


export function* fetchUserCollectionsStartAsync(action) {
    try {
        const userId = action.payload;
        const listId = action.listId;
        const fetchKey = action.fetchKey;
        const collectionsData = yield select(selectCollectionsDataRaw);
        let collectionRef = (listId) => ref(database, "users/" + userId + "/news/lists/collections" + `/${listId}`);
        let collectionRefs = ref(database,"users/" + userId + "/news/lists/collections");

        const attachIndividualListeners = (listId, lastRefKey) => {
            let lastKey = lastRefKey;
            onChildAdded(query(collectionRef(listId), orderByChild("timestamp"), limitToLast(1)), async snapshot => {
                if (snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    let collectionsData = await store.getState()?.["userData"]?.["collectionsData"]?.["data"];
                    let parentRefPath = snapshot.ref.parent.toString();
                    let parentRefKey = parentRefPath.slice(parentRefPath.lastIndexOf("/") + 1);

                    if((snapshot.key !== lastKey) && !collectionsData?.[parentRefKey]?.[snapshot.key]) {
                        store.dispatch(fetchUserCollectionsSuccess(
                            { [parentRefKey]: snapshotData },
                            "add",
                            "single"
                        ))
                    } else console.log("COLLECTION UPDATE PREVENTED")
                }
            })
            onChildRemoved(collectionRef(listId), async snapshot => {
                let snapshotData = await snapshot.val();
                let parentRefPath = snapshot.ref.parent.toString();
                let parentRefKey = parentRefPath.slice(parentRefPath.lastIndexOf("/") + 1);
                lastKey = "";

                store.dispatch(fetchUserCollectionsSuccess(
                    { [parentRefKey]: snapshotData },
                    "delete",
                    "single"
                ))
            })
        }

        if(fetchKey === "general") {
            yield onValue(collectionRefs, async snapshot => {
                if (snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    let snapshotPreview = {};
                    let snapshotKeyArr = [];
                    Object.entries(snapshotData).forEach((entry, index) => {
                        snapshotKeyArr.push(entry[0])
                        let values = sortBy(Object.values(entry[1]).map(article => (article)), "timestamp").reverse();
                        Object.assign(snapshotPreview, { [snapshotKeyArr[index]]: values })
                    });

                    store.dispatch(fetchUserCollectionsSuccess(snapshotData, snapshotPreview, fetchKey))

                    snapshotKeyArr.forEach(snapKey => {
                        if (!collectionsData?.[snapKey]) {
                            let lastKey = snapshotPreview[snapKey][0]?.articleId;
                            attachIndividualListeners(snapKey, lastKey)
                        }
                    })
                } else {
                    store.dispatch(fetchUserCollectionsSuccess({}, {}, fetchKey, []))
                }
            }, { onlyOnce: true })

            yield onChildAdded(collectionRefs, async snapshot => {
                if (snapshot.exists()) {
                    const snapshotData = await snapshot.val();
                    let collectionsData = store.getState()?.["userData"]?.["collectionsData"]?.["data"];

                    if(collectionsData && !collectionsData?.[snapshot.key]) {
                        store.dispatch(fetchUserCollectionsSuccess({ [snapshot.key]: snapshotData }, "add", "general"))
                        attachIndividualListeners(snapshot.key, Object.keys(snapshotData)[0])
                    } else console.log("COLLECTION ADDITION PREVENTED")
                }
            })
            yield onChildRemoved(collectionRefs, async snapshot => {
                const snapshotData = await snapshot.val();
                store.dispatch(fetchUserCollectionsSuccess({ [snapshot.key]: snapshotData }, "delete", "general"))
                off(collectionRef(snapshot.key));
            })
        } else {
            let lastKey = "";
            yield onValue(collectionRef(listId), async snapshot => {
                if(snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    let snapshotArr = sortBy(Object.values(snapshotData).map(entry => entry), "timestamp").reverse();
                    lastKey = snapshotArr[0].articleId;

                    store.dispatch(fetchUserCollectionsSuccess(
                        { [snapshot.key]: snapshotData },
                        { [snapshot.key]: snapshotArr },
                        fetchKey
                    ))
                    attachIndividualListeners(listId, lastKey);
                } else {
                    store.dispatch(fetchUserCollectionsSuccess(
                        { [listId]: {} },
                        { [listId]: [] },
                        fetchKey
                    ))
                }
            }, { onlyOnce: true })
        }
    } catch (error) {
        yield put(fetchUserCollectionsFailure(error.message))
    }
}

export function* fetchUserCollectionsStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_COLLECTIONS_START,
        fetchUserCollectionsStartAsync
    )
}


export function* fetchUserAccessedStartAsync(action) {
    try {
        const userId = action.payload;
        let recentRef = ref(database, "users/" + userId + "/news/recent");
        let lastKey = ""

        yield onValue(recentRef, async snapshot => {
            if(snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let snapshotArr = [];
                Object.values(snapshotData).forEach(key => snapshotArr.unshift(key));
                lastKey = sortBy(snapshotArr, "timestamp")[snapshotArr.length - 1];

                store.dispatch(fetchUserAccessedSuccess(snapshotArr, "recent"))
            } else {
                store.dispatch(fetchUserAccessedSuccess([], "recent"))
            }
        }, { onlyOnce: true })

        yield onChildAdded(query(recentRef, orderByChild("timestamp"), limitToLast(1)), async snapshot => {
            if(snapshot.exists()) {
                let snapshotData = await snapshot.val();
                let accessedData = await store.getState()?.["userData"]?.["accessedData"];
                if(!isEqual(snapshotData, lastKey) && !accessedData?.some(({ listId }) => listId === snapshot.key)) {
                    store.dispatch(fetchUserAccessedSuccess(snapshotData, "add"))
                } else console.log("RECENT ACCESS UPDATE PREVENTED")
            }
        })
        yield onChildRemoved(recentRef, async snapshot => {
            let snapshotData = await snapshot.val();
            store.dispatch(fetchUserAccessedSuccess(snapshotData, "delete"))
        })
        yield onChildChanged(recentRef, async snapshot => {
            let snapshotData = await snapshot.val();
            store.dispatch(fetchUserAccessedSuccess(snapshotData, "change"))
        })
    } catch (error) {
        put(fetchUserAccessedFailure(error.message))
    }
}

export function* fetchUserAccessedStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_ACCESSED_START,
        fetchUserAccessedStartAsync
    )
}


export function* fetchUserSearchesStartAsync(action) {
    try {
        const userId = action.payload;
        let searchRef = ref(database, "users/" + userId + "/news/search");
        let lastKey = "";

        const getSearchData = async (fetchKey = "general", queryRef) => {
            await onValue((queryRef ?? query(searchRef, limitToLast(12))), async snapshot => {
                if (snapshot.exists()) {
                    let snapshotData = await snapshot.val();
                    if(fetchKey === "general") lastKey = Object.keys(snapshotData).reverse()?.[0];  // snapshotArr[0].timestamp;

                    store.dispatch(fetchUserSearchesSuccess(snapshotData, fetchKey))
                } else {
                    store.dispatch(fetchUserSearchesSuccess([], fetchKey));
                }
            }, { onlyOnce: true })
        }
        yield getSearchData();

        yield onChildAdded(query(searchRef, limitToLast(1)), async snapshot => {
            if(snapshot.exists()) {
                let snapshotData = snapshot.val();
                let searchData = await store.getState()?.["userData"]?.["searchData"]?.["data"];
                if(snapshot.key !== lastKey && !searchData?.[snapshot.key]) {
                    store.dispatch(fetchUserSearchesSuccess({ [snapshot.key]: snapshotData }, "add"))
                } else console.log("RECENT SEARCH UPDATE PREVENTED")
            }
        })
        yield onChildRemoved(searchRef, async snapshot => {
            let snapshotData = await snapshot.val();
            let searchData = store.getState()?.["userData"]?.["searchData"]?.["data"];

            if(Object.keys(searchData)[0]) await getSearchData("add", query(searchRef, orderByKey(), endBefore(Object.keys(searchData)[0]), limitToLast(1)))
            store.dispatch(fetchUserSearchesSuccess({ snapshotData, key: snapshot.key }, "delete"))
        })
    } catch (error) {
        yield put(fetchUserSearchesFailure(error.message))
    }
}

export function* fetchUserSearchesStart() {
    yield takeLatest(
        UserDataActionTypes.FETCH_USER_SEARCHES_START,
        fetchUserSearchesStartAsync
    )
}


export function* userDataSagas() {
    yield all([
        call(fetchUserDataStart),
        call(fetchUserBookmarksStart),
        call(fetchUserChannelsStart),
        call(fetchUserHistoryStart),
        call(fetchUserListsStart),
        call(fetchUserCollectionsStart),
        call(fetchUserAccessedStart),
        call(fetchUserSearchesStart)
    ])
}