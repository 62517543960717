import React from "react";

export const headData = {
    title: {
        de: "Behalte den Überblick.",
        fr: "Garde la vue d'ensemble.",
        en: "Get the Full Picture."
    },
    description: {
        de: "Lese alle deine News an einem Ort. Auf deine Interessen zugeschnitten, inhaltsorientiert und neutral: das ist reavide News - made in Switzerland. Unser Launch rückt näher, also bleib dran!",
        fr: "Lis toutes tes nouvelles en un seul endroit. Un site adapté à tes intérêts, centré sur le contenu et neutre - fabriqué en Suisse. La date de notre lancement se rapproche, alors reste à l'écoute!",
        en: "Read all of your news in one place. One that is tailored to your interests, centered around content and neutral - made in Switzerland. Our launch date is getting closer, so stay tuned!"
    }
}

export const navData = {
    "home": {
        icon: "home",
        desc: {
            de: "Für Mich",
            fr: "Pour Moi",
            en: "For Me"
        }
    },
    "popular": {
        icon: "popular",
        desc: {
            de: "Top Stories",
            fr: "Top Stories",
            en: "Top Stories"
        }
    },
    "explore": {
        icon: "explore",
        desc: {
            de: "Entdecken",
            fr: "Découvrir",
            en: "Explore"
        }
    },
    "channels": {
        icon: "channels",
        desc: {
            de: "Meine Channels",
            fr: "Mez Canaux",
            en: "My Channels",
        }
    },
    "library": {
        icon: "library",
        desc: {
            de: "Für Später",
            fr: "Pour Après",
            en: "For Later",
        }
    },
    "timeline": {
        icon: "timeline",
        desc: {
            de: "Breaking News",
            fr: "Breaking News",
            en: "Breaking News",
        }
    },
    "account": {
        icon: "account",
        desc: {
            de: "Account",
            fr: "Compte",
            en: "Account",
        }
    },
    "trends": {
        icon: "trends",
        desc: {
            de: "Trends",
            fr: "Trends",
            en: "Trends",
        }
    },
    "feedback": {
        icon: "feedback",
        desc: {
            de: "Feedback",
            fr: "Feedback",
            en: "Feedback",
        }
    }
}

export const titleData = {
    popular: {
        de: "Meistgelesen",
        fr: "Populaire",
        en: "Most read"
    },
    explore: {
        de: "Channels",
        fr: "Canaux",
        en: "Channels"
    },
    channels: {
        de: "Interessen",
        fr: "Intérêts",
        en: "Interests"
    },
    timeline: {
        de: "Timeline",
        fr: "Timeline",
        en: "Timeline"
    },
    library: {
        lists: {
            url: "library/lists",
            desc: {
                de: "Listen",
                fr: "Listes",
                en: "Lists"
            }
        },
        bookmarks: {
            url: "library/bookmarks",
            desc: {
                de: "Lesezeichen",
                fr: "Signets",
                en: "Bookmarks"
            }
        }
    },
    account: {
        de: "Mein reavide",
        fr: "Mon reavide",
        en: "My reavide"
    },
    settings: {
        de: "Einstellungen",
        fr: "Paramètres",
        en: "Settings"
    },
    history: {
        de: "Leseverlauf",
        fr: "Histoire de lecture",
        en: "Reading History"
    },
    searchResults: {
        de: "Suchergebnisse",
        fr: "Résultats de recherche",
        en: "Search Results"
    },
    recentAccess: {
        de: "Kürzlich zugegriffen",
        fr: "Récemment accédé",
        en: "Recently Accessed"
    },
    forYou: {
        de: "Artikel für dich",
        fr: "Articles pour toi",
        en: "Articles for you"
    }
}

export const articleDataCC = {
    deletedTitle: {
        de: "Artikel entfernt",
        fr: "Article supprimé",
        en: "Article removed"
    },
    deletedDesc: {
        de: "Dieser Artikel ist leider nicht mehr verfügbar.",
        fr: "Malheureusement cet article n'est plus disponible.",
        en: "Unfortunately this article is no longer available."
    },
    deletedDescExt: {
        de: "Versuche es stattdessen bitte mit einem anderen. Da findet sich bestimmt was :)",
        fr: "Tu peux en essayer un autre à la place. Sûrement il se trouvera quelque chose :)",
        en: "Please try another one instead. There is bound to be something :)"
    },
    undefinedTitle: {
        de: "Artikel existiert nicht",
        fr: "Article n'éxiste pas",
        en: "Article doesn't exist"
    },
    undefinedDesc: {
        de: "Wir konnten diesen Artikel leider nicht finden.",
        fr: "Désolé, nous n'avons pas pu trouver cet article.",
        en: "Sorry, we have not been able to find this article."
    },
    undefinedDescExt: {
        de: "Versuche es stattdessen bitte mit einem anderen. Da findet sich bestimmt was :)",
        fr: "Tu peux en essayer un autre à la place. Sûrement il se trouvera quelque chose :)",
        en: "Please try another one instead. There is bound to be something :)"
    }
}

export const channelData = {
    readMore: {
        de: "Mehr lesen",
        fr: "Lire plus",
        en: "Read more"
    },
    following: {
        de: "Folge Ich",
        fr: "Suivi",
        en: "Following"
    },
    stopFollowing: {
        de: "Nicht mehr folgen?",
        fr: "Ne plus suivre?",
        en: "Stop following?"
    }
}

export const searchData = {
    urlSearchTitle: {
        de: "Suche mit Url",
        fr: "Recherche avec Url",
        en: "Search With Url"
    },
    urlSearchDesc: {
        de: "Hast du einen Artikel Link bei dem du glaubst, dass dieser bei uns verfügbar sein könnte? Finde es hier heraus!",
        fr: "Tu as une adresse d'article qui pourrait être disponible sur notre site? Découvre-le ici!",
        en: "Do you have an article link that you believe might be available in our catalogue? Find out here!"
    },
    voiceSearch: {
        request: {
            title: {
                de: "Mikrofonzugriff",
                fr: "Accès au microphone",
                en: "Microphone Access"
            },
            desc: {
                de: "Um die Sprachsuche zu aktivieren, brauchen wir Zugriff auf dein Mikrofon.",
                fr: "Afin d'utiliser la recherche vocale, tu dois autoriser l'accès à ton microphone.",
                en: "In order to use voice search you need to enable access to your microphone."
            },
            action: {
                de: "Erlauben",
                fr: "Autoriser",
                en: "Grant Permission"
            }
        },
        pending: {
            title: {
                de: "Mikrofonzugriff",
                fr: "Accès au microphone",
                en: "Microphone Access"
            },
            desc: {
                de: 'Der Browser sollte nun eine Aufforderung anzeigen. Klicke auf "Zulassen" um die Sprachsuche gleich nutzen zu können!',
                fr: 'Le navigateur devrait afficher une invite en ce moment. Clique sur "autoriser" pour commencer à utiliser la recherche vocale maintenant!',
                en: 'The Browser should be showing a prompt right now. Click "allow" to start using voice search now!'
            }
        },
        granted: {
            title: {
                de: "Sprachsuche aktiviert",
                fr: "Recherche vocale activé",
                en: "Voice Search activated"
            },
            desc: {
                de: "Gute Entscheidung! Ab sofort brauchst du um die besten Artikel zu finden nicht einmal mehr deine Tastatur.",
                fr: "Bonne décision! Maintenant tu n'a même plus besoin de ton clavier pour trouver les meilleurs articles.",
                en: "Good decision! Now you don't even need your keyboard anymore to find the best articles."
            },
            action: {
                de: "Jetzt ausprobieren",
                fr: "Essayer maintenant",
                en: "Try it out now"
            }
        },
        denied: {
            title: {
                de: "Zugriff gescheitert",
                fr: "Accés échoué",
                en: "Access failed"
            },
            desc: {
                de: "Schade! Solltest du deine Meinung ändern musst du den Zugriff in den Browsereinstellungen anpassen.",
                fr: "Dommage! Si tu change d'avis, tu devra modifier les autorisations dans les paramètres du navigateur.",
                en: "That's unfortunate! In case you change your mind you will have to change permissions in the browser settings."
            },
            action: {
                de: "Mehr erfahren",
                fr: "Apprendre plus",
                en: "Learn more"
            }
        },
    },
    results: (lang, results, processingTime) => {
        let result = {
            de: `Ungefähr ${results}`,
            fr: `Environ ${results}`,
            en: `Approximately ${results}`
        }
        let noResult = {
            de: "Keine Treffer",
            fr: "Aucun résultat",
            en: "No results"
        }
        let resultExprPlural = {
            de: "Treffer in",
            fr: "résultats en",
            en: "results in"
        }
        let resultExprSingular = {
            de: "Treffer in",
            fr: "résultat",
            en: "result in"
        }
        let processingTimeToSeconds = (processingTime / 100).toString(); // really its /1000 but this appears unrealistic due to the added loading time of retrieving the desired article content
        let resultTime = {
            de: `${processingTimeToSeconds.replace(".", ",")} Sekunden`,
            fr: `${processingTimeToSeconds.replace(".", ",")} secondes`,
            en: `${processingTimeToSeconds} seconds`
        }
        if(results < 1) return noResult[lang]
        else return `${result[lang]} ${results > 1 ? resultExprPlural[lang] : resultExprSingular[lang]} ${resultTime[lang]}`
    },
    placeholder: {
        main: {
            de: "reavide Durchsuchen",
            fr: "Chercher en reavide",
            en: "Search reavide"
        },
        filter: {
            articles: {
                de: "Artikel finden",
                fr: "Trouver article",
                en: "Find Article"
            },
            lists: {
                de: "Liste finden",
                fr: "Trouver liste",
                en: "Find List"
            }
        },
        attributes: {
            de: "Suche nach",
            fr: "Chercher par",
            en: "Search by"
        }
    },
    filter: {
        title: {
            de: "Sortieren nach",
            fr: "Triér par",
            en: "Sort by"
        },
        options: {
            articles: {
                date: {
                    icon: "clock",
                    desc: {
                        de: "Hinzugefügt",
                        fr: "Ajouté",
                        en: "Added"
                    }
                },
                publisher: {
                    icon: "110014",
                    desc: {
                        de: "Zeitung",
                        fr: "Journal",
                        en: "Newspaper"
                    }
                },
                title: {
                    icon: "a_to_z",
                    desc: {
                        de: "Titel",
                        fr: "Titre",
                        en: "Title"
                    }
                },
                readingTime: {
                    icon: "timer",
                    desc: {
                        de: "Lesedauer",
                        fr: "Temps de lecture",
                        en: "Reading Time"
                    }
                },
                category: {
                    icon: "160011",
                    desc: {
                        de: "Kategorie",
                        fr: "Catégorie",
                        en: "Category"
                    }
                }
            },
            lists: {
                date: {
                    icon: "clock",
                    desc: {
                        de: "Datum",
                        fr: "Date",
                        en: "Date"
                    }
                },
                creator: {
                    icon: "account",
                    desc: {
                        de: "Ersteller",
                        fr: "Créateur",
                        en: "Creator"
                    }
                },
                title: {
                    icon: "a_to_z",
                    desc: {
                        de: "Titel",
                        fr: "Titre",
                        en: "Title"
                    }
                },
                length: {
                    icon: "length",
                    desc: {
                        de: "Länge",
                        fr: "Longueur",
                        en: "Length"
                    }
                }
            }
        }
    },
    attributes: [
        {
            uriQuery: "articleId",
            desc: {
                de: "Artikel ID",
                fr: "ID de l'article",
                en: "Article ID"
            }
        },
        {
            uriQuery: "author",
            desc: {
                de: "Autor",
                fr: "Auteur",
                en: "Author"
            }
        },
        {
            uriQuery: "tag",
            desc: {
                de: "Tag",
                fr: "Tag",
                en: "Tag"
            }
        },
        {
            uriQuery: "flair",
            desc: {
                de: "Flair",
                fr: "Flair",
                en: "Flair"
            }
        },
        {
            uriQuery: "topic",
            desc: {
                de: "Thema",
                fr: "Sujet",
                en: "Topic"
            }
        }
    ]
}

export const miscData = {
    articles: {
        de: "Artikel",
        fr: "Articles",
        en: "Articles"
    },
    by: {
        de: "von",
        fr: "par",
        en: "by"
    },
    copyright: {
        de: "Alle Rechte vorbehalten.",
        fr: "Tous droits réservés.",
        en: "All Rights Reserved."
    },
    search: {
        de: "Suchen",
        fr: "Chercher",
        en: "Search"
    },
    remove: {
        de: "Entfernen",
        fr: "Supprimer",
        en: "Remove"
    },
    or: {
        de: "oder",
        fr: "ou",
        en: "or"
    },
    you: {
        de: "dir",
        fr: "toi",
        en: "you"
    },
    here: {
        de: "hier",
        fr: "ici",
        en: "here"
    },
    more: {
        de: "Mehr erfahren",
        fr: "Apprendre plus",
        en: "Learn more"
    },
    loading: {
        de: "Lädt",
        fr: "Charge",
        en: "Loading"
    },
    title: {
        de: "Titel",
        fr: "Titre",
        en: "Title"
    },
    allow: {
        de: "Erlauben",
        fr: "Autoriser",
        en: "Allow"
    },
    read: {
        de: "Lesen",
        fr: "Lire",
        en: "Read"
    },
    desc: {
        de: "Beschreibung (Optional)",
        fr: "Description (Optionnel)",
        en: "Description (Optional)"
    },
    send: {
        de: "Senden",
        fr: "Envoyer",
        en: "Send"
    },
    private: {
        de: "Privat",
        fr: "Privé",
        en: "Private"
    },
    readingTime: {
        de: "Geschätzte Lesedauer",
        fr: "Durée de lecture estimée",
        en: "Estimated Reading Time"
    },
    uploadImage: {
        de: "Bild hochladen",
        fr: "Télécharger Image",
        en: "Upload Image"
    },
    changeImage: {
        de: "Bild ändern",
        fr: "Changer Image",
        en: "Change Image"
    },
    deleteImage: {
        de: "Bild löschen",
        fr: "Supprimer Image",
        en: "Delete Image"
    },
    dragndropImage: {
        de: "Ziehe ein Bild hierhin um es sofort hochzuladen.",
        fr: "Fais glisser et dépose ton image pour la télécharger.",
        en: "Drag and drop your image to upload it instantly."
    },
    public: {
        de: "Öffentlich",
        fr: "Publique",
        en: "Public"
    },
    confirmAction: {
        de: "Bitte bestätige deine Handlung um fortzufahren. Vielen Dank!",
        fr: "Confirme ton action pour procéder. Merci beaucoup!",
        en: "Please confirm your action in order to proceed. Thank You!"
    },
    confirmIrreversible: {
        de: "Bitte bestätige deine Handlung um fortzufahren. Dies kann leider nicht rückgängig gemacht werden.",
        fr: "Confirme ton action pour procéder. Cette action est irréversible.",
        en: "Please confirm your action in order to proceed. This cannot be undone."
    },
    confirm: {
        de: "Bestätigen",
        fr: "Confirmer",
        en: "Confirm"
    },
    cancel: {
        de: "Abbrechen",
        fr: "Annuler",
        en: "Cancel"
    },
    updated: {
        de: "aktualisiert",
        fr: "actualisé",
        en: "updated"
    },
    constUpdated: {
        de: "Inhalt wird laufend erweitert",
        fr: "Le contenu est élargi en permanence",
        en: "Content is constantly expanded"
    },
    updates: {
        de: "Aktualisierungen",
        fr: "Updates",
        en: "mises à jour"
    },
    writtenBy: {
        de: "Geschrieben von",
        fr: "Écris par",
        en: "Written by"
    }
}

export const timeData = {
    /*weekday: [
        {
            de: "Sonntag",
            fr: "Dimanche",
            en: "Sunday"
        },
        {
            de: "Montag",
            fr: "Lundi",
            en: "Monday"
        },
        {
            de: "Dienstag",
            fr: "Mardi",
            en: "Tuesday"
        },
        {
            de: "Mittwoch",
            fr: "Mercredi",
            en: "Wednesday"
        },
        {
            de: "Donnerstag",
            fr: "Jeudi",
            en: "Thursday"
        },
        {
            de: "Freitag",
            fr: "Vendredi",
            en: "Friday"
        },
        {
            de: "Samstag",
            fr: "Samedi",
            en: "Saturday"
        },
    ],
    month: [
        {
            de: "Januar",
            fr: "Janvier",
            en: "January"
        },
        {
            de: "Februar",
            fr: "Février",
            en: "February"
        },
        {
            de: "März",
            fr: "Mars",
            en: "March"
        },
        {
            de: "April",
            fr: "Avril",
            en: "April"
        },
        {
            de: "Mai",
            fr: "Mai",
            en: "May"
        },
        {
            de: "Juni",
            fr: "Juin",
            en: "June"
        },
        {
            de: "Juli",
            fr: "Juillet",
            en: "July"
        },
        {
            de: "August",
            fr: "Août",
            en: "August"
        },
        {
            de: "September",
            fr: "Septembre",
            en: "September"
        },
        {
            de: "Oktober",
            fr: "Octobre",
            en: "October"
        },
        {
            de: "November",
            fr: "Novembre",
            en: "November"
        },
        {
            de: "Dezember",
            fr: "Décembre",
            en: "December"
        },
    ],*/
    today: {
        de: "Heute",
        fr: "Aujourd'hui",
        en: "Today"
    },
    yesterday: {
        de: "Gestern",
        fr: "Hier",
        en: "Yesterday"
    },
    at: {
        de: "um",
        fr: "à",
        en: "at"
    }
}

export const timePassedData = {
    passed: {
        de: "Vor",
        fr: "Il y a",
        en: "ago"
    },
    year: {
        singular: {
            de: "Vor einem Jahr",
            fr: "Il y a une Année",
            en: "One Year ago"
        },
        plural: {
            de: "Jahren",
            fr: "Ans",
            en: "Years"
        },
    },
    month: {
        singular: {
            de: "Vor einem Monat",
            fr: "Il y a un Mois",
            en: "One Month ago"
        },
        plural: {
            de: "Monaten",
            fr: "Mois",
            en: "Months"
        },
    },
    day: {
        singular: {
            de: "Vor einem Tag",
            fr: "Il y a un Jour",
            en: "One Day ago"
        },
        plural: {
            de: "Tagen",
            fr: "Jours",
            en: "Days"
        },
    },
    hour: {
        singular: {
            de: "Vor einer Stunde",
            fr: "Il y a une Heure",
            en: "One Hour ago"
        },
        plural: {
            de: "Stunden",
            fr: "Heures",
            en: "Hours"
        },
    },
    minute: {
        singular: {
            de: "Vor einer Minute",
            fr: "Il y a une Minute",
            en: "One Minute ago"
        },
        plural: {
            de: "Minuten",
            fr: "Minutes",
            en: "Minutes"
        },
    },
    second: {
        singular: {
            de: "Vor einer Sekunde",
            fr: "Il y a une Seconde",
            en: "One Second ago"
        },
        plural: {
            de: "Sekunden",
            fr: "Secondes",
            en: "Seconds"
        },
    }
}

export const greetingData = {
    morning: {
        de: "Guten Morgen",
        fr: "Bon Matin",
        en: "Good Morning"
    },
    noon: {
        de: "Guten Mittag",
        fr: "Bon Midi",
        en: "Hey there"
    },
    afternoon: {
        de: "Guten Nachmittag",
        fr: "Bon Après-Midi",
        en: "Good Afternoon"
    },
    evening: {
        de: "Guten Abend",
        fr: "Bonne Soirée",
        en: "Good Evening"
    },
    night: {
        de: "Gute Nacht",
        fr: "Bonne Nuit",
        en: "Good Night"
    },
    weekend: {
        de: "Schönes Wochenende",
        fr: "Bon Weekend",
        en: "Nice Weekend"
    }
}

export const loginData = {
    login: {
        de: "Anmelden",
        fr: "Connecter",
        en: "Sign in"
    },
    logout: {
        de: "Ausloggen",
        fr: "Déconnecter",
        en: "Logout"
    },
    signup: {
        de: "Jetzt registrieren",
        fr: "Enregistrer maintenant",
        en: "Register now"
    },
    password: {
        de: "Passwort (min. 8 Zeichen)",
        fr: "Mot de passe (min. 8 caractères)",
        en: "Password (min. 8 characters)"
    },
    hasAccQ: {
        de: "Schon registriert?",
        fr: "Déjà enregistré?",
        en: "Already registered?"
    },
    hasNoAccQ: {
        de: "Noch keinen Account?",
        fr: "Pas encore de compte?",
        en: "No account yet?"
    },
    greeting: {
        de: "Herzlich Willkommen! \nGleich bist du dabei",
        fr: "Bienvenu! Tu est \ndéjà presque arrivé...",
        en: "Welcome! Boarding \nis as easy as 123..."
    },
    wallTitle: {
        de: "Danke für deinen Besuch bei",
        fr: "Merci pour ta visite chez",
        en: "Thank your for visiting"
    },
    wallSubTitle: {
        de: "Melde dich an um diesen Artikel und tausende weitere von diversen Zeitungen zu lesen!",
        fr: "Inscris-toi pour lire cet article et des milliers d'autres provenant de différents journaux!",
        en: "Sign up to read this article and thousands more from various newspapers!"
    },
    andMore: {
        de: "viele Mehr",
        fr: "beaucoup plus",
        en: "many more"
    },
    moreInfo: {
        de: "Mehr Informationen über uns gibts",
        fr: "Tu trouvera plus d'info sur nous",
        en: "You can find more information on us"
    },
    readDirect: (lang, newspaper) => {
        const directBase = {
            de: "Lese den Artikel direkt ",
            fr: "Lis cet article directement chez",
            en: "Read this article directly at"
        }
        const directPrepositionV1 = { de: "bei der" }
        const directPrepositionV2 = { de: "beim" }

        const v2PrepositionNewspapers = ["Tagblatt", "Badener Tagblatt", "Grenchner Tagblatt", "Oltner Tagblatt"]

        return directBase[lang] + (lang === "de" ? v2PrepositionNewspapers.includes(newspaper) ? directPrepositionV2[lang] : directPrepositionV1[lang] : "")
    },
    services: {
        login: {
            apple: {
                de: "Mit Apple anmelden",
                fr: "Connecter avec Apple",
                en: "Sign In with Apple"
            },
            google: {
                de: "Mit Google anmelden",
                fr: "Connecter avec Google",
                en: "Sign In with Google"
            },
            email: {
                de: "Mit Email anmelden",
                fr: "Connecter avec Email",
                en: "Sign In with Email"
            }
        },
        signup: {
            apple: {
                de: "Mit Apple registrieren",
                fr: "Registrer avec Apple",
                en: "Register with Apple"
            },
            google: {
                de: "Mit Google registrieren",
                fr: "Registrer avec Google",
                en: "Register with Google"
            },
            email: {
                de: "Mit Email registrieren",
                fr: "Registrer avec Email",
                en: "Register with Email"
            }
        }
    }
}

export const settingsData = {
    firstName: {
        de: "Vorname",
        fr: "Prénom",
        en: "First Name"
    },
    lastName: {
        de: "Nachname",
        fr: "Nom",
        en: "Last Name"
    },
    titles: {
        account: {
            de: "Account",
            fr: "Compte",
            en: "Account"
        },
        experience: {
            de: "Erfahrung",
            fr: "Expérience",
            en: "Experience"
        },
        misc: {
            de: "Weiteres",
            fr: "Plus",
            en: "Miscellaneous"
        }
    },
    embeds: {
        general: {
            allowAll: {
                de: "Alle erlauben",
                fr: "Permettre tous",
                en: "Allow All"
            },
            info: {
                de: "Wenn du einem Anbieter oder allen zustimmst, werden die entsprechenden Inhalte ab sofort angezeigt ohne manuelle Bestätigung. Diese Inhalte werden jeweils von externen Websiten bereitgestellt. Dies bedeutet, dass nicht verifizierte Cookies von Drittanbietern verwendet werden können, um deine Browseraktivitäten zu verfolgen. Um mehr über die jeweiligen Datenschutzerklärungen zu erfahren, klicke auf das Cookie Symbol beim Anbieter.",
                fr: "Si tu acceptes un ou plusieurs fournisseurs, le contenu correspondant s'affichera immédiatement sans confirmation manuelle. Ces contenus sont toujours fournis par des sites web externes. Cela signifie que des cookies non vérifiés peuvent être utilisés par des entreprises tiers pour suivre ton activité de navigation. Pour en savoir plus sur les déclarations de confidentialité respectives, clique sur le symbole de cookie sur la ligne du fournisseur.",
                en: "Whether you agree to one or all providers, the corresponding content will be displayed without manual confirmation from then on. This content includes interactive graphics, livestreams, news tickers etc. and is provided by external websites. This means that unverified third-party cookies may be used to track your browsing activity. To learn more about the respective privacy statements, click on the cookie icon in the provider's row."
            }
        },
        specific: {
            embedProviders: {
                de: "Liste der Anbieter",
                fr: "Origines du contenu integré",
                en: "Embed Providers"
            },
            info: {
                de: '* Das "+" bei manchen Anbietern bedeutet, dass diese selbst dynamisch Inhalte laden können, über deren Anbieter wir wiederum keine Kontrolle haben und die ebenfalls Cookies in deinem Browser platzieren könnten.',
                fr: 'Le « + » de certains fournisseurs signifie qu\'ils peuvent eux-mêmes charger dynamiquement des contenus sur lesquels nous n\'avons à nouveau aucun contrôle et qui pourraient placer des cookies dans ton navigateur.',
                en: '* The "+" at certain providers means, that they themselves are capable of further loading more dynamic content whose providers in turn we have no control over and who could further place cookies in your browser.'
            }
        }
    },
    account: {
        email: {
            de: "Email",
            fr: "Email",
            en: "Email"
        },
        subscription: {
            de: "Abonnement verwalten",
            fr: "Menager Abonnement",
            en: "Manage Subscription"
        },
        password: {
            de: "Passwort ändern",
            fr: "Changer Mot de passe",
            en: "Reset Password"
        }
    },
    experience: {
        theme: {
            de: "Farbmodus",
            fr: "Mode de Couleur",
            en: "Color Mode"
        },
        language: {
            de: "Sprache",
            fr: "Langue",
            en: "Language"
        },
        region: {
            de: "Region",
            fr: "Région",
            en: "Region"
        },
        embeds: {
            de: "Eingebettete Inhalte",
            fr: "Contenu intégré",
            en: "Embedded Content"
        }
    },
    misc: [
        {
            icon: "about",
            type: "external",
            link: "reavi.de/about",
            desc: {
                de: "Über Uns",
                fr: "Sur Nous",
                en: "About Us"
            }
        },
        {
            icon: "faq",
            type: "external",
            link: "reavi.de/faq",
            desc: {
                de: "FAQ",
                fr: "FAQ",
                en: "FAQ"
            }
        },
        {
            icon: "impressum",
            type: "external",
            link: "reavi.de/impressum",
            desc: {
                de: "Impressum",
                fr: "Impressum",
                en: "Impressum"
            }
        },
        {
            icon: "support",
            type: "external",
            link: "reavi.de/support",
            desc: {
                de: "Support",
                fr: "Assistance",
                en: "Support"
            }
        },
        {
            icon: "tos",
            type: "external",
            link: "reavi.de/tos",
            desc: {
                de: "Nutzungsbedingungen",
                fr: "Conditions d'Utilisation",
                en: "Terms of Use"
            }
        },
        {
            icon: "privacy",
            type: "external",
            link: "reavi.de/privacy",
            desc: {
                de: "Datenschutzerklärung",
                fr: "Politique de Confidentialité",
                en: "Privacy Policy"
            }
        },
        {
            icon: "updates",
            type: "external",
            link: "reavi.de/updates",
            desc: {
                de: "Update Hinweise",
                fr: "Notes d'Actualisation",
                en: "Release Notes"
            }
        },
        {
            icon: "blog",
            type: "external",
            link: "reavi.de/blog",
            desc: {
                de: "Blog",
                fr: "Blog",
                en: "Blog"
            }
        }
    ],
    theme: {
        dark: {
            de: "Dunkel",
            fr: "Nuit",
            en: "Dark"
        },
        auto: {
            de: "Auto",
            fr: "Auto",
            en: "Auto"
        },
        light: {
            de: "Hell",
            fr: "Jour",
            en: "Light"
        }
    }
}

export const signUpTermsData = (lang) => {
    if (lang === "de") {
        return (
            <span>
                Ich akzeptiere die
                <a href={"https://reavi.de/tos"} target={"#"}> Nutzungsbedingungen </a>
                und
                <a href={"https://reavi.de/privacy"} target={"#"}> Datenschutzerklärung</a>
                .
            </span>
        )
    } else if (lang === "fr") {
        return (
            <span>
                J'accepte les
                <a href={"https://reavi.de/tos"} target={"#"}> conditions d'utilisation </a>
                et la
                <a href={"https://reavi.de/privacy"} target={"#"}> politique de confidentialité</a>
                .
            </span>
        )
    } else {
        return (
            <span>
                I accept the
                <a href={"https://reavi.de/tos"} target={"#"}> Terms of service </a>
                and
                <a href={"https://reavi.de/privacy"} target={"#"}> Privacy Policy</a>
                .
            </span>
        )
    }
}

export const errorData = {
    accAlreadyExists: {
        de: "Account existiert bereits",
        fr: "Compte existe déjà",
        en: "Acount already exists"
    },
    wrongCredentials :{
        de: "Inkorrekte Angaben",
        fr: "Mauvaises Données",
        en: "Incorrect Credentials"
    },
    noUserFound: {
        de: "Nutzer existiert nicht",
        fr: "Utilisateur n'éxiste pas",
        en: "User doesn't exist"
    },
    defaultError: {
        de: "Es ist ein Fehler aufgetreten",
        fr: "Une erreur s'est produite",
        en: "An Error occurred"
    },
    articleOmitted: (lang, num) => {
        const omittedSingular = {
            de: "nicht verfügbarer Artikel versteckt",
            fr: "article non-disponible caché",
            en: "unavailable article hidden"
        }
        const omittedPlural = {
            de: "nicht verfügbare Artikel versteckt",
            fr: "articles non-disponibles cachés.",
            en: "unavailable articles hidden"
        }

        if(num === 1) return omittedSingular[lang]
        else return omittedPlural[lang]
    },
    contentError: {
        info: {
            de: "Leider ist ein Fehler passiert. \nBitte überprüfe deine Internetverbindung.",
            fr: "Malheureusement une erreur s'est produite. \nVérifie ta connexion d'Internet.",
            en: "Unfortunately an error happened. \nPlease check your internet connection."
        },
        action: {
            de: "Erneut versuchen",
            fr: "Réessayer",
            en: "Try again"
        }
    },
    articleMissing: {
        info: {
            de: "Leider haben wir zu deiner Suche keinen Artikel finden können :(",
            fr: "Malheureusement nous avons pas pû trouver un article correspendant à ta recherche :(",
            en: "Unfortunately we couldn't find an article matching your search :("
        },
        action: {
            de: "Weiterlesen",
            fr: "Continuer à lire",
            en: "Continue reading"
        }
    },
    noChannels: {
        info: {
            de: "Sobald du einigen Channels folgst, wirst du diese hier wiederfinden.",
            fr: "Une fois que tu aura suivi quelques canaux, tu les retrouvera ici.",
            en: "As soon as you follow some channels you will find them here."
        },
        action: {
            de: "Channels entdecken",
            fr: "Découvrir Canaux",
            en: "Discover Channels"
        }
    },
    noChannelsHome: {
        info: {
            de: "Wähle aus einer grossen Auswahl von Themen und personalisiere so deinen Feed entsprechend deinen Interessen!",
            fr: "Choisis d'une grande séléction de sujets pour ainsi personaliser ton feed selon tes interêts!",
            en: "Choose from a wide variety of tops and customize your personal feed according to your interests!"
        },
        action: {
            de: "Channels entdecken",
            fr: "Découvrir Canaux",
            en: "Discover Channels"
        }
    },
    noBookmarks: {
        info: {
            de: "Wie es scheint hast du noch keine Lesezeichen gesetzt...",
            fr: "Il semble que tu n'as pas encore créé des signets...",
            en: "It seems like you haven't bookmarked any articles yet..."
        },
        action: {
            de: "Finde Artikel",
            fr: "Trouver articles",
            en: "Find articles"
        }
    },
    noHistory: {
        info: {
            de: "Wie es scheint hast du noch keine Artikel gelesen. Auf geht's!",
            fr: "Il semble que tu n'as pas encore lu d'articles. Allons y!",
            en: "It seems like you haven't read any articles yet. Let's go!"
        },
        action: {
            de: "Artikel lesen",
            fr: "Lire articles",
            en: "Read articles"
        }
    },
    noLists: {
        info: {
            de: "Wie es scheint hast du noch keine Leseliste erstellt...",
            fr: "Il semble que tu n'as pas encore crée une liste de lécture...",
            en: "It seems like you haven't created any reading lists yet..."
        },
        action: {
            de: "Liste erstellen",
            fr: "Créer Liste",
            en: "Create List"
        }
    },
    noSearch: {
        info: {
            de: "Leider konnten wir keine Ergebnisse für deine Suche finden...",
            fr: "Malheureusement nous avons pas pû trouver de résultats pour ta recherche...",
            en: "Unfortunately we couldn't find any results for your search..."
        },
        action: {
            de: "Neue Suche",
            fr: "Nouvelle recherche",
            en: "New Search"
        }
    },
    noCollection: {
        info: {
            de: "Sieht so aus als hättest du noch keine passenden Artikel für diese Liste gefunden...",
            fr: "On dirait que tu n'as pas encore trouvé d'articles correspondants à cette liste...",
            en: "Looks like you haven't found any articles to store in this list yet..."
        },
        action: {
            de: "Finde Artikel",
            fr: "Trouver articles",
            en: "Find articles"
        }
    },
}

export const geoData = {
    language: {
        de: "Deutsch",
        fr: "Français",
        en: "English"
    }
}

export const shareData = {
    title: {
        article: {
            de: "Artikel teilen",
            fr: "Partager Article",
            en: "Share Article"
        },
        channel: {
            de: "Channel teilen",
            fr: "Partager Canal",
            en: "Share Channel"
        }
    },
    sites: {
        twitter: {
            icon: "twitter",
            desc: {
                de: "Auf Twitter teilen",
                fr: "Partager sur Twitter",
                en: "Share on Twitter"
            }
        },
        reddit: {
            icon: "reddit",
            desc: {
                de: "Auf Reddit teilen",
                fr: "Partager sur Reddit",
                en: "Share on Reddit"
            }
        },
        facebook: {
            icon: "facebook",
            desc: {
                de: "Auf Facebook teilen",
                fr: "Partager sur Facebook",
                en: "Share on Facebook"
            }
        },
        linkedin: {
            icon: "linkedin",
            desc: {
                de: "Auf LinkedIn teilen",
                fr: "Partager sur LinkedIn",
                en: "Share on LinkedIn"
            }
        },
        whatsapp: {
            icon: "whatsapp",
            desc: {
                de: "Auf WhatsApp teilen",
                fr: "Partager sur WhatsApp",
                en: "Share on Whatsapp"
            }
        },
        separator: { type: "separator" },
        email: {
            icon: "email",
            desc: {
                de: "Per Email teilen",
                fr: "Partager par Email",
                en: "Share via Email"
            }
        },
        link: {
            icon: "link",
            desc: {
                de: "Quick Link kopieren",
                fr: "Copier Quick Link",
                en: "Copy Quick Link"
            }
        },
        id: {
            icon: "fingerprint",
            desc: {
                de: "Artikel ID kopieren",
                fr: "Copier ID de l'article",
                en: "Copy Article ID"
            }
        },
    },
    content: {
        subject: {
            de: "Dieser Artikel auf reavide News ist für dich!",
            fr: "Cet article sur reavide News est pour toi!",
            en: "This article on reavide News is for you!"
        },
        greeting: {
            de: "Hey, ich denke dieser Artikel wäre was für dich:",
            fr: "Salut, je pense que cet article serait intéressant pour toi:",
            en: "Hey, just thought you should check this one out:"
        },
        article: {
            de: "\n\nLese diese Story und noch mehr hier",
            fr: "\n\nLis cet article et plus ici",
            en: "\n\nRead this story and more here"
        },
        channel: {
            de: "ist ein total spannender Channel!\n\nEntdecke diesen und mehr hier",
            fr: "est un canal super interessant!\n\nDécouvre celui-ci et plus ici",
            en: "is a super interesting Channel!\n\nDiscover this one and more here"
        }
    }
}

export const accountData = {
    targetReads: {
        today: {
            de: "Heute",
            fr: "Aujourd'hui",
            en: "Today"
        },
        setTarget: {
            de: "Passe dein Ziel an",
            fr: "Ajuste ton bût",
            en: "Set your Target"
        },
        setTargetInfo: {
            de: "Ein tägliches Ziel für deine Leseroutine. Du kannst es jederzeit anpassen wenn du dich wohl fühlst.",
            fr: "Un bût pour ta routine de lecture quotidienne. Tu peux l'ajuster au fil du temps quand tu te sens à l'aise.",
            en: "A goal for your everyday reading routine. You can always increase it over time when you feel comfortable."
        },
        title: {
            de: "Leseziel",
            fr: "Bût du jour",
            en: "Target Reads"
        },
        desc: {
            de: "Wie viele Artikel du täglich lesen willst.",
            fr: "Combien d'articles tu aimerais lire par jour.",
            en: "How many articles you want to read per day."
        },
        adjust: {
            de: "Ziel anpassen",
            fr: "Ajuster l'objectif",
            en: "Adjust Target"
        },
        newCantBeOld: {
            de: "Dein gewähltes Ziel stimmt mit dem alten überein. Bitte wähle ein anderes.",
            fr: "L'objectif choisi correspont avec l'ancien. Choisis-en un autre.",
            en: "The set goal is equal to your current target. Please choose another one."
        }
    },
    barChart: {
        title: {
            de: "Zeitlicher Fortschritt",
            fr: "Progrès dans le temps",
            en: "Progress over Time"
        },
        labels: {
            short: {
                de: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
                fr: ["Lu", "Ma", "Me", "Je", "Ve", "Sa", "Di"],
                en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            },
            long: {
                de: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
                fr: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
                en: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
            },
        },
        options: {
            week: {
                de: "Woche",
                fr: "Semaine",
                en: "Week"
            },
            "30days": {
                de: "30 Tage",
                fr: "30 jours",
                en: "30 days"
            }
        }
    },
    pieChart: {
        title: {
            de: "Meine Kategorien",
            fr: "Mes Catégories",
            en: "My Categories"
        },
        labels: {
            de: ["Politik", "Gesellschaft", "Sport", "Wissenschaft", "Technologie", "Wirtschaft", "Spezial"],
            fr: ["Politique", "Société", "Sport", "Science", "Technologie", "Économie", "Spécial"],
            en: ["Politics", "Society", "Sports", "Science", "Technology", "Economy", "Special"]
        },
        options: {
            week: {
                de: "Woche",
                fr: "Semaine",
                en: "Week"
            },
            "30days": {
                de: "30 Tage",
                fr: "30 jours",
                en: "30 days"
            }
        }
    },
    highlights: {
        currentStreak: {
            de: "Anzahl erfüllter Ziele",
            fr: "Nombre de bûts completés",
            en: "Current Streak of Targets"
        },
        maxStreak: {
            de: "Längste Anzahl von Zielen",
            fr: "Ton record personnel de bûts",
            en: "Longest Streak of Targets"
        },
        weeklyTotal: {
            de: "Diese Woche gelesen",
            fr: "Articles lu cette Semaine",
            en: "Articles Read this Week"
        },
        totalArticles: {
            de: "Total der Artikel gelesen",
            fr: "Numéro total d'articles",
            en: "Total Number of Articles"
        },
        weeklyTrend: {
            de: "Vergleich zu letzter Woche",
            fr: "Trend de cette semaine",
            en: "Compared to Last Week"
        },
        avgArticles: {
            de: "Total der Artikel pro Tag",
            fr: "Moyenne d'articles par jour",
            en: "Total Articles per Day"
        }
    }
}

export const listsData = {
    title: {
        de: "Optionen",
        fr: "Options",
        en: "Options"
    },
    options: {
        edit: {
            icon: "edit",
            desc: {
                de: "Bearbeiten",
                fr: "Modifier",
                en: "Edit Details"
            },
        },
        pin: {
            type: "switch",
            true: {
                icon: "unpin",
                desc: {
                    de: "Liste entpinnen",
                    fr: "Désépingler liste",
                    en: "Unpin List"
                },
            },
            false: {
                icon: "pin",
                desc: {
                    de: "Liste pinnen",
                    fr: "Épingler liste",
                    en: "Pin List"
                },
            }
        },
        separator: {
            type: "separator"
        },
        delete: {
            type: "delete",
            icon: "delete",
            desc: {
                de: "Löschen",
                fr: "Supprimer",
                en: "Delete"
            },
        },
    },
    readingList: {
        de: "Leseliste",
        fr: "Liste de lecture",
        en: "Reading List"
    },
    addToList: {
        de: "Zu Liste hinzufügen",
        fr: "Ajouter à liste",
        en: "Add To List"
    },
    addToListDesc: {
        de: "Speichere den ausgewählten Artikel in einer deiner praktischen Leselisten.",
        fr: "Enregistre l'article sélectionné à l'une de tes listes de lecture pratiques.",
        en: "Save the selected article to one of your handy reading lists."
    },
    addNewList: {
        de: "Liste hinzufügen",
        fr: "Ajouter Liste",
        en: "Add New List"
    },
    createList: {
        de: "Neue Liste erstellen",
        fr: "Créer nouvelle liste",
        en: "Create new List"
    },
    createListDesc: {
        de: "Organisiere deine Lieblingsartikel in praktischen Leselisten, um sie später schnell wiederzufinden.",
        fr: "Organise tes articles préférés dans des listes de lecture pratiques pour les retrouver facilement plus tard.",
        en: "Organize your favorite articles in handy reading lists to find them again later."
    },
    deleteList: {
        de: "Liste löschen",
        fr: "Supprimer liste",
        en: "Delete List"
    },
    editList: {
        de: "Liste anpassen",
        fr: "Éditer liste",
        en: "Edit List"
    }
}

export const feedbackData = {
    title: {
        de: "Feedback geben",
        fr: "Donner feedback",
        en: "Give Feedback"
    },
    desc: {
        de: "Hast du einen Fehler entdeckt oder möchtest uns mitteilen was wir in Zukunft besser machen könnten?",
        fr: "As-tu trouvé une erreur ou souhaites-tu nous dire ce que nous pourrions améliorer ou ajouter à l'avenir?",
        en: "Have you found a bug or want to tell us what we could do better in the future?"
    },
    types: {
        bugReport: {
            type: "bugReport",
            icon: "bug",
            desc: {
                de: "Fehler melden",
                fr: "Rapport d'erreur",
                en: "Report Bug"
            },
            cta: {
                de: "Bitte beschreibe das Problem",
                fr: "Décris l'erreur s'il te plaît",
                en: "Please describe the problem"
            }
        },
        idea: {
            type: "idea",
            icon: "idea",
            desc: {
                de: "Funktion vorschlagen",
                fr: "Proposer fonctionnalité",
                en: "Request Feature"
            },
            cta: {
                de: "Bitte beschreibe deine Idee",
                fr: "Décris ton idée s'il te plaît",
                en: "Please describe your idea"
            }
        }
    },
    disclaimer: (lang) => {
        if (lang === "de") {
            return (
                <span>
                        Bei Fragen zu News oder deinem Account wende dich bitte an
                        <a href={"https://reavi.de/faq"} target={"#"}> unser FAQ</a>
                        . Kontaktiere uns bei Hinweisen zur Sicherheit unserer App
                        <a href={"mailto:security@reavide.com"} target={"#"}> bitte direkt</a>.
                    </span>
            )
        } else if (lang === "fr") {
            return (
                <span>
                        Si tu a des questions concernant News ou ton compte, consulte
                        <a href={"https://reavi.de/faq"} target={"#"}> notre FAQ</a>
                        . Pour toute enquête parvenant à la sécurité de notre application,
                        <a href={"mailto:security@reavide.com"} target={"#"}> contacte nous </a>
                        directement s’il te plaît.
                    </span>
            )
        } else {
            return (
                <span>
                        Questions about News or your account? Kindly refer to
                        <a href={"https://reavi.de/faq"} target={"#"}> our FAQ</a>
                        . For all enquiries concerning the security of our app, please
                        <a href={"mailto:security@reavide.com"} target={"#"}> contact us </a>
                        directly.
                    </span>
            )
        }
    },
    attachments: {
        screenshot: {
            de: "Screenshot beifügen",
            fr: "Inclure capture d'écran",
            en: "Include Screenshot"
        },
        traceLog: {
            de: "Aktivitätslog beifügen",
            fr: "Inclure journal d'activités",
            en: "Include Trace Logs"
        }
    }
}

export const consentData = {
    embeddedContent: {
        de: "Eingebetteter Inhalt",
        fr: "Contenu intégré",
        en: "Embedded Content"
    },
    embeddedInfo: {
        de: "Der folgende Inhalt wird von einer externen Website bereitgestellt. Dies bedeutet, dass nicht verifizierte Cookies von Drittanbietern verwendet werden können, um deine Browseraktivitäten zu verfolgen.",
        fr: "Le contenu suivant est fourni par un site web externe. Cela signifie que des cookies tiers non vérifiés peuvent être utilisés pour suivre tes activité de navigation.",
        en: "The following embedded content is provided by an external website. This means that unverified third-party cookies can be used to track you depending on your Browser settings."
    },
    followLink: {
        de: "Link folgen",
        fr: "Suivir link",
        en: "Follow Link"
    },
    providerPrivacy: {
        "admin.ch": {
            de: "https://www.bfs.admin.ch/bfs/de/home/bfs/bundesamt-statistik/rechtliche-hinweise.html",
            fr: "https://www.bfs.admin.ch/bfs/fr/home/ofs/office-federal-statistique/informations-juridiques.html",
            en: "https://www.bfs.admin.ch/bfs/en/home/fso/swiss-federal-statistical-office/legal-framework.html"
        },
        bbvms: {
            de: "https://www.bluebillywig.com/privacy-statement/",
            fr: "https://www.bluebillywig.com/privacy-statement/",
            en: "https://www.bluebillywig.com/privacy-statement/"
        },
        datawrapper: {
            de: "https://www.datawrapper.de/privacy",
            fr: "https://www.datawrapper.de/privacy",
            en: "https://www.datawrapper.de/privacy"
        },
        facebook: {
            de: "https://www.facebook.com/policy.php",
            fr: "https://www.facebook.com/policy.php",
            en: "https://www.facebook.com/policy.php"
        },
        flourish: {
            de: "https://flourish.studio/privacy/",
            fr: "https://flourish.studio/privacy/",
            en: "https://flourish.studio/privacy/"
        },
        genially: {
            de: "https://genial.ly/cookies/",
            fr: "https://genial.ly/cookies/",
            en: "https://genial.ly/cookies/"
        },
        giphy: {
            de: "https://support.giphy.com/hc/en-us/articles/360032872931",
            fr: "https://support.giphy.com/hc/en-us/articles/360032872931",
            en: "https://support.giphy.com/hc/en-us/articles/360032872931"
        },
        "google-maps": {
            de: "https://policies.google.com/privacy",
            fr: "https://policies.google.com/privacy",
            en: "https://policies.google.com/privacy"
        },
        "gr.ch": {
            de: "https://www.gr.ch/de/Seiten/Impressum.aspx",
            fr: "https://www.gr.ch/en/Seiten/Impressum.aspx",
            en: "https://www.gr.ch/en/Seiten/Impressum.aspx"
        },
        infogram: {
            de: "https://infogram.com/de/privacy",
            fr: "https://infogram.com/fr/privacy",
            en: "https://infogram.com/privacy"
        },
        instagram: {
            de: "https://help.instagram.com/1896641480634370/",
            fr: "https://help.instagram.com/1896641480634370/",
            en: "https://help.instagram.com/1896641480634370/"
        },
        "keystone-video": {
            de: "https://www.keystone-sda.ch/web/guest/datenschutz",
            fr: "https://www.keystone-sda.ch/fr/protection-des-donnees",
            en: "https://www.keystone-sda.ch/en/data-protection"
        },
        knightlab: {
            de: "https://timeline.knightlab.com/docs/",
            fr: "https://timeline.knightlab.com/docs/",
            en: "https://timeline.knightlab.com/docs/"
        },
        livecenter: {
            de: "https://livecenter.com/cookie-policy/",
            fr: "https://livecenter.com/cookie-policy/",
            en: "https://livecenter.com/cookie-policy/"
        },
        nhl: {
            de: "https://www.nhl.com/de/info/privacy-policy",
            fr: "https://www.nhl.com/fr/info/privacy-policy",
            en: "https://www.nhl.com/info/privacy-policy"
        },
        pageflow: {
            de: "https://www.pageflow.io/datenschutzerklaerung/",
            fr: "https://www.pageflow.io/en/privacy-policy/",
            en: "https://www.pageflow.io/en/privacy-policy/"
        },
        pinterest: {
            de: "https://policy.pinterest.com/de/privacy-policy",
            fr: "https://policy.pinterest.com/fr/privacy-policy",
            en: "https://policy.pinterest.com/en/privacy-policy"
        },
        playbuzz: {
            de: "https://www.playbuzz.com/privacy-policy",
            fr: "https://www.playbuzz.com/privacy-policy",
            en: "https://www.playbuzz.com/privacy-policy"
        },
        "sda-liveblog": {
            de: "https://liveblog.pro/de/termsofuse/",
            fr: "https://liveblog.pro/fr/termsofuse/",
            en: "https://liveblog.pro/en/termsofuse/"
        },
        "sg.ch": {
            de: "https://www.sg.ch/f/datenschutz-sg-ch.html",
            fr: "https://www.sg.ch/f/datenschutz-sg-ch.html",
            en: "https://www.sg.ch/f/datenschutz-sg-ch.html"
        },
        sketchfab: {
            de: "https://sketchfab.com/privacy",
            fr: "https://sketchfab.com/privacy",
            en: "https://sketchfab.com/privacy"
        },
        sportresultate: {
            de: "https://www.luzernerzeitung.ch/services/impressum/datenschutzerklaerung-ld.1022830",
            fr: "https://www.luzernerzeitung.ch/services/impressum/datenschutzerklaerung-ld.1022830",
            en: "https://www.luzernerzeitung.ch/services/impressum/datenschutzerklaerung-ld.1022830"
        },
        spotify: {
            de: "https://www.spotify.com/ch-de/legal/privacy-policy/",
            fr: "https://www.spotify.com/ch-fr/legal/privacy-policy/",
            en: "https://www.spotify.com/uk/legal/privacy-policy/"
        },
        "srf-player": {
            de: "https://www.srf.ch/hilfe/rechtliches/datenschutzerklaerung",
            fr: "https://www.srf.ch/hilfe/rechtliches/datenschutzerklaerung",
            en: "https://www.srf.ch/hilfe/rechtliches/datenschutzerklaerung"
        },
        srgssr: {
            de: "https://www.srgssr.ch/de/datenschutz",
            fr: "https://www.srgssr.ch/fr/protection-donees",
            en: "https://www.srgssr.ch/en/data-privacy"
        },
        twitter: {
            de: "https://privacy.twitter.com/de",
            fr: "https://privacy.twitter.com/fr",
            en: "https://privacy.twitter.com/en"
        },
        wilmaa: {
            de: "https://www.wilmaa.com/de/privacy-policy/",
            fr: "https://www.wilmaa.com/fr/privacy-policy/",
            en: "https://www.wilmaa.com/en/privacy-policy/"
        }
    },
    providerCookies: {
        "admin.ch": "6",
        bbvms: "13",
        datawrapper: "1",
        facebook: "5",
        flourish: "1",
        genially: "8",
        giphy: "7",
        "google-maps": "6",
        "gr.ch": "3",
        infogram: "16",
        instagram: "11",
        knightlab: "6",
        "keystone-video": "4",
        livecenter: "2+",
        nhl: "34",
        pageflow: "1",
        pinterest: "21",
        playbuzz: "28",
        "sda-liveblog": "7+",
        "sg.ch": "3",
        sketchfab: "14",
        sportresultate: "39+",
        spotify: "3",
        "srf-player": "15",
        srgssr: "2",
        twitter: "2",
        wilmaa: "13"
    },
    providerNames: {
        "admin.ch": "admin.ch",
        bbvms: "BBVMS",
        "google-maps": "Google-Maps",
        "gr.ch": "gr.ch",
        "keystone-video": "Keystone-Video",
        nhl: "NHL",
        "sda-liveblog": "SDA-Liveblog",
        "sg.ch": "sg.ch",
        "srf-player": "SRF-Player",
        srgssr: "SRGSSR"
    },
    cookiesPlural: {
        de: "Cookies verwendet",
        fr: "Cookies utilisés",
        en: "Cookies used"
    },
    cookiesSingular: {
        de: "Cookie verwendet",
        fr: "Cookie utilisé",
        en: "Cookie used"
    }
}