import styled from "styled-components";
import { color } from "../../design/theme";

export const ToggleSwitchContainer = styled.div`
    width: 40px;
    height: 24px;
    padding: 2px;
    border-radius: 20px !important;
    background-color: ${props => props.active ? color.blue : props.darkmode ? color.gray3 : color.gray4};
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    opacity: ${props => props.disabled ? "0.4" : "1"};
    display: flex;
    justify-content: ${props => props.active ? "flex-end" : "flex-start"};
`

export const ToggleSwitchButton = styled.div`
    width: 20px;
    height: 100%;
    border-radius: 20px !important;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
`