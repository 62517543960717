import styled from "styled-components";
import { color, font, radius } from "../../../design/theme";
import { textColor } from "../../../components/general.utils";
import Icon from "../../../design/icons/icon.component";

export const ListViewHeaderContainer = styled.section`
    width: inherit;
    height: auto;
    text-align: left;
    justify-content: left;
    margin: 80px 0 30px 0;
    position: relative;
    display: flex;
`;

export const ListViewHeaderImage = styled.div`
    height: 150px;
    width: 150px;
    margin-right: 20px;
    border-radius: ${radius.smallRadius};

    @media only screen and (max-width: 750px) {
        height: 100px;
        width: 100px;
    }
`

export const ListViewHeaderContentContainer = styled.div`
    display: flex;
    width: available;
    flex-grow: 100;
    flex-direction: column;
    text-align: left;
    align-content: center;
    justify-content: center;
`

export const ListViewHeaderTitle = styled.h1`
    font-size: 60px;
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    margin: 5px 0 0 0;

    @media only screen and (max-width: 750px) {
        font-size: 40px;
    }
`

export const ListViewHeaderSubtitle = styled.div`
    font-size: 16px;
    font-family: ${font.pulpDisplayBold};
    letter-spacing: 0.02em;
    color: ${props => props.darkmode ? color.gray3 : color.gray2};
`

export const ListViewHeaderInfoWrapper = styled.div`
    margin-top: 20px;
    width: inherit;
    height: auto;
  
    &>div:first-child {
        margin-bottom: 10px;
    }
`;

export const ListViewHeaderInfoContainer = styled.div`
    width: inherit;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-left: 2px;
`;

export const ListViewHeaderInfoIconContainer = styled(Icon)`
    height: 100%;
    width: 25px;
    display: flex;
`;

export const ListViewHeaderInfoTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    width: auto;
    margin-left: 10px;
`

export const ListViewHeaderInfoTextContainer = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    word-break: break-word;
    line-height: 22px;
    letter-spacing: 0.045em;
    margin-top: ${props => props.desc ? "10px" : "0"};
    color: ${props => props.desc ? props.darkmode ? color.gray3 : color.gray2 : textColor(props.darkmode)};
    height: inherit;
    width: auto;

    &>span:nth-child(0n + 2) {
        color: ${color.blue};
    }
`