import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import { categoryColor } from "../general.utils";
import { Link } from "react-router-dom";

export const ArticleCardWrapper = styled.div`
    width: 100%;
    height: 300px;
    border-radius: ${radius.largeRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;
    user-select: none;
    transform: translate3d(0,0,0); // forces tap into webGPU
    content-visibility: auto;
    // will-change: scroll-position; --> moved into renderController
    
    &:hover  {
        // transform: scale(1.01);
        // transition: 0.1s;
        box-shadow: 0 0 0 2px ${color.blue};
        outline: none;
    }
    &:active {
        transform: scale(0.98);
        // transition: 0.1s;
        outline: none;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }

    @media only screen and (max-width: 750px) {
        height: 270px;
    }
`;

export const ArticleCardContainer = styled.div`
    height: inherit;
    width: inherit;
    position: relative; 
    display: flex;
`

export const ArticleCardLink = styled(Link)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
`

export const ArticleCardImageContainer = styled.div`
    right: 0;
    width: 50%;
    height: 100%;
    position: relative;
    display: block;

    @media only screen and (max-width: 750px) {
        width: 40%;
    }
`;

export const ArticleCardImage = styled.div`
    width: 100%;
    height: 100%;
    opacity: ${props => parseInt(props.progress) >= 80 ? 0.3 : 1};
`;

export const ArticleCardContentContainer = styled.div`
    width: 50%;
    text-align: left;
    border-radius: ${radius.largeRadius};
    padding: 28px 35px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: visible;
    opacity: ${props => parseInt(props.progress) >= 80 ? 0.4 : 1};
    row-gap: 15px;

    @media only screen and (max-width: 750px) {
        width: 60%;
        padding: 25px 20px;
    }
`;

export const ArticleCardNewspaperContainer = styled.h2`
    font-size: 16px;
    font-family: ${font.pulpDisplayBold};
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${props => categoryColor(props.categoryId, props.darkmode)};
    letter-spacing: 0.02em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const ArticleCardTitleContainer = styled.h1`
    font-size: 20px;
    line-height: 24px;
    display: flex;
    font-family: ${font.interBold};
    color: ${props => props.darkmode ? color.white : color.black};
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media only screen and (max-width: 750px) {
        font-size: 16px;
        line-height: 18px;
    }
`;

export const ArticleCardCatchlineContainer = styled.div`
    font-size: 16px;
    font-family: ${font.interMedium};
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${props => props.darkmode ? color.gray4 : color.gray3};
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 20px;
    letter-spacing: 0.01em;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.numCatchlines ?? 0};
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media only screen and (max-width: 750px) {
        line-height: 18px;
    }
`;

export const ArticleCardProgressContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4px;
    width: 100%;
    z-index: 3;
    background-color: ${props => props.darkmode ? "rgba(56,115,247,0.3)" : color.blueActiveLight};
    opacity: ${props => parseInt(props.progress) >= 80 ? 0.5 : 1};
`;

export const ArticleCardProgressBar = styled.div`
    width: ${props => props.progress + "%"};
    background-color: ${color.blue};
    height: inherit;
`;
