import styled from "styled-components";
import { color, font } from "../../../design/theme";
import { textColor } from "../../../components/general.utils";

export const ArticleContainer = styled.article`
    justify-content: left;
    width: inherit;
    height: auto;
    text-align: left;
    margin-top: 90px;
    padding-bottom: 40px;
    // position: relative;
  
    & > div {
        will-change: scroll-position;
    }
`;


export const ArticleLogoContainer = styled.div`
    width: inherit;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const ArticleFlairContainer = styled.h3`
    margin: 15px 10px 5px 0;
    font-size: 16px;
    font-family: ${font.pulpDisplayBold};
    letter-spacing: 0.03em;
    color: ${props => props.darkmode ? color.gray3 : color.gray2};
    position: relative;
  
    &>span:first-child {
        color: ${color.gray3};
        margin-left: 70px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
  
    &::before {
        content: "";
        width: 40px;
        height: 20px;
        margin-left: 20px;
        top: -8px;
        position: absolute;
        border-radius: 0 0 0 15px;
        border-left: solid 3px ${props => props.color};
        border-bottom: solid 3px ${props => props.color};
    }
`

export const ArticleTitleContainer = styled.h1`
    font-size: ${props => props.small ? "40px" : "50px"};
    line-height: ${props => props.small ? "50px" : "60px"};
    letter-spacing: 0.01em;
    font-family: ${font.interExtraBold};
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 30px 0;
    color: ${props => textColor(props.darkmode)};

    @media only screen and (max-width: 750px) {
        font-size: 30px;
        line-height: 40px;
    }
`;

export const ArticleTextContainer = styled.div`
    font-size: 20px;
    font-family: ${font.interMedium};
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    text-overflow: ellipsis;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    line-height: 34px;
    letter-spacing: 0.03em;
    white-space: pre-wrap;

    @media only screen and (max-width: 750px) {
        font-size: 18px;
        line-height: 32px;
    }
`;
