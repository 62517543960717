import styled from "styled-components";
import { color, font } from "../../design/theme";

export const SeparatorContainer = styled.div`
    width: 100%;
    height: auto;
    color: ${color.gray3};
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    user-select: none;
    justify-content: center;
    vertical-align: center;
    text-align: center;
    
    &::before {
        content: "";
        height: 3px;
        width: 40%;
        background-color: ${color.gray3};
        border-radius: 10px;
        position: absolute;
        left: 0;
    }
    
    &::after {
        content: "";
        height: 3px;
        width: 40%;
        background-color: ${color.gray3};
        border-radius: 10px;
        position: absolute;
        right: 0;
    }
`;