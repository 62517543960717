import React from "react";
import styled from "styled-components";

import { radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    height: ${props => props.height + "px"};
    border-radius: ${radius.largeRadius};
    overflow: hidden;
    position: relative;
`

const IFrameContent = styled.div`
    width: inherit;
    height: inherit;
    background: content-box center no-repeat ${props => `url(${props.bgUrl})`};
    background-size: contain;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
`

const IFrameBackdrop = styled.div`
    width: inherit;
    height: inherit;
    background: no-repeat center ${props => `url(${props.bgUrl})`};
    background-size: cover;
    z-index: 0;
    filter: blur(15px);
    position: absolute;
    top: 0;
    left: 0;
`

const EmbedGiphy = ({ width, height, embedUrl }) => {
    return (
        <IFrameWrapper width={width} height={height}>
            <IFrameContent bgUrl={embedUrl} />
            <IFrameBackdrop bgUrl={embedUrl} />
        </IFrameWrapper>
    )
}

export default EmbedGiphy;