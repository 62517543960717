import React, { useState, useEffect, useCallback, Fragment, useLayoutEffect } from "react";
import { connect } from "react-redux";

import { setArticleProgress } from "../../redux/persistData/persistData.actions";
import { selectArticleReadingProgress } from "../../redux/persistData/persistData.selectors";

import { ReadingProgressContainer } from "./readingProgress.styles";
import Progress from "../progress/progress.component";

const ReadingProgress = ({ target, setArticleProgress, articleId, articleReadingProgress }) => {
    const [readingProgress, setReadingProgress] = useState(0);
    let timeout;

    const scrollListener = useCallback((event) => {
        if(event) event.stopPropagation();
        if (!target.current) return;

        const element = target.current;
        const totalHeight = element.clientHeight - element.offsetTop - (window.innerHeight);
        const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || window.scrollY || document.scrollingElement.scrollTop || 0;
        if (windowScrollTop === 0) {
            return setReadingProgress(0);
        }
        if (windowScrollTop > totalHeight) {
            return setReadingProgress(100);
        }
        // setReadingProgress((windowScrollTop / totalHeight) * 100);
        setReadingProgress(
            Number(
                Math.round(
                    parseFloat(((windowScrollTop / totalHeight) * 100) + 'e' + 3)
                ) + 'e-' + 3
            )
        )
    }, [target]);
    const scrollListenerController = (event) => {
        if(timeout) window.cancelAnimationFrame(timeout);
        timeout = window.requestAnimationFrame(() => scrollListener(event));
    }

    const articleProgress = articleReadingProgress ? parseInt(articleReadingProgress) : 0;
    const adjustedReadingProgress = readingProgress.toFixed(0);

    useLayoutEffect(() => {
        scrollListener(null)
    }, [articleId])

    useEffect(() => {
        const observer = new MutationObserver(() => scrollListener(null));
        if(target.current) observer.observe(target.current, { attributes: true, childList: true, subtree: true });
    }, [target])

    useEffect(() => {
        window.addEventListener("scroll", scrollListenerController, { passive: true, capture: true });

        if (
            (articleProgress < adjustedReadingProgress)
            && (adjustedReadingProgress >= 20)
            && (adjustedReadingProgress%5 === 0)
        ) {
            setArticleProgress({
                articleProgress: (adjustedReadingProgress >= 80) ? "100" : adjustedReadingProgress.toString(),
                articleId: articleId
            })
        }

        return () => window.removeEventListener("scroll", scrollListenerController, { passive: true, capture: true });
    }, [scrollListener, adjustedReadingProgress, setArticleProgress, articleId, articleProgress]);

    return (
        <Fragment>
            <ReadingProgressContainer
                aria-label={`You've read ${readingProgress.toFixed(0)}% of the article.`}
            >
                <Progress
                    value={readingProgress}
                    type={"linear"}
                />
            </ReadingProgressContainer>
        </Fragment>
    );
};

const mapStateToProps = (state, ownProps) => ({
    articleReadingProgress: selectArticleReadingProgress(ownProps.articleId)(state)
})

const mapDispatchToProps = (dispatch) => ({
    setArticleProgress: (data) => dispatch(setArticleProgress(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReadingProgress);