import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { color } from "../../design/theme";

import { SubIndicatorContainer, SubIndicatorIcon, SubIndicatorTitle } from "./subIndicator.styles";

const SubIndicator = ({ icon, title, darkmode }) => {
    return (
        <SubIndicatorContainer>
            <SubIndicatorIcon
                name={icon}
                color={color.blue}
                size={34}
            />
            <SubIndicatorTitle darkmode={darkmode}>
                <span>{title}</span>
            </SubIndicatorTitle>
        </SubIndicatorContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(SubIndicator);