import styled from "styled-components";
import { color, radius } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleInfoboxWrapper = styled.div`
    height: auto;
    width: inherit;
    // box-shadow: inset 0 0 0 3px ${color.blue};
    border-radius: ${radius.largeRadius};
    background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    padding: 35px 45px;
    margin: 40px 0;
  
    &>div:last-child {
        margin: 0;
    }
`

export const ArticleInfoboxTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 20px;
`

export const ArticleInfoboxBodyComponent = styled.div`
    display: flex;
    width: auto;
    height: auto;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
`

export const ArticleInfoboxTitleIconContainer = styled.div`
    height: inherit;
    display: inline-flex;
    width: 40px;
    margin: -5px 15px 0 -5px;
    flex-direction: column;
    justify-content: flex-start;
`

export const ArticleInfoboxTitleIcon = styled(Icon)`
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
`