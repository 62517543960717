import styled from "styled-components";
import Logo from "../../design/logos";

export const FullscreenLoaderContainer = styled.div`
    height: 100vh;
    width: 100vw;
    justify-content: center;
    position: fixed;
    display: flex;
    align-items: center;
    overflow: hidden;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const FullscreenLoaderLogo = styled(Logo)`
    height: auto;
    width: auto;
    position: relative;
    display: flex;
`;