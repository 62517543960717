import React, { Fragment, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { BreakingContext } from "../../../context/breaking.context";
import { createStructuredSelector } from "reselect";
import { selectBreakingList, selectBreakingUpdate, selectBreakingListFetching } from "../../../redux/breakingData/breakingData.selectors";
import { selectUserId, selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { fetchBreakingDataStart } from "../../../redux/breakingData/breakingData.actions";
import { textColor } from "../../../components/general.utils";
import { titleData, navData } from "../../../components/lang.data";

import {
    TimelineContainer,
    TimelineContentContainer,
    TimelineHeaderContainer,
    TimelineTitleContainer,
    TimelineSubTitleContainer,
    TimelineBars,
    TimelineFeedContainer
} from "./timeline.styles";
import Icon from "../../../design/icons/icon.component";
import BreakingCard from "../../../components/breakingCard/breakingCard.component";
import Indicator from "../../../components/indicator/indicator.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const TimelinePage = ({ fetchBreakingDataStart, breakingList, userId, lang,
                          darkmode, appSize, breakingUpdate, loading }) => {

    const { renderList, highlightUpdate, alertUpdate } = useContext(BreakingContext);

    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting || entry.intersectionRatio > 0) {
                console.log("OBSERVER ENTRY", entry.target.id)
                if(breakingList && !loading) fetchBreakingDataStart({ userId, lang })
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0 });

    useEffect(() => {
        if(renderList) {
            let observeId = renderList[renderList.length - 3]?.id;
            let observeElem = document.getElementById(observeId);
            if(observeElem) observer.observe(observeElem);
        }
    }, [renderList])

    return (
        <Fragment>
            {
                (appSize >= 3) ? (
                    <Fragment>
                        <SiteHead title={navData.timeline.desc[lang]} />
                        <Indicator
                            pageLabel={titleData.timeline[lang]}
                            pageLabelEmoji={"🪁"}
                            type={"timeline"}
                        />
                    </Fragment>
                ) : null
            }
            <TimelineContainer>
                <TimelineHeaderContainer darkmode={darkmode}>
                    <Icon
                        name={"timeline"}
                        size={34}
                        color={textColor(darkmode)}
                        alert={alertUpdate ? "true" : null}
                        style={{ position: "absolute" }}
                    />
                    <TimelineTitleContainer>
                        Breaking News
                    </TimelineTitleContainer>
                    <TimelineSubTitleContainer darkmode={darkmode}>
                        <span>{("// Updates 24/7").toUpperCase()}</span>
                    </TimelineSubTitleContainer>
                </TimelineHeaderContainer>
                <TimelineContentContainer>
                    <TimelineBars darkmode={darkmode} />
                    <TimelineFeedContainer>
                        {
                            renderList ? renderList.map((breakingArticle, index) => (
                                <BreakingCard
                                    key={breakingArticle.id}
                                    breakingArticle={breakingArticle}
                                    highlightUpdate={breakingArticle.id === breakingUpdate ? highlightUpdate : null}
                                    fetchTrigger={index === renderList.length - 3}
                                />
                            )) : null
                        }
                    </TimelineFeedContainer>
                </TimelineContentContainer>
            </TimelineContainer>
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    lang: selectUserLanguage,
    breakingList: selectBreakingList,
    breakingUpdate: selectBreakingUpdate,
    loading: selectBreakingListFetching,
    darkmode: selectDarkmode
})

const mapDispatchToProps = (dispatch) => ({
    fetchBreakingDataStart: (payload) => dispatch(fetchBreakingDataStart(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelinePage);