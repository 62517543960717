import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const ModalContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    backdrop-filter: blur(5px);
    background-color: ${props => props.darkmode ? "rgba(26, 26, 26, 0.9)" : "rgba(256, 256, 256, 0.80)"};
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    z-index: 999;
`;

export const ModalContentContainer = styled.div`
    width: ${props => props.type === "preview" ? "600px" : "420px"};
    height: auto;
    max-height: ${props => props.type === "preview" ? "100vh" : "80vh"};
    max-width: 90vw;
    text-align: left;
    padding: ${props => props.type === "preview" ? "0" : "25px 30px 30px 30px"};
    position: relative;
    margin-top: ${props => props.type === "preview" ? "20px" : "-10vh"};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    box-shadow: 0 1px 10px 0 ${props => props.darkmode ? color.black : color.gray3};
    border-radius: ${radius.largeRadius};
    overflow-y: ${props => props.type === "preview" ? "visible" : "scroll"};
`;

export const ModalTitleContainer = styled.div`
    font-family: ${font.pulpDisplayBold};
    font-size: 30px;
    margin-bottom:  ${props => props.desc ? "10px" : "20px"};
    color: ${props => textColor(props.darkmode)};
    text-align: ${props => props.type === "prompt" ? "center" : "left"};
    
    ${
        props => props.type === "list" ? `
            &::after { 
                content: "✏️\xa0"; 
                display: inline-block; 
                transform: scaleX(-1);
            }` 
        : null
    }
`

export const ModalDescContainer = styled.div`
    font-size: 20px;
    line-height: 25px;
    font-family: ${font.pulpDisplayMedium};
    display: flex;
    flex-direction: column;
    color: ${props => props.darkmode ? color.gray4 : color.gray3};
    margin-bottom: 20px;
    text-align: ${props => props.type === "prompt" ? "center" : "left"};
`;

export const ModalCloseButtonContainer = styled.div`
    position: absolute;
    margin: 15px 15px 0 0;
    height: 25px;
    width: 25px;
    top: 0;
    right: 0;
`

export const ModalCloseButton = styled(Icon)`
    position: fixed;
    cursor: pointer;
    z-index: 1;
`

export const ModalPromptIconContainer = styled.div`
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
`

export const ModalPromptIcon = styled(Icon)`
    width: 85px;
    height: 85px;
`
export const ModalPromptSecondaryIcon = styled(Icon)`
    width: 30px;
    height: 30px;
    margin: 0 0 -35px -15px;
`