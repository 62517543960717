import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserId } from "../../redux/userData/userData.selectors";
import { addToTraceLog } from "../../redux/sessionData/sessionData.actions";
import { sendFeedback } from "../../redux/userEvents/userEvents.actions";

import ErrorDisplay from "../errorDisplay/errorDisplay.component";
import ArticleFallback from "../articleContent/articleFallback/articleFallback.content";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: error };
    }

    componentDidCatch(error, errorInfo) {
        console.log("COMPONENT ERROR", error, errorInfo);
        this.props.addToTraceLog({
            type: "ERROR_BOUNDARY_CATCH_" + (this.props.type === "global" ? "FATAL" : "STANDARD"),
            payload: error,
            info: errorInfo
        })
        if(this.props.type === "global" && process.env.NODE_ENV === "production") {
            this.props.sendFeedback(this.props.userId, {
                type: "bugReport",
                desc: "Report sent automatically by global error boundary component",
                attachTraceLog: true
            })
        }
    }

    render() {
        if(this.props.type === "articleComponent" && this.state.hasError) {
            return <ArticleFallback />
        } else if (this.props.type === "global" && this.state.hasError) {
            return <ErrorDisplay type={"contentError"} link={0} fullscreen />
        }
        return this.props.children;
    }
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId
})

const mapDispatchToProps = (dispatch) => ({
    addToTraceLog: (action) => dispatch(addToTraceLog(action)),
    sendFeedback: (userId, feedbackValues) => dispatch(sendFeedback(userId, feedbackValues))
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);