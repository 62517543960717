import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Form, Field } from "react-final-form";
import { toCanvas } from "html-to-image";

import { selectUserId } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { sendFeedback } from "../../redux/userEvents/userEvents.actions";
import { miscData, feedbackData } from "../lang.data";

import { ModalFeedbackDisclaimer } from "./modalFeedback.styles";
import Dropdown from "../dropdown/dropdown.component";
import Attachment from "../attachment/attachment.component";
import InputField from "../inputField/inputField.component";
import ModalAction from "../modalAction/modalAction.component";

const ModalFeedback = ({ onDismiss, darkmode, lang, userId, sendFeedback }) => {
    const [feedbackType, setFeedbackType] = useState("bugReport");
    const [screenshotDataUrl, setScreenshotDataUrl] = useState("");
    const [screenshotLoading, setScreenshotLoading] = useState(true);
    const [screenshotError, setScreenshotError] = useState(false);

    const required = (value) => ((!value || value.length === 0 || value.length > 280) ? "required" : undefined)

    const onSubmit = async (values) => {
        const { desc, attachScreenshot, attachTraceLog } = values || {};
        sendFeedback(userId, {
            type: feedbackType,
            desc: desc ,
            screenshot: attachScreenshot ? screenshotDataUrl : "",
            attachTraceLog: attachTraceLog
        })
        onDismiss()
    }

    useEffect(() => {
        if(!screenshotDataUrl) {
            (() => {
                toCanvas(document.body, {
                    filter: (node) => node.id !== "modal"
                }).then((canvas) => {
                    let dataUrl = canvas.toDataURL("image/webp", 1);
                    setScreenshotDataUrl(dataUrl)
                    setScreenshotLoading(false);
                }).catch(() => {
                    setScreenshotError(true);
                    setScreenshotLoading(false);
                });
            })()
        }
    }, [])

    return (
        <Fragment>
            <Dropdown
                contentData={feedbackData.types}
                activeType={feedbackType}
                callback={(type) => setFeedbackType(type)}
            />
            <Form
                onSubmit={onSubmit}
                subscription={{ submitting: true, invalid: true, pristine: true }}
                mutators={{
                    toggleScreenshotCheck: (args, state, utils) => {
                        utils.changeValue(state, "attachScreenshot", () => {
                            const { attachScreenshot } = state.formState.values;
                            return !attachScreenshot;
                        })
                    },
                    toggleTraceLogCheck: (args, state, utils) => {
                        utils.changeValue(state, "attachTraceLog", () => {
                            const { attachTraceLog } = state.formState.values;
                            return !attachTraceLog;
                        })
                    }
                }}
                keepDirtyOnReinitialize={false}
                render={({ handleSubmit, form, pristine, invalid , submitting}) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            validate={required}
                            subscription={{ touched: true, dirty: true, error: true, value: true, valid: true, initial: true, pristine: true }}
                            name={"desc"}
                            component={InputField}
                            placeholder={feedbackData.types[feedbackType].cta[lang] + "..."}
                            initialValue={""}
                            darkmode={darkmode}
                            type={"text"}
                            max={"280"}
                            checkType={true}
                            area={"true"}
                            required={"true"}
                            modal
                        />
                        <ModalFeedbackDisclaimer darkmode={darkmode}>
                            {feedbackData.disclaimer(lang)}
                        </ModalFeedbackDisclaimer>
                        {
                            feedbackType !== "idea" ? (
                                <Fragment>
                                    <Field
                                        name={"attachScreenshot"}
                                        type={"checkbox"}
                                        initialValue={true}
                                        render={({ input }) => (
                                            <Attachment
                                                {...input}
                                                desc={feedbackData.attachments.screenshot[lang]}
                                                previewData={screenshotDataUrl}
                                                previewType={"screenshot"}
                                                previewError={screenshotError}
                                                callback={form.mutators.toggleScreenshotCheck}
                                            />
                                        )}
                                    />
                                    <Field
                                        name={"attachTraceLog"}
                                        type={"checkbox"}
                                        initialValue={true}
                                        render={({ input }) => (
                                            <Attachment
                                                {...input}
                                                desc={feedbackData.attachments.traceLog[lang]}
                                                icon={"tos"}
                                                callback={form.mutators.toggleTraceLogCheck}
                                            />
                                        )}
                                    />
                                </Fragment>
                            ) : null
                        }
                        <ModalAction
                            type={"submit"}
                            actionDesc={miscData.send[lang]}
                            disabled={invalid || pristine || submitting || screenshotLoading}
                            onDismiss={onDismiss}
                        />
                    </form>
                )}
            />
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

const mapDispatchToProps = (dispatch) => ({
    sendFeedback: (userId, feedbackValues) => dispatch(sendFeedback(userId, feedbackValues))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalFeedback);