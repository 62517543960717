import React, { useEffect, useState, Fragment, useContext } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { SearchContext } from "../../../context/search.context";
import { HeaderContext } from "../../../context/header.context";
import { selectUserId, selectUserLanguage} from "../../../redux/userData/userData.selectors";
import { selectSearchResults, selectSearchResultsArr, selectUserEventsLoading, selectUserEventsError } from "../../../redux/userEvents/userEvents.selectors";
import { selectArticleArrayForPreview } from "../../../redux/articleData/articleData.selectors";
import { selectFeedRefObj } from "../../../redux/sessionData/sessionData.selectors";
import { performSearchStart } from "../../../redux/userEvents/userEvents.actions";
import { getUrlQueries, encodeURIQuery, getURIQueryResultKey } from "../../../components/general.utils";
import { titleData, searchData, miscData } from "../../../components/lang.data";

import Indicator from "../../../components/indicator/indicator.component";
import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";

const SearchPage = ({ userId, lang, searchResults, articlePreviewList, searchLoading, performSearchStart, error }) => {

    const { setValue, attribute, handleAttribute, clearInputAndFocus } = useContext(SearchContext);
    const { setSimpleEnv } = useContext(HeaderContext);

    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const queryParam = encodeURIQuery(params.get("q"), "reverse") || "";
    const attributeParam = params.get("a") || "";
    const resultKey = getURIQueryResultKey(queryParam, attributeParam);

    const { totalResults, processingTime, currentPage, maxPage } = searchResults[resultKey] || {};
    const [subLabel, setSubLabel] = useState("");

    const performSearchOperation = () => {
        if(!processingTime || currentPage < maxPage - 1) {
            performSearchStart(userId, queryParam, attributeParam, "standard")
        }
    }

    useEffect(() => {
        if(!searchLoading && resultKey && !searchResults[resultKey]) {
            performSearchOperation()
        }

    }, [userId, queryParam, attributeParam])

    useEffect(() => {
        if(!queryParam) history.push("/home");
        else setValue(queryParam);
        if(!attribute && attributeParam) handleAttribute(attributeParam);
        if(attribute && !attributeParam) handleAttribute(attribute);

        setSimpleEnv(true);
        if(processingTime || searchLoading) {
            setSubLabel(searchLoading ? `${miscData.loading[lang]}...` : `// ${searchData.results(lang, totalResults, processingTime)}`)
        }

        return () => setSimpleEnv(null)
    }, [lang, searchLoading, processingTime, searchResults, setSimpleEnv])

    return (
        <Fragment>
            <Indicator
                pageLabel={titleData.searchResults[lang]}
                pageLabelEmoji={"⚡️"}
                subLabel={subLabel || null}
            />
            {
                articlePreviewList?.length ? (
                    <ArticleFeed
                        articlePreviewList={articlePreviewList}
                        infiniteLoader={() => performSearchOperation()}
                        loading={searchLoading}
                        error={error}
                        type={"standard"}
                        refType={resultKey}
                    />
                ) : (!searchLoading && articlePreviewList?.length === 0 && totalResults === 0) ? (
                    <ErrorDisplay
                        type={"noSearch"}
                        callback={() => {
                            history.goBack();
                            clearInputAndFocus.trigger?.()
                        }}
                        icon={"arrow"}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { q, a } = getUrlQueries(ownProps.location.search);
    const resultKey = getURIQueryResultKey(q, a);

    return {
        userId: selectUserId(state),
        lang: selectUserLanguage(state),
        searchResults: selectSearchResults(state),
        articlePreviewList: selectArticleArrayForPreview(selectSearchResultsArr(resultKey)(state))(state),
        feedRefObj: selectFeedRefObj(state),
        searchLoading: selectUserEventsLoading("userSearchResults")(state),
        error: selectUserEventsError("userSearchResults")(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    performSearchStart: (userId, query, attribute, type) => dispatch(performSearchStart(userId, query, attribute, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);