import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { textColor } from "../general.utils";

import {
    DropdownWrapper,
    DropdownContainer,
    DropdownItem,
    DropdownIcon,
    DropdownDesc
} from "./dropdown.styles";

const Dropdown = ({ darkmode, lang, activeType, callback, contentData }) => {
    const [active, setActive] = useState(false);
    const ref = useRef(null);

    const handleClick = (event, type) => {
        event.stopPropagation();
        if(type) {
            callback(type)
            setActive(!active)
        }
        if(!ref.current?.contains(event.target)) setActive(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick)

        return () => document.removeEventListener("mousedown", handleClick)
    }, [ref])

    return (
        <DropdownWrapper ref={ref}>
            <DropdownContainer darkmode={darkmode} active={active}>
                {
                    Object.values(contentData).map(({ type, icon, desc }) => {
                        if(type === activeType || active) {
                            return (
                                <DropdownItem
                                    key={type}
                                    onClick={(event) => handleClick(event, type)}
                                    darkmode={darkmode}
                                >
                                    <DropdownIcon
                                        name={icon}
                                        size={30}
                                        color={(active && type === activeType) ? color.blue : textColor(darkmode)}
                                    />
                                    <DropdownDesc darkmode={darkmode} active={active && type === activeType}>
                                        <span>{desc[lang]}</span>
                                    </DropdownDesc>
                                    {
                                        type === activeType ? (
                                            <DropdownIcon
                                                name={active ? "checkmark" : "enter"}
                                                size={30}
                                                color={active ? color.blue : color.gray3}
                                                secondary={"true"}
                                            />
                                        ) : null
                                    }
                                </DropdownItem>
                            )
                        }
                    })
                }
            </DropdownContainer>
        </DropdownWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(Dropdown);