import styled from "styled-components";
import Icon from "../../design/icons/icon.component";

export const DynamicImageWrapper = styled.picture`
    height: inherit;
    width: inherit;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
`

export const DynamicImagePicture = styled.img`
    height: ${props => props.fullscreen && props.widthOrient ? "auto" : "100%"};
    width: ${props => props.fullscreen && props.widthOrient ? "100%" : props.fullscreen ? "auto" : "100%"};
    object-fit: ${props => props.fullscreen ? "contain" : "cover"};
    user-select: none;
    -webkit-user-drag: none;
    z-index: 1;
`

export const DynamicImageStatusIcon = styled(Icon)`
    width: auto;
    height: auto;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
`