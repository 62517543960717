import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setTheme } from "../../redux/persistData/persistData.actions";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";

import {
    ModeSelectorContainer,
    ModeSelectorOptionContainer
} from "./modeSelector.styles";

const ModeSelector = ({ data, current, type, setTheme, lang, darkmode, onClick, adapt }) => {
    const handleSelectionClick = (event, value) => {
        event.stopPropagation();

        console.log("CURRENT THEME", current)

        if (value !== current) {
            console.log("MODE SELECTOR HEADER KEY", value);
            if (type === "theme") {
                setTheme(value)
            } else if(type === "barChart") {
                onClick(value)
            }
        }
    }

    return (
        <ModeSelectorContainer darkmode={darkmode} adapt={adapt}>
            {
                Object.entries(data).map(entry => (
                    <ModeSelectorOptionContainer
                        onClick={event => handleSelectionClick(event, entry[0])}
                        key={entry[0]}
                        active={entry[0] === current}
                        darkmode={darkmode}
                    >
                        <span>{entry[1][lang]}</span>
                    </ModeSelectorOptionContainer>
                ))
            }
        </ModeSelectorContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

const mapDispatchToProps = (dispatch) => ({
    setTheme: (mode) => dispatch(setTheme(mode))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelector);