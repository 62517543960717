import styled from "styled-components";
import { color, radius } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const HeaderActionContainer = styled.div`
    height: 50px;
    width: 50px;
    display: inline-flex;
    flex-shrink: 0;
    position: relative;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    justify-content: center;
    border-radius: ${radius.smallRadius};
    cursor: pointer;
    
    &:hover {
        background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    }
    &:active {
        transform: scale(0.95);
        transition: 0.1s;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }
`;

export const HeaderActionIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
`;