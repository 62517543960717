import SessionDataActionTypes from "./sessionData.types";

export const setScrollState = (scrollState, pathName) => ({
    type: SessionDataActionTypes.SET_SCROLL_STATE,
    payload: scrollState,
    pathName: pathName
})

export const setImageRef = (imgState, refKey) => ({
    type: SessionDataActionTypes.SET_IMAGE_REF,
    payload: imgState,
    refKey: refKey
})

export const setEmbedConsent = (provider, addData) => ({
    type: SessionDataActionTypes.SET_EMBED_CONSENT,
    payload: provider,
    addData: addData,
    refKey: "embedConsent"
})

export const setFeedRef = (feedRef, articleId) => ({
    type: SessionDataActionTypes.SET_FEED_REF,
    payload: feedRef,
    addData: articleId,
    refKey: "feedRef"
})

export const addToTraceLog = (recordedAction) => ({
    type: SessionDataActionTypes.ADD_TO_TRACE_LOG,
    payload: recordedAction
})