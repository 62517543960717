import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { textColor } from "../general.utils";
import { signUpGoogleStart, signInGoogleStart, signUpAppleStart } from "../../redux/userLogin/userLogin.actions";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLoginError, selectUserLoginFetching } from "../../redux/userLogin/userLogin.selectors";
import { codes } from "../codes.data";

import { LoginServicesContainer } from "./loginServices.styles";
import CustomButton from "../customButton/customButton.component";
import Notice from "../notice/notice.component";

const LoginServices = ({ data, param, lang, darkmode, loading, loginError,
                           signUpGoogleStart, signInGoogleStart, signUpAppleStart }) => {

    const history = useHistory();

    const handleOnClick = (value) => {
        if (value === "email") {
            history.push(`/${param}/email`)
        } else if ((value === "google") && (param === "signup")) {
            // signUpGoogleStart()
        } else if ((value === "google") && (param === "login")) {
            // signInGoogleStart()
        } else if ((value === "apple") && (param === "signup")) {
            // signUpAppleStart()
        } else if ((value === "apple") && (param === "login")) {
            console.log("LOGIN", value, param)
        }
    }

    return (
        <LoginServicesContainer>
            {
                data && (param === "login" || param === "signup") ?
                    Object.entries(data[param]).map(entry => (
                        <CustomButton
                            key={entry[0]}
                            callback={() => handleOnClick(entry[0])}
                            desc={entry[1][lang]}
                            icon={entry[0]}
                            color={textColor(darkmode)}
                            loading={loading ? 1 : 0}
                            disabled={entry[0] !== "email" || param === "signup"}
                            service
                        />
                )) : null
            }
            {
                loginError ? (
                    <Notice
                        desc={codes[loginError][lang]}
                        type={"error"}
                    />
                ) : null
            }
        </LoginServicesContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode,
    loading: selectUserLoginFetching,
    loginError: selectUserLoginError
})

const mapDispatchToProps = (dispatch) => ({
    // signUpGoogleStart: () => dispatch(signUpGoogleStart()),
    // signInGoogleStart: () => dispatch(signInGoogleStart()),
    // signUpAppleStart: () => dispatch(signUpAppleStart())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginServices);