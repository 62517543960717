import React, { useContext, useState, useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { HeaderContext } from "../../context/header.context";
import { bookmarkArticle, followChannel, logRecentAccessStart } from "../../redux/userEvents/userEvents.actions";
import { deleteListStart } from "../../redux/userEvents/userEvents.actions";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectChannelDataRaw, selectBookmarkDataRaw, selectUserId } from "../../redux/userData/userData.selectors";

import {
    HeaderContainer,
    HeaderContentContainer,
    HeaderMainContainer
} from "./header.styles";
import ReadingProgress from "../readingProgress/readingProgress.component";
import HeaderAction from "../headerAction/headerAction.component";
import Searchbar from "../searchbar/searchbar.component";

const Header = ({ userId, bookmarkArticle, bookmarkDataRaw, darkmode, followChannel, channelDataRaw,
                    deleteListStart, logRecentAccessStart }) => {

    const { mainWidth } = useContext(LayoutContext);
    const { articleEnv, channelEnv, simpleEnv, listEnv } = useContext(HeaderContext);

    const history = useHistory();
    const [added, setAdded] = useState(false);
    const { ref, articleId } = articleEnv || {};
    const { channelId } = channelEnv || {};
    const { listId } = listEnv || {};

    const handleRefStatus = useCallback((refId, refData) => {
        if (refData) {
            refData[refId] ? setAdded(true) : setAdded(false)
        } else {
            setAdded(false)
        }
    }, [bookmarkDataRaw, channelDataRaw])

    useEffect(() => {
        if (articleEnv) handleRefStatus(history.location.pathname.substring(1), bookmarkDataRaw);
        else if (channelEnv) handleRefStatus(channelId, channelDataRaw);

    }, [articleEnv, bookmarkDataRaw, handleRefStatus, history.location.pathname, channelEnv, channelId, channelDataRaw])

    const handleActionIconClick = () => {
        if (articleEnv) {
            bookmarkArticle(userId, history.location.pathname.substring(1));
        } else if (channelEnv) {
            followChannel(userId, channelId);
        }
    }

    return (
        <HeaderContainer darkmode={darkmode} mainWidth={mainWidth}>
            <HeaderContentContainer>
                {
                    (articleEnv || channelEnv || simpleEnv || listEnv) ? (
                        <HeaderAction
                            icon={"back"}
                            type={"action"}
                            callback={() => history.goBack()}
                        />
                    ) : null
                }
                <HeaderMainContainer darkmode={darkmode}>
                    {
                        (articleId) ? (
                            <ReadingProgress target={ref} articleId={articleId} />
                        ) : (
                            <Searchbar main={1} reduced={(listEnv || channelEnv) && mainWidth < 600} />
                        )
                    }
                </HeaderMainContainer>
                {
                    (articleEnv || channelEnv) ? (
                        <Fragment>
                            <HeaderAction
                                icon={channelEnv ? added ? "check" : "add": "bookmark"}
                                added={added}
                                outline={articleEnv ? !added : null}
                                type={"action"}
                                callback={() => handleActionIconClick()}
                                env={articleEnv}
                            />
                            <HeaderAction
                                icon={"share"}
                                type={"share"}
                                env={articleEnv || channelEnv}
                            />
                            {
                                articleEnv ?
                                    <HeaderAction
                                        icon={"lists"}
                                        type={"modal"}
                                        env={articleEnv}
                                    /> : null
                            }
                        </Fragment>
                    ) : null
                }
                {
                    listEnv ? (
                        <Fragment>
                            <HeaderAction
                                icon={"edit"}
                                type={"modal"}
                                env={listEnv}
                            />
                            <HeaderAction
                                icon={"delete"}
                                type={"modal"}
                                env={listEnv}
                                callback={() => {
                                    history.push("/library/lists");
                                    logRecentAccessStart(userId, listId, "delete")
                                    deleteListStart(userId, listId);
                                }}
                            />
                        </Fragment>
                    ) : null
                }
            </HeaderContentContainer>
        </HeaderContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    darkmode: selectDarkmode,
    bookmarkDataRaw: selectBookmarkDataRaw,
    channelDataRaw: selectChannelDataRaw
})

const mapDispatchToProps = (dispatch) => ({
    bookmarkArticle: (userId, articleId) => dispatch(bookmarkArticle(userId, articleId)),
    followChannel: (userId, channelId) => dispatch(followChannel(userId, channelId)),
    deleteListStart: (userId, listId) => dispatch(deleteListStart(userId, listId)),
    logRecentAccessStart: (userId, listId, refType) => dispatch(logRecentAccessStart(userId, listId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);