import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { fetchChannelRefStart } from "../../../redux/channelRef/channelRef.actions";
import { fetchUserChannelsStart } from "../../../redux/userData/userData.actions";
import { selectChannelRefFetching, selectChannelRefList, selectChannelRefError } from "../../../redux/channelRef/channelRef.selectors";
import { selectUserId, selectUserLanguage, selectChannelDataRaw, selectUserDataFetching } from "../../../redux/userData/userData.selectors";
import { titleData, navData } from "../../../components/lang.data";

import { ChannelOverviewContainer } from "./channelOverview.styles";
import Indicator from "../../../components/indicator/indicator.component";
import ChannelCategory from "../../../components/channelCategory/channelCategory.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

let currentLang = null;

const ChannelOverview = ({ fetchChannelRefStart, channelRefList, channelRefLoading, userId, lang, error,
                           fetchUserChannelsStart, channelData, channelDataLoading }) => {
    const[reload, setReload] = useState(false)

    useEffect(() => {
        const reloadReset = reload ? setTimeout(() => setReload(false), 500) : null
        if(!channelData && !channelDataLoading) fetchUserChannelsStart({ userId, lang })
        if(!channelRefLoading && ((currentLang && (currentLang !== lang)) || !channelRefList)) {
            fetchChannelRefStart({ userId, lang })
            currentLang = lang;
        }

        return () => clearTimeout(reloadReset)
    }, [fetchChannelRefStart, channelRefList, userId, lang, reload])

    return (
        <ChannelOverviewContainer>
            <SiteHead title={navData.explore.desc[lang]} />
            {
                (!error) ? (
                    <Fragment>
                        <Indicator
                            pageLabel={titleData.explore[lang]}
                            pageLabelEmoji={"📦"}
                        />
                        {
                            channelRefList && channelData ?
                                Object.keys(channelRefList).map(key => (
                                    <ChannelCategory
                                        key={key}
                                        categoryId={key}
                                        categoryContent={channelRefList[key]}
                                    />
                                )) : null
                        }
                    </Fragment>
                ) : <ErrorDisplay type={"contentError"} callback={setReload} />
            }
        </ChannelOverviewContainer>
    )
}

const mapStateToProps = (state) => ({
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    channelRefList: selectChannelRefList(state),
    channelRefLoading: selectChannelRefFetching(state),
    channelData: selectChannelDataRaw(state),
    channelDataLoading: selectUserDataFetching("channelData")(state),
    error: selectChannelRefError("general")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchChannelRefStart: (reqHeaders) => dispatch(fetchChannelRefStart(reqHeaders)),
    fetchUserChannelsStart: (reqHeaders) => dispatch(fetchUserChannelsStart(reqHeaders)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelOverview);