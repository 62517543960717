import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { textColor } from "../general.utils";
import { Link } from "react-router-dom";
import Icon from "../../design/icons/icon.component";

export const ListCardContainer = styled.div`
    width: 100%;
    height: 100px;
    border-radius: ${radius.smallRadius};
    cursor: pointer;
    display: flex;
    position: relative;
    flex-grow: 0;
    min-width: 0;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    color: ${props => textColor(props.darkmode)};
    padding: 12.5px ${props => props.preview ? "12.5px" : props.pinned ? "80px" : "50px"} 12.5px 12.5px;
    overflow: ${props => props.preview ? "hidden" : "visible"};
    will-change: scroll-position;
    
    &:hover  {
        box-shadow: inset 0 0 0 2px ${color.blue};
        outline: none;
    }
    &:active {
        transform: scale(0.98);
        transition: 0.1s;
        z-index: 3;
        outline: none;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }
`;

export const ListCardLink = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`

export const ListCardPictureContainer = styled.div`
    height: 75px;
    width: 75px;
    display: inline-flex;
    margin-right: 20px;
    border-radius: 8px;
`

export const ListCardContentContainer = styled.div`
    height: 75px;
    width: auto;
    display: inline-flex;
    flex-grow: 100;
    min-width: 0;
    flex-direction: column;
    text-align: left;
    align-content: center;
    justify-content: center;
    font-family: ${font.pulpDisplayBold};
`

export const ListCardActionsContainer = styled.div`
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12.5px;
`
export const ListCardContentOptions = styled.div`
    position: relative;
    display: inline-block;
    justify-content: center;
    height: 25px;
  
    &:hover {
        background-color: ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
        border-radius: 5px;
    }
`

export const ListCardContentPinned = styled.div`
    position: relative;
    display: inline-block;
    justify-content: center;
    cursor: default;
    margin: 0 6px;
    z-index: 0;
  
    &::after {
        content: "";
        height: 50px;
        width: 25px;
        margin: -20px 0 0 0;
        left: 0;
        z-index: -1;
        top: 0;
        position: absolute;
        background-color: ${color.blue};
        border-radius: 6px;
    }
`

export const ListCardTitleContainer = styled.h2`
    font-size: 25px;
    line-height: 25px;
    height: ${props => props.placeholder ? "auto" : "28px"};
    min-width: 0;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.placeholder ? "2" : "1"};
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: ${props => props.placeholder ? "visible" : "hidden"};
    // white-space: nowrap;

    @media only screen and (max-width: 750px) {
        font-size: 22px;
    }
`

export const ListCardDetailsContainer = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.025em;
    color: ${color.gray3};
    margin-top: 2px;
`

export const ListCardCreationDetails = styled.div`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &>span:nth-child(0n + 2) {
        color: ${color.blue};
    }
    &>span:nth-child(0n + 3) {
        padding: 0 2px;
    }
`

export const ListCardStateDetails = styled.div`
    display: block;
`

export const ListCardStateDownload = styled(Icon)`
    height: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 -4px 4px;
`