import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext} from "../../App";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { miscData } from "../lang.data";

import {
    SettingsCardWrapper,
    SettingsCardTitle,
    SettingsCardContainer,
    SettingsCardItemContainer,
    SettingsCardLinkExternal,
    SettingsCardItemIcon,
    SettingsCardItemImage,
    SettingsCardItemTitle,
    SettingsCardActionContainer,
    SettingsCardActionIcon,
    SettingsCardActionLabel,
    SettingsCardInfo
} from "./settingsCard.styles";
import StaticImage from "../staticImage/staticImage.component";

const SettingsCard = ({ contentData, darkmode, lang, title, info }) => {
    const { mainWidth } = useContext(LayoutContext);
    const history = useHistory();

    const onClick = (event, link, type) => {
        event.stopPropagation();
        if (type === "internal" && type !== "locked") {
            history.push(`${link}`);
        }
    }

    return (
        <SettingsCardWrapper>
            <SettingsCardTitle darkmode={darkmode}>
                <span>{title}</span>
            </SettingsCardTitle>
            <SettingsCardContainer darkmode={darkmode}>
                {
                    contentData.map(({ icon, image, link, type, desc, label, actionIcon, action, actionLabel }) => (
                        <SettingsCardItemContainer
                            darkmode={darkmode}
                            key={icon || image}
                            onClick={(event) => onClick(event, link, type)}
                            type={type}
                            single={contentData.length === 1}
                        >
                            {
                                type === "external" ? (
                                    <SettingsCardLinkExternal to={{ pathname: `https://${link}` }} target="_blank" />
                                ) : null
                            }
                            {
                                icon ? (
                                    <SettingsCardItemIcon
                                        name={icon}
                                        size={30}
                                        color={color.blue}
                                    />
                                ) : image && (
                                    <SettingsCardItemImage darkmode={darkmode}>
                                        <StaticImage
                                            url={image}
                                            type={"logo"}
                                            rect
                                        />
                                    </SettingsCardItemImage>
                                )
                            }
                            <SettingsCardItemTitle darkmode={darkmode}>
                                <span>{typeof desc === "object" ? desc[lang] : desc}</span>
                            </SettingsCardItemTitle>
                                <SettingsCardActionContainer>
                                    {
                                        (type !== "action") ? (
                                            <Fragment>
                                                {
                                                    mainWidth > 600 ? (
                                                        <SettingsCardActionLabel darkmode={darkmode}>
                                                            <span>{ type === "external" ? miscData.more[lang] : (label || "") }</span>
                                                        </SettingsCardActionLabel>
                                                    ) : null
                                                }
                                                <SettingsCardActionIcon
                                                    name={type === "external" ? "external" : actionIcon}
                                                    size={30}
                                                    color={darkmode ? color.gray3 : color.gray4}
                                                />
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                { actionLabel }
                                                { action }
                                            </Fragment>
                                        )
                                    }
                                </SettingsCardActionContainer>
                        </SettingsCardItemContainer>
                    ))
                }
            </SettingsCardContainer>
            {
                info ? (
                    <SettingsCardInfo>
                        <span>{info}</span>
                    </SettingsCardInfo>
                ) : null
            }
        </SettingsCardWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(SettingsCard);