import { createSelector } from "reselect";

const selectUserLogin = state => state.userLogin;

export const selectUserAuth = createSelector(
    [selectUserLogin],
    userLogin => userLogin.userAuth
)

export const selectUserLoginFetching = createSelector(
    [selectUserLogin],
    userLogin => userLogin.userAuthFetching
)

export const selectUserLoginError = createSelector(
    [selectUserLogin],
    userLogin => userLogin.errorMessage
)

export const selectUserAuthId = createSelector(
    [selectUserAuth],
    userAuth => userAuth.uid
)