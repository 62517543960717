import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const AttachmentContainer = styled.div`
    width: 100%;
    height: 50px;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
`

export const AttachmentDesc = styled.div`
    font-size: 18px;
    font-family: ${font.pulpDisplayMedium};
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    letter-spacing: 0.02em;
    margin-left: 15px;
    margin-right: auto;
    user-select: none;
`

export const AttachmentPreview = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${color.gray3};
    overflow: hidden;
    margin-left: auto;
`

export const AttachmentCanvas = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const AttachmentIcon = styled(Icon)`
    width: auto;
    height: max-content;
    justify-content: center;
`