import { takeLatest, call, put, all, select } from "redux-saga/effects";

import ChannelRefActionTypes from "./channelRef.types";

import { fetchChannelRefSuccess, fetchChannelRefFailure } from "./channelRef.actions";
import { fetchArticleRefSuccess } from "../articleRef/articleRef.actions";
import { fetchArticleDataStart } from "../articleData/articleData.actions";
import { selectArticleRefPage } from "../articleRef/articleRef.selectors";
import { newsAPI } from "../backendAPI";

export function* fetchChannelRefStartAsync(action) {
    const { userId, lang } = action.payload;
    const refKey = action.refKey;
    const refPage = yield select(selectArticleRefPage(refKey));
    const page = (refPage || refPage === 0) ? refPage + 1 : 0;

    try {
        if (refKey && (refKey !== "email")) {
            const response = yield newsAPI.get(`/channels/${refKey}?page=${page}&limit=10`, {
                headers: {
                    user: userId,
                    lang: lang
                }
            }).catch((err) => console.log("BACKEND FETCH FAILED", err))
            console.log("TEST API RESPONSE", response);
            const channelRefData = yield response.data.content;
            const { articles, ...data } = yield channelRefData;
            yield put(fetchChannelRefSuccess({ [refKey]: data }, refKey));
            yield put(fetchArticleRefSuccess(articles, page, refKey));
            yield put(fetchArticleDataStart(refKey));
        } else if (!refKey) {
            const response = yield newsAPI.get(`/channels`, {
                headers: {
                    user: userId,
                    lang: lang
                }
            }).catch((err) => console.log("BACKEND FETCH FAILED", err));
            console.log("TEST API RESPONSE", response);
            const channelRefList = yield response.data.content;
            yield put(fetchChannelRefSuccess(channelRefList));
        }
        // const response = yield newsAPI.get(`/channels`)
    } catch (error) {
        yield put(fetchChannelRefFailure(error.message, refKey))
    }
}

export function* fetchChannelRefStart() {
    yield takeLatest(
        ChannelRefActionTypes.FETCH_CHANNEL_REF_START,
        fetchChannelRefStartAsync
    )
}

export function* channelRefSagas() {
    yield all([
        call(fetchChannelRefStart)
    ])
}