import styled from "styled-components";
import { color, font } from "../../design/theme";

export const ModeSelectorContainer = styled.div`
    width: auto;
    padding: 3px;
    height: 34px;
    display: flex;
    text-align: left;
    justify-content: center;
    flex-direction: row;
    letter-spacing: 0.045em;
    border-radius: 7px !important;
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    line-height: 100%;
    user-select: none;
    font-size: 16px;
    color: ${color.blue};
    font-family: ${font.pulpDisplayMedium};
    cursor: pointer;
    
    div:first-child {
        margin: 0 !important;
    }
`;

export const ModeSelectorOptionContainer = styled.div`
    padding: 0 15px;
    height: 28px;
    width: auto;
    flex-grow: 1;
    color: ${props => props.active ? color.blue : color.gray3};
    background-color: ${props => props.active ? props.darkmode ? color.gray2 : color.white : "inherit"};
    display: inline-flex;
    border-radius: 5px !important;
    justify-content: center;
    vertical-align: center;
    align-items: center;
    margin-left: 3px;
    line-height: 100%;
    flex-direction: column;
`;