import styled from "styled-components";
import { color } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const StaticImageContainer = styled.picture`
    height: ${props => (props.height && props.rect) ? props.height + "px" : "inherit"};
    width: ${props => (props.height && props.rect) ? props.height + "px" : "inherit"};
    border-radius: inherit;
    display: flex;
    justify-content: center;
    background-color: ${props => (props.success && !props.placeholder && !props.deleted) ? "transparent" : props.main ? props.darkmode ? color.gray2 : color.white : props.darkmode ? color.gray1 : color.gray5};
    user-select: none;
    position: relative;
    -webkit-user-drag: none;
    pointer-events: none;
`

export const StaticImagePicture = styled.img`
    height: inherit;
    width: inherit;
    border-radius: inherit;
    z-index: 1;
`

export const StaticImageDefaultIcon = styled(Icon)`
    height: inherit;
    justify-content: center;
`

export const StaticImageStatusIcon = styled(Icon)`
    width: auto;
    height: auto;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
`