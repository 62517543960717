import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { navMap, navColor } from "./navigation.utils";
import { NavContainer, NavContentContainer, NavLogoContainer } from "./navigation.styles";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { LayoutContext} from "../../App";
import { navData, feedbackData } from "../lang.data";

import NavigationItem from "../navigationItem/navigationItem.component";
import Modal from "../modal/modal.component";
import ModalFeedback from "../modalFeedback/modalFeedback.component";

const Navigation = ({ lang, darkmode }) => {
    const { appSize } = useContext(LayoutContext);

    const [feedbackActive, setFeedbackActive] = useState(false);
    const navOrder = ["home", "popular", "explore", "channels", "library", "account"]
    if(appSize >= 3) navOrder.splice(4, 0, "timeline")
    const location = useLocation().pathname;

    return (
        <NavContainer>
            <NavContentContainer>
                <a href={"/home"}>
                    <NavLogoContainer
                        name={(appSize < 1) ? "reavide" : "reavideIcon"}
                        size={50}
                        color={navColor({darkmode})}
                    />
                </a>
                {
                    navMap(navOrder, navData, lang).map(navItem => (
                        <NavigationItem
                            key={navItem.name}
                            properties={navItem}
                            active={location.includes(navItem.name, 0) ? 1 : 0}
                            darkmode={darkmode}
                        />
                    ))
                }
                <NavigationItem
                    properties={{
                        icon: "wand",
                        desc: "Coming Soon"
                    }}
                    type={"action"}
                    darkmode={darkmode}
                />
                <NavigationItem
                    callback={() => setFeedbackActive(!feedbackActive)}
                    properties={{
                        icon: "feedback",
                        desc: "Feedback"
                    }}
                    type={"action"}
                    darkmode={darkmode}
                />
                {
                    feedbackActive ? (
                        <Modal
                            title={`${feedbackData.title[lang]} 📮`}
                            desc={feedbackData.desc[lang]}
                            onDismiss={() => setFeedbackActive(!feedbackActive)}
                        >
                            <ModalFeedback onDismiss={() => setFeedbackActive(!feedbackActive)} />
                        </Modal>
                    ) : null
                }
            </NavContentContainer>
        </NavContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(Navigation);