import { createSelector } from "reselect";
import { defaultLanguage } from "../../components/general.utils";

const selectUserData = state => state.userData;

export const selectUser = createSelector(
    [selectUserData],
    userData => userData.user
)

export const selectUserDataFetching = (refKey) =>
    createSelector(
        [selectUserData],
        userData => userData.userDataFetching[refKey]
    )

export const selectUserId = createSelector(
    [selectUser],
    user => user?.userId
)

export const selectUserEmail = createSelector(
    [selectUser],
    user => user.email
)

export const selectUserLanguage = createSelector(
    [selectUser],
    user => user?.language || defaultLanguage()
)

export const selectUserTargetReads = createSelector(
    [selectUser],
    user => parseInt(user.targetReads)
)

export const selectUserSubscriptionType = createSelector(
    [selectUser],
    user => user.subscriptionType
)

export const selectUserAllowAllEmbeds = createSelector(
    [selectUser],
    user => user.allowAllEmbeds
)
export const selectUserAllowEmbeds = createSelector(
    [selectUser],
    user => user.allowEmbeds ?? {}
)

export const selectBookmarkData = createSelector(
    [selectUserData],
    userData => userData.bookmarkData
)
export const selectBookmarkDataRaw = createSelector(
    [selectBookmarkData],
    bookmarkData => bookmarkData ? bookmarkData.data : null
)
export const selectBookmarkDataForPreview = createSelector(
    [selectBookmarkData],
    bookmarkData => bookmarkData ? bookmarkData.preview : null
)

export const selectChannelData = createSelector(
    [selectUserData],
    userData => userData.channelData
)
export const selectChannelDataRaw = createSelector(
    [selectChannelData],
    channelData => channelData.data
)
export const selectChannelDataForPreview = createSelector(
    [selectChannelData],
    channelData => channelData.preview
)
export const selectChannelDataFetchKey = createSelector(
    [selectChannelData],
    channelData => channelData.fetchKey
)

export const selectHistoryObj = createSelector(
    [selectUserData],
    userData => userData.historyData
)
export const selectHistoryData = createSelector(
    [selectHistoryObj],
    historyData => historyData.data
)
export const selectHistoryDataFetchKey = createSelector(
    [selectHistoryObj],
    historyData => historyData.fetchKey
)

export const selectListsData = createSelector(
    [selectUserData],
    userData => userData.listsData
)
export const selectListsDataRaw = createSelector(
    [selectListsData],
    listsData => listsData.data
)
export const selectListsDataForPreview = createSelector(
    [selectListsData],
    listsData => listsData.preview
)
export const selectListsDataFetchKey = createSelector(
    [selectListsData],
    listsData => listsData.fetchKey
)
export const selectListsDataDetails = (refKey) =>
    createSelector(
        [selectListsData],
        listsData => listsData.data[refKey]
    )

export const selectCollectionsData = createSelector(
    [selectUserData],
    userData => userData.collectionsData
)
export const selectCollectionsDataRaw = createSelector(
    [selectCollectionsData],
    collectionsData => collectionsData.data
)
export const selectCollectionsDataPreview = createSelector(
    [selectCollectionsData],
    collectionsData => collectionsData.preview
)
export const selectCollectionsDataRefArray = (refKey) =>
    createSelector(
        [selectCollectionsData],
        collectionsData => collectionsData.preview?.[refKey] || []
    )
export const selectCollectionsDataFetchKey = createSelector(
    [selectCollectionsData],
    collectionsData => collectionsData.fetchKey
)

export const selectRecentAccess = createSelector(
    [selectUserData],
    userData => userData.accessedData
)

export const selectRecentSearches = createSelector(
    [selectUserData],
    userData => userData.searchData.preview
)

export const selectUserDataError = (refKey) =>
    createSelector(
        [selectUserData],
        userData => userData.userDataError[refKey]
    )