import UserSettingsActionTypes from "./userSettings.types";

const INITIAL_STATE = {
    userSettingsFetching: {},
    userSettingsError: {}
}

const userSettingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserSettingsActionTypes.SET_USER_LANGUAGE_START:
            return {
                ...state,
                userSettingsFetching: Object.assign(state.userSettingsFetching, { [action.refKey]: true })
            }
        case UserSettingsActionTypes.SET_USER_LANGUAGE_SUCCESS:
            return {
                ...state,
                userSettingsError: Object.assign(state.userSettingsError, { [action.refKey]: null }),
                userSettingsFetching: Object.assign(state.userSettingsFetching, { [action.refKey]: false })
            }
        case UserSettingsActionTypes.SET_USER_LANGUAGE_FAILURE:
            return {
                ...state,
                userSettingsError: Object.assign(state.userDataError, { [action.refKey]: action.payload }),
                userSettingsFetching: Object.assign(state.userSettingsFetching, { [action.refKey]: false })
            }
        default:
            return state;
    }
}

export default userSettingsReducer;