import styled from "styled-components";
import { color, font } from "../../../design/theme";
import { textColor } from "../../../components/general.utils";

export const TimelineContainer = styled.div`
    right: 0;
    margin-right: 30px;
    // position: fixed;
    width: 350px;
    text-align: left;
    z-index: 1;
    padding: 120px 0 0 2px;
    top: 0;
    bottom: 0;
    display: flex;
  
  // 1300 + 195

    @media only screen and (max-width: 1540px) {
        height: 100vh;
        right: auto;
        padding: 0;
        margin: 0 0 0 50px;
        position: relative;
        flex-shrink: 0;
    }
    @media only screen and (max-width: 1360px) { // 1400
        width: 300px;
        margin: 0 0 0 40px;
    }
    @media only screen and (max-width: 1100px) { // 1300
        width: inherit;
        height: max-content;
        justify-content: flex-start;
        position: relative;
        padding: 0 0 0 2px;
        margin: 0 0 0 0;
        overflow: visible;
    }
  
    &::after {
        content: "";
        width: 8px;
        height: 110%;
        margin: 20px 0 0 14px;
        z-index: -1;
        background-color: ${color.blue};
        border-radius: 10px 10px 0 0;
        position: fixed;
        top: 0;

        @media only screen and (max-width: 1100px) { // 1300
            top: 85px;
            // margin: -145px 0 0 11px;
        }
    }
`;

export const TimelineContentContainer = styled.section`
    width: inherit;
    max-width: 100%;
    position: fixed;
    overflow-y: scroll;
    padding-top: 120px;
    top: 0;
    bottom: 0;
  
    @media only screen and (max-width: 1100px) { // 1301 -
        height: 100vh;
        padding-top: 0;
        position: relative;
        overflow-y: visible;
        left: 0;
    }
`

export const TimelineBars = styled.div`
    height: 0;
    width: 0;
    position: absolute;
    z-index: 0;
    margin-top: -70px;

    @media only screen and (max-width: 1100px) { // 1300
        margin-top: -120px;
    }
    @media only screen and (max-width: 750px) {
        margin-top: -80px;
    }
    
    &::before {
        content: "";
        width: 40px;
        height: 16px;
        z-index: -1;
        background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
        position: absolute;
        top: 25px;
        transform: rotate(30deg);
    }
    
    &::after {
        content: "";
        width: 40px;
        height: 16px;
        z-index: -1;
        background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
        position: absolute;
        top: 60px;
        transform: rotate(30deg);
    }
`;

export const TimelineHeaderContainer = styled.section`
    height: auto;
    width: inherit;
    top: 0;
    z-index: 1;
    color: ${props => textColor(props.darkmode)};
    margin: 30px 0 40px 55px;
    position: fixed;
    user-select: none;

    &::after {
        content: "";
        width: 100%;
        height: 130px;
        position: absolute;
        z-index: -2;
        margin: -120px 0 0 -10px;
        background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    }

    @media only screen and (max-width: 1100px) { // 1300
        display: none;
    }
`

export const TimelineTitleContainer = styled.h1`
    font-size: 25px;
    font-family: ${font.pulpDisplayBold};
    height: 100%;
    display: flex;
    margin-left: 50px;
`;

export const TimelineSubTitleContainer = styled.div`
    font-size: 16px;
    font-family: ${font.pulpDisplayBold};
    height: 100%;
    display: flex;
    text-align: left;
    margin: 10px 0 0 50px;
    color: ${props => props.darkmode ? color.gray3 : color.gray2};
`;

export const TimelineFeedContainer = styled.div`
    height: auto;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;

    @media only screen and (max-width: 1100px) { // 1300
        position: relative;
        height: auto;
        width: auto;
    }
`;