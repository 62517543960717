import styled from "styled-components";

export const LoginServicesContainer = styled.div`
    width: 100%;
    position: relative;
    
    button {
        margin-bottom: 15px;
    }
    
    button:last-child {
        margin-bottom: 0;
    }
`;