import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { LayoutContext } from "../../App";
import { searchData } from "../lang.data";
import { filterData } from "./interactionBar.utils";

import { FilterContainer, InteractionContainer, SearchbarContainer } from "./interactionBar.styles";
import Searchbar from "../searchbar/searchbar.component";
import ContextMenu from "../contextMenu/contextMenu.component";

const InteractionBar = ({ type, subType, orderData, orderCallback }) => {
    const { mainWidth } = useContext(LayoutContext);
    const [localValue, setLocalValue] = useState("");

    const { search } = useLocation();
    const filter = new URLSearchParams(search);
    const filterParam = filter.get("sortBy") || "date";

    useEffect(() => {
        if(orderData) orderCallback(filterData(orderData, filterParam, localValue, type, subType))
    }, [orderData, filterParam, localValue, type])

    return(
        <InteractionContainer fullWidth={mainWidth <= 600} type={type}>
            <SearchbarContainer>
                <Searchbar
                    setLocalValue={setLocalValue}
                    localValue={localValue}
                    type={type}
                    main={0}
                />
            </SearchbarContainer>
            <FilterContainer>
                <ContextMenu
                    type={"filter"}
                    current={filterParam}
                    title={searchData.filter.title}
                    data={searchData.filter.options[type]}
                />
            </FilterContainer>
        </InteractionContainer>
    )
}

export default InteractionBar;