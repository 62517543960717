import { color } from "../../design/theme";

export const navMap = (navOrder, navData, lang) => {
    return navOrder.map(navPoint => ({
            name: navPoint,
            desc: navData[navPoint].desc[lang],
            icon: navData[navPoint].icon
        })
    )
}

export const navColor = (props) => {
    if(props.active) {
        return color.blue
    } else if (props.darkmode) {
        return color.white
    } else {
        return color.black
    }
}

export const navBackground = (props) => {
    if(props.darkmode && props.active) {
        return color.blueActiveDark
    } else if (props.darkmode) {
        return color.gray2
    } else if (props.active) {
        return color.blueActiveLight
    } else {
        return color.white
    }
}