const ArticleDataActionTypes = {
    FETCH_ARTICLE_DATA_START: "FETCH_ARTICLE_DATA_START",
    FETCH_ARTICLE_DATA_SUCCESS: "FETCH_ARTICLE_DATA_SUCCESS",
    FETCH_ARTICLE_DATA_FAILURE: "FETCH_ARTICLE_DATA_FAILURE",

    FETCH_ARTICLE_PREVIEW_START: "FETCH_ARTICLE_PREVIEW_START",
    FETCH_ARTICLE_PREVIEW_SUCCESS: "FETCH_ARTICLE_PREVIEW_SUCCESS",
    FETCH_ARTICLE_PREVIEW_FAILURE: "FETCH_ARTICLE_PREVIEW_FAILURE"
}

export default ArticleDataActionTypes;