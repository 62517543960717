import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import { selectBookmarkDataRaw, selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { bookmarkArticle } from "../../redux/userEvents/userEvents.actions";
import { color } from "../../design/theme";
import { listsData } from "../lang.data";

import { ArticleActionContainer, ArticleActionIcon } from "./articleAction.styles";
import Modal from "../modal/modal.component";
import ModalSelection from "../modalSelection/modalSelection.component";

const ArticleAction = ({ type, articleId, bookmarkDataRaw, darkmode, bookmarkArticle, userId, beside, lang }) => {
    const [listActive, setListActive] = useState(false);
    const [bookmark, setBookmark] = useState(false);

    const handleOnClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(type === "bookmark") bookmarkArticle(userId, articleId)
        if(type === "list") setListActive(!listActive)
    }

    useEffect(() => {
        if (bookmarkDataRaw) {
            bookmarkDataRaw[articleId] ? setBookmark(true) : setBookmark(false)
        } else {
            setBookmark(false)
        }

    }, [bookmarkDataRaw, articleId])

    return (
        <Fragment>
            <ArticleActionContainer
                onClick={(event) => handleOnClick(event)}
                aria-label={type === "bookmark" ? "Bookmark this Article" : "Add Article to List"}
                tabIndex={"0"}
                darkmode={darkmode}
                type={type}
                beside={beside}
            >
                <ArticleActionIcon
                    name={type === "bookmark" ? "bookmark" : "lists"}
                    outline={type === "bookmark" ? !bookmark : null}
                    color={darkmode ? color.white : color.black}
                    size={25}
                />
            </ArticleActionContainer>
            {
                listActive ? (
                    <Modal
                        title={`${listsData.addToList[lang]} 🧺`}
                        desc={listsData.addToListDesc[lang]}
                        onDismiss={() => setListActive(!listActive)}
                    >
                        <ModalSelection
                            type={type}
                            refValue={articleId}
                            onDismiss={() => setListActive(!listActive)}
                        />
                    </Modal>
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    bookmarkDataRaw: selectBookmarkDataRaw,
    userId: selectUserId,
    lang: selectUserLanguage
})

const mapDispatchToProps = (dispatch) => ({
    bookmarkArticle: (userId, articleId) => dispatch(bookmarkArticle(userId, articleId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleAction);