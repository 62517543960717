import { capitalize, validateLang } from "../../components/general.utils";

export const createUserProfileDocument = (userId, email, firstName, lastName, userLang, location, imageUrl = "") => {
    return {
        general: {
            userId: userId,
            firstName: capitalize(firstName),
            lastName: capitalize(lastName),
            name: `${capitalize(firstName)} ${capitalize(lastName)}`,
            email: email,
            profilePicture: imageUrl,
            language: validateLang(userLang),
        },
        details: {
            emailVerified: false,
            banned: false,
            backupEmail: "",
            backupEmailVerified: false,
            signUpLocation: location,
            createdAt: new Date().toISOString()
        },
        news: {
            general: {
                loginEnabled: true,
                targetReads: "0",
                allowAllEmbeds: false,
                allowEmbeds: {}
            },
            details: {
                joinedAt: new Date().toISOString(),
                subscriptionType: "student",
                subscriptionActiveUntil: "",
                studVerified: true,
                studEligible: true,
                studVerifiedYearsTotal: 0,
                studVerifiedUntil: ""
            }
        }
    }
}