import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { color, radius } from "../../../../design/theme";
import { capitalize } from "../../../general.utils";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color.white};
    padding: 20px;
    border-radius: ${radius.largeRadius};
    overflow: hidden;
`

const EmbedDatawrapper = ({ embedUrl, width, provider, contentId }) => {
    const ref = useRef(null);

    useEffect(() => {
        const adjustHeight = (e) => {
            if(void 0 !== e.data["datawrapper-height"]) {
                for(let a in e.data["datawrapper-height"]) {
                    if (ref.current.contentWindow === e.source) {
                        ref.current.style.height = e.data["datawrapper-height"][a] + "px";
                    }
                }
            }
        }
        if(ref.current) window.addEventListener("message", adjustHeight)

        return () => window.removeEventListener("message", adjustHeight)
    }, [ref])

    return (
        <IFrameWrapper width={width}>
            <iframe
                title={`${capitalize(provider)} Iframe`}
                id={"modularIframe_" + contentId}
                width={width - 2*20}
                allowFullScreen
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                referrerPolicy={"no-referrer"}
                sandbox={"allow-popups allow-scripts"}
                style={{ display: "block" }}
                src={embedUrl}
                ref={ref}
            > </iframe>
        </IFrameWrapper>
    )
}

export default EmbedDatawrapper;