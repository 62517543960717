import styled from "styled-components";
import { color, font } from "../../design/theme";
import { textColor } from "../general.utils";

export const ArticleTimeContainer = styled.div`
    font-size: 16px;
    font-family: ${font.pulpDisplayMedium};
    align-items: center;
    letter-spacing: 0.01em;
    justify-content: flex-start;
    flex-direction: row;
    text-align: left;
    display: flex;
    color: ${props => props.contrast ? textColor(props.darkmode) : props.darkmode ? color.gray4 : color.gray2};    
    text-overflow: ellipsis;
    word-break: break-word;
    margin-top: auto;
  
    &>span {
        margin-left: 10px;
    } 
`

export const ArticleTimeLive = styled.h3`
    font-size: 14px;
    font-family: ${font.pulpDisplayMedium};
    letter-spacing: 0.02em;
    text-overflow: ellipsis;
    word-break: break-word;
    color: ${color.white};
    background-color: ${color.red};
    border-radius: 5px;
    padding: 2px 7px;
    margin: 2px 0 0 10px;
`;