import React, { useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { generateCdnUrl } from "../../general.utils";

import { ArticleAudioWrapper, ArticleAudioContainer } from "./articleAudio.styles";
import ArticleCaption from "../articleCaption/articleCaption.content";
import ArticleSubtitle from "../articleSubtitle/articleSubtitle.content";

const ArticleAudio = ({ content, contentId, darkmode }) => {
    const ref = useRef(null);
    const { audioUrl, caption, copyright, title, type } = content || {};

    useEffect(() => {
        let plyr = null;
        if(ref.current) {
            plyr = new Plyr(ref.current, {
                title: title,
                settings: ["captions", "quality", "speed"],
                controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "captions", "settings", "pip", "airplay", "fullscreen"],
                autopause: false,
                autoplay: false,
                volume: 0.75,
                resetOnEnd: false,
                keyboard: { focused: true, global: false },
                speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5] },
                iconUrl: generateCdnUrl("static/plyr-v3.6.9.svg")
            })
            plyr.source = {
                type: "audio",
                title: title,
                sources: [{ src: audioUrl, type: type }]
            };
            plyr.on("ready", () => {
                let parentDOM = document.querySelector("#a_" + contentId);
                let fullUi = parentDOM.querySelector(".plyr--audio");
                let timeInfo = parentDOM.querySelector(".plyr__time");
                let rangeSlider = parentDOM.querySelectorAll('[type="range"]');
                rangeSlider[0].style.cursor = "pointer";
                rangeSlider[1].style.cursor = "pointer";
                fullUi.style.borderRadius = "14px";
                timeInfo.style.cursor = "default";
                timeInfo.style.userSelect = "none";
            });
        }
        return () => plyr.destroy();
    }, [ref])

    return (
        <Fragment>
            {
                title && <ArticleSubtitle content={{ text: title }} />
            }
            <ArticleAudioWrapper
                id={"a_" + contentId}
                title={copyright ? `© ${copyright}` : null}
                darkmode={darkmode}
            >
                <ArticleAudioContainer ref={ref} />
            </ArticleAudioWrapper>
            {
                caption ? (
                    <ArticleCaption
                        caption={caption}
                        type={"audio"}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(ArticleAudio)