import styled from "styled-components";

export const ModalUrlSearchInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
  
    &>div:first-child {
        margin-right: 15px;
    }
`

export const ModalUrlSearchButtonContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
`;

export const ModalUrlSearchResultContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
`