import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleFootnoteWrapper,
    ArticleFootnoteContainer,
    ArticleFootnoteIconContainer,
    ArticleFootnoteIcon
} from "./articleFootnote.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleFootnote = ({ content, darkmode, metadata }) => {
    const { text } = content || {};
    const { prevType } = metadata || {};

    return (
        <ArticleFootnoteWrapper adjacentToFootnote={prevType === "footnote"}>
            <ArticleFootnoteIconContainer>
                <ArticleFootnoteIcon
                    name={"footnote"}
                    size={40}
                    color={color.blue}
                />
            </ArticleFootnoteIconContainer>
            <ArticleFootnoteContainer darkmode={darkmode}>
                <ArticleText text={text} italic />
            </ArticleFootnoteContainer>
        </ArticleFootnoteWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleFootnote);