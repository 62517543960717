import React from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { createStructuredSelector } from "reselect";

import { signUpEmailStart, signInEmailStart } from "../../redux/userLogin/userLogin.actions";
import { loginData, settingsData, signUpTermsData } from "../lang.data";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLoginError, selectUserLoginFetching } from "../../redux/userLogin/userLogin.selectors";
import { codes } from "../codes.data";
import { color } from "../../design/theme";
import { isValidEmail } from "../general.utils";

import {
    LoginFormNameContainer
} from "./loginForm.styles";
import InputField from "../../components/inputField/inputField.component";
import CustomButton from "../../components/customButton/customButton.component";
import OptIn from "../../components/optIn/optIn.component";
import Notice from "../notice/notice.component";

const LoginForm = ({ darkmode, signUpEmailStart, signInEmailStart, lang, loginError, loading, param }) => {

    const required = (value) => (value ? undefined : 'required')
    const mustBeEmail = (value) => (
        (!isValidEmail(value) || (value.length === 0)) ?
            'typeError' : undefined
    )
    const minLengthEight = (value) => {
        return (((value.length <= 7) || (value.length === 0) || (value.length > 64)) ? 'typeError' : undefined)
    }
    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    const onSubmit = async (values) => {
        if (param === "signup") {
            // await signUpEmailStart(values);
        } else if (param === "login") {
            await signInEmailStart(values);
        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            subscription={{ submitting: true, invalid: true, pristine: true }}
            initialValues={{ acceptedTerms: false }}
            render={({ handleSubmit, submitting, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                    {
                        param === "signup" ? (
                            <LoginFormNameContainer>
                                <Field
                                    validate={required}
                                    subscription={{ touched: true, error: true, value: true, valid: true }}
                                    name={"firstName"}
                                    component={InputField}
                                    placeholder={settingsData.firstName[lang]}
                                    darkmode={darkmode}
                                    type={"name"}
                                    checkType={false}
                                />
                                <Field
                                    validate={required}
                                    subscription={{ touched: true, error: true, value: true, valid: true }}
                                    name={"lastName"}
                                    component={InputField}
                                    placeholder={settingsData.lastName[lang]}
                                    darkmode={darkmode}
                                    type={"name"}
                                    checkType={false}
                                />
                            </LoginFormNameContainer>
                        ) : null
                    }
                    <Field
                        validate={composeValidators(required, mustBeEmail)}
                        subscription={{ touched: true, error: true, value: true, valid: true }}
                        name={"email"}
                        component={InputField}
                        placeholder={"Email"}
                        darkmode={darkmode}
                        type={"email"}
                        checkType={true}
                    />
                    <Field
                        validate={composeValidators(required, minLengthEight)}
                        subscription={{ touched: true, error: true, value: true, invalid: true }}
                        name={"password"}
                        component={InputField}
                        placeholder={loginData.password[lang]}
                        darkmode={darkmode}
                        type={"password"}
                        checkType={true}
                    />
                    {
                        loginError ? (
                            <Notice
                                desc={codes[loginError][lang]}
                                type={"error"}
                            />
                        ) : null
                    }
                    {
                        (param === "signup") ? (
                            <Field
                                name={"acceptedTerms"}
                                validate={required}
                                type={"checkbox"}
                                render={({ input }) => (
                                    <OptIn
                                        {...input}
                                        desc={signUpTermsData(lang)}
                                    />
                                )}
                            />
                        ) : null
                    }
                    <CustomButton
                        type={"submit"}
                        desc={param === "signup" ? loginData.signup[lang] : loginData.login[lang]}
                        disabled={invalid || submitting || pristine || param === "signup"}
                        submitError={loginError}
                        loading={loading ? 1 : 0}
                        color={color.blue}
                        solid
                    />
                </form>
            )}
        />
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode,
    loading: selectUserLoginFetching,
    loginError: selectUserLoginError
})

const mapDispatchToProps = (dispatch) => ({
    // signUpEmailStart: (values) => dispatch(signUpEmailStart(values)),
    signInEmailStart: (values) => dispatch(signInEmailStart(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);