import styled from "styled-components";
import { font, color } from "../../design/theme";

export const ModalFeedbackDisclaimer = styled.div`
    font-family: ${font.pulpDisplayMedium};
    color: ${color.gray3};
    font-size: 16px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-bottom: 18px;
  
    & > a {
        color: ${color.blue}
    }
`