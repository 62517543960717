import styled from "styled-components";
import { color, font } from "../../../design/theme";
import { textColor } from "../../general.utils";
import { Link } from "react-router-dom";
import Icon from "../../../design/icons/icon.component";

export const ArticleTextContainer = styled.div`
    font-size: ${props => props.size ? props.size + "px" : props.small ? "16px" : "20px"};
    font-family: ${props => (props.italic && props.bold) ? font.interBoldItalic : props.italic ? font.interMediumItalic : props.bold ? font.interBold : font.interMedium};
    font-style: ${props => props.italic ? "italic" : "normal"};
    position: relative;
    width: ${props => props.auto ? "auto" : "100%"};
    min-width: 0;
    height: auto;
    color: ${props => props.color ? props.color : props.contrast ? textColor(props.darkmode) : props.darkmode ? color.gray4 : color.gray2};
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    line-height: ${props => props.small ? props.narrow ? "20px" : "24px" : props.narrow ? "30px" : "36px"};
    letter-spacing: ${props => props.narrow ? "0.01em" : "0.035em"};
    white-space: pre-wrap;
  
    a {
        font-family: ${props => props.italic ? font.interBoldItalic : font.interBold};
    }
    sup {
        vertical-align: bottom;
        position: relative;
        bottom: 6px;
    }
    sub {
        vertical-align: top;  
        position: relative;
        top: 6px;
    }

    @media only screen and (max-width: 750px) {
        font-size: ${props => props.size ? props.size + "px" : props.small ? "16px" : "18px"};
        line-height: ${props => props.small ? props.narrow ? "20px" : "24px" : props.narrow ? "30px" : "32px"};
    }
`;

export const ArticleTextLink = styled(Link)`
    margin-right: ${props => !props.internal ? "0" : "0"};
    position: relative;
  
    &:hover {
        opacity: 0.85;
    }
`

export const ArticleTextIcon = styled(Icon)`
    display: inline-flex;
    align-self: center;
    top: 0.175em;
    position: relative;
    padding-top: 4px;
  
    & path {
        fill: ${color.blue}
    }
`