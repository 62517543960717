import styled from "styled-components";
import { color, font } from "../../../design/theme";
import { textColor } from "../../../components/general.utils";
import Logo from "../../../design/logos";

import BostonSyklineCleanRegularWoff2 from "../../../design/fonts/BostonSkylineClean-Regular.woff2"
import BostonSyklineCleanRegularWoff from "../../../design/fonts/BostonSkylineClean-Regular.woff"
import BostonSyklineCleanRegularTtf from "../../../design/fonts/BostonSkylineClean-Regular.ttf"

export const LoginContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    background-color: ${props => props.darkmode ? "none" : color.white};

    @font-face {
        font-family: "BostonSkylineClean-Regular=latin rev=1";
        font-style: normal;
        font-display: swap;
        src: url('../../../design/fonts/BostonSkylineClean-Regular.woff2') format("woff2"),
             url('../../../design/fonts/BostonSkylineClean-Regular.woff') format("woff"),
             url('../../../design/fonts/BostonSkylineClean-Regular.ttf') format("truetype");
    }
`;

export const LoginFormContainer = styled.div`
    display: flex;
    text-align: left;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

export const LoginArtContainer = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    
    > img {
        width: inherit;
        height: inherit;
        z-index: 5;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
    }
`;

export const LoginFormContentWrapper = styled.div`
    width: max-content;
    height: max-content;
    position: relative;
`

export const LoginFormContentContainer = styled.div`
    height: auto;
    max-height: 100vh;
    width: 400px;
    position: relative;
    margin-bottom: -50px;
    padding: 20px 2px;
    overflow-y: scroll;
  
    & > form {
        width: 100%;
    }
`;

export const LoginFormHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 25px;
`;

export const LoginFormLogo = styled(Logo)`
    margin-top: 2px;
    user-select: none;
`;

export const LoginFormTitleContainer = styled.h1`
    font-size: 50px;
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    margin-left: 20px;
`

export const LoginHomeButtonContainer = styled.div`
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: center;
`

export const LoginHomeButton = styled.a`
    height: 40px;
    padding: 0 20px;
    width: max-content;
    border-radius: 50px;
    background-color: ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.025em;
    margin-top: 10px;
    cursor: help;
    user-select: none;
  
    &:hover {
        opacity: 0.8;
    }
    &:active {
        transform: scale(0.98);
    }
`

export const LoginFormGreeting = styled.div`
    @font-face {
        font-family: "Boston Skyline Clean";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: local("Boston Skyline Clean"), local("BostonSkylineClean-Regular"),
        url(${BostonSyklineCleanRegularWoff2}) format("woff2"),
        url(${BostonSyklineCleanRegularWoff}) format("woff"),
        url(${BostonSyklineCleanRegularTtf}) format("truetype");
    }
    
    font-size: 34px;
    line-height: 34px;
    white-space: pre-wrap;
    font-family: "Boston Skyline Clean", Helvetica, Arial, sans-serif;
    color: ${props => textColor(props.darkmode)};
    position: absolute;
    top: -130px;
    left: 210px;
`

export const LoginFormArrow = styled.img`
    position: absolute;
    width: 60px;
    top: -50px;
    left: 340px;
    filter: ${props => props.darkmode ? "none" : "invert(1)"};
    user-select: none;
    -webkit-user-drag: none;
`