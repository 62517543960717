import styled from "styled-components";
import { textColor } from "../general.utils";
import { color, radius, font } from "../../design/theme";

export const AccountChartContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    border-radius: ${radius.largeRadius};
    text-align: left;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    position: relative;
    font-family: ${font.interBold};
    padding: ${props => props.type === "pie" ? "20px 40px" : "20px 30px"};
`;

export const AccountChartTitleContainer = styled.div`
    color: ${props => textColor(props.darkmode)};
    font-size: 25px;
    line-height: 24px;
    margin-bottom: 22px;
    display: inline-block;
`;

export const AccountChartOptionsContainer = styled.div`
    width: ${props => props.mobileView ? "calc(100% - 60px)" : "auto"};
    display: inline-block;
    position: absolute;
    margin-top: -8px;
    right: 30px;
    top: ${props => props.mobileView ? "auto" : "20px"};
    bottom: ${props => props.mobileView ? "20px" : "auto"};
`;

export const AccountChartCanvasWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: -45px;
    padding-top: 45px;
    padding-bottom: ${props => props.mobileView ? "45px" : "0"};
`

export const AccountChartCanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`

export const AccountChartCanvasBox = styled.div`
    height: ${props => props.type === "pie" ? "auto" : "100%"};
    width: ${props => props.type === "pie" ? (props.pieWidth + "px" || "100%") : "100%"};
    max-height: 100%;
`