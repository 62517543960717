import styled from "styled-components";

export const ArticleFeedWrapper = styled.section`
    width: 100%;
    display: ${props => props.type === "image" ? "grid" : "block"};
    padding: 0 2px;

    ${props => props.type === "image" ? 
            `grid-template-columns: repeat(${props.numColumns}, 1fr); row-gap: 20px; column-gap: 20px;` : null
    };
`