import styled from "styled-components";
import { color, font, radius } from "../../../design/theme";
import { textColor } from "../../general.utils";
import Icon from "../../../design/icons/icon.component";

export const ArticleEmbedWrapper = styled.div`
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    margin: 30px 0;
`

export const ArticleEmbedContainer = styled.div`
    display: flex;
    width: ${props => !props.consent ? props.width + "px" : "auto"};
    height: ${props => !props.consent ? props.height + "px" : "auto"};
    min-height: 300px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: ${props => !props.consent ? radius.largeRadius : null};
    background-color: ${props => !props.consent ? props.darkmode ? color.gray2 : color.white : "transparent"};
`

export const ArticleEmbedConsentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: clamp(60%, 540px, 80%);
`

export const ArticleEmbedConsentImageContainer = styled.div`
    display: inline-flex;
    height: ${props => props.height + "px"};
    width: 40px;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 15px;
  
    & > picture {
        border-radius: 50px;
        box-shadow: 0 0 0 1px ${props => !props.darkmode ? color.gray4 : "transparent"};
        overflow: hidden;
    }
`

export const ArticleEmbedConsentContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 100;
`

export const ArticleEmbedConsentTitleContainer = styled.div`
    font-family: ${font.interBold};
    text-align: left;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.01em;
    margin-bottom: 15px;
    color: ${props => props.darkmode ? color.gray4 : color.gray3};
    white-space: pre-wrap;
    
    & > span:nth-child(2) {
        color: ${props => textColor(props.darkmode)};
    }
`

export const ArticleEmbedCookieInfoContainer = styled.div`
    width: inherit;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-left: 2px;
`;

export const ArticleEmbedCookieInfoIconContainer = styled(Icon)`
    height: 100%;
    width: 25px;
    display: flex;

    &:hover {
        border-radius: 50%;
        background-color: ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
        box-shadow: 0 0 0 3px ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
    }
`;

export const ArticleEmbedCookieInfoTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    width: auto;
    margin-left: 10px;
`

export const ArticleEmbedCookieInfoTextContainer = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    word-break: break-word;
    line-height: 22px;
    letter-spacing: 0.045em;
    color: ${props => props.desc ? props.darkmode ? color.gray3 : color.gray2 : textColor(props.darkmode)};
    height: inherit;
    width: auto;
`

export const ArticleEmbedConsentInfoContainer = styled.div`
    font-family: ${font.interMedium};
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.035em;
    margin: 10px 0 25px 0;
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    white-space: pre-wrap;
    text-overflow: ellipsis;
`

export const ArticleEmbedConsentActionContainer = styled.div`
    display: flex;
    width: 200px;
    height: auto;
    flex-direction: row;
    
    & > button {
        margin-right: 10px;
    }
`