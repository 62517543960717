import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectCollectionsDataRaw, selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { listArticle } from "../../redux/userEvents/userEvents.actions";
import { color } from "../../design/theme";
import { miscData } from "../lang.data";

import {
    ListSelectionContainer,
    ListSelectionPictureContainer,
    ListSelectionContentContainer,
    ListSelectionTitleContainer,
    ListSelectionDescContainer,
    ListSelectionDescIcon,
    ListSelectionIcon
} from "./listSelection.styles";
import StaticImage from "../staticImage/staticImage.component";

const ListSelection = ({ list, userId, darkmode, type, minimal, mapped, onDismiss, listArticle,
                           articleId, collectionsData, lang }) => {

    const [hover, setHover] = useState(false)
    const [addedToList, setAddedToList] = useState(false);

    const { title, creator, length, imageId, restricted, listId } = list || {};

    useEffect(() => {
        if(collectionsData?.[listId]?.[articleId]) setAddedToList(true)
        else setAddedToList(false)
    }, [collectionsData, articleId, listId])

    const handleListClick = (event) => {
        event.stopPropagation();
        if(type !== "delete") {
            listArticle(userId, articleId, listId)
            onDismiss()
        }
    }
    const handleListHover = (event) => {
        event.stopPropagation();
        setHover(true);
    }
    const handleListHoverLeave = (event) => {
        event.stopPropagation();
        setHover(false);
    }

    return (
        <ListSelectionContainer
            onClick={(event) => handleListClick(event)}
            onMouseOver={(event) => handleListHover(event)}
            onMouseLeave={(event) => handleListHoverLeave(event)}
            darkmode={darkmode}
            mapped={mapped}
            minimal={minimal}
        >
            <ListSelectionPictureContainer minimal={minimal}>
                <StaticImage
                    imageId={imageId}
                    main
                />
            </ListSelectionPictureContainer>
            <ListSelectionContentContainer>
                <ListSelectionTitleContainer darkmode={darkmode}>
                    <span>{title}</span>
                </ListSelectionTitleContainer>
                {
                    !minimal ? (
                        <ListSelectionDescContainer type={type}>
                            {
                                type === "delete" ? (
                                    <Fragment>
                                        <span>{`${restricted ? miscData.private[lang] 
                                                             : miscData.public[lang]} `}</span>
                                        <ListSelectionDescIcon
                                            name={restricted ? "lock" : "global"}
                                            size={16}
                                            color={color.blue}
                                        />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <span>{miscData.by[lang]} </span>
                                        <span>{creator === userId ? miscData.you[lang] : "reavide"}</span>
                                    </Fragment>
                                )
                            }
                            <span>{` • `}</span>
                            <span>{`${length} ${miscData.articles[lang]}`}</span>
                        </ListSelectionDescContainer>
                    ) : null
                }
            </ListSelectionContentContainer>
            <ListSelectionIcon
                name={type === "delete" ? "delete" : (addedToList && hover) ? "remove" : addedToList ? "check" : "add"}
                hover={hover}
                size={34}
                color={type === "delete" ? color.gray4 : color.blue}
            />
        </ListSelectionContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    collectionsData: selectCollectionsDataRaw,
    userId: selectUserId,
    lang: selectUserLanguage
})

const mapDispatchToProps = (dispatch) => ({
    listArticle: (userId, articleId, listId) => dispatch(listArticle(userId, articleId, listId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListSelection);