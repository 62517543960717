const UserDataActionTypes = {
    FETCH_USER_DATA_START: "FETCH_USER_DATA_START",
    FETCH_USER_DATA_SUCCESS: "FETCH_USER_DATA_SUCCESS",
    FETCH_USER_DATA_FAILURE: "FETCH_USER_DATA_FAILURE",

    FETCH_USER_BOOKMARKS_START: "FETCH_USER_BOOKMARKS_START",
    FETCH_USER_BOOKMARKS_SUCCESS: "FETCH_USER_BOOKMARKS_SUCCESS",
    FETCH_USER_BOOKMARKS_FAILURE: "FETCH_USER_BOOKMARKS_FAILURE",

    FETCH_USER_CHANNELS_START: "FETCH_USER_CHANNELS_START",
    FETCH_USER_CHANNELS_SUCCESS: "FETCH_USER_CHANNELS_SUCCESS",
    FETCH_USER_CHANNELS_FAILURE: "FETCH_USER_CHANNELS_FAILURE",

    FETCH_USER_HISTORY_START: "FETCH_USER_HISTORY_START",
    FETCH_USER_HISTORY_SUCCESS: "FETCH_USER_HISTORY_SUCCESS",
    FETCH_USER_HISTORY_FAILURE: "FETCH_USER_HISTORY_FAILURE",

    FETCH_USER_LISTS_START: "FETCH_USER_LISTS_START",
    FETCH_USER_LISTS_SUCCESS: "FETCH_USER_LISTS_SUCCESS",
    FETCH_USER_LISTS_FAILURE: "FETCH_USER_LISTS_FAILURE",

    FETCH_USER_COLLECTIONS_START: "FETCH_USER_COLLECTIONS_START",
    FETCH_USER_COLLECTIONS_SUCCESS: "FETCH_USER_COLLECTIONS_SUCCESS",
    FETCH_USER_COLLECTIONS_FAILURE: "FETCH_USER_COLLECTIONS_FAILURE",

    FETCH_USER_ACCESSED_START: "FETCH_USER_ACCESSED_START",
    FETCH_USER_ACCESSED_SUCCESS: "FETCH_USER_ACCESSED_SUCCESS",
    FETCH_USER_ACCESSED_FAILURE: "FETCH_USER_ACCESSED_FAILURE",

    FETCH_USER_SEARCHES_START: "FETCH_USER_SEARCHES_START",
    FETCH_USER_SEARCHES_SUCCESS: "FETCH_USER_SEARCHES_SUCCESS",
    FETCH_USER_SEARCHES_FAILURE: "FETCH_USER_SEARCH_FAILURE",
}

export default UserDataActionTypes;