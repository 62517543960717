import React from "react";
/*
const ArticleParagraph = lazy(() => import("../articleParagraph/articleParagraph.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleFootnote = lazy(() => import("../articleFootnote/articleFootnote.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleSubtitle = lazy(() => import("../articleSubtitle/articleSubtitle.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleFallback = lazy(() => import("../articleFallback/articleFallback.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleInfobox = lazy(() => import("../articleInfobox/articleInfobox.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleTicker = lazy(() => import("../articleTicker/articleTicker.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleImage = lazy(() => import("../articleImage/articleImage.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleQuote = lazy(() => import("../articleQuote/articleQuote.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleVideo = lazy(() => import("../articleVideo/articleVideo.content").catch(() =>import("../articleFallback/articleFallback.content")));
const ArticleList = lazy(() => import("../articleList/articleList.content").catch(() =>import("../articleFallback/articleFallback.content")));
*/
import ArticleParagraph from "../articleParagraph/articleParagraph.content";
import ArticleFootnote from "../articleFootnote/articleFootnote.content";
import ArticleSubtitle from "../articleSubtitle/articleSubtitle.content";
import ArticleFallback from "../articleFallback/articleFallback.content";
import ArticleInfobox from "../articleInfobox/articleInfobox.content";
import ArticleGallery from "../articleGallery/articleGallery.content";
import ArticleTicker from "../articleTicker/articleTicker.content";
import ArticleImage from "../articleImage/articleImage.content";
import ArticleQuote from "../articleQuote/articleQuote.content";
import ArticleAudio from "../articleAudio/articleAudio.content";
import ArticleVideo from "../articleVideo/articleVideo.content";
import ArticleEmbed from "../articleEmbed/articleEmbed.content";
import ArticleList from "../articleList/articleList.content";

import ErrorBoundary from "../../errorBoundary/errorBoundary.component";

const ArticleInterface = ({ type, content, contentId, metadata }) => {
    const components = {
        paragraph: ArticleParagraph,
        footnote: ArticleFootnote,
        subtitle: ArticleSubtitle,
        infobox: ArticleInfobox,
        gallery: ArticleGallery,
        ticker: ArticleTicker,
        image: ArticleImage,
        quote: ArticleQuote,
        audio: ArticleAudio,
        video: ArticleVideo,
        embed: ArticleEmbed,
        list: ArticleList,

        fallback: ArticleFallback
    }
    const componentType = components[type] ? type : "fallback";
    const TagComponent = components[componentType];

    return (
        <ErrorBoundary type={"articleComponent"}>
            <TagComponent content={content} contentId={contentId} metadata={metadata}/>
        </ErrorBoundary>
    )
}

export default ArticleInterface;