import styled from "styled-components";
import Logo from "../../design/logos";

export const NavContainer = styled.nav`
    left: 0;
    bottom: 0;
    align-items: flex-start;
    width: 250px;
    margin: 0 0 0 25px;
    display: flex;
    height: calc(100vh - 20px);
    // position: fixed;
    text-align: left;

    @media only screen and (max-width: 1540px) {
        width: 55px;
        margin: 0;
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
    }
`;

export const NavContentContainer = styled.div`
    display: flex;
    position: fixed;
    width: inherit;
    height: inherit;
    flex-direction: column;
    padding-bottom: 15px;
    overflow-y: scroll;
    will-change: transform;
  
    & > div:nth-last-child(2) {
        margin-top: auto;
    }
`

export const NavLogoContainer = styled(Logo)`
    left: 0;
    width: 100%;
    margin: 0 0 20px -25px;
    justify-content: left;
    user-select: none;

    @media only screen and (max-width: 1540px) {
        margin: 0 0 20px 0;
    }
`;