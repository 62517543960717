import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { doc, getDoc } from "firebase/firestore";
import { orderByKey, ref, query, endBefore, limitToLast, onValue, onChildAdded, onChildRemoved } from "firebase/database";

import BreakingDataActionTypes from "./breakingData.types";

import { store } from "../store";
import { firestore, breaking } from "../../config/firebase.config";
import { fetchBreakingDataSuccess, fetchBreakingDataFailure } from "./breakingData.actions";
import { selectBreakingList } from "./breakingData.selectors";

export function* fetchBreakingDataStartAsync(action) {
    try {
        const { lang } = action.payload;
        let breakingGeneral = yield ref(breaking, "general");
        let breakingList = yield select(selectBreakingList);
        let anchorKey = breakingList?.length ? breakingList[breakingList.length - 1].key : ""
        let latestKey = "";

        let breakingGeneralRef = anchorKey ? query(breakingGeneral, orderByKey(), endBefore(anchorKey), limitToLast(10)) : query(breakingGeneral, limitToLast(10));
        yield onValue(breakingGeneralRef, async snapshot => {
            if(snapshot.exists()) {
                const snapshotData = await snapshot.val();
                const snapshotArr = [];
                const snapshotObj = {};
                Object.values(snapshotData).forEach(({ breakingId }) => snapshotArr.unshift(breakingId))
                Object.entries(snapshotData).forEach(entry => {
                    const { breakingId } = entry[1];
                    Object.assign(snapshotObj, { [breakingId]: { key: entry[0] } })
                })
                latestKey = Object.values(snapshotData).pop()["breakingId"];

                ((articleIds) => {
                    let docRefs = articleIds.map(async id => {
                        let docRef = doc(firestore, "breaking-news", id);
                        return await getDoc(docRef);
                    });
                    let langRefs = articleIds.map(async id => {
                        let langRef = doc(firestore, "breaking-news", id, "language", lang)
                        return await getDoc(langRef);
                    });
                    Promise.all([docRefs, langRefs].flat())
                        .then(docs => {
                            let result = docs.map(doc => doc.data());
                            let length = (result.length / 2)
                            let resultArray = [];
                            for (let i = 0; i < length; i++) {
                                if(result[i] && result[i + length]) {
                                    resultArray.push(Object.assign(result[i], result[i + length], snapshotObj[result[i].id]))
                                }
                            }
                            store.dispatch(fetchBreakingDataSuccess(resultArray, "batch"))
                        })
                })(snapshotArr)
            } else {
                store.dispatch(fetchBreakingDataSuccess([]))
            }
        }, { onlyOnce: true })

        yield !anchorKey && onChildAdded(query(breakingGeneral, limitToLast(1)), async snapshot => {
            if (snapshot.exists()) {
                const snapshotData = await snapshot.val();

                (({ breakingId }) => {
                    let docRef = getDoc(doc(firestore, "breaking-news", breakingId))
                    let langRef = getDoc(doc(firestore, "breaking-news", breakingId, "language", lang))
                    Promise.all([docRef, langRef].flat())
                        .then(docs => {
                            let result = { ...docs[0].data(), ...docs[1].data(), key: snapshot.key }
                            if (result.id !== latestKey) {
                                store.dispatch(fetchBreakingDataSuccess(result, "child_added"))
                            } else console.log("BREAKING UPDATE PREVENTED")
                        })
                })(snapshotData)
            }
        })
        yield !anchorKey && onChildRemoved(breakingGeneral, async snapshot => {
            if(snapshot.exists()) {
                const { breakingId } = await snapshot.val();
                store.dispatch(fetchBreakingDataSuccess({ id: breakingId }, "child_removed"))
            }
        })
    } catch (error) {
        yield put(fetchBreakingDataFailure(error.message))
    }
}

export function* fetchBreakingDataStart() {
    yield takeLatest(
        BreakingDataActionTypes.FETCH_BREAKING_DATA_START,
        fetchBreakingDataStartAsync
    )
}

export function* breakingDataSagas() {
    yield all([
        call(fetchBreakingDataStart)
    ])
}