import React, { useEffect, useRef, useState, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { LayoutContext } from "../../../App";
import { HeaderContext } from "../../../context/header.context";
import { selectArticle } from "../../../redux/articleData/articleData.selectors";
import { selectDarkmode, selectArticleReadingProgress } from "../../../redux/persistData/persistData.selectors";
import { categoryColor, sanitizeStringToUrl } from "../../../components/general.utils";
import { fetchArticleDataStart } from "../../../redux/articleData/articleData.actions";
import { fetchUserBookmarksStart } from "../../../redux/userData/userData.actions";
import { logArticleRead } from "../../../redux/userEvents/userEvents.actions";
import { selectUserId, selectBookmarkDataRaw, selectUserLanguage, selectUserDataFetching } from "../../../redux/userData/userData.selectors";
import { color } from "../../../design/theme";
import { articleDataCC } from "../../../components/lang.data";

import {
    ArticleContainer,
    ArticleLogoContainer,
    ArticleFlairContainer,
    ArticleTitleContainer,
    ArticleTextContainer
} from "./article.styles";
import ArticleImage from "../../../components/articleContent/articleImage/articleImage.content";
import ArticleInterface from "../../../components/articleContent/articleComponent/article.interface";
import ArticleInfo from "../../../components/articleInfo/articleInfo.component";
import Logo from "../../../design/logos";
import Icon from "../../../design/icons/icon.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const ArticlePage = ({ article, darkmode, articleId, fetchArticleDataStart, articleProgress, logArticleRead,
                         userId, fetchUserBookmarksStart, bookmarkData, lang, bookmarkDataLoading }) => {

    const { mainWidth } = useContext(LayoutContext);
    const { setArticleEnv, setSimpleEnv } = useContext(HeaderContext);

    const[timeout, logTimeout] = useState(true);
    const[articleLog, setArticleLog] = useState(false);
    const history = useHistory();

    const { content, metadata, source } = article || {};
    const { title, catchline, components, flair, teaserImage, showTeaser } = content || {};
    const { publisher, newspaper, author, originalUrl } = source || {};
    const { category, publishedAt, updatedAt, readingTime, type, numUpdates } = metadata || {};

    const deletedState = type === "deleted";
    const undefinedState = type === "undefined";

    const ref = useRef(null);
    const histLog = setTimeout(() => logTimeout(false), 15000);

    useEffect(() => {
        if(!bookmarkData && !bookmarkDataLoading) fetchUserBookmarksStart(userId);
        if(!article && articleId !== "settings") fetchArticleDataStart("", articleId);

        if(ref && title && articleId && !deletedState && !undefinedState) {
            setArticleEnv({ ref, title, catchline, articleId });
            if(window.requestIdleCallback) {
                window.requestIdleCallback(() => history.replace(`?t=${sanitizeStringToUrl(title)}`));
            } else history.replace(`?t=${sanitizeStringToUrl(title)}`);

            if(!timeout && parseInt(articleProgress) >= 40 && !articleLog) {
                logArticleRead(userId, { articleId, category, lang });
                setArticleLog(true);
            }
        }
        if(deletedState || undefinedState) setSimpleEnv(true)

        return () => {
            clearTimeout(histLog);
            setArticleEnv(null);
            setSimpleEnv(null);
        }
    }, [setArticleEnv, articleId, title, timeout, articleProgress, article, ref, userId, components])

    return (
        <ArticleContainer ref={ref}>
            {
                article ? (
                    <Fragment>
                        <SiteHead
                            title={deletedState ? `${articleDataCC.deletedTitle[lang]} 😔` : undefinedState ? `${articleDataCC.undefinedTitle[lang]} 🤔` : title}
                            desc={deletedState ? articleDataCC.deletedDesc[lang] + " " + articleDataCC.deletedDescExt[lang] : undefinedState ? articleDataCC.undefinedDesc[lang] + " " + articleDataCC.undefinedDescExt[lang] : catchline}
                            type={(!deletedState && !undefinedState) ? "article" : ""}
                            data={{
                                shareUrl: `https://reavi.de/${articleId}`,
                                imageUrl: teaserImage?.imageUrl,
                                imageDesc: teaserImage?.caption,
                                imageType: teaserImage?.type,
                                publishedAt,
                                updatedAt,
                                category,
                                author
                            }}
                        />
                        <ArticleLogoContainer title={(deletedState || undefinedState) ?  newspaper : null}>
                            {
                                (deletedState || undefinedState) ? (
                                    <Icon
                                        name={deletedState ? "warning" : "faq"}
                                        size={34}
                                        color={darkmode ? color.gray3 : color.gray4}
                                    />
                                ) : (
                                    <Logo
                                        name={newspaper}
                                        size={mainWidth > 600 ? 34 : 25}
                                        color={categoryColor(category, darkmode)}
                                    />
                                )
                            }
                            {
                                flair ? (
                                    <ArticleFlairContainer
                                        darkmode={darkmode}
                                        color={categoryColor(category, darkmode)}
                                    >
                                        <span>{flair.toUpperCase()}</span>
                                    </ArticleFlairContainer>
                                ) : null
                            }
                        </ArticleLogoContainer>
                        <ArticleTitleContainer darkmode={darkmode} small={title?.length >= 100}>
                            <span>{deletedState ? `${articleDataCC.deletedTitle[lang]} 😔` : undefinedState ? `${articleDataCC.undefinedTitle[lang]} 🤔` : title}</span>
                        </ArticleTitleContainer>
                        {
                            (catchline || deletedState || undefinedState) ? (
                                <ArticleTextContainer darkmode={darkmode}>
                                    <span>{deletedState ? articleDataCC.deletedDesc[lang] + " " + articleDataCC.deletedDescExt[lang] : undefinedState ? articleDataCC.undefinedDesc[lang] + " " + articleDataCC.undefinedDescExt[lang] : catchline}</span>
                                </ArticleTextContainer>
                            ) : null
                        }
                        {
                            !(deletedState || undefinedState) ? (
                                <ArticleInfo
                                    infoProps={{ publisher, author, originalUrl, publishedAt,
                                        updatedAt, readingTime, type, numUpdates }}
                                />
                            ) : null
                        }
                        {
                            (showTeaser || deletedState || undefinedState) ? (
                                <ArticleImage content={teaserImage} removed={deletedState || undefinedState} />
                            ) : null
                        }
                        {
                            components ? components.map((component, index) => (
                                <ArticleInterface
                                    key={component.componentId}
                                    type={component.component}
                                    content={component.content}
                                    contentId={component.componentId}
                                    metadata={{
                                        ownIndex: index,
                                        maxIndex: components.length - 1,
                                        prevType: index >= 1 ? components[(index - 1)]?.component : null,
                                        nextType: components[(index + 1)]?.component || null
                                    }}
                                />
                            )) : null
                        }
                    </Fragment>
                ) : null
            }
        </ArticleContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    darkmode: selectDarkmode(state),
    article: selectArticle(ownProps.match.params.articleId)(state),
    articleId: ownProps.match.params.articleId,
    articleProgress: selectArticleReadingProgress(ownProps.match.params.articleId)(state),
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    bookmarkData: selectBookmarkDataRaw(state),
    bookmarkDataLoading: selectUserDataFetching("bookmarkData")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchArticleDataStart: (refKey, articleId) => dispatch(fetchArticleDataStart(refKey, articleId)),
    logArticleRead: (userId, { articleId, category, lang }) => dispatch(logArticleRead(userId, { articleId, category, lang })),
    fetchUserBookmarksStart: (userId) => dispatch(fetchUserBookmarksStart(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);