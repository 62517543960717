import { compareDates } from "../../components/general.utils";

export const configureHistoryPreview = (historyData) => {
    if(!historyData?.length) return [];

    let ctrlDate = "";
    let ctrlNum = 0;
    let ctrlId = "";
    const resultArr = [];
    historyData.forEach((article) => {
        if(compareDates(ctrlDate, article.timestamp)) {
            if(ctrlId === article.articleId) {
                let lastItemIndex = resultArr[ctrlNum].articleLogList.length - 1;
                resultArr[ctrlNum].articleLogList[lastItemIndex].articleCount++;
            } else {
                resultArr[ctrlNum].articleLogList.push(Object.assign(article, { articleCount: 1 }));
            }
        } else {
            if(resultArr.length) ctrlNum++;
            resultArr.push({ timestamp: article.timestamp, articleLogList: [] })
            resultArr[ctrlNum].articleLogList.push(Object.assign(article, { articleCount: 1 }));
        }
        ctrlId = article.articleId;
        ctrlDate = article.timestamp;
    })
    return resultArr;
}