import React, { useRef, useState, useEffect, useMemo, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { color } from "../../design/theme";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectHistoryData, selectUserLanguage, selectUserTargetReads } from "../../redux/userData/userData.selectors";
import { calcArticleTotal } from "../../pages/main/accountPage/account.utils";

import {
    AccountProgressContainer,
    AccountProgressIconBar,
    AccountProgressIconBarContainer,
    AccountProgressIconContainer,
    AccountProgressTitleContainer,
    AccountProgressTotalContainer
} from "./accountProgress.styles"

const AccountProgress = ({ darkmode, lang, targetReads, historyData, title }) => {
    const { mainWidth } = useContext(LayoutContext);

    const[leftBorder, setLeftBorder] = useState(true);
    const[rightBorder, setRightBorder] = useState(true);

    const childRef = useRef(null);
    const parentRef = useRef(null);

    let todayTotal = useMemo(() => calcArticleTotal("today", historyData), [historyData]);
    let todayLeft = (targetReads - todayTotal) <= 0 ? 0 : (targetReads - todayTotal);
    let todayDone = todayTotal > targetReads ? targetReads : todayTotal;
    let todayAdd = todayTotal > targetReads ? todayTotal - targetReads : 0;

    const scrollListener = (event) => {
        event?.preventDefault();
        parentRef.current.scrollLeft === 0 ? setLeftBorder(true) : setLeftBorder(false);
        (parentRef.current.scrollLeft + parentRef.current.offsetWidth) === parentRef.current.scrollWidth ? setRightBorder(true) : setRightBorder(false);
    }

    useEffect(() => scrollListener(), [mainWidth, targetReads, todayAdd])
    useEffect(() => {
        parentRef.current.addEventListener("scroll", (event) => scrollListener(event), true);

        return () => window.removeEventListener("scroll", scrollListener, true);
    }, [])


    return(
        <AccountProgressContainer darkmode={darkmode}>
            <AccountProgressTitleContainer
                darkmode={darkmode}
                gradient={leftBorder}
                long={(lang === "fr")}
            >
                <span>{title}</span>
            </AccountProgressTitleContainer>
            <AccountProgressIconBarContainer ref={parentRef} long={(lang === "fr")}>
                <AccountProgressIconBar ref={childRef}>
                    {
                        todayTotal ? [...Array(todayDone)].map((item, index) => (
                            <AccountProgressIconContainer
                                key={index}
                                margin={targetReads}
                                name={"check"}
                                color={color.green}
                                size={30}
                            />
                        )) : null
                    }
                    {
                        (todayLeft || todayAdd) ? [...Array(todayLeft || todayAdd)].map((item, index) => (
                            <AccountProgressIconContainer
                                key={index}
                                margin={targetReads}
                                name={todayLeft ? "circle" : "check"}
                                color={todayLeft ? color.gray3 : darkmode ? color.greenActiveDark : color.greenActiveLight}
                                size={30}
                            />
                        )) : null
                    }
                </AccountProgressIconBar>
            </AccountProgressIconBarContainer>
            <AccountProgressTotalContainer
                darkmode={darkmode}
                gradient={rightBorder}
                target={targetReads}
                long={(lang === "fr")}
            >
                <AccountProgressIconContainer
                    name={"arrow"}
                    color={(todayTotal >= targetReads) ? color.green : color.red}
                    size={30}
                />
                <span>{todayTotal}</span>
            </AccountProgressTotalContainer>
        </AccountProgressContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage,
    targetReads: selectUserTargetReads,
    historyData: selectHistoryData
})

export default connect(mapStateToProps)(AccountProgress);