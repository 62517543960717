import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";

import {
    ToggleSwitchContainer,
    ToggleSwitchButton
} from "./toggleSwitch.styles";

const ToggleSwitch = ({ darkmode, active = false, disabled, callback }) => {

    const handleOnClick = (event) => {
        event.stopPropagation();
        (callback && !disabled) && callback();
    }

    return (
        <ToggleSwitchContainer
            onClick={(event) => handleOnClick(event)}
            active={active}
            disabled={disabled}
            darkmode={darkmode}
        >
            <ToggleSwitchButton darkmode={darkmode} />
        </ToggleSwitchContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ToggleSwitch)