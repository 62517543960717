import styled from "styled-components";
import { color } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleQuoteWrapper = styled.div`
    width: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 35px 0;
`;

export const ArticleQuoteContainer = styled.div`
    max-width: 70%;
    height: auto;
    position: relative;
    text-align: left;
`;

export const ArticleQuoteIcon = styled(Icon)`
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0 0 -60px;

    @media only screen and (max-width: 750px) {
        margin: 0 0 0 -50px;
    }
`

export const ArticleQuoteAuthorContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    justify-content: flex-end;
    text-align: right;
  
    & > div {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
  
    & > div::before {
        content: "";
        height: 2px;
        width: 45px;
        border-radius: 5px;
        margin-right: 10px;
        display: flex;
        background-color: ${color.blue};
    }
`