import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { SearchContext } from "../../context/search.context";
import { deleteSearch } from "../../redux/userEvents/userEvents.actions";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage, selectUserId } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { searchData } from "../lang.data";

import {
    SearchElementContainer,
    SearchElementIconContainer,
    SearchElementIcon,
    SearchElementQuery,
    SearchElementAttribute,
    SearchAttributeWrapper,
    SearchAttributeContainer,
    SearchAttribute
} from "./searchElement.styles";

const SearchElement = ({ details, darkmode, type, lang, activeAttribute, callback, deleteSearch, userId, focusCallback }) => {
    const { value, setValue, currentAttribute, handleAttribute } = useContext(SearchContext);
    const [hover, setHover] = useState(false);
    const { query, attribute, key } = details || {};

    const handleClick = (event) => {
        event.stopPropagation();
        focusCallback();
        deleteSearch(userId, { key, query, attribute });
        if(currentAttribute === attribute && value === query) {
            setValue("");
            handleAttribute(attribute)
        }
    }
    const handleTest = (event) => {
        if(!event.target?.ownerSVGElement?.id?.toLowerCase()?.includes("delete_search") && !event.target?.id?.toLowerCase()?.includes("delete_search")) {
            callback(event, query, attribute)
        }
    }

    return (
        <SearchElementContainer
            onMouseEnter={() => setHover(true)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={type !== "attributes" ? (event) => handleTest(event) : null}
            attributes={type === "attributes"}
            darkmode={darkmode}
        >
            <SearchElementIcon
                name={type === "attributes" ? "enter" : type}
                size={30}
                color={hover ? color.blue : type === "attributes" ? darkmode ? color.gray3 : color.gray4 : darkmode ? color.gray4 : color.gray2}
            />
            <SearchElementQuery darkmode={darkmode} hover={hover} attributes={type === "attributes"}>
                <span>{type === "attributes" ? searchData.placeholder.attributes[lang] + ":" : query}</span>
            </SearchElementQuery>
            {
                attribute ? (
                    <SearchElementAttribute darkmode={darkmode}>
                        <span>{searchData.attributes.find(attributeCC => attributeCC.uriQuery === attribute)?.desc[lang]}</span>
                    </SearchElementAttribute>
                ) : null
            }
            {
                type === "attributes" ? (
                    <SearchAttributeWrapper>
                        <SearchAttributeContainer>
                            {
                                searchData.attributes.map(attribute => (
                                    <SearchAttribute
                                        onClick={(event) => callback(event, attribute.uriQuery)}
                                        key={attribute.uriQuery}
                                        darkmode={darkmode}
                                        active={attribute.uriQuery === activeAttribute}
                                        id={`attribute_${attribute.uriQuery}`}
                                    >
                                        {attribute.desc[lang]}
                                    </SearchAttribute>
                                ))
                            }
                        </SearchAttributeContainer>
                    </SearchAttributeWrapper>
                ) : (
                    <SearchElementIconContainer>
                        {
                            hover && type === "history" ? (
                                <SearchElementIcon
                                    id={`delete_search_${attribute.uriQuery}`}
                                    onClick={(event) => handleClick(event)}
                                    type={"close"}
                                    darkmode={darkmode}
                                    name={"add"}
                                    size={30}
                                    color={darkmode ? color.gray3 : color.gray4}
                                />
                            ) : null
                        }
                        <SearchElementIcon
                            name={"arrow"}
                            size={30}
                            color={hover ? color.blue : darkmode ? color.gray3 : color.gray4}
                        />
                    </SearchElementIconContainer>
                )
            }
        </SearchElementContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage,
    userId: selectUserId
})

const mapDispatchToProps = (dispatch) => ({
    deleteSearch: (userId, searchDetails) => dispatch(deleteSearch(userId, searchDetails))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchElement);