import { sortBy } from "lodash";
import { containsString } from "../general.utils";

export const filterData = (orderData, paramValue, query, type, subType) => {
    if(!orderData.length) return [];

    let filteredData = query ? [] : orderData;
    if(query) orderData.forEach((dataPoint) => {
        let addString = type === "articles" ? subType === "bookmarks" ? dataPoint.newspaper : dataPoint.catchline + dataPoint.newspaper : "";
        let searchString = dataPoint.title + addString;
        if(containsString(searchString, query)) filteredData.push(dataPoint);
    })

    const sortData = (filteredList) => {
        if(paramValue === "date" || paramValue === "length" || paramValue === "publisher") {
            let orderValue = paramValue === "date" ? "timestamp" : paramValue
            return sortBy(filteredList, orderValue).reverse();
        } else {
            return sortBy(filteredList, [(item) => item?.title.toLowerCase()]);
        }
    }

    if(type === "lists") {
        let pinnedList = [];
        let normalList = [];
        filteredData.forEach(list => {
            list.pinned ? pinnedList.push(list) : normalList.push(list)
        })

        let sortedPinnedList = sortData(pinnedList);
        let sortedNormalList = sortData(normalList);

        return [...sortedPinnedList, ...sortedNormalList]
    } else {
        return sortData(filteredData);
    }
}