import React, { Fragment, useEffect, useState, useContext } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
    selectListsDataForPreview,
    selectListsDataFetchKey,
    selectUserDataFetching,
    selectUserId,
    selectUserLanguage,
    selectUserDataError
} from "../../../redux/userData/userData.selectors";
import { LayoutContext } from "../../../App";
import { listsData as listsDataCC, titleData } from "../../../components/lang.data";
import { fetchUserListsStart } from "../../../redux/userData/userData.actions";

import { ListsContainer } from "./lists.styles";
import ListCard from "../../../components/listCard/listCard.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import InteractionBar from "../../../components/interactionBar/interactionBar.component";
import Modal from "../../../components/modal/modal.component";
import ModalList from "../../../components/modalList/modalList.component";
import ModalInput from "../../../components/modalInput/modalInput.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const ListsSubPage = ({ listsData, loading, fetchUserListsStart, error, userId, lang, fetchKey }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [createActive, setCreateActive] = useState(false);
    const [orderedList, setOrderedList] = useState([]);
    const [reload, setReload] = useState(false)

    const handleOnClick = (event) => {
        event.stopPropagation();
        setCreateActive(!createActive)
    }

    useEffect(() => {
        const reloadReset = reload ? setTimeout(() => setReload(false), 500) : null
        if(!loading && fetchKey !== "general") fetchUserListsStart(userId, "", "general");

        return () => clearTimeout(reloadReset)
    }, [fetchUserListsStart, reload, fetchKey])

    return (
        <Fragment>
            <SiteHead title={titleData.library.lists.desc[lang]} />
            {
                (!error) ? (
                    <Fragment>
                        <InteractionBar
                            type={"lists"}
                            orderData={listsData}
                            orderCallback={setOrderedList}
                        />
                        <ListsContainer numColumns={mainWidth > 600 ? 2 : 1}>
                            <div onClick={(event) => handleOnClick(event)}>
                                <ListCard placeholder={"true"} />
                            </div>
                            {
                                createActive ? (
                                    <Modal
                                        type={"list"}
                                        title={listsDataCC.createList[lang]}
                                        desc={listsDataCC.createListDesc[lang]}
                                        onDismiss={() => setCreateActive(!createActive)}
                                    >
                                        <ModalList />
                                        <ModalInput
                                            type={"list"}
                                            onDismiss={() => setCreateActive(!createActive)}
                                        />
                                    </Modal>
                                ) : null
                            }
                            {
                                listsData ? orderedList.map(list => (
                                    <ListCard
                                        key={list.listId}
                                        list={list}
                                    />
                                )) : null
                            }
                        </ListsContainer>
                    </Fragment>
                ) : <ErrorDisplay type={"contentError"} callback={setReload} />
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    listsData: selectListsDataForPreview,
    userId: selectUserId,
    lang: selectUserLanguage,
    fetchKey: selectListsDataFetchKey,
    loading: selectUserDataFetching("listsData"),
    error: selectUserDataError("listsData")
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserListsStart: (userId, listId, fetchKey) => dispatch(fetchUserListsStart(userId, listId, fetchKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListsSubPage);