import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { selectDarkmode } from "../../../../redux/persistData/persistData.selectors";
import { color, radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    min-height: ${props => props.height + "px"};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    border-radius: ${radius.largeRadius};
    overflow: hidden;
`

const EmbedLivecenter = ({ embedUrl, width, height, darkmode }) => {
    const ref = useRef(null);
    const modUrl = ((embedUrlString) => {
        let sportsFeed = false;
        let cleanUrl = embedUrlString.split("?")[0];
        if(cleanUrl.endsWith("default")) cleanUrl = embedUrlString.split("default")[0]
        else if(cleanUrl.endsWith("darkmode")) cleanUrl = embedUrlString.split("darkmode")[0]
        else if(cleanUrl.endsWith("sports")) {
            sportsFeed = true;
            cleanUrl = embedUrlString.split("sports")[0]
        }

        if(cleanUrl !== embedUrlString) return cleanUrl + (darkmode ? "darkmode" : sportsFeed ? "sports" : "default");
        else return cleanUrl;
    })(embedUrl)

    useEffect(() => {
        const adjustHeight = (n) => {
            const c = (t, i) => {
                n[e](h, (n) => {
                    let r, u;
                    if (
                        n &&
                        ((r = n[n.message ? "message" : "data"] + ""),
                        r && r.substr && r.substr(0, 3) === "nc:") &&
                        ((u = r.split(":")), u[1] === i)
                    )
                        switch (u[2]) {
                            case "h":
                                t.style.height = u[3] + "px";
                                return;
                            case "scrolltotop":
                                t.scrollIntoView();
                                return;
                        }
                }, !1);
            }
            for (
                var t,
                    u,
                    f,
                    i,
                    s,
                    e = n.addEventListener ? "addEventListener" : "attachEvent",
                    h = e === "attachEvent" ? "onmessage" : "message",
                    o = n.document.querySelectorAll(".live-center-embed"),
                    r = 0;
                r < o.length;
                r++
            )
                // eslint-disable-next-line
                ((t = o[r]), t.getAttribute("data-rendered")) ||
                ((u = t.getAttribute("data-src")), u &&
                (t.setAttribute("data-rendered", "true"),
                    (f = n.ncVizCounter || 1e3),
                    (n.ncVizCounter = f + 1),
                    (i = f + ""),
                    (s = "nc-frame-c-" + i),
                    (t.innerHTML =
                        '<div style="position: relative; height: ' + height +'px; border: none;" id="' + s + '">'
                        + '<iframe ' +
                            'src="' + u + "?" + i + '" ' +
                            'allowFullScreen ' +
                            'marginHeight="0" marginWidth="0" frameBorder="0" ' +
                            'style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: block" ' +
                            'allowtransparency="true" ' +
                            'referrerPolicy="no-referrer" ' +
                            'sandbox="allow-popups allow-scripts">'
                        + '</iframe>' +
                        '</div>'
                    ), c(t.firstChild, i)
                ))
        }
        if(ref.current) adjustHeight(window);
    }, [ref])

    return (
        <IFrameWrapper width={width} height={height} darkmode={darkmode}>
            <div
                className={"live-center-embed"}
                data-src={modUrl}
                ref={ref}
            />
        </IFrameWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(EmbedLivecenter);