import React, { useRef, useLayoutEffect, useState, useContext } from "react";
import { connect } from "react-redux";

import { LayoutContext } from "../../App";
import { selectArticleReadingProgress, selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { calcNumCatchlines } from "./articleCard.utils";

import {
    ArticleCardWrapper,
    ArticleCardContainer,
    ArticleCardLink,
    ArticleCardContentContainer,
    ArticleCardImageContainer,
    ArticleCardNewspaperContainer,
    ArticleCardTitleContainer,
    ArticleCardCatchlineContainer,
    ArticleCardImage,
    ArticleCardProgressContainer,
    ArticleCardProgressBar
} from "./articleCard.styles";
import ArticleTime from "../articleTime/articleTime.component";
import ArticleAction from "../articleAction/articleAction.component";
import DynamicImage from "../dynamicImage/dynamicImage.component";

const ArticleCard = ({ articlePreview, darkmode, articleProgress }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [numCatchlines, setNumCatchlines] = useState(0);
    const ref = useRef(null)

    const { articleId, newspaper, title, catchline, image, category, publishedAt, updatedAt, similarArticles, type } = articlePreview;
    const { imageUrl, copyright, imageId } = image || {};

    useLayoutEffect(() => {
        if(ref.current) {
            setNumCatchlines(calcNumCatchlines(ref.current.offsetHeight, mainWidth))
        }
    }, [ref.current, mainWidth])

    return (
        <ArticleCardWrapper
            darkmode={darkmode}
            tabIndex={"0"}
        >
            <ArticleCardContainer>
                <ArticleCardContentContainer progress={articleProgress}>
                    <ArticleCardNewspaperContainer
                        categoryId={category}
                        darkmode={darkmode}
                    >
                        <span>{newspaper.toUpperCase()}</span>
                    </ArticleCardNewspaperContainer>
                    <ArticleCardTitleContainer darkmode={darkmode}>
                        <span ref={ref}>{title}</span>
                    </ArticleCardTitleContainer>
                    {
                        catchline && numCatchlines ? (
                            <ArticleCardCatchlineContainer
                                numCatchlines={numCatchlines}
                                darkmode={darkmode}
                            >
                                <span>{catchline}</span>
                            </ArticleCardCatchlineContainer>
                        ) : null
                    }
                    <ArticleTime
                        publishedAt={publishedAt}
                        updatedAt={updatedAt}
                        live={type === "ticker"}
                        contrast
                    />
                </ArticleCardContentContainer>
                <ArticleCardImageContainer
                    title={copyright ? `© ${copyright}` : null}
                    aria-label={"Article Image"}
                >
                    <ArticleCardImage progress={articleProgress}>
                        <DynamicImage
                            imageId={imageId}
                            fallbackUrl={imageUrl}
                        />
                    </ArticleCardImage>
                    <ArticleAction articleId={articleId} type={"bookmark"} />
                    <ArticleAction articleId={articleId} type={"list"} />
                </ArticleCardImageContainer>
                {
                    articleProgress ? (
                        <ArticleCardProgressContainer darkmode={darkmode} progress={articleProgress}>
                            <ArticleCardProgressBar progress={articleProgress} />
                        </ArticleCardProgressContainer>
                    ) : null
                }
            </ArticleCardContainer>
            <ArticleCardLink to={`/${articleId}`} />
        </ArticleCardWrapper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    darkmode: selectDarkmode(state),
    articleProgress: selectArticleReadingProgress(ownProps.articlePreview.articleId)(state)
})

export default connect(mapStateToProps)(ArticleCard);