import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Logo from "../../design/logos";
import Icon from "../../design/icons/icon.component";
import loginEmphasis from "../../design/assets/login-emphasis.svg";

export const LoginWallWrapper = styled.div`
    width: content-box;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    position: absolute;
    padding: 0 5px 10px 5px;
    margin-left: -5px;
    bottom: 0;
    z-index: 2;
`

export const LoginWallLogo = styled(Logo)`
    height: 40px;
    width: auto;
    margin-bottom: 20px;
`

export const LoginWallTitle = styled.h1`
    font-family: ${font.pulpDisplayBold};
    font-size: 24px;
    text-align: center;
    color: ${props => textColor(props.darkmode)};
    margin-bottom: 20px;
  
    & > span:last-child {
        width: auto;
        position: relative;
        
        &::after {
            content: "";
            width: 85px;
            height: 20px;
            background: center no-repeat url(${loginEmphasis});
            background-size: auto;
            position: absolute;
            left: 0;
            top: 24px;
        }
    }
`

export const LoginWallSubtitle = styled.h2`
    font-family: ${font.pulpDisplayMedium};
    font-size: 20px;
    color: ${props => props.darkmode ? "rgb(150, 150, 150)" : "rgb(140, 140, 140)"};
    text-align: center;
    line-height: 25px;
    letter-spacing: 0.01em;
    width: 85%;
`

export const LoginWallBrandsContainer = styled.div`
    margin: 25px 0 30px 0;
    width: max-content;
    height: auto;
    display: flex;
    align-items: center;
  
    & > div:first-child {
        margin-left: 0;
    }
    & > a:last-child {
        margin-left: 10px;
    }
`

export const LoginWallBrandBubble = styled.div`
    height: 40px;
    width: 40px;
    box-shadow: 0 1px 9px 0 ${props => props.darkmode ? color.gray1 : color.gray4};
    border-radius: 40px;
    margin-left: -8px;
`

export const LoginWallInfoCard = styled.div`
    width: 100%;
    height: auto;
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    border-radius: ${radius.smallRadius};
    padding: 15px 30px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    & > div:first-child {
       margin-bottom: 12px;
    }
`

export const LoginWallInfoCardLine = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    & > span {
        color: ${props => textColor(props.darkmode)}
    }
    & > span:nth-child(2) {
        color: ${color.blue}
    }
`

export const LoginWallInfoCardIcon = styled(Icon)`
    margin: ${props => props.lowered ? "0 0 -2px 2px" : "0 12px 0 0"};
`

export const LoginWallInfoDesc = styled.div`
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    font-size: 16px;
    letter-spacing: 0.03em;
`

export const LoginWallInfoDescLink = styled.a`
    font-family: ${font.pulpDisplayBold};
    font-size: 16px;
    color: ${color.blue};
    letter-spacing: 0.02em;
`