import styled, { css } from "styled-components";
import { color, radius, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

const sharedAreaStyles = css`
    min-height: 30px;
    max-width: 100%;
    overflow: hidden;
    font-size: 20px;
    line-height: 25px;
    max-height: 162px;
    overflow-y: scroll;
    font-family: ${font.pulpDisplayMedium};
    color: ${props => textColor(props.darkmode)};
    background-color: transparent;
    display: flex;
    border: none;
    outline: none;
    padding-left: 5px;
    padding-bottom: -25px;
    margin: 10px 0 40px 0;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    grid-area: 1 / 1 / 2 / 2;
`

export const InputFieldContainer = styled.div`
    max-width: 100%;
    flex-grow: 100;
    height: ${props => props.area ? "max-content" : "50px"};
    display: ${props => !props.hidden ? props.area ? "grid" : "inherit" : "none"};
    position: relative;
    user-select: none;
    margin-bottom: 15px;
    cursor: text;
    border-radius: ${radius.smallRadius};
    padding: 0 ${props => (
          (props.checkType && (props.value.length >= 1) && props.error) ||
          (props.checkType && (props.value.length >= 1) && props.verified) ||
          (props.checkType && props.error)) ? "60px" : "15px"} 0 15px;
    text-indent: 5px;
    box-shadow: 0 0 0 2px ${props => props.error ? `${color.red} !important` : "none"};
    background-color: ${props => props.darkmode ? props.modal ? color.gray1 : color.gray2 : color.gray5};
    z-index: 3;
    
    &::after {
        content: ${props => props.area ? 'attr(data-replicated-value) " "' : "none"};
        visibility: hidden;
        ${sharedAreaStyles};
    }
    &:focus-within {
        outline: none;
        box-shadow: 0 0 0 2px ${props => props.error ? color.red : color.blue} !important;
    }
    &:hover {
        box-shadow: inset 0 0 0 2.5px ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
    }
`;

export const InputFieldInput = styled.input`
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 100%;
    outline: none;
    user-select: text;
    font-family: ${font.pulpDisplayMedium};
    color: ${props => textColor(props.darkmode)};
    letter-spacing: ${props => ((props.type === "password") && (props.value.length >= 1)) ? "0.30em" : 0};
    background-color: transparent;
    -webkit-text-stroke-width: ${props => ((props.type === "password") && (props.value.length >= 1)) ? "0.2em" : 0};
    display: flex;
    flex-direction: column;
    border: none;
    text-indent: 5px;
    z-index: 1;
    
    &[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
`

export const InputFieldArea = styled.textarea`
    resize: none;
    ${sharedAreaStyles};
`

export const InputFieldIconContainer = styled(Icon)`
    height: 50px;
    justify-content: center;
    margin: 0 15px 0 10px;
    right: 0;
    display: inline-block;
    position: absolute;
    user-select: none;
`;

export const InputAreaInfoContainer = styled.div`
    height: 20px;
    width: auto;
    margin: 10px 15px 10px 0;
    display: flex;
    align-items: center;
    position: absolute;
    user-select: none;
    bottom: 0;
    right: 0;
`

export const InputAreaNumericProgress = styled.div`
    height: inherit;
    line-height: inherit;
    font-size: 16px;
    font-family: ${font.pulpDisplayMedium};
    color: ${color.gray3};
    
    &>span:first-child {
        color: ${props => props.length > props.max ? color.red : "inherit"}
    }
`

export const InputAreaVisualProgress = styled.div`
    height: inherit;
    width: 20px;
    margin-right: 10px;
`