import styled from "styled-components";
import { Link } from "react-router-dom";
import { color, font, radius } from "../../design/theme";
import { categoryColor, categoryContentColor, textColor } from "../general.utils";

export const ImageCardContainer = styled.article`
    width: 100%;
    height: 300px;
    display: inline-flex;
    border-radius: ${radius.largeRadius};
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: ${props => textColor(props.darkmode)};
    transform: translate3d(0,0,0); // forces tap into webGPU
    content-visibility: auto;
    // will-change: scroll-position;
    
    &:hover {
        box-shadow: 0 0 0 2px ${color.blue};
        outline: none;
    }
    
    &:active {
        transform: scale(0.98);
        transition: 0.1s;
        outline: none;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }

    @media only screen and (max-width: 750px) {
        height: 270px;
    }
`;

export const ImageCardLink = styled(Link)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

export const ImageCardPictureContainer = styled.div`
    width: inherit;
    height: inherit;
    z-index: 0;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 10%, ${props => props.darkmode ? "rgba(0,0,0,0.7)" : "rgba(256,256,256,0.75)"} ${props => props.darkmode ? "60%" : "80%"});
    }
`

export const ImageCardContentContainer = styled.div`
    width: 100%;
    height: auto;
    bottom: 0;
    padding: 25px;
    justify-content: left;
    text-align: left;
    position: absolute;
    user-select: none;
`

export const ImageCardNewspaperContainer = styled.h2`
    font-size: 13px;
    font-family: ${font.pulpDisplayMedium};
    letter-spacing: 0.02em;
    vertical-align: bottom;
    justify-content: center;
    flex-direction: column;
    color: ${color.gray4};    
    text-overflow: ellipsis;
    word-break: break-word;
    display: inline-block;
    color: ${props => categoryContentColor(props.categoryId, props.darkmode)};
    background-color: ${props => categoryColor(props.categoryId, props.darkmode)};
    border-radius: 5px;
    padding: 2px 7px;
`

export const ImageCardTitleContainer = styled.h1`
    font-size: 20px;
    font-family: ${font.interBold};
    margin: 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media only screen and (max-width: 750px) {
        font-size: 18px;
    }
`