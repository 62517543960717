import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { errorArtOrigin } from "./errorDisplay.utils";
import { errorData } from "../lang.data";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { color } from "../../design/theme";

import {
    ErrorDisplayContainer,
    ErrorDisplayImage,
    ErrorDisplayText
} from "./errorDisplay.styles";
import CustomButton from "../customButton/customButton.component";

const ErrorDisplay = ({ type, lang, darkmode, link, icon, callback, fullscreen, modal }) => {
    const history = useHistory();
    const handleButtonClick = () => {
        if(!callback) {
            if(link === 0) history.go(0);
            else history.push(`/${link}`)
        } else {
            callback(true)
        }
    }

    return (
        <ErrorDisplayContainer fullscreen={fullscreen}>
            <ErrorDisplayImage
                src={errorArtOrigin(type)}
                alt={`${type} Error Image`}
                modal={modal}
            />
            <ErrorDisplayText darkmode={darkmode} modal={modal}>
                <span>{errorData[type].info[lang]}</span>
            </ErrorDisplayText>
            <CustomButton
                callback={() => handleButtonClick()}
                desc={errorData[type].action[lang]}
                icon={icon ? icon : "reload"}
                color={color.blue}
                width={250}
                solid
            />
        </ErrorDisplayContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ErrorDisplay);