import styled from "styled-components";
import { color, font } from "../../../design/theme";

export const ArticleListWrapper = styled.div`
    width: inherit;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
  
    &>div {
        margin-bottom: 15px;
    }
    &>div:last-child {
        margin-bottom: 0;
    }
`

export const ArticleListContainer = styled.div`
    width: 75%;
    height: auto;
    position: relative;
    text-align: left;
  
    ${props => props.type === "unordered" ? `&::before {
        content: "";
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: ${color.blue};
        position: absolute;
        left: 0;
        margin: 8px 0 0 -40px;
    }` : `&::before {
        content: "${props.index}.";
        font-family: ${font.interBlackItalic};
        font-style: italic;
        font-size: 20px;
        color: ${color.blue};
        position: absolute;
        left: 0;
        margin: 5px 0 0 -40px;
    }`}
`;