import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import { selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDeletedImage, selectUploadedImage, selectUserEventsError } from "../../redux/userEvents/userEvents.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { deleteImageStart, uploadImageStart } from "../../redux/userEvents/userEvents.actions";
import { miscData } from "../lang.data";
import { color } from "../../design/theme";
import { codes } from "../codes.data";

import {
    ModalListImageSetter,
    ModalListImageContainer,
    ModalListImagePreview,
    ModalListButtonContainer,
    ModalListActionContainer,
    ModalListDropzoneWrapper,
    ModalListDropzoneContainer,
    ModalListDropzoneInput,
    ModalListDropzoneContent,
    ModalListDropzoneIcon,
    ModalListDropzoneDesc
} from "./modalList.styles";
import Notice from "../notice/notice.component";
import Icon from "../../design/icons/icon.component";
import CustomButton from "../customButton/customButton.component";
import StaticImage from "../staticImage/staticImage.component";

const ModalList = ({ userId, lang, uploadedImage, deletedImage, imageId, uploadImageStart,
                       deleteImageStart, error, darkmode }) => {

    const [fileDrag, setFileDrag] = useState(false);
    const [dropzoneActive, setDropzoneActive] = useState(false);
    const ref = useRef(null);

    const fileDropHandler = (event) => {
        event.preventDefault();
        if(event.dataTransfer.files[0]) uploadImageStart(userId, event.dataTransfer.files[0], "list");
        fileDragAction(false);
        setDropzoneActive(false);
    }

    const fileDragAction = (value, event) => {
        if(!(!value && ref?.current?.contains(event?.target))) setFileDrag(value)
    };

    useEffect(() => {
        document.addEventListener("dragenter", () => fileDragAction(true));
        document.addEventListener("dragover", () => fileDragAction(true));
        document.addEventListener("dragleave", (event) => fileDragAction(false, event));
        document.addEventListener("dragend", (event) => fileDragAction(false, event));

        return () => {
            document.removeEventListener("dragenter", fileDragAction)
            document.removeEventListener("dragover", fileDragAction)
            document.removeEventListener("dragleave", fileDragAction)
            document.removeEventListener("dragend", fileDragAction)
        }
    }, [ref])

    return (
        <Fragment>
            {
                (fileDrag || dropzoneActive) ? (
                    <ModalListDropzoneWrapper dropzoneactive={dropzoneActive} darkmode={darkmode} ref={ref}>
                        <ModalListDropzoneInput
                            onDragOver={() => setDropzoneActive(true)}
                            onDragEnter={() => setDropzoneActive(true)}
                            onDragLeave={() => setDropzoneActive(false)}
                            onDragEnd={() => setDropzoneActive(false)}
                            onDrop={(event) => fileDropHandler(event)}
                            type={"file"}
                            title={""}
                            accept={"image/png, image/jpeg, image/jpg, image/webp"}
                            multiple={false}
                        />
                        <ModalListDropzoneContainer>
                            <ModalListDropzoneContent>
                                <ModalListDropzoneIcon
                                    name={"cloud_upload"}
                                    size={70}
                                    color={color.blue}
                                />
                                <ModalListDropzoneDesc>
                                    <span>{miscData.dragndropImage[lang]}</span>
                                </ModalListDropzoneDesc>
                            </ModalListDropzoneContent>
                        </ModalListDropzoneContainer>
                    </ModalListDropzoneWrapper>
                ) :  null
            }
            <ModalListImageSetter>
                <ModalListImageContainer>
                    <ModalListImagePreview
                        id={"canvas-list-preview"}
                        visible={(uploadedImage && !deletedImage)}
                    />
                    {
                        !uploadedImage ? (
                            <StaticImage
                                imageId={imageId}
                                deleted={deletedImage}
                                // placeholder={deletedImage ? 1 : 0}
                                largeDefault={1}
                            />
                        ) : null
                    }
                </ModalListImageContainer>
                <ModalListActionContainer>
                    <Icon
                        name={"cloud_upload"}
                        size={40}
                        color={color.blue}
                    />
                    <ModalListButtonContainer>
                        <CustomButton
                            desc={deletedImage || (!imageId && !uploadedImage) ? miscData.uploadImage[lang] : miscData.changeImage[lang]}
                            callback={(fileRef) => uploadImageStart(userId, fileRef, "list")}
                            color={color.gray4}
                            contentColor={color.blue}
                            upload
                            solid
                            size={"small"}
                        />
                        <CustomButton
                            desc={miscData.deleteImage[lang]}
                            callback={() => deleteImageStart(userId, null)}
                            contentColor={color.blue}
                            color={color.gray4}
                            disabled={deletedImage || (!imageId && !uploadedImage)}
                            solid
                            size={"small"}
                        />
                    </ModalListButtonContainer>
                </ModalListActionContainer>
            </ModalListImageSetter>
            <Notice
                type={"info"}
                desc={"min. 256x256, PNG/ JPEG/ WEBP"}
                size={"small"}
            />
            {
                error ? (
                    <Notice
                        type={"error"}
                        desc={codes[error][lang]}
                        size={"small"}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    error: selectUserEventsError("userUploadedImage")(state),
    uploadedImage: selectUploadedImage(state),
    deletedImage: selectDeletedImage(state),
    darkmode: selectDarkmode(state)
})

const mapDispatchToProps = (dispatch) => ({
    uploadImageStart: (userId, fileRef, refKey) => dispatch(uploadImageStart(userId, fileRef, refKey)),
    deleteImageStart: (userId, refType) => dispatch(deleteImageStart(userId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalList);