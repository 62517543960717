import UserEventsActionTypes from "./userEvents.types";

const INITIAL_STATE = {
    userUploadedImage: {
        webp: null,
        fallback: null
    },
    userDeletedImage: false,
    userRecentAccess: [],
    userSearchResults: {},
    userUrlSearchResults: {},
    userEventsLoading: {},
    userEventsError: {}
}

const searchDataSetter = (resultObj, refKey, stateObj) => {
    const stateData = stateObj[refKey];
    const { resultKey, query } = resultObj || {};

    if(refKey === "userSearchResults") {
        if(stateData[resultKey]) {
            resultObj.results.forEach(result => {
                if(!stateData[resultKey].results.includes(result)) {
                    stateData[resultKey].results.push(result)
                }
            })
            stateData[resultKey].currentPage = resultObj.currentPage
            return Object.assign({}, stateData)
        } else {
            return Object.assign({}, stateData, { [resultKey]: resultObj })
        }
    } else if(refKey === "userUrlSearchResults") {
        return Object.assign({}, stateData, { [query]: resultObj.articleId })
    }
}

const userEventsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserEventsActionTypes.UPLOAD_IMAGE_START:
        case UserEventsActionTypes.SET_LIST_START:
        case UserEventsActionTypes.DELETE_LIST_START:
        case UserEventsActionTypes.LOG_RECENT_ACCESS_START:
        case UserEventsActionTypes.PERFORM_SEARCH_START:
        case UserEventsActionTypes.PERFORM_URL_SEARCH_START:
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: true })
            }
        case UserEventsActionTypes.DELETE_IMAGE_SUCCESS:
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: false }),
                userDeletedImage: !action.refType,
                userUploadedImage: {
                    webp: action.payload,
                    fallback: action.payload
                },
                userEventsError: Object.assign(state.userEventsError, { "userUploadedImage": null })
            }
        case UserEventsActionTypes.UPLOAD_IMAGE_SUCCESS:
        case UserEventsActionTypes.SET_LIST_SUCCESS:
        case UserEventsActionTypes.DELETE_LIST_SUCCESS:
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: false }),
                userDeletedImage: false,
                userUploadedImage: {
                    webp: action.payload,
                    fallback: action.fallback || null
                },
                userEventsError: Object.assign(state.userEventsError, { "userUploadedImage": null })
            }
        case UserEventsActionTypes.LOG_RECENT_ACCESS_SUCCESS:
            state.userRecentAccess.unshift(action.payload)
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: false }),
                userRecentAccess: state.userRecentAccess,
                userEventsError: Object.assign(state.userEventsError, { [action.refKey]: null })
            }
        case UserEventsActionTypes.PERFORM_SEARCH_SUCCESS:
        case UserEventsActionTypes.PERFORM_URL_SEARCH_SUCCESS:
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: false }),
                [action.refKey]: searchDataSetter(action.payload, action.refKey, state)
            }
        case UserEventsActionTypes.UPLOAD_IMAGE_FAILURE:
        case UserEventsActionTypes.DELETE_IMAGE_FAILURE:
        case UserEventsActionTypes.SET_LIST_FAILURE:
        case UserEventsActionTypes.DELETE_LIST_FAILURE:
        case UserEventsActionTypes.LOG_RECENT_ACCESS_FAILURE:
        case UserEventsActionTypes.PERFORM_SEARCH_FAILURE:
        case UserEventsActionTypes.PERFORM_URL_SEARCH_FAILURE:
            return {
                ...state,
                userEventsLoading: Object.assign(state.userEventsLoading, { [action.refKey]: false }),
                userEventsError: Object.assign(state.userEventsError, { [action.refKey]: action.payload })
            }
        default:
            return state;
    }
}

export default userEventsReducer;