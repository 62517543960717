import styled from "styled-components";

export const LibraryPageContainer = styled.div`
    width: inherit;
    height: auto;
    padding-bottom: 80px;
`

export const BookmarksListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 20px;
`;