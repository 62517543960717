export const codes = {
    "404": {
        de: "Es ist ein Fehler aufgetreten",
        fr: "Une erreur s'est produite",
        en: "An Error occurred"
    },
    "405": {
        de: "Account existiert bereits",
        fr: "Compte existe déjà",
        en: "Acount already exists"
    },
    "406" :{
        de: "Inkorrekte Angaben",
        fr: "Mauvaises Données",
        en: "Incorrect Credentials"
    },
    "407": {
        de: "Nutzer existiert nicht",
        fr: "Utilisateur n'éxiste pas",
        en: "User doesn't exist"
    },
    "408": {
        de: "Nicht unterstütztes Dateiformat",
        fr: "Format de fichier non supporté",
        en: "Unsupported File Format"
    },
    "409": {
        de: "Das gewählte Bild ist zu klein",
        fr: "L'image sélectionnée est trop petite",
        en: "The selected image is too small"
    },
    "410": {
        de: "Das gewählte Bild ist über 5mb.",
        fr: "L'image sélectionnée est plus de 5mb.",
        en: "The selected image is over 5mb."
    }
}