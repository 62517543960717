import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { version } from "./../../package.json";

const config = {
    apiKey: "AIzaSyAuDaMMzrfqNRJpXAz8IhXD6l8x1FC8d8I",
    authDomain: "auth.reavide.com",
    databaseURL: "https://reavidenews.firebaseio.com",
    projectId: "reavidenews",
    storageBucket: "reavidenews.appspot.com",
    messagingSenderId: "590917288166",
    appId: "1:590917288166:web:99b7cf341d378e28d63b27",
    app_version: version,
    measurementId: "G-93026ZRP9T"
}

const otherConfig = {
    apiKey: "AIzaSyBrIkhT8D5fXDcv1SiRZSt0a-2T58O5WMU",
    authDomain: "reavide.firebaseapp.com",
    projectId: "reavide",
    storageBucket: "reavide",
    messagingSenderId: "319416485901",
    appId: "1:319416485901:web:3dda918ad498c71e60ed44",
    measurementId: "G-KFZ06KVJ46"
}

const reavidenews = initializeApp(config);
const reavide = initializeApp(otherConfig, "reavide");

export const auth = getAuth(reavidenews);
export const firestore = getFirestore(reavidenews);
export const database = getDatabase(reavidenews);
export const breaking = getDatabase(reavidenews, "https://breaking-ch.europe-west1.firebasedatabase.app/");
export const storage = getStorage(reavide);
export const feedback = getFirestore(reavide);

export const analytics = getAnalytics(reavidenews);
export const performance = getPerformance(reavidenews);

/*export const appCheck = initializeAppCheck(reavidenews, {
    provider: new ReCaptchaEnterpriseProvider("6LeV1_YdAAAAANtACuaWNvwrmi2jrFggPszuY8fy"),
    isTokenAutoRefreshEnabled: true
})
export const appCheck2 = initializeAppCheck(reavide, {
    provider: new ReCaptchaEnterpriseProvider("6LcNyvYdAAAAAIewT9Cov2EsSFWmsZB-9UId5EQU"),
    isTokenAutoRefreshEnabled: true
})*/

export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");

export const appleProvider = new OAuthProvider("apple.com");
appleProvider.setCustomParameters({
    locale: "en_US"
});

export const actionCodeSettings = (email) => ({
    url: "http://localhost:3000/?email=" + email,
    handleCodeInApp: true,
    iOS: {
        bundleId: "com.reavide.newsapp"
    },
    android: {
        packageName: "com.example.android",
        installApp: true,
        minimumVersion: "12"
    },
    dynamicLinkDomain: "auth.reavide.com"
});