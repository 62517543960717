import styled from "styled-components";
import { font, color } from "../../../design/theme";
import { textColor } from "../../general.utils";
import Icon from "../../../design/icons/icon.component";

export const ArticleTickerWrapper = styled.div`
    height: auto;
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin: ${props => props.tickerNum && props.tickerNum === 1 ? "40px" : "60px"} 0 -10px;

    @media only screen and (max-width: 750px) {
        margin: 40px 0 -10px;
    }
`

export const ArticleTickerIcon = styled(Icon)`
    position: relative;
    margin-right: 15px;
    display: flex;

    @media only screen and (max-width: 750px) {
        font-size: 18px;
        margin-right: 10px;
    }
`

export const ArticleTickerContainer = styled.div`
    font-size: 20px;
    margin-right: 30px;
    display: flex;
    font-family: ${font.interBold};
    color: ${props => textColor(props.darkmode)};

    @media only screen and (max-width: 750px) {
        font-size: 18px;
        margin-right: 10px;
    }
`

export const ArticleTickerSeparator = styled.div`
    flex-grow: 100;
    display: flex;
    height: 3px;
    border-radius: 5px;
    background-color: ${color.blue};
`