import { takeLatest, call, put, all, select} from "redux-saga/effects";
import { reverse, pick, isEqual, uniqWith } from "lodash";
import { doc, getDoc } from "firebase/firestore";

import ArticleDataActionTypes from "./articleData.types";

import { store } from "../store";
import { firestore } from "../../config/firebase.config";
import { newsAPI } from "../backendAPI";
import {
    fetchArticleDataSuccess, fetchArticleDataFailure,
    fetchArticlePreviewSuccess, fetchArticlePreviewFailure
} from "./articleData.actions";
import { selectArticleRefArray } from "../articleRef/articleRef.selectors";
import { selectArticleList } from "./articleData.selectors";
import { selectBookmarkDataForPreview, selectUserLanguage, selectHistoryData, selectCollectionsDataRefArray } from "../userData/userData.selectors";


export function* fetchArticleDataStartAsync(action) {
    try {
        const collectionRef = action.payload;
        const payloadRef = action.payloadRef;
        const lang = yield select(selectUserLanguage);

        const articleRefArray = yield (collectionRef === "bookmarks") ? select(selectBookmarkDataForPreview)
                                    : (collectionRef === "history") ? reverse(select(selectHistoryData))
                                    : (collectionRef === "collections") ? select(selectCollectionsDataRefArray(payloadRef))
                                    : (payloadRef && !collectionRef) ? (Array.isArray(payloadRef) ? payloadRef : [{ articleId: payloadRef }])
                                    : select(selectArticleRefArray(collectionRef));
        const articleListObject = yield select(selectArticleList);

        if (articleRefArray ? articleRefArray.length : false) {
            const cleanRefArray = articleRefArray.map(articleRef => pick(articleRef, "articleId"));
            const articlesToFetch = yield uniqWith(cleanRefArray, isEqual).filter((ref) => {
                if (!articleListObject[ref.articleId] || articleListObject[ref.articleId].contentLang !== lang) {
                    return typeof ref
                } else {
                    return null
                }
            }).slice(0, 10).map(refElement => refElement.articleId)
            console.log("ARTICLE FETCH ARRAY", articlesToFetch)

            // const collectionRef = yield firestore.collection("articles");
            // const collectionRef = yield firestore.collection("articles-new")
            // const getLangRef = yield (articleId, lang) => collectionRef.doc(articleId).collection("language").doc(lang).get()
            const getLangRef = yield async (articleId, lang) => {
                let langRef = doc(firestore, "articles-new", articleId, "language", lang)
                return await getDoc(langRef);
            }
            const getArticleRef = yield async (articleId) => {
                let articleRef = doc(firestore, "articles-new", articleId)
                return await getDoc(articleRef);
            }

            const getItems = yield (articleIds) => {
                let docRefs = articleIds.map(id => {
                    return getArticleRef(id)
                });

                Promise.all(docRefs).then(docs => {
                    let docRefsArr = docs.map(doc => doc.data());
                    let resultArr = [];
                    let docRefsObj = {};
                    let deleteCount = 0;
                    console.log("DOC REFS ARR", docRefsArr)
                    /*docRefsArr.forEach((docRef, index) => {
                        docRefsArr.splice(index, 1);
                        Object.assign(docRefsObj, { [docRef.articleId]: docRef })
                    })*/
                    for(let i = 0; i < docRefsArr.length; i++) {
                        if(!docRefsArr[i]) {
                            /*docRefsArr.splice(i, 1);
                            i--;*/
                            Object.assign(docRefsObj, {
                                [articlesToFetch[i]]: {
                                    articleId: articlesToFetch[i],
                                    metadata: { type: "undefined", originalLang: lang }
                                }
                            })
                        } else Object.assign(docRefsObj, { [docRefsArr[i].articleId]: docRefsArr[i] })
                    }
                    console.log("DOC REFS OBJ", docRefsObj)
                    const responseHandler = (docRef, result, lang) => {
                        let localId = docRef.articleId;
                        // if(!Object.keys(result).length) {
                             // remove(docRefsArr, { articleId: localId }) // deleteCount++
                        // } else
                        resultArr.push(Object.assign(docRefsObj[localId], result, { contentLang: lang }))

                        console.log("RESPONSE HANDLER", docRefsArr, resultArr)
                        if((docRefsArr.length - deleteCount) === resultArr.length) {
                            store.dispatch(fetchArticleDataSuccess(resultArr))
                        }
                    }

                    if(!Object.keys(docRefsObj).length) store.dispatch(fetchArticleDataSuccess(null))
                    else docRefsArr.forEach((docRef, index) => {
                        if(!docRef || docRef.metadata.type === "deleted") {
                            let docRefResponse = docRef || Object.values(docRefsObj)[index]
                            responseHandler(docRefResponse, {}, docRefResponse.metadata.originalLang)
                        } else {
                            let langRefMain = getLangRef(docRef.articleId, lang);
                            let { originalLang } = docRef.metadata || {};
                            langRefMain.then(snapshot => {
                                if (snapshot.exists()) responseHandler(docRef, snapshot.data(), lang)
                                else if (lang !== "en") {
                                    let langRefSecondary = getLangRef(docRef.articleId, "en");
                                    langRefSecondary.then(snapshot => {
                                        if (snapshot.exists()) responseHandler(docRef, snapshot.data(), "en")
                                        else if (lang !== originalLang) {
                                            let langRefTertiary = getLangRef(docRef.articleId, originalLang);
                                            langRefTertiary.then(snapshot => {
                                                if (snapshot.exists()) responseHandler(docRef, snapshot.data(), originalLang)
                                                else responseHandler(docRef, {}, lang)
                                            })
                                        } else responseHandler(docRef, {}, lang)
                                    })
                                } else {
                                    let langRefTertiary = getLangRef(docRef.articleId, originalLang)
                                    langRefTertiary.then(snapshot => {
                                        if (snapshot.exists()) responseHandler(docRef, snapshot.data(), originalLang)
                                        else responseHandler(docRef, {}, lang)
                                    })
                                }
                            })
                        }
                    })
                })
            }
            if(articlesToFetch.length) yield getItems(articlesToFetch)
            else yield put(fetchArticleDataSuccess(null))
            /*const getItems = yield (articleIds) => {
                console.log("ARTICLE DATA SAGA PASSED ARTICLE IDs", articleIds)
                let docRefs = articleIds.map(id => {
                    return collectionRef.doc(id).get();
                });
                console.log("ARTICLE DATA SAGA DOC REFS", docRefs)
                Promise.all(docRefs)
                    .then(docs => {
                        let data = docs.map(doc => doc.data());
                        console.log("ARTICLE DATA SAGA RETURNED DATA", data)
                        store.dispatch(fetchArticleDataSuccess(data))
                    })
            }
            yield getItems(articlesToFetch)*/
        } else {
            yield put(fetchArticleDataSuccess(null))
        }
        /*console.log("ITEMS", getItems)
        const snapshot2 = yield collectionRef.get();
        const articleListMap2 = yield call(
            convertArticleListRAWtoMap,
            snapshot2
        )
        yield console.log("articleListMap ??? ::", articleListMap2);
        yield console.log("articleListObject:", articleListObject);
        yield console.log("articlesToFetch:", articlesToFetch);
        yield put(fetchArticleDataSuccess(articleListMap2))*/

        /*const articlesToFetch = [
            "4SlxZzdO6",
            "t n q a k4Lz2"
        ]
        const collectionRef = yield firestore.collection("articles-new")
        const getLangRef = yield (articleId, lang) => collectionRef.doc(articleId).collection("language").doc(lang).get()

        const getItems = yield (articleIds) => {
            let docRefs = articleIds.map(id => {
                return collectionRef.doc(id).get()
            });

            Promise.all(docRefs).then(docs => {
                let docRefsArr = docs.map(doc => doc.data());
                let resultArr = [];
                let docRefsObj = {};
                docRefsArr.forEach(docRef => {
                    Object.assign(docRefsObj, { [docRef.articleId]: docRef })
                })
                const responseHandler = (docRef, result) => {
                    let localId = docRef.articleId;
                    resultArr.push(Object.assign(docRefsObj[localId], result))
                    if(docRefsArr.length  === resultArr.length) {
                        store.dispatch(fetchArticleDataSuccess(resultArr))
                    }
                }

                docRefsArr.forEach(docRef => {
                    if(docRef.metadata.type === "deleted") {
                        responseHandler(docRef, {})
                    } else {
                        let langRefMain = getLangRef(docRef.articleId, lang);
                        let { originalLang } = docRef.metadata || {};
                        langRefMain.then(snapshot => {
                            if (snapshot.exists) {
                                responseHandler(docRef, snapshot.data())
                            } else if (lang !== "en") {
                                let langRefSecondary = getLangRef(docRef.articleId, "en")
                                langRefSecondary.then(snapshot => {
                                    if (snapshot.exists) responseHandler(docRef, snapshot.data())
                                })
                            } else if (lang !== originalLang) {
                                let langRefTertiary = getLangRef(docRef.articleId, originalLang)
                                langRefTertiary.then(snapshot => {
                                    if (snapshot.exists) responseHandler(docRef, snapshot.data())
                                })
                            } else {
                                responseHandler(docRef, {})
                            }
                        })
                    }
                });
            })
        }
        yield getItems(articlesToFetch)*/
    } catch (error) {
        yield put(fetchArticleDataFailure(error.message))
    }
}

export function* fetchArticleDataStart() {
    yield takeLatest(
        ArticleDataActionTypes.FETCH_ARTICLE_DATA_START,
        fetchArticleDataStartAsync
    )
}


export function* fetchArticlePreviewStartAsync(action) {
    try {
        const articleId = action.payload;
        const lang = action.lang;
        const response = yield newsAPI.get(`/preview/${articleId}`, {
            headers: {
                lang: lang
            }
        }).catch((err) => console.log("BACKEND FETCH FAILED", err))
        yield put(fetchArticlePreviewSuccess(response.data.content))
    } catch (error) {
        yield put(fetchArticlePreviewFailure(error.message))
    }
}

export function* fetchArticlePreviewStart() {
    yield takeLatest(
        ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_START,
        fetchArticlePreviewStartAsync
    )
}

export function* articleDataSagas() {
    yield all([
        call(fetchArticleDataStart),
        call(fetchArticlePreviewStart)
    ])
}