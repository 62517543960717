import PersistDataActionTypes from "./persistData.types";
import { defaultTheme } from "../../components/general.utils";

const INITIAL_STATE = {
    darkmode: null,
    articleProgress: {},
    theme: "auto"
}

const darkmodeSetter = (mode) => {
    if (mode === "dark") {
        return 1
    } else if (mode === "light") {
        return 0
    } else {
        return defaultTheme();
    }
}

const articleProgressSetter = ({ articleProgress, articleId }, stateObj) => {
    console.log("ARTICLE PROGRESS REDUCER", articleProgress, articleId)
    // if(!stateObj.articleProgress) return Object.assign(stateObj, { articleProgress : {} })
    return Object.assign(stateObj.articleProgress, { [articleId] : articleProgress })
    /*return INITIAL_STATE.articleProgress[articleId] ?
        articleProgressUpdater(articleProgress, articleId)
        : Object.assign(INITIAL_STATE.articleProgress, { [articleId] : articleProgress })*/
}

/*const articleProgressUpdater = (articleProgress, articleId) => {
    console.log("ARTICLE PROGRESS UPDATE FIRE TRIGGER")
    return Object.assign(INITIAL_STATE.articleProgress, { [articleId] : INITIAL_STATE.articleProgress[articleId] = articleProgress })

}*/

const persistDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PersistDataActionTypes.SET_THEME:
            return {
                ...state,
                darkmode: darkmodeSetter(action.payload),
                theme: action.payload
            }
        case PersistDataActionTypes.SET_ARTICLE_PROGRESS:
            return {
                ...state,
                articleProgress: articleProgressSetter(action.payload, state)
            }
        default:
            return state;
    }
}

export default persistDataReducer;