import { createSelector } from "reselect";

const selectSessionData = state => state.sessionData;

export const selectScrollState = createSelector(
    [selectSessionData],
    sessionData => sessionData.scrollState
)

export const selectImageRefObj = createSelector(
    [selectSessionData],
    sessionData => sessionData.imageRefObj
)

export const selectEmbedConsent = createSelector(
    [selectSessionData],
    sessionData => sessionData.embedConsent
)

export const selectFeedRefObj = createSelector(
    [selectSessionData],
    sessionData => sessionData.feedRefObj
)

export const selectTraceLog = createSelector(
    [selectSessionData],
    sessionData => sessionData.traceLog
)