import styled from "styled-components";
import { radius, color, font } from "../../../design/theme";
import { textColor } from "../../general.utils";

export const ArticleAudioWrapper = styled.div`
    width: inherit;
    height: auto;
    position: relative;
    margin: 30px 0 20px 0;
    border-radius: ${radius.smallRadius};
  
    --plyr-color-main: ${color.blue};
    --plyr-control-spacing: 12px;
    --plyr-audio-controls-background: ${props => props.darkmode ? color.gray2 : color.white};
    --plyr-audio-control-color: ${props => textColor(props.darkmode)};
    --plyr-audio-progress-buffered-background: ${props => props.darkmode ? "rgba(255, 255, 255, 0.20)" : "rgba(0, 0, 0, 0.2)"};
    --plyr-audio-range-track-background: ${props => props.darkmode ? "rgba(255, 255, 255, 0.25)" : "rgba(0, 0, 0, 0.25)"};
    --plyr-badge-background: ${color.gray2};
    --plyr-badge-text-color: ${color.white};
    --plyr-badge-border-radius: 4px;
    --plyr-control-radius: 5px;
    --plyr-menu-color: ${color.gray2};
    --plyr-menu-radius: 7px;
    --plyr-menu-arrow-size: 7px;
    --plyr-menu-item-arrow-color: ${color.gray2};
    --plyr-menu-item-arrow-size: 5px;
    --plyr-menu-border-color: ${color.gray2};
    --plyr-menu-border-shadow-color: transparent;
    --plyr-tooltip-background: ${color.white};
    --plyr-tooltip-color: ${color.gray2};
    --plyr-tooltip-radius: 4px;
    --plyr-tooltip-arrow-size: 7px;
    --plyr-font-family: ${font.pulpDisplayBold};
    --plyr-font-smoothing: true;
    --plyr-font-size-small: 15px;
`

export const ArticleAudioContainer = styled.audio`
    width: inherit;
    overflow: hidden;
    border-radius: inherit;
`