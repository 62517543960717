import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Helmet } from "react-helmet"; // change data-react-helmet constant in node_modules es+lib to data-rh

import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { generateCdnUrl } from "../general.utils";
import { headData } from "../lang.data";

const SiteHead = ({ lang, darkmode, title = "", desc, data, type = "website" }) => {
    const { author, shareUrl, imageUrl, imageDesc, imageType, publishedAt, updatedAt, category } = data || {};
    const alternateLangs = ["de", "en", "fr"].filter(item => item !== lang);
    const websiteTypes = {
        website: "https://ogp.me/ns/website#",
        article: "https://ogp.me/ns/article#",
        profile: "https://ogp.me/ns/profile#"
    }
    const itemTypes = {
        website: "https://schema.org/WebApplication",
        article: "https://schema.org/NewsArticle"
    }
    const indexPages = ["/", "/login", "/signup"];
    const currentPage = window.location.pathname;
    const pageTitle = title ? `${title} | reavide News` : `reavide News: ${headData.title[lang]}`;
    const pageDesc = desc ?? headData.description[lang];

    useEffect(() => {
        const headTag = document.getElementsByTagName("head")
        if(headTag[0]) headTag[0].setAttribute("prefix", "og: https://ogp.me/ns# fb: https://ogp.me/ns/fb# " + `${type}: ` + websiteTypes[type])
    })

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang : lang, itemtype: itemTypes[type] }}>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDesc} />
                <meta name="author" content={author || "reavide News"} />
                <meta name="color-scheme" content={darkmode ? "dark" : "light"} />

                <link rel="publisher" href="https://reavi.de/twitter" />
                <link rel="shortlink" href={"https://reavi.de/news"} />
                <meta property="al:web:url" content={"https://reavi.de/news"} />

                {
                    indexPages.some(path => path === currentPage) && type !== "article" ? (
                        <meta name="robots" content={"index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1, noimageindex, noodp"} />
                    ) : <meta name="robots" content={"noindex, nofollow, noodp"} />
                }

                <meta property="og:type" content={type} />
                <meta property="og:author" content={author || "reavide News"} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDesc} />
                <meta property="og:locale" content={lang} />
                <meta property="og:url" content={shareUrl || "https://reavi.de/news/"} />

                <meta name="twitter:domain" content={shareUrl || "https://reavi.de/news/"} />
                <meta name="twitter:site" content={"@reavideNews"} />
                <meta name="twitter:creator" content={"@reavideNews"} />
                <meta name="twitter:card" content={"summary"} />

                <meta property="fb:app_id" content={"272522984360685"} />
                <meta property="fb:pages" content={"109811594848977"} />


                {
                    alternateLangs.map(lang => (
                        <meta key={lang} property="og:locale:alternate" content={lang} />
                    ))
                }
            </Helmet>
            <Fragment>
                {
                    type === "article" ? (
                        <Helmet>
                            <meta property="og:article:author" content={author} />
                            <meta property="og:image" content={imageUrl || ""} />
                            <meta property="og:image:secure_url" content={imageUrl || ""} />
                            <meta property="og:image:alt" content={imageDesc || "Article Image"} />
                            <meta property="og:image:type" content={imageType || ""} />

                            <meta property="article:published_time" content={publishedAt} />
                            <meta property="article:modified_time" content={updatedAt} />
                            <meta property="article:author" content={author} />
                            <meta property="article:section" content={category} />
                        </Helmet>
                    ) : (
                        <Helmet>
                            <meta property="og:image" content={generateCdnUrl("social/reavide-news-og.png")} />
                            <meta property="og:image:alt" content={"reavide News Showcase Large"} />
                            <meta property="og:image:type" content={"image/png"} />
                            <meta property="og:image:width" content={"1920"} />
                            <meta property="og:image:height" content={"1080"} />
                            <meta property="og:image" content={generateCdnUrl("social/reavide-news-small-og.png")} />
                            <meta property="og:image:alt" content={"reavide News Showcase Small"} />
                            <meta property="og:image:type" content={"image/png"} />
                            <meta property="og:image:width" content={"400"} />
                            <meta property="og:image:height" content={"400"} />
                        </Helmet>
                    )
                }
            </Fragment>
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(SiteHead);