import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { ArticleSubtitleContainer } from "./articleSubtitle.styles";

import ArticleText from "../articleText/articleText.content";

const ArticleSubtitle = ({ content, darkmode }) => {
    const { mainWidth } = useContext(LayoutContext);
    const { text } = content || {};

    return (
        <ArticleSubtitleContainer darkmode={darkmode}>
            <ArticleText
                size={mainWidth > 600 ? 25 : 22}
                text={text}
                narrow
                contrast
                bold
            />
        </ArticleSubtitleContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleSubtitle);