import React, { Fragment, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { RecentContext } from "../../../context/recent.context";
import { fetchArticleRefStart } from "../../../redux/articleRef/articleRef.actions";
import { selectArticleArrayForPreview } from "../../../redux/articleData/articleData.selectors";
import { selectArticleRefArray, selectArticleRefError, selectArticleRefFetching } from "../../../redux/articleRef/articleRef.selectors";
import { selectArticleFetching } from "../../../redux/articleData/articleData.selectors";
import { selectUserId, selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { titleData, navData } from "../../../components/lang.data";

import Indicator from "../../../components/indicator/indicator.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import SubIndicator from "../../../components/subIndicator/subIndicator.component";
import RecentlyAccessed from "../../../components/recentlyAccessed/recentlyAccessed.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const HomePage = ({ userId, lang, refLoading, fetchArticleRefStart, articlePreviewList, error,
                      articleLoading, articleRefList }) => {

    const { recentList, recentInitialize } = useContext(RecentContext);

    useEffect(() => {
        recentInitialize();
        if(!refLoading && !articlePreviewList.length) fetchArticleRefStart({ userId, lang }, "home")

    }, [fetchArticleRefStart, userId, lang])

    return (
        <Fragment>
            <SiteHead title={navData.home.desc[lang]} />
            <Indicator />
            {
                recentList && articlePreviewList?.length ? (
                    <Fragment>
                        <SubIndicator icon={"updates"} title={titleData.recentAccess[lang]} />
                        <RecentlyAccessed />
                        <SubIndicator icon={"magic"} title={titleData.forYou[lang]} />
                    </Fragment>
                ) : null
            }
            {
                articlePreviewList?.length ? (
                    <ArticleFeed
                        articlePreviewList={articlePreviewList}
                        loading={refLoading || articleLoading}
                        infiniteLoader={() => fetchArticleRefStart({ userId, lang }, "home")}
                        error={error}
                        type={"standard"}
                        refType={"home"}
                    />
                ) : (refLoading !== undefined && !refLoading && !articleLoading && articleRefList?.length === 0) ? (
                    <ErrorDisplay type={"noChannelsHome"} link={"explore"} icon={"110015"} />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    refLoading: selectArticleRefFetching(state),
    articleLoading: selectArticleFetching(state),
    articlePreviewList: selectArticleArrayForPreview(selectArticleRefArray("home")(state))(state),
    articleRefList: selectArticleRefArray("home")(state),
    error: selectArticleRefError("home")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchArticleRefStart: (reqHeaders, refKey) => dispatch(fetchArticleRefStart(reqHeaders, refKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);