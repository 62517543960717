import React, { useState, Fragment, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { GalleryContext } from "../../../context/gallery.context";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleGalleryWrapper,
    ArticleGalleryAction,
    ArticleGalleryActionIcon,
    ArticleGalleryCounter,
    ArticleGalleryCounterPoint
} from "./articleGallery.styles";
import ArticleImage from "../articleImage/articleImage.content";
import ArticleVideo from "../articleVideo/articleVideo.content";
import ArticleCaption from "../articleCaption/articleCaption.content";

const ArticleGallery = ({ content, darkmode }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [firstInteraction, setFirstInteraction] = useState(false);
    const [mediaIndex, setMediaIndex] = useState(0);
    const [hoverActive, setHoverActive] = useState(false);
    const [galleryEnv, setGalleryEnv] = useState(null);

    const { body } = content || {};
    const arrLength = body.length - 1 || 0;

    const handleActionClick = (event, direction) => {
        event.stopPropagation();
        if(!firstInteraction) setFirstInteraction(true)
        if(direction) {
            if(mediaIndex !== arrLength) setMediaIndex(mediaIndex + 1)
            else setMediaIndex(0)
        } else {
            if(mediaIndex !== 0) setMediaIndex(mediaIndex - 1)
            else setMediaIndex(arrLength)
        }
    }
    const handleCounterClick = (event, index) => {
        event.stopPropagation();
        if(!firstInteraction) setFirstInteraction(true)
        if(mediaIndex !== index) setMediaIndex(index)
    }

    return (
        <Fragment>
            <ArticleGalleryWrapper
                onMouseOver={() => setHoverActive(true)}
                onMouseLeave={() => setHoverActive(false)}
                mainWidth={mainWidth}
            >
                {
                    body[mediaIndex].component === "image" ? (
                        <ArticleImage
                            content={body[mediaIndex].content}
                            setEnv={setGalleryEnv}
                            gallery
                        >
                            <Fragment>
                                {
                                    hoverActive ? (
                                        <Fragment>
                                            <ArticleGalleryAction
                                                direction={0}
                                                hidden={!firstInteraction}
                                                darkmode={darkmode}
                                                backDropEnabled={CSS.supports("backdrop-filter", "blur(8px)")}
                                                onClick={(event) => handleActionClick(event, 0)}
                                            >
                                                <ArticleGalleryActionIcon
                                                    name={"arrow"}
                                                    color={darkmode ? color.white : color.black}
                                                    size={34}
                                                />
                                            </ArticleGalleryAction>
                                            <ArticleGalleryAction
                                                direction={1}
                                                darkmode={darkmode}
                                                onClick={(event) => handleActionClick(event, 1)}
                                            >
                                                <ArticleGalleryActionIcon
                                                    name={"arrow"}
                                                    color={darkmode ? color.white : color.black}
                                                    size={34}
                                                />
                                            </ArticleGalleryAction>
                                            {
                                                !galleryEnv.mediaZoom ? (
                                                    <ArticleGalleryCounter
                                                        onMouseDown={(event) => event.preventDefault()}
                                                        darkmode={darkmode}
                                                        backDropEnabled={CSS.supports("backdrop-filter", "blur(8px)")}
                                                    >
                                                        {
                                                            body.map((i, index) => (
                                                                <ArticleGalleryCounterPoint
                                                                    onClick={(event) => handleCounterClick(event, index)}
                                                                    key={index}
                                                                    activeKey={mediaIndex === index}
                                                                    darkmode={darkmode}
                                                                />
                                                            ))
                                                        }
                                                    </ArticleGalleryCounter>
                                                ) : null
                                            }
                                        </Fragment>
                                    ) : null
                                }
                            </Fragment>
                        </ArticleImage>
                    ) : body[mediaIndex].component === "video" ? (
                        <ArticleVideo
                            content={body[mediaIndex].content}
                            contentId={body[mediaIndex].componentId}
                            setEnv={setGalleryEnv}
                            gallery
                        />
                    ) : null
                }
            </ArticleGalleryWrapper>
            <GalleryContext.Provider value={{ galleryEnv }}>
                <ArticleCaption
                    caption={body[mediaIndex].content.caption}
                    type={"gallery"}
                    currentPosition={mediaIndex + 1}
                    maxPosition={arrLength + 1}
                />
            </GalleryContext.Provider>
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleGallery);