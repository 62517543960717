import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const CustomButtonContainer = styled.button`
    width: ${props => props.width ? props.width + "px" : "100%"};
    height: ${props => props.size ? "35px" : "50px"};
    border-radius: ${props => props.size ? "10px" : radius.smallRadius};
    justify-content: center;
    text-align: center;
    text-decoration: none;
    background-color: ${props => props.solid ? props.color : "transparent"};
    color: ${props => props.solid ? props.contentColor ? props.contentColor : color.white : props.contentColor ? props.contentColor : props.color};
    box-shadow: inset 0 0 0 3px ${props => props.solid ? "transparent" : props.color};
    cursor: ${props => (props.disabled || props.loading) ? "not-allowed" : "pointer"};
    font-family: ${props => props.size ? font.pulpDisplayBold : font.pulpDisplayMedium};
    font-size: ${props => props.size ? "16px" : "20px"};
    line-height: ${props => props.size ? "35px" : "50px"};
    white-space: nowrap;
    letter-spacing: ${props => props.size ? "0.045em" : "0"};
    display: flex;
    border: none;
    filter: ${props => (props.active || props.disabled) ? "opacity(0.4)" : "none"};
    position: ${props => props.center ? "absolute" : "relative"};
    margin: 0 0 ${props => props.center ? "35px" : "0"} 0;
    padding: ${props => props.desc ? "0 15px 0 20px" : "0 15px"};
    
    ${
        props => props.center ? "left: 50%;-webkit-transform: translateX(-50%);transform: translateX(-50%);" : null
    }
    
    &:hover {
        filter: ${props => ((props.size && props.active) || props.disabled) ? "opacity(0.4)" : "opacity(0.8)"};
        // props => props.disabled ? "none" : ((props.size && props.active) ? "opacity(0.4)" : "opacity(0.8)")
        // padding: ${props => props.desc ? "0 15px" : "0 15px 0 20px"};
    }
    &:focus {
        border: none;
        outline: none;
    }
    &:active {
        transform: ${props => props.center ? "translateX(-50%) scale(0.98);" : "scale(0.98)"};
        border: none;
        outline: none;
        // padding: ${props => props.desc ? "0 15px" : "0 15px 0 20px"};
        // margin-bottom: ${props => props.center ? "40px" : "0"};
        ${props => props.center ? "margin-bottom: 40px;" : null};
    }
    & > div:nth-child(2) {
        margin-left: 4px;
    }
`;

export const CustomButtonIconContainer = styled.div`
    width: ${props => props.desc ? "20px" : "25px"};
    height: 100%;
    display: flex;
    flex-shrink: 0;
`

export const CustomButtonIcon = styled(Icon)`
    height: 100%;
    width: auto;
    justify-content: center;
    user-select: none;

    ${
        props => props.loading && props.spinonload ? `
            animation: loading-spin 0.6s infinite linear;
            @keyframes loading-spin {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(359deg);
                }
            }
        ` : null
    }
`;

export const CustomButtonDescContainer = styled.div`
    height: 100%;
    width: max-content;
    line-height: 100%;
    display: flex;
    margin-left: ${props => props.service ? "10px !important" : "0"};
    justify-content: center;
    flex-direction: column;
    user-select: none;
`

export const CustomButtonFileInput = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    margin: -2px -2px -2px -2px;
    background-color: transparent;
    color: transparent;
    opacity: 0;
    font-size: 0;
    cursor: pointer !important;

    &:hover {
        padding: 0;
        border: none;
        outline: none;
        margin: 0;
    }
    &:focus {
        border: none;
        outline: none;
    }
`

export const CustomButtonLoadingContainer = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
`

export const CustomButtonLoading = styled.div`
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${color.white};
    color: ${color.white};
    box-shadow: 9984px 0 0 0 ${color.white}, 9999px 0 0 0 ${color.white}, 10014px 0 0 0 ${color.white};
    animation: btnLoading 1.5s infinite linear;

    @keyframes btnLoading {
        0% {
            box-shadow: 9984px 0 0 -1px ${color.white}, 9999px 0 0 1px ${color.white}, 10014px 0 0 -1px ${color.white};
        }
        50% {
            box-shadow: 10014px 0 0 -1px ${color.white}, 9984px 0 0 -1px ${color.white}, 9999px 0 0 1px ${color.white};
        }
        100% {
            box-shadow: 9999px 0 0 1px ${color.white}, 10014px 0 0 -1px ${color.white}, 9984px 0 0 -1px ${color.white};
        }
    }
`