import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { GalleryContext } from "../../../context/gallery.context";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleCaptionWrapper,
    ArticleCaptionIconContainer,
    ArticleCaptionIcon,
    ArticleCaptionDescContainer,
    ArticleCaptionCounterWrapper,
    ArticleCaptionCounterContainer
} from "./articleCaption.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleCaption = ({ darkmode, callback, caption, type, currentPosition, maxPosition }) => {
    const { galleryEnv } = useContext(GalleryContext) || {};

    const handleOnClick = (event) => {
        event.stopPropagation();
        if(callback) callback();
        if(type === "gallery") galleryEnv.mediaAction();
    }

    return (
        <ArticleCaptionWrapper darkmode={darkmode} gallery={type === "gallery"}>
            <ArticleCaptionIconContainer gallery={type === "gallery"}>
                <ArticleCaptionIcon
                    name={(type === "audio" || type === "embed" || (type !== "gallery" && !callback)) ? "info" :"zoom"}
                    size={20}
                    color={darkmode ? color.gray4 : color.gray2}
                    onClick={(event) => handleOnClick(event)}
                    callback={(callback || galleryEnv) ? 1 : 0}
                />
            </ArticleCaptionIconContainer>
            <ArticleCaptionDescContainer gallery={type === "gallery"}>
                <ArticleText text={caption} small />
            </ArticleCaptionDescContainer>
            {
                (type === "gallery") ? (
                    <ArticleCaptionCounterWrapper darkmode={darkmode}>
                        <ArticleCaptionCounterContainer darkmode={darkmode}>
                            <span>{currentPosition}</span>
                            <span>{` / ${maxPosition}`}</span>
                        </ArticleCaptionCounterContainer>
                    </ArticleCaptionCounterWrapper>
                ) : null
            }
        </ArticleCaptionWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleCaption);