import React, { Fragment, useRef, useLayoutEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { generateCdnUrl } from "../../general.utils";

import { ArticleVideoWrapper, ArticleVideoContainer } from "./articleVideo.styles";
import ArticleCaption from "../articleCaption/articleCaption.content";
import ArticleSubtitle from "../articleSubtitle/articleSubtitle.content";

const ArticleVideo = ({ content, contentId, darkmode, lang, gallery, setEnv }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [player, setPlayer] = useState(null);
    const [playInit, setPlayInit] = useState(null);
    const [fullscreen, setFullscreen] = useState(false);
    const ref = useRef(null);
    const { videoUrl, provider, title, caption, copyright, type, poster } = content || {};

    const playerSrc = provider !== "cdn" ? { provider: provider } : { type: type };

    useLayoutEffect(() => {
        let plyr = null;
        if(ref.current) {
            plyr = new Plyr(ref.current, {
                title: title,
                settings: ["captions", "quality", "speed"],
                controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "captions", "settings", "pip", "airplay", "fullscreen"],
                autopause: true,
                volume: 0.75,
                resetOnEnd: false,
                hideControls: true,
                keyboard: { focused: false, global: false },
                captions: { active: false, language: lang, update: true },
                speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5] },
                vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false, dnt: true },
                youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, hl: lang, cc_lang_pref: lang, cc_load_policy: 0 },
                iconUrl: generateCdnUrl("static/plyr-v3.6.9.svg"),
                blankVideo: generateCdnUrl("static/plyr-blank.mp4")
            })
            plyr.source = {
                type: "video",
                title: title,
                sources: [Object.assign({ src: videoUrl }, playerSrc)],
                poster: poster ? poster : ""
            }
            plyr.on("enterfullscreen", () => {
                let parentDOM = document.querySelector("#v_" + contentId);
                let fullscreen = parentDOM.querySelector('.plyr__controls > [data-plyr="fullscreen"]');
                fullscreen.hidden = false;
                setFullscreen(true);
                if(!playInit) plyr.play();
            });
            plyr.on("exitfullscreen", () => {
                let parentDOM = document.querySelector("#v_" + contentId);
                let fullscreen = parentDOM.querySelector('.plyr__controls > [data-plyr="fullscreen"]');
                setFullscreen(false);
                fullscreen.hidden = !!caption;
            });
            plyr.on("ready", () => {
                let parentDOM = document.querySelector("#v_" + contentId);
                let timeInfo = parentDOM.querySelector(".plyr__time");
                let controls = parentDOM.querySelector(".plyr__controls");
                let fullscreen = controls.querySelector('[data-plyr="fullscreen"]');
                let rangeSlider = controls.querySelectorAll('[type="range"]');
                timeInfo.style.cursor = "default";
                timeInfo.style.userSelect = "none";
                rangeSlider[0].style.cursor = "pointer";
                rangeSlider[1].style.cursor = "pointer";
                fullscreen.hidden = !!caption;
                controls.hidden = true;
            });
            plyr.on("play", () => {
                let parentDOM = document.querySelector("#v_" + contentId);
                let controls = parentDOM.querySelector(".plyr__controls");
                controls.hidden = false;
                setPlayInit(true);
            });
            setPlayer(plyr);
            if(gallery) setEnv({ mediaAction: () => plyr.fullscreen.enter() });
        }

        return () => {
            plyr.destroy();
            if(gallery) setEnv(null);

            let vidEmbedScriptTags = document.getElementsByTagName("script");
            for (let scriptTag of vidEmbedScriptTags) {
                let scriptSrc = scriptTag.getAttribute("src");
                if(scriptSrc.includes("youtube") || scriptSrc.includes("vimeo")) scriptTag.remove();
            }
        }
    }, [ref])

    return (
        <Fragment>
            {
                title && <ArticleSubtitle content={{ text: title }} />
            }
            <ArticleVideoWrapper
                title={(copyright && !fullscreen) ? `© ${copyright}` : null}
                darkmode={darkmode}
                gallery={gallery}
                mainWidth={mainWidth}
                id={"v_" + contentId}
            >
                <ArticleVideoContainer ref={ref} />
            </ArticleVideoWrapper>
            {
                (caption && !gallery) ? (
                    <ArticleCaption
                        caption={caption}
                        callback={player ? () => player.fullscreen.enter() : null}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(ArticleVideo);