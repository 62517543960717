import React, { useState, Fragment, useContext } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { channelData } from "../lang.data";
import { categoryContentColor } from "../general.utils";
import { followChannel } from "../../redux/userEvents/userEvents.actions";
import { selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";

import {
    ChannelListContainer,
    ChannelListIconContainer,
    ChannelListDescriptionContainer,
    ChannelListButtonContainer,
    ChannelListActionContainer
} from "./channelList.styles";
import CustomButton from "../customButton/customButton.component";

const ChannelList = ({ channel, categoryId, userId, followChannel, lang, darkmode }) => {
    const { mainWidth } = useContext(LayoutContext);
    const extended = mainWidth > 600;
    const { channelName, iconId, channelRef, channelId } = channel;
    const history = useHistory();

    const [unsubscribe, setUnsubscribe] = useState(false);

    return (
        <ChannelListContainer
            onClick={() => history.push(`/channel/${encodeURI(channelRef).toLowerCase()}`)}
            categoryId={categoryId}
            darkmode={darkmode}
        >
            <ChannelListIconContainer
                name={iconId}
                size={30}
                color={categoryContentColor(categoryId, darkmode)}
            />
            <ChannelListDescriptionContainer>
                <span>{ channelName }</span>
            </ChannelListDescriptionContainer>
            <ChannelListButtonContainer>
                {
                    unsubscribe ? (
                        <Fragment>
                            {
                                extended ? (
                                    <ChannelListActionContainer>
                                        <span>{channelData.stopFollowing[lang]}</span>
                                    </ChannelListActionContainer>
                                ) : null
                            }
                            <CustomButton
                                callback={() => followChannel(userId, channelId)}
                                size={"small"}
                                width={60}
                                icon={"checkmark"}
                                active={"true"}
                                color={categoryContentColor(categoryId, darkmode)}
                            />
                            <CustomButton
                                callback={() => setUnsubscribe(false)}
                                size={"small"}
                                color={categoryContentColor(categoryId, darkmode)}
                                width={60}
                                icon={"cancel"}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <CustomButton
                                callback={() => setUnsubscribe(true)}
                                size={"small"}
                                desc={channelData.following[lang]}
                                color={categoryContentColor(categoryId, darkmode)}
                                width={130}
                                icon={"check"}
                                active={"true"}
                            />
                            {
                                extended ? (
                                    <CustomButton
                                        callback={() => history.push(`/channel/${encodeURI(channelRef).toLowerCase()}`)}
                                        size={"small"}
                                        desc={channelData.readMore[lang]}
                                        width={120}
                                        color={categoryContentColor(categoryId, darkmode)}
                                    />
                                ) : null
                            }
                        </Fragment>
                    )
                }
            </ChannelListButtonContainer>
        </ChannelListContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

const mapDispatchToProps = (dispatch) => ({
    followChannel: (userId, channelId) => dispatch(followChannel(userId, channelId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelList);