import styled from "styled-components";
import { color, radius } from "../../design/theme";

export const RenderControllerContainer = styled.article`
    width: auto;
    height: auto;
    position: relative;
    will-change: scroll-position;
`

export const RenderControllerFallback = styled.div`
    width: auto;
    height: ${props => props.defaultheight ? props.defaultheight + "px" : "inherit"};
    background-color: ${props => props.defaultheight ? props.darkmode ? color.gray2 : color.white : null};
    border-radius: ${props => props.defaultheight ? props.defaultheight > 100 ? radius.largeRadius : radius.smallRadius : "inherit"};
    user-select: none;
    margin-bottom: ${props => props.defaultfeed ? "30px" : null};
    // transform: translate3d(0,0,0); // forces tap into webGPU
    // content-visibility: auto;
    // will-change: scroll-position;
`