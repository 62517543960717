import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { setUserLanguageStart } from "../../redux/userSettings/userSettings.actions";
import { logSharedContent } from "../../redux/userEvents/userEvents.actions";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { textColor, shareContent, copyToClipboard } from "../general.utils";
import { color } from "../../design/theme";

import {
    ContextMenuContainer,
    ContextMenuRefGhostContainer,
    ContextMenuSelectionContainer,
    ContextMenuSelectionCell,
    ContextMenuIcon
} from "./contextMenu.styles";

const ContextMenu = ({ data, current, userId, type, setUserLanguage, darkmode, lang, title, shareTitle, shareId,
                      logSharedContent, state, callbacks, contextState, contextCallback, contextRef, shareDesc }) => {

    const [active, setActive] = useState(false);
    const dropdownRef = useRef(null);
    const offsetRef = useRef(null);

    const history = useHistory();

    const handleOnClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(contextCallback) {
            if(contextState) contextCallback();
            else setActive(!active);
        } else setActive(!active)
    }

    const handleSelectionClick = (event, value) => {
        event.stopPropagation();
        if(contextCallback) {
            if(contextState) contextCallback();
            if(active) setActive(false);
        } else setActive(!active)

        if ((type === "lang") || (type === "region")) {
            if (value !== current) {
                setUserLanguage({ userId, value })
            }
        } else if(type === "options") {
            if(callbacks[value]) callbacks[value]()
        } else if(type === "filter") {
            if(value !== "date") {
                history.replace(`?sortBy=${value}`)
            } else history.replace({ search: ""})
        } else {
            if (value === "link" || value === "id") {
                const valueToCopy = value === "id" ? shareId : `reavi.de/${type === "channel" ? "c/" : ""}${shareId}`;
                copyToClipboard(valueToCopy);
            } else {
                const shareType = (type === "article") ? "article" : "channel";
                const win = window.open(shareContent(value, lang, shareId, shareTitle, shareType, shareDesc), "_blank");
                win.focus();
            }
            logSharedContent(userId, { type, shareId, value })
        }
    }

    useEffect(() => {
        if(contextState && active) {
            setActive(false);
            contextCallback();
        }

        const handleClickOutside = (event) => {
            event.stopPropagation();
            if (!offsetRef.current.contains(event.target)
                    && (dropdownRef.current && !dropdownRef.current.contains(event.target))) {
                if(contextCallback) {
                    if (!contextRef.current.contains(event.target)
                        && (contextRef.current && !contextRef.current.contains(event.target))) {
                        if(contextState) contextCallback();
                        setActive(false);
                    } else {
                        if(contextState && active) {
                            setActive(false);
                            contextCallback();
                        }
                    }
                } else setActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dropdownRef, contextState])

    return (
        <Fragment>
            {
                ((type === "lang") || (type === "region") || (type === "filter") || (type === "options")) ? (
                    <ContextMenuContainer
                        onClick={(event) => handleOnClick(event)}
                        ref={offsetRef}
                        type={type}
                        darkmode={darkmode}
                    >
                        {
                            current ? (
                                <span>{(type === "filter") ? data[current].desc[lang] : data[current]}</span>
                            ) : null
                        }
                        {
                            (type === "filter" || type === "options") ? (
                                <ContextMenuIcon
                                    type={type}
                                    name={type === "filter" ? "filter" : "options"}
                                    size={type === "filter" ? 22 : 24}
                                    color={type === "filter" ? color.gray3 : color.gray4}
                                />
                            ) : null
                        }
                    </ContextMenuContainer>
                ) : (
                    <ContextMenuRefGhostContainer
                        onClick={(event) => handleOnClick(event)}
                        ref={offsetRef}
                    />
                )
            }
            {
                (active || contextState) ? (
                    <ContextMenuSelectionContainer
                        ref={dropdownRef}
                        offset={(active || contextState) ? offsetRef.current.offsetWidth : null}
                        offsetTop={(type === "filter") || (type === "options")}
                        darkmode={darkmode}
                        variant={(type === "article") || (type === "channel") || (type === "options")}
                        share={(type === "article") || (type === "channel")}
                        noOffset={(type === "article") || (type === "channel") || type === "lang"}
                    >
                        {
                            ((type === "article") || (type === "channel") || (type === "filter") || (type === "options")) ? (
                                <ContextMenuSelectionCell
                                    onClick={(event) => handleOnClick(event)}
                                    darkmode={darkmode}
                                    desc={1}
                                    variant={(type === "article") || (type === "channel") || (type === "filter") || (type === "options")}
                                >
                                    <span>{(type === "filter") || (type === "options") ? title[lang] : title[type][lang]}</span>
                                </ContextMenuSelectionCell>
                            ) : null
                        }
                        {
                            Object.entries(data).map(entry => (
                                <Fragment key={entry[0]}>
                                    {
                                        !(type === "channel" && entry[0] === "id") ? (
                                            <ContextMenuSelectionCell
                                                onMouseDown={(event) => entry[0] === "separator" ? handleOnClick(event) : handleSelectionClick(event, entry[0])}
                                                copy={entry[0] === "link" || entry[0] === "id"}
                                                active={type !== "options" ? entry[0] === current : null}
                                                variant={(type === "article") || (type === "channel") || (type === "filter") || (type === "options")}
                                                darkmode={darkmode}
                                                separator={entry[0] === "separator"}
                                                danger={entry[0] === "delete"}
                                            >
                                                {
                                                    entry[1].type === "switch" ? (
                                                        <Fragment>
                                                            <ContextMenuIcon
                                                                name={state[entry[0]] ? entry[1].true.icon : entry[1].false.icon}
                                                                left={1}
                                                                size={25}
                                                                color={color.gray4}
                                                            />
                                                            <span>{state[entry[0]] ? entry[1].true.desc[lang] : entry[1].false.desc[lang]}</span>
                                                        </Fragment>
                                                    ) : entry[1].type !== "separator" ? (
                                                        <Fragment>
                                                            {
                                                                ((type === "article") || (type === "channel") || (type === "filter") || (type === "options")) ? (
                                                                    <ContextMenuIcon
                                                                        name={entry[1].icon}
                                                                        left={1}
                                                                        size={25}
                                                                        color={entry[0] === "delete" ? color.red : (type === "filter" || (type === "options")) ? entry[0] === current ? color.blue : color.gray4 : textColor(darkmode)}
                                                                    />
                                                                ) : null
                                                            }
                                                            <span>{((type === "article") || (type === "channel") || (type === "filter") || (type === "options")) ? entry[1].desc[lang] : entry[1]}</span>
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </ContextMenuSelectionCell>
                                        ) : null
                                    }
                                </Fragment>
                            ))
                        }
                    </ContextMenuSelectionContainer>
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

const mapDispatchToProps = (dispatch) => ({
    setUserLanguage: (reqHeaders) => dispatch(setUserLanguageStart(reqHeaders)),
    logSharedContent: (userId, shareDetails) => dispatch(logSharedContent(userId, shareDetails))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);