import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { listsData, miscData, shareData } from "../lang.data";
import { textColor } from "../general.utils";
import { color } from "../../design/theme";

import { HeaderActionContainer, HeaderActionIcon } from "./headerAction.styles";
import ContextMenu from "../contextMenu/contextMenu.component";
import Modal from "../modal/modal.component";
import ListSelection from "../listSelection/listSelection.component";
import ModalAction from "../modalAction/modalAction.component";
import ModalSelection from "../modalSelection/modalSelection.component";
import ModalList from "../modalList/modalList.component";
import ModalInput from "../modalInput/modalInput.component";

const HeaderAction = ({ icon, callback, darkmode, type, env, added, lang, outline }) => {
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);
    const { title, articleId, catchline, channelName, channelId, list } = env || {};

    const handleActionIconClick = (event) => {
        event.stopPropagation();
        if(type === "action") callback()
        if(type === "modal") setActive(!active)
    }
    const handleIconHover = (event) => {
        event.stopPropagation();
        setHover(true);
    }
    const handleIconHoverLeave = (event) => {
        event.stopPropagation();
        setHover(false);
    }

    return (
        <HeaderActionContainer
            onClick={(event) => handleActionIconClick(event)}
            onMouseOver={(event) => handleIconHover(event)}
            onMouseLeave={(event) => handleIconHoverLeave(event)}
            darkmode={darkmode}
        >
            {
                type === "share" ? (
                    <ContextMenu
                        data={shareData.sites}
                        title={shareData.title}
                        type={articleId ? "article" : "channel"}
                        shareTitle={articleId ? title : channelName}
                        shareId={articleId ? articleId : channelId}
                        shareDesc={catchline || ""}
                    />
                ) : null
            }
            {
                active && type === "modal" && icon === "lists" ? (
                    <Modal
                        title={`${listsData.addToList[lang]} 🧺`}
                        desc={listsData.addToListDesc[lang]}
                        onDismiss={() => setActive(!active)}
                    >
                        <ModalSelection
                            type={"list"}
                            refValue={articleId}
                            onDismiss={() => setActive(!active)}
                        />
                    </Modal>
                ) : null
            }
            {
                active && type === "modal" && icon === "edit" ? (
                    <Modal
                        type={"list"}
                        title={listsData.editList[lang]}
                        onDismiss={() => setActive(!active)}
                    >
                        <ModalList imageId={list.imageId} />
                        <ModalInput
                            type={"list"}
                            onDismiss={() => setActive(!active)}
                            imageId={list.imageId}
                            refValues={{
                                title: list.title,
                                desc: list.desc,
                                listId: list.listId
                            }}
                        />
                    </Modal>
                ) : null
            }
            {
                active && type === "modal" && icon === "delete" ? (
                    <Modal
                        title={`${listsData.deleteList[lang]} ⚠️`}
                        desc={miscData.confirmIrreversible[lang]}
                        onDismiss={() => setActive(!active)}
                    >
                        <ListSelection
                            type={"delete"}
                            list={list}
                        />
                        <ModalAction
                            callback={callback}
                            onDismiss={() => setActive(!active)}
                            actionColor={color.red}
                        />
                    </Modal>
                ) : null
            }
            <HeaderActionIcon
                name={icon}
                size={34}
                color={added ? articleId ? textColor(darkmode) : color.blue : hover ? textColor(darkmode) : darkmode ? color.gray3 : color.gray4}
                outline={outline}
            />
        </HeaderActionContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(HeaderAction);