import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { addScript, removeScript, removeIframe, scriptPresent, generateCdnUrl } from "../../../general.utils";
import { selectDarkmode } from "../../../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../../../redux/userData/userData.selectors";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
`

const EmbedTwitter = ({ embedUrl, width, darkmode, lang }) => {
    const ref = useRef(null);
    const modUrl = "https://twitter.com/widgets/status/" + embedUrl.substring(embedUrl.lastIndexOf("id=") + 3)

    useEffect(() => {
        if(!scriptPresent("twitter")) {
            addScript(generateCdnUrl("dist/js/embeds/twitter-sdk.js"))
        } else if(window.twttr) {
            window.twttr.widgets.load(ref.current);
        }

        return () => {
            removeScript("twitter")
            removeIframe("Twitter")
        }
    }, [ref, darkmode, lang])

    return (
        <IFrameWrapper
            width={width}
            ref={ref}
        >
            <blockquote
                className="twitter-tweet"
                data-theme={darkmode ? "dark" : "light"}
            >
                <p lang={lang} dir={"ltr"}> </p>
                <a href={modUrl}> </a>
            </blockquote>
        </IFrameWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(EmbedTwitter);