import React, { useEffect, useLayoutEffect, Fragment, useState, useRef, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { selectBookmarkDataRaw, selectUserId } from "../../redux/userData/userData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { fetchUserBookmarksStart } from "../../redux/userData/userData.actions";
import { selectFeedRefObj } from "../../redux/sessionData/sessionData.selectors";
import { setFeedRef } from "../../redux/sessionData/sessionData.actions";
import { bookmarkArticle, listArticle } from "../../redux/userEvents/userEvents.actions";
import { selectArticleFetching, selectArticleList, selectArticleError } from "../../redux/articleData/articleData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { errorData } from "../lang.data";

import { ArticleFeedWrapper } from "./articleFeed.styles";
import ArticleCard from "../articleCard/articleCard.component";
import ImageCard from "../imageCard/imageCard.component";
import Notice from "../notice/notice.component";
import RenderController from "../renderController/renderController.component";
import ErrorDisplay from "../errorDisplay/errorDisplay.component";

const ArticleFeed = ({ articlePreviewList, articleRefList, loading, type, userId, bookmarkData, refType,
                         lang, infiniteLoader, fetchUserBookmarksStart, articleList, bookmarkArticle,
                           listArticle, setFeedRef, feedRefObj, darkmode, error, articlesFetching }) => {

    const { mainWidth } = useContext(LayoutContext);
    const [deleteList, setDeleteList] = useState([]);
    const [loadActive, setLoadActive] = useState(false);
    const [reloadActive, setReloadActive] = useState(false);
    const isLoading = loading || articlesFetching;
    const ref = useRef(null);

    const handleNoticeCallback = () => {
        if(refType === "bookmarks") deleteList.forEach(articleId => bookmarkArticle(userId, articleId))
        else deleteList.forEach(articleId => listArticle(userId, articleId, refType, deleteList.length))
    }

    let observer = (loader) => new IntersectionObserver((entries, observer) => {
        const [ entry ] = entries;
        if(entry.target && entry.isIntersecting) {
            if(!loader) {
                if (!feedRefObj[refType]?.includes(entry.target.id) && entry.target.id) {
                    setFeedRef(refType, entry.target.id);
                }
            } else {
                if(window.requestIdleCallback) {
                    window.requestIdleCallback(() => setLoadActive(true), { timeout: 250 })
                } else setLoadActive(true);
            }
            observer.unobserve(entry.target);
        }
    }, { threshold: 0.5, root: null });

    useLayoutEffect(() => {
        if(articleRefList && articlePreviewList) {
            let tempList = [];
            articleRefList.forEach(({ articleId }) => {
                let omittedTypes = ["deleted", "undefined"];
                if(omittedTypes.includes(articleList[articleId]?.metadata.type)) {
                    tempList.unshift(articleId)
                }
            })
            setDeleteList(tempList);
        }
        if(articlePreviewList && !articleRefList && ref.current) {
            // let queryFeed = document.querySelector("#main")
            let queryList = ref.current.querySelectorAll("article")
            queryList?.forEach((articleElement, index) => {
                if(!feedRefObj[refType]?.includes(articleElement.id)) {
                    observer().observe(articleElement)

                    if(queryList.length - 4 === index) {
                        observer(true).observe(articleElement)
                        console.log("__SIDE_OPERATION :: LOADING_OBSERVER_PLANTED", articleElement)
                        setLoadActive(false);
                    }
                }
            })
        }
    }, [articlePreviewList, articleRefList, ref])

    useEffect(() => {
        const reloadReset = reloadActive && setTimeout(() => setReloadActive(false), 1000);
        if(!bookmarkData) fetchUserBookmarksStart(userId);
        if((loadActive || reloadActive || !articlePreviewList.length) && !isLoading) {
            console.log("__MAIN_OPERATION :: LOADING_CALLBACK_TRIGGER_EXEC");
            infiniteLoader()
        }

        return () => clearTimeout(reloadReset)
    }, [bookmarkData, userId, loadActive, reloadActive])

    const components = {
        standard: ArticleCard,
        image: ImageCard,
    }
    const componentType = components[type] ? type : "standard";
    const TagComponent = components[componentType];

    return (
        <Fragment>
            {
                deleteList.length ? (
                    <Notice
                        type={"warning"}
                        desc={deleteList.length + " " + errorData.articleOmitted(lang, deleteList.length)}
                        feed={"true"}
                        callback={refType ? () => handleNoticeCallback() : null}
                    />
                ) : null
            }
            <ArticleFeedWrapper type={type} ref={ref} numColumns={mainWidth > 600 ? 2 : 1}>
                {
                    bookmarkData ? articlePreviewList?.map(articlePreview => (
                        <RenderController
                            key={articlePreview.articleId}
                            id={articlePreview.articleId}
                            defaultHeight={300}
                            defaultFeed={true}
                            darkmode={darkmode}
                        >
                            <TagComponent articlePreview={articlePreview} />
                        </RenderController>
                    )) : null
                }
            </ArticleFeedWrapper>
            {
                error ? (
                    <ErrorDisplay type={"contentError"} callback={setReloadActive} />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    bookmarkData: selectBookmarkDataRaw,
    articleList: selectArticleList,
    feedRefObj: selectFeedRefObj,
    lang: selectUserLanguage,
    darkmode: selectDarkmode,
    articlesFetching: selectArticleFetching
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserBookmarksStart: (userId) => dispatch(fetchUserBookmarksStart(userId)),
    listArticle: (userId, articleId, listId, deleteLength) => dispatch(listArticle(userId, articleId, listId, deleteLength)),
    bookmarkArticle: (userId, articleId) => dispatch(bookmarkArticle(userId, articleId)),
    setFeedRef: (feedRef, articleId) => dispatch(setFeedRef(feedRef, articleId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleFeed);