import axios from "axios";

export const newsAPI = axios.create({
    // baseURL: "http://reavide.tomaskoturenka.eu/v1"
    baseURL: "https://api.reavide.com/news/v1"
    // baseURL: "http://api.tomaskoturenka.eu/v1"
    // baseURL: "http://192.168.1.249/v1"
    // baseURL: "http://localhost:3002"
});

export const locationAPI = axios.create({
    baseURL: "https://ipapi.co/json"
    // Prev :: baseURL: "https://ip-api.com/json"
})