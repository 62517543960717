import React, { Fragment, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserId } from "../../redux/userData/userData.selectors";
import { selectUploadedImage, selectDeletedImage } from "../../redux/userEvents/userEvents.selectors";
import { deleteImageStart } from "../../redux/userEvents/userEvents.actions";
import { color } from "../../design/theme";

import {
    ModalContainer,
    ModalContentContainer,
    ModalTitleContainer,
    ModalDescContainer,
    ModalCloseButtonContainer,
    ModalCloseButton,
    ModalPromptIconContainer,
    ModalPromptIcon,
    ModalPromptSecondaryIcon
} from "./modal.styles";

const Modal = ({ darkmode, onDismiss, type, title, desc, deleteImageStart, userId,
                   uploadedImage, deletedImage, children, icon, secondaryIcon, refType }) => {

    const ref = useRef(null);

    const handleOnClick = (event, type) => {
        event.stopPropagation();
        if(!ref.current?.contains(event.target) || type === "dismiss") {
            if(uploadedImage || deletedImage) deleteImageStart(userId, "reset")
            onDismiss()
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleOnClick)

        return () => document.removeEventListener("click", handleOnClick)
    }, [ref])

    return createPortal(
        <ModalContainer
            darkmode={darkmode}
            onClick={(event) => handleOnClick(event)}
        >
            <ModalContentContainer
                type={type}
                darkmode={darkmode}
                ref={ref}
            >
                <ModalCloseButtonContainer>
                    <ModalCloseButton
                        onClick={(event) => handleOnClick(event, "dismiss")}
                        name={"close"}
                        size={25}
                        color={darkmode ? color.gray3 : color.gray4}
                    />
                </ModalCloseButtonContainer>
                {
                    type === "prompt" ? (
                        <ModalPromptIconContainer>
                            <ModalPromptIcon
                                name={icon}
                                color={refType === "warning" ? color.yellow : color.blue}
                                size={85}
                            />
                            {
                                secondaryIcon ? (
                                    <ModalPromptSecondaryIcon
                                        name={secondaryIcon}
                                        color={refType === "warning" ? color.yellow : color.blue}
                                        size={15}
                                    />
                                ) : null
                            }
                        </ModalPromptIconContainer>
                    ) : null
                }
                {
                    title ? (
                        <ModalTitleContainer darkmode={darkmode} type={type} desc={desc}>
                            <span>{title}</span>
                        </ModalTitleContainer>
                    ) : null
                }
                {
                    desc ? (
                        <ModalDescContainer darkmode={darkmode} type={type}>
                            <span>{desc}</span>
                        </ModalDescContainer>
                    ) : null
                }
                {
                    children ? (
                        <Fragment>{ children }</Fragment>
                    ) : null
                }
            </ModalContentContainer>
        </ModalContainer>,
        document.querySelector("#modal")
    )
}

const mapStateToProps = (state) => ({
    darkmode: selectDarkmode(state),
    userId: selectUserId(state),
    uploadedImage: selectUploadedImage(state),
    deletedImage: selectDeletedImage(state)
})

const mapDispatchToProps = (dispatch) => ({
    deleteImageStart: (userId, refType) => dispatch(deleteImageStart(userId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal);