import styled from "styled-components";
import { font, color } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const EmbedsSettingsContainer = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 100px;
    padding-top: 90px;
`;

export const EmbedsSettingsCookieContainer = styled.a`
    height: 30px;
    width: auto;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-right: 18px;
    padding: 2px 2px 2px 10px;
  
    &:hover {
        background-color: ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
    }
`

export const EmbedsSettingsCookieNum = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 18px;
    color: ${props => props.darkmode ? color.gray4 : color.gray3};
    margin-right: 10px;
`

export const EmbedsSettingsCookieIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    width: auto;
`