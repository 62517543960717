import styled from "styled-components";

export const RecentlyAccessedContainer = styled.section`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${props => props.numColumns}, 1fr);
    row-gap: 20px;
    column-gap: 20px;
    position: relative;
    margin-bottom: 40px;
    padding: 0 2px;
`