import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import { sanitizeForTraceLog } from "../components/general.utils";
import { addToTraceLog } from "./sessionData/sessionData.actions";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const omittedTypes = ["SET_IMAGE_REF", "ADD_TO_TRACE_LOG"];
const loggerMiddleware = storeAPI => next => action => {
    if(!omittedTypes.includes(action.type)) storeAPI.dispatch(addToTraceLog(sanitizeForTraceLog(action)))
    return next(action)
}

if(process.env.NODE_ENV === "development") {
    const logger = createLogger({
        predicate: (getState, action) => !omittedTypes.includes(action.type),
    });
    middlewares.push(logger)
}
const store = createStore(rootReducer, applyMiddleware(...middlewares, loggerMiddleware));

sagaMiddleware.run(rootSaga);
const persistedStore = persistStore(store);

export { store, persistedStore }