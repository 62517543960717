import BreakingDataActionTypes from "./breakingData.types";
import { isPlainObject, isEmpty, remove } from "lodash";

const INITIAL_STATE = {
    breakingUpdate: "",
    breakingList: null,
    breakingListFetching: false,
    errorMessage: null
}

const breakingDataSetter = (breakingPayload, breakingRefKey, stateObj) => {
    if(breakingPayload && (breakingRefKey === "batch" || breakingRefKey === "child_added")) {
        if(stateObj.breakingList?.length) {
            if(Array.isArray(breakingPayload)) return [...stateObj.breakingList, ...breakingPayload]
            if(breakingRefKey === "child_added" && isPlainObject(breakingPayload) && !isEmpty(breakingPayload)
                && !stateObj.breakingList.some(({ id }) => id === breakingPayload.id)) {
                stateObj.breakingList.unshift(breakingPayload);
            }
            return stateObj.breakingList;
        } else if(isPlainObject(breakingPayload) && !isEmpty(breakingPayload)) return [breakingPayload]
        else return breakingPayload;
    } else {
        if(isPlainObject(breakingPayload) && !isEmpty(breakingPayload)) {
            remove(stateObj.breakingList, ({ id }) => id === breakingPayload.id)
        }
        return stateObj.breakingList
    }
}

const breakingUpdateSetter = (breakingPayload, breakingRefKey, stateObj) => {
    if(isPlainObject(breakingPayload) && !isEmpty(breakingPayload)) {
        if(breakingRefKey === "child_added" && !stateObj.breakingList.some(({ id }) => id === breakingPayload.id)) {
            return breakingPayload.id
        } else if(breakingRefKey === "child_removed") return "removed_" + breakingPayload.id
    }
}

const breakingDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BreakingDataActionTypes.FETCH_BREAKING_DATA_START:
            return {
                ...state,
                breakingListFetching: true
            }
        case BreakingDataActionTypes.FETCH_BREAKING_DATA_SUCCESS:
            return {
                ...state,
                breakingListFetching: false,
                breakingUpdate: breakingUpdateSetter(action.payload, action.refKey, state) || state.breakingUpdate,
                breakingList: breakingDataSetter(action.payload, action.refKey, state)
            }
        case BreakingDataActionTypes.FETCH_BREAKING_DATA_FAILURE:
            return {
                ...state,
                breakingListFetching: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default breakingDataReducer;