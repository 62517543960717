import styled from "styled-components";
import { color } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleFootnoteWrapper = styled.div`
    width: inherit;
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    margin: ${props => !props.adjacentToFootnote ? "70px 0" : "-50px 0 70px 0"};

    ${
        props => !props.adjacentToFootnote ? (
            `&::before {
                content: "";
                width: inherit;
                left: 0;
                height: 3px;
                margin-top: -20px;
                position: absolute;
                display: flex;
                border-radius: 5px;
                background-color: ${color.gray3};
            }`
        ) : null
    }
`

export const ArticleFootnoteContainer = styled.div`
    display: inline-flex;
    width: auto;
    flex-grow: 100;
    text-align: left;
    height: auto;
    position: relative;
`

export const ArticleFootnoteIconContainer = styled.div`
    height: inherit;
    display: inline-flex;
    width: 40px;
    margin: -5px 15px 0 0;
    flex-direction: column;
    justify-content: flex-start;
`

export const ArticleFootnoteIcon = styled(Icon)`
    width: auto;
    height: auto;
    justify-content: center;
`