import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { selectUserLanguage } from "../../../../redux/userData/userData.selectors";
import { radius } from "../../../../design/theme";
import { capitalize } from "../../../general.utils";

const IFrameWrapper = styled.div`
    height: ${props => props.height + "px"};
    width: ${props => props.width + "px"};
    background-color: ${props => props.background || "transparent"};
    padding: ${props => props.padding ? props.padding + "px" : "0"};
    border-radius: ${props => props.radius ? radius.smallRadius : radius.largeRadius};
    transform: translate3d(0, 0, 0);
    overflow: hidden;
`

const EmbedDefault = ({ embedUrl, width, height, provider, lang }) => {
    const providerProperties = {
        "admin.ch": {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" }
        },
        bbvms: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" }
        },
        flourish: {
            background: "#fff",
            padding: 15,
            sandbox: "allow-popups allow-scripts"
        },
        genially: {
            sandbox: "allow-popups allow-scripts"
        },
        "google-maps": {
            sandbox: "allow-popups allow-scripts",
            radius: "small"
        },
        "gr.ch": {
            sandbox: "allow-popups allow-scripts",
            style: { display: "block" }
        },
        "keystone-video": {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" }
        },
        knightlab: {
            background: "#fff",
            sandbox: "allow-popups allow-scripts",
            style: { display: "block" },
            modUrl: ((embedUrl) => embedUrl.split("&lang=")[0] + "&lang=" + lang)(embedUrl)
        },
        nhl: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            modUrl: embedUrl.includes("embed") ? embedUrl : ((embedUrl) => {
                let splitArr = embedUrl.split("/video");
                return splitArr[0] + "/video/embed" + splitArr[1]
            })(embedUrl)
        },
        "sg.ch": {
            sandbox: "allow-popups allow-scripts",
            style: { display: "block" }
        },
        sketchfab: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" }
        },
        sportresultate: {
            sandbox: "allow-popups allow-scripts"
        },
        spotify: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" },
            allow: "encrypted-media"
        },
        "srf-player": {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            background: "#000"
        },
        srgssr: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            style: { display: "block" }
        },
        wilmaa: {
            sandbox: "allow-popups allow-scripts",
            background: "#002065"
        }
    }
    const { background, padding, sandbox, modular, modUrl, radius, style, allow } = providerProperties[provider] || {};

    return (
        <IFrameWrapper
            width={width}
            height={!modular ? height : null}
            background={background}
            padding={padding}
            radius={radius}
        >
            <iframe
                title={`${capitalize(provider)} Iframe`}
                height={modular ? null : padding ? (height - 2*padding) : height}
                width={padding ? (width - 2*padding) : width}
                allowFullScreen
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                referrerPolicy={"no-referrer"}
                allow={allow || null}
                sandbox={sandbox || "allow-popups"}
                style={style || null}
                src={modUrl || embedUrl}
            > </iframe>
        </IFrameWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(EmbedDefault);