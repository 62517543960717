export const calcNumColumns = (mainWidth) => {
    return mainWidth < 700 ? mainWidth < 500 ? 2 : 3 : 4;
}

export const calcCategoryHeight = (channelNumber, mainWidth) => {
    const staticHeight = mainWidth > 600 ? 150 : 125;
    const rowIndent = ((channelNumber, numColumns) => {
        const rows = Math.floor(channelNumber / numColumns);
        const leftOver = (channelNumber / numColumns) - rows;
        return leftOver ? rows : rows - 1;
    })(channelNumber, calcNumColumns(mainWidth))
    return (staticHeight + rowIndent * 100) + "px"
}

export const calcCategoryWidth = (channelNumber, mainWidth) => {
    const staticWidth = mainWidth > 600 ? 40 : 30;
    const numColumns = calcNumColumns(mainWidth);
    const gridElemWidth = (mainWidth - staticWidth - ((numColumns - 1) * 10)) / numColumns;

    return (numColumns === 4 ? mainWidth - 1.5 * gridElemWidth - 10 : mainWidth - 0.5 * gridElemWidth) + "px";
}

export const calcSubCategoryHeight = (channelNumber, mainWidth) => {
    const staticHeight = mainWidth > 600 ? 105 : 85;
    return (staticHeight + channelNumber * 75) + "px"
}