import styled from "styled-components";
import { color } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const ArticleActionContainer = styled.div`
    height: 40px;
    width: 40px;
    justify-content: center;
    flex-direction: column;
    top: ${props => (props.type === "list" && !props.beside) ? "50px" : "0"};
    right: ${props => props.beside ? "50px" : "0"};
    border-radius: 50%;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    position: absolute;
    margin: 15px;
    cursor: pointer;
    // box-shadow: 0 0 25px 0 rgba(220, 220, 220, 0.4);
    overflow: hidden;
    z-index: 2;
    
    &:hover {
        background-color: ${props => props.darkmode ? "rgba(26, 26, 26, 0.6)" : "rgba(256, 256, 256, 0.6)"};
        // box-shadow: 0 0 25px 0 rgba(220, 220, 220, 0.9);
    }
    &:active {
        transform: scale(0.95);
        transition: 0.1s;
    }
  
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }
    
    /*&::after{
        content: "";
        height: 40px;
        width: 40px;
        top: 0;
        right: 0;
        z-index: -1;
        position: absolute;
        border-radius: 50%;
        box-shadow: inset 0 0 80px ${color.gray2};
        background: inherit;
        filter: blur(4px);
    }*/
`;

export const ArticleActionIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 5;
`;