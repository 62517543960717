import React, { Fragment, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { HeaderContext } from "../../../context/header.context";
import { fetchChannelRefStart } from "../../../redux/channelRef/channelRef.actions";
import { selectChannelRefData, selectChannelRefFetching, selectChannelRefError } from "../../../redux/channelRef/channelRef.selectors";
import { fetchUserChannelsStart } from "../../../redux/userData/userData.actions";
import { selectChannelDataRaw, selectUserDataFetching, selectUserDataError } from "../../../redux/userData/userData.selectors";
import { selectUserId, selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { selectArticleArrayForPreview } from "../../../redux/articleData/articleData.selectors";
import { selectArticleRefArray } from "../../../redux/articleRef/articleRef.selectors";
import { selectFeedRefObj } from "../../../redux/sessionData/sessionData.selectors";

import Indicator from "../../../components/indicator/indicator.component";
import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const ChannelPage = ({ channelRef, fetchChannelRefStart, channelData, fetchUserChannelsStart, channelDataLoading,
                         userId, lang, channelRefData, channelRefLoading, articlePreviewList, channelRefError }) => {

    const { setChannelEnv } = useContext(HeaderContext);
    const { channelId, channelName, iconId, categoryRef } = channelRefData?.[channelRef] || {};

    useEffect(() => {
        if(!channelData && !channelDataLoading) fetchUserChannelsStart({ userId, lang })
        if(!channelRefData?.[channelRef]) fetchChannelRefStart({ userId, lang }, channelRef);
        else setChannelEnv({ channelRef, channelId, channelName });

        return () => setChannelEnv(null)
    }, [fetchChannelRefStart, userId, lang, channelRef, channelRefData, setChannelEnv])

    return (
        <section>
            {
                channelRefData?.[channelRef] ? (
                    <Fragment>
                        <SiteHead title={`${channelName} Channel`} data={{ shareUrl: `https://reavi.de/c/${channelId}` }}/>
                        <Indicator
                            pageLabel={channelName}
                            subLabel={"CHANNEL //"}
                            icon={iconId}
                            category={categoryRef}
                        />
                        <ArticleFeed
                            articlePreviewList={articlePreviewList}
                            infiniteLoader={() => fetchChannelRefStart({ userId, lang }, channelRef)}
                            loading={channelRefLoading}
                            error={channelRefError}
                            type={"standard"}
                            refType={channelId}
                        />
                    </Fragment>
                ) : null
            }
        </section>
    )
}

const mapStateToProps = (state, ownProps) => ({
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    channelRef: ownProps.match.params.channelId,
    channelRefData: selectChannelRefData(state),
    channelRefLoading: selectChannelRefFetching(state),
    channelRefError: selectChannelRefError(ownProps.match.params.channelId)(state),
    channelData: selectChannelDataRaw(state),
    channelDataLoading: selectUserDataFetching("channelData")(state),
    channelDataError: selectUserDataError("channelData")(state),
    articlePreviewList: selectArticleArrayForPreview(selectArticleRefArray(ownProps.match.params.channelId)(state))(state),
    feedRefObj: selectFeedRefObj(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchChannelRefStart: (reqHeaders, refKey) => dispatch(fetchChannelRefStart(reqHeaders, refKey)),
    fetchUserChannelsStart: (reqHeaders) => dispatch(fetchUserChannelsStart(reqHeaders))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPage);