import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectArticleList, selectArticleFetching } from "../../redux/articleData/articleData.selectors";
import { selectUserId, selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectUrlSearchResults, selectUserEventsLoading } from "../../redux/userEvents/userEvents.selectors";
import { performUrlSearchStart } from "../../redux/userEvents/userEvents.actions";
import { isValidUrl, compactArticleData } from "../general.utils";
import { color } from "../../design/theme";
import { miscData } from "../lang.data";

import { ModalUrlSearchInputWrapper, ModalUrlSearchButtonContainer, ModalUrlSearchResultContainer } from "./modalUrlSearch.styles";
import InputField from "../inputField/inputField.component";
import CustomButton from "../customButton/customButton.component";
import PreviewCard from "../previewCard/previewCard.component";
import ArticleText from "../articleContent/articleText/articleText.content";

const ModalUrlSearch = ({ darkmode, onDismiss, lang, userId, resultLoading, articleLoading,
                            performUrlSearchStart, articleList, urlSearchResults }) => {
    const[urlString, setUrlString] = useState("");
    const[initiated, setInitiated] = useState(false);
    const[articleId, setArticleId] = useState("");
    const[resetCount, setResetCount] = useState(0);
    const loading = resultLoading || articleLoading;
    const history = useHistory();

    useEffect(() => {
        let urlSearchResultId = urlSearchResults[urlString];
        if(urlSearchResultId === "unavailable" || (urlSearchResultId && articleList[urlSearchResultId])) {
            setArticleId(urlSearchResultId);
        }
    }, [articleList, initiated, urlSearchResults])

    const required = (value) => ((!value || value.length === 0) ? 'required' : undefined)
    const typeOfUrl = (value) => !isValidUrl(value) ? 'titleError' : undefined;

    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    const invalidSubmission = (invalid, loading, pristine) => {
        return (invalid || loading || pristine)
    }
    const onSubmit = async (values, form) => {
        let urlValue = values.url
        if(!initiated) {
            if (!urlSearchResults[urlValue]) performUrlSearchStart({ userId: userId, lang: lang }, values)
            setUrlString(urlValue)
            setInitiated(true);
        } else {
            setUrlString("");
            setInitiated(false);
            setArticleId("");
            form.resetFieldState("url");
            form.restart();
            setResetCount(resetCount + 1);
        }
    }

    return(
        <Fragment>
            <Form
                onSubmit={onSubmit}
                subscription={{ submitting: true, invalid: true, pristine: true, values: true }}
                keepDirtyOnReinitialize={false}
                render={({ handleSubmit, pristine, invalid, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalUrlSearchInputWrapper>
                            <Field
                                validate={composeValidators(required, typeOfUrl)}
                                subscription={{ touched: true, dirty: true, error: true, value: true, valid: true, initial: true, pristine: true }}
                                name={"url"}
                                component={InputField}
                                placeholder={"newspaper.ch/article"}
                                initialValue={""}
                                darkmode={darkmode}
                                reset={resetCount}
                                type={"text"}
                                checkType={true}
                                modal
                            />
                            <CustomButton
                                type={"submit"}
                                loading={loading}
                                spinIconOnLoad={"true"}
                                disabled={invalidSubmission(invalid, loading, pristine, values)}
                                color={color.blue}
                                icon={urlString ? "reload" : "search" }
                                width={50}
                                solid
                            />
                        </ModalUrlSearchInputWrapper>
                    </form>
                )}
            />
            {
                articleId ? (
                    <ModalUrlSearchResultContainer>
                        {
                            articleId === "unavailable" ? (
                                <ArticleText text={"No Match Found :("} italic auto />
                            ) : (
                                <PreviewCard
                                    article={compactArticleData(articleList[articleId])}
                                    minimal={"true"}
                                    modal={"true"}
                                />
                            )
                        }
                    </ModalUrlSearchResultContainer>
                ) : null
            }
            <ModalUrlSearchButtonContainer>
                <CustomButton
                    callback={() => onDismiss()}
                    desc={miscData.cancel[lang]}
                    color={darkmode ? color.gray4 : color.gray3}
                />
                <CustomButton
                    disabled={!articleList[articleId]}
                    callback={() => {
                        onDismiss()
                        history.push(`/${articleId}`)
                    }}
                    desc={miscData.read[lang]}
                    color={color.blue}
                    icon={"arrow"}
                    solid
                />
            </ModalUrlSearchButtonContainer>
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage,
    userId: selectUserId,
    urlSearchResults: selectUrlSearchResults,
    articleList: selectArticleList,
    articleLoading: selectArticleFetching,
    resultLoading: selectUserEventsLoading("userUrlSearchResults")
})

const mapDispatchToProps = (dispatch) => ({
    performUrlSearchStart: (reqHeaders, urlQuery) => dispatch(performUrlSearchStart(reqHeaders, urlQuery))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalUrlSearch);