import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import { fetchArticleRefStart } from "../../../redux/articleRef/articleRef.actions";
import { selectArticleArrayForPreview } from "../../../redux/articleData/articleData.selectors";
import { selectArticleRefArray, selectArticleRefFetching, selectArticleRefError } from "../../../redux/articleRef/articleRef.selectors";
import { selectUserLanguage, selectUserId } from "../../../redux/userData/userData.selectors";
import { titleData, navData } from "../../../components/lang.data";

import Indicator from "../../../components/indicator/indicator.component";
import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const PopularPage = ({ fetchArticleRefStart, articlePreviewList, lang, userId, loading, error }) => {

    useEffect(() => {
        if(!loading && !articlePreviewList.length) fetchArticleRefStart({ userId, lang }, "latest");

    }, [fetchArticleRefStart, userId, lang])

    return (
        <Fragment>
            <SiteHead title={navData.popular.desc[lang]} />
            <Indicator
                pageLabel={titleData.popular[lang]}
                pageLabelEmoji={"✨"}
            />
            {
                articlePreviewList.length ? (
                    <ArticleFeed
                        articlePreviewList={articlePreviewList}
                        infiniteLoader={() => fetchArticleRefStart({ userId, lang }, "latest")}
                        loading={loading}
                        error={error}
                        type={"standard"}
                        refType={"latest"}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    lang: selectUserLanguage(state),
    userId: selectUserId(state),
    loading: selectArticleRefFetching(state),
    articlePreviewList: selectArticleArrayForPreview(selectArticleRefArray("latest")(state))(state),
    error: selectArticleRefError("latest")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchArticleRefStart: (reqHeaders, refKey) => dispatch(fetchArticleRefStart(reqHeaders, refKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopularPage);