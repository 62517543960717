import styled from "styled-components";
import { color, font, radius } from "../../../design/theme";

export const ArticleVideoWrapper = styled.div`
    width: inherit;
    height: ${props => (props.mainWidth * 0.5625) + "px"};
    position: relative;
    margin: ${props => props.gallery ? "0" : "30px"} 0;
    background-color: ${props => props.darkmode ? color.black : color.white};
    border-radius: ${radius.smallRadius};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateZ(0);
  
    & > div {
        width: 100%;
        height: 100%;
    }
    
    --plyr-color-main: ${color.blue};
    --plyr-control-spacing: 12px;
    --plyr-video-background: ${props => props.darkmode ? color.black : color.white};
    --plyr-badge-background: ${color.gray2};
    --plyr-badge-text-color: ${color.white};
    --plyr-badge-border-radius: 4px;
    --plyr-control-radius: 5px;
    --plyr-menu-color: ${color.gray2};
    --plyr-menu-radius: 7px;
    --plyr-menu-arrow-size: 7px;
    --plyr-menu-item-arrow-color: ${color.gray2};
    --plyr-menu-item-arrow-size: 5px;
    --plyr-menu-border-color: ${color.gray2};
    --plyr-menu-border-shadow-color: transparent;
    --plyr-tooltip-background: ${color.white};
    --plyr-tooltip-color: ${color.gray2};
    --plyr-tooltip-radius: 4px;
    --plyr-tooltip-arrow-size: 7px;
    --plyr-font-family: ${font.pulpDisplayBold};
    --plyr-font-smoothing: true;
    --plyr-font-size-small: 15px;
`

export const ArticleVideoContainer = styled.video`
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
`