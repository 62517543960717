import React, { useState, useRef, useEffect, Fragment } from "react"

import { RenderControllerContainer, RenderControllerFallback } from "./renderController.styles";

const RenderController = ({ defaultHeight, visibleOffset = 600, children, darkmode, defaultFeed, id, onlyOnce }) => {
    const [isVisible, setIsVisible] = useState(null);
    const [isDefiniteVisible, setIsDefiniteVisible] = useState(null);
    const placeholderHeight = useRef(defaultHeight);
    const intersectionRef = useRef(null);

    useEffect(() => {
        if (intersectionRef.current) {
            const observer = new IntersectionObserver(entries => {
                if (typeof window !== undefined && window.requestIdleCallback) {
                    window.requestIdleCallback(
                        () => setIsVisible(entries[0].isIntersecting),
                        { timeout: 200 }
                    )
                } else setIsVisible(entries[0].isIntersecting)
            }, { root: document.querySelector("body"), rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px`, threshold: [0, 0.5] })

            observer.observe(intersectionRef.current)

            return () => intersectionRef.current && observer.unobserve(intersectionRef.current);
        }
    }, [intersectionRef])

    useEffect(() => {
        if(intersectionRef.current && isVisible) {
            placeholderHeight.current = intersectionRef.current.offsetHeight
        }
        if(isVisible && onlyOnce) setIsDefiniteVisible(true);
    }, [isVisible, intersectionRef])

    return (
        <Fragment>
            {
                !isDefiniteVisible ? (
                    <RenderControllerContainer ref={intersectionRef} id={id ?? null} as={onlyOnce ? "div" : "article"}>
                        {
                            isVisible ? (
                                <Fragment>
                                    {children}
                                </Fragment>
                            ) : (
                                <RenderControllerFallback
                                    defaultheight={placeholderHeight.current}
                                    defaultfeed={defaultFeed}
                                    darkmode={darkmode}
                                />
                            )
                        }
                    </RenderControllerContainer>
                ) : (
                    <Fragment>
                        {children}
                    </Fragment>
                )
            }
        </Fragment>
    )
}

export default RenderController;