import { getLangCode } from "../general.utils";

export const recognizeSpeech = (lang, eventCallback, resultCallback, setVoiceInstance) => {
    const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    let speechOccurred = false;

    try {
        const recognition = new speechRecognition();
        setVoiceInstance(recognition);

        recognition.continuous = false;
        recognition.lang = getLangCode(lang);
        recognition.interimResults = true;
        recognition.maxAlternatives = 1;

        recognition.start();
        setTimeout(() => !speechOccurred && eventCallback(false), 6000)

        recognition.onresult = (event) => {
            console.log("SPEECH RECOGNITION RESULT", event)
            speechOccurred = true;
            resultCallback(event.results[0][0].transcript, event.results[0].isFinal)
        }
        recognition.onspeechend = (event) => {
            console.log("FINISHED SPEECH", event)
            eventCallback(false)
            recognition.stop()
        }
        recognition.onaudiostart = (event) => {
            console.log("STARTED AUDIO", event)
            eventCallback(true)
        }
        recognition.audioend = (event) => {
            console.log("FINISHED AUDIO", event)
            eventCallback(false)
        }
        return {
            stop: recognition.stop,
        }
    } catch (e) {
        resultCallback("Speech Recognition Error")
    }
}


/*export const getVoiceStream = async () => {
    try {
        const sampleRate = 16000
        const stream = navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: "default",
                sampleRate: sampleRate,
                sampleSize: 16,
                channelCount: 1
            },
            video: false
        })
        class MyWorkletNode extends AudioWorkletNode {
            constructor(context) {
                super(context, "voice-worker");
            }
        }

        const audioContext = new AudioContext({ sampleRate: sampleRate })
        const source = audioContext.createMediaStreamSource(stream)

        await audioContext.resume();
        await audioContext.audioWorklet.addModule("/voice.worker.js").then(() => {
            let node = new MyWorkletNode(audioContext)
        });

        const voiceNode = new AudioWorkletNode(audioContext, 'voice-worker', {
            outputChannelCount: [1]
        })
        source.connect(voiceNode)

        const connection = new WebSocket("ws://localhost:8080/ws/stt")
        voiceNode.port.onmessage = event => connection.send(event.data)
        voiceNode.port.start()
    } catch (e) {

    }
}*/


/* // PUT IN SEPARATE FILE VOICE.WORKER.JS
const quantumSize = 128

class AudioProcessor extends AudioWorkletProcessor {
    constructor(options) {
        super()
        this.quantaPerFrame = 12
        this.quantaCount = 0
        this.frame = new Int16Array(quantumSize * this.quantaPerFrame)
    }

    process(inputs, outputs, parameters) {
        const offset = quantumSize * this.quantaCount
        inputs[0][0].forEach((sample, idx) => this.frame[offset + idx] = Math.floor(sample * 0x7fff))
        this.quantaCount = this.quantaCount + 1
        if (this.quantaCount === this.quantaPerFrame) {
            this.port.postMessage(this.frame)
            this.quantaCount = 0
        }

        const sourceLimit = Math.min(inputs.length, outputs.length);
        for (let inputNum = 0; inputNum < sourceLimit; inputNum++) {
            let input = inputs[inputNum];
            let output = outputs[0];
            let channelCount = Math.min(input.length, output.length);

            for (let channelNum = 0; channelNum < channelCount; channelNum++) {
                let sampleCount = input[channelNum].length;

                for (let i = 0; i < sampleCount; i++) {
                    let sample = output[channelNum][i] + input[channelNum][i];

                    if (sample > 1.0) {
                        sample = 1.0;
                    } else if (sample < -1.0) {
                        sample = -1.0;
                    }

                    output[channelNum][i] = sample;
                }
            }
        }
        return true
    }
}

registerProcessor("voice-worker", AudioProcessor)*/


/*
import React, { createElement, Fragment, useEffect, useRef, useState, useMemo } from "react";
import { render } from "react-dom";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";

import { searchClient } from "../../config/algolia.config";

const Autocomplete = (props) => {
    const [autocompleteState, setAutocompleteState] = useState({});

    const autocomplete = useMemo(() => {
        return createAutocomplete({
            onStateChange({ state }) {
                setAutocompleteState(state);
                console.log("STATE CHANGE IN AUTOCOMPLETE", state)
            },
            getSources({ query }) {
                return [
                    {
                        sourceId: 'querySuggestions',
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: 'reavideNews_query_suggestions',
                                        query,
                                    },
                                ],
                            });
                        },
                        getItemInputValue({ item }) {
                            return item.query;
                        },
                        // ...
                    },
                    {
                        sourceId: "predictions",
                        getItems() {
                            return getAlgoliaResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: "reavideNews", // EXCLUDE --> RETRIEVES ARTICLES, MAYBE IMPLEMENT FOR CHANNELS LATER
                                        query
                                    }
                                ],
                            });
                        },
                        getItemUrl({ item }) {
                            return item.query;
                        },
                    },
                ];
            },
        })
    },[]);

    // ...
    return (
        <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
            <input className="aa-Input" {...autocomplete.getInputProps({})} />
            <div className="aa-Panel" {...autocomplete.getPanelProps({})}>
                {autocompleteState.isOpen &&
                autocompleteState.collections.map((collection, index) => {
                    const { source, items } = collection;

                    return (
                        <div key={`source-${index}`} className="aa-Source">
                            {items.length > 0 && (
                                <ul className="aa-List" {...autocomplete.getListProps()}>
                                    {items.map((item) => (
                                        <li
                                            key={item.objectID}
                                            className="aa-Item"
                                            {...autocomplete.getItemProps({
                                                item,
                                                source,
                                            })}
                                        >
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Autocomplete;*/
