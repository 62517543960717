export const isBlockEligible = (historyDataBlock, index, articleList, preview) => {
    const checkIfBlockPopulated = (historyDataBlock) => {
        const { articleLogList } = historyDataBlock || {};

        if(Array.isArray(articleLogList)) {
            let logContentArr = []
            articleLogList.forEach(articleLog => {
                if (articleList?.[articleLog.articleId]) logContentArr.push(articleLog.articleId)
            })
            if (!logContentArr.length) return 0
            else return logContentArr.length === articleLogList.length
        } else return false
    }

    if(index === 0) return checkIfBlockPopulated(historyDataBlock) !== 0;
    else {
        let currentPopulation = checkIfBlockPopulated(historyDataBlock)
        let prevPopulation = checkIfBlockPopulated(preview[index - 1])
        return currentPopulation || (prevPopulation && currentPopulation !== 0)
    }
}