import styled from "styled-components";
import { color, radius, font } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleImageWrapper = styled.div`
    width: inherit;
    height: ${props => (props.mainWidth * 0.5625) + "px"};
    background-color: ${props => props.active ? props.darkmode ? color.gray2 : color.white : "transparent"};
    border-radius: ${radius.largeRadius};
    margin-top: ${props => !props.gallery ? "30px" : null};
    position: relative;
`

export const ArticleImageContainer = styled.div`
    height: ${props => props.active ? "100vh" : "inherit"};
    width: ${props => props.active ? "100vw" : "inherit"};
    position: ${props => props.active ? "fixed" : "relative"};
    backdrop-filter: blur(5px);
    justify-content: center; 
    vertical-align: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: ${props => props.active ? "4vh" : "0"};
    background-color: ${props => props.active ?  props.darkmode ? "rgba(26, 26, 26, 0.9)" : "rgba(256, 256, 256, 0.85)" : "transparent"};
    
    ${props => props.active ? "top: 0; left: 0; right: 0; bottom: 0; z-index: 999" : props.gallery ? "margin: 0" : "margin: 30px 0 0 0;"};

    @media only screen and (max-width: 750px) {
        padding: ${props => props.active ? "2vh" : "0"};
    }
`;

export const ArticleImagePictureContainer = styled.div`
    width: ${props => props.active && !props.widthOrient ? "auto" : "100%"};
    height: ${props => props.active && props.widthOrient ? "auto" : "100%"};
    border-radius: ${props => props.active ? radius.largeRadius : radius.smallRadius};
    overflow: hidden;
    background-color: ${props => props.active ? "transparent" : props.darkmode ? color.gray2 : color.white};
    cursor: ${props => !props.error ? props.active ? "zoom-out" : "zoom-in" : "default"};
    position: relative;

    @media only screen and (max-width: 750px) {
        border-radius: ${radius.smallRadius};
    }
`;

export const ArticleImageZoomIconContainer = styled.div`
    height: 45px;
    width: 45px;
    backdrop-filter: blur(8px);
    background-color: ${props => CSS.supports("backdrop-filter", "blur(8px)") ? props.darkmode ? "rgba(0, 0, 0, 0.45)" : "rgba(255, 255, 255, 0.45)" : props.darkmode ? color.gray2 : color.gray5};
    justify-content: center;
    flex-direction: column;
    top: 0;
    right: 0;
    border-radius: 50%;
    position: absolute;
    margin: 20px;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
`;

export const ArticleImageZoomIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    position: relative;
    transform: rotate(45deg);
    // opacity: ${props => props.darkmode ? 0.7 : 0.8};
    z-index: 5;
    
    &:hover {
        // opacity: ${props => props.darkmode ? 0.8 : 0.9}; // 0.4 : 0.5
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }
`;

export const ArticleImageZoomDescContainer = styled.div`
    width: auto;
    height: 36px;
    border-radius: ${radius.smallRadius};
    backdrop-filter: blur(8px);
    background-color: ${props => CSS.supports("backdrop-filter", "blur(8px)") ? props.darkmode ? "rgba(0, 0, 0, 0.45)" : "rgba(255, 255, 255, 0.45)" : props.darkmode ? color.gray2 : color.white};
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    display: block;
    padding: 7px 15px;
    bottom: 0;
    right: 0;
    position: absolute;
    cursor: text;
    text-align: right;
    margin: 20px;
    z-index: 1;
`;

export const ArticleImageDescContainer = styled.div`
    width: auto;
    height: auto;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    display: flex;
    padding: 7px 15px;
    margin-bottom: 30px;
`;

export const ArticleImageIconContainerDiv = styled.div`
    height: inherit;
    width: auto;
    justify-content: center;
    float: left;
    margin: 2px 10px 2px 0;
    display: inline-flex;
`;

export const ArticleImageIconContainer = styled(Icon)`
    width: auto;
    justify-content: center;
    cursor: pointer;
`;

export const ArticleImageDescTextContainer = styled.div`
    font-size: 16px;
    font-family: ${font.interMedium};
    color: inherit;
    height: 100%;
    width: auto;
  
    & > span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    & > div {
        line-height: 22px;
    }
`;