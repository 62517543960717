import React, { useEffect, useContext, useLayoutEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setTheme } from "../../redux/persistData/persistData.actions";
import { selectTheme } from "../../redux/persistData/persistData.selectors";
import { selectArticlePreview } from "../../redux/articleData/articleData.selectors";
import { LayoutContext } from "../../App";

import { MainContainer, MainScrollContainer } from "./main.styles";
import HomePage from "../main/homePage/home.page";
import PopularPage from "../main/popularPage/popular.page";
import ArticlePage from "../main/articlePage/article.page";
import ChannelOverview from "../main/channelOverview/channelOverview.page";
import ChannelSubscribed from "../main/channelSubscribed/channelSubscribed.page";
import ChannelPage from "../main/channelPage/channel.page";
import LibraryPage from "../main/libraryPage/library.page";
import Header from "../../components/header/header.component";
import SettingsPage from "../main/settingsPage/settings.page";
import AccountPage from "../main/accountPage/account.page";
import TimelinePage from "../main/timelinePage/timeline.page";
import ListView from "../main/listView/listView.page";
import HistoryPage from "../main/historyPage/history.page";
import SearchPage from "../main/searchPage/search.page";
import EmbedsSettingsPage from "../settings/embedsSubPage/embeds.settingsPage";

const MainWrapper = ({ setTheme, theme, articlePreview }) => {
    const { appSize, handleMainWidthAdjustment } = useContext(LayoutContext);
    const history = useHistory();

    const onChange = (event) => {
        event.preventDefault();
        if(theme === "auto") setTheme(theme)
    }

    useLayoutEffect(() => handleMainWidthAdjustment(), []);
    useEffect(() => {
        if(articlePreview?.articleId) history.replace(`/${articlePreview.articleId}`)
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", onChange)

        return () => window.removeEventListener("change", onChange)
    }, [])

    return (
        <MainContainer id={"#main"}>
            <Header />
            <MainScrollContainer>
                <Switch>
                    <Route exact path={"/home"} component={HomePage} />
                    <Route exact path={"/popular"} component={PopularPage} />
                    <Route exact path={"/explore"} component={ChannelOverview} />
                    <Route exact path={"/channels"} component={ChannelSubscribed} />
                    <Route exact path={"/account"} component={AccountPage} />

                    <Route exact path={"/settings"} component={SettingsPage} />
                    <Route exact path={"/settings/embeds"} component={EmbedsSettingsPage} />

                    <Route exact path={"/search"} component={SearchPage} />
                    <Route exact path={"/history"} component={HistoryPage} />
                    <Route path={"/library"} component={LibraryPage} />

                    {
                        (appSize >= 3) ? (
                            <Route exact path={"/timeline"} render={(props) => (
                                <TimelinePage {...props} appSize={appSize} />
                            )} />
                        ) : <Route exact path={"/login"} render={() => <Redirect to={"/home"} />} />
                    }

                    <Route exact path={"/login"} render={() => <Redirect to={"/home"} />} />
                    <Redirect exact from={"/login"} to={"/home"} />
                    <Redirect exact from={"/login/email"} to={"/home"} />
                    <Redirect exact from={"/signup"} to={"/home"} />
                    <Redirect exact from={"/signup/email"} to={"/home"} />
                    <Redirect exact from={"/"} to={"/home"} />

                    <Route exact path={"/list/:listId"} component={ListView} />
                    <Route exact path={"/channel/:channelId"} component={ChannelPage}/>
                    <Route exact path={"/:articleId"} component={ArticlePage} />

                    <Route render={() => <Redirect to={"/home?origin=404"} />} />
                </Switch>
            </MainScrollContainer>
        </MainContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    theme: selectTheme,
    articlePreview: selectArticlePreview
})

const mapDispatchToProps = (dispatch) => ({
    setTheme: (mode) => dispatch(setTheme(mode))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainWrapper);