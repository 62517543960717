import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { capitalize, textColor } from "../general.utils";
import { color } from "../../design/theme";

import { AttachmentContainer, AttachmentDesc, AttachmentIcon, AttachmentPreview, AttachmentCanvas } from "./attachment.styles";
import Checkbox from "../checkbox/checkbox.component";

const Attachment = ({ desc, icon, darkmode, previewData, previewType, previewError, callback, ...input }) => {

    return (
        <AttachmentContainer
            onClick={() => callback()}
            darkmode={darkmode}
        >
            <Checkbox
                type={"checkbox"}
                input={input}
            />
            <AttachmentDesc darkmode={darkmode}>
                <span>{desc}</span>
            </AttachmentDesc>
            {
                icon ? (
                    <AttachmentIcon
                        name={icon}
                        size={30}
                        color={textColor(darkmode)}
                    />
                ) : null
            }
            {
                previewType ? (
                    <AttachmentPreview>
                        {
                            (previewData && !previewError) ? (
                                <AttachmentCanvas alt={`${capitalize(previewType)} Preview`} src={previewData} />
                            ) : (
                                <AttachmentIcon
                                    name={previewError ? "error" : "updates"}
                                    size={18}
                                    color={darkmode ? color.gray4 : color.gray2}
                                />
                            )
                        }
                    </AttachmentPreview>
                ) : null
            }
        </AttachmentContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(Attachment);