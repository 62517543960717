import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { categoryContentColor } from "../general.utils";
import { selectChannelDataRaw, selectUserId } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { followChannel } from "../../redux/userEvents/userEvents.actions";

import {
    ChannelCardWrapper,
    ChannelCardContainer,
    ChannelCardActionIconContainer,
    ChannelCardTopicIcon,
    ChannelCardTopicContent,
    ChannelCardContent
} from "./channelCard.styles";
import Icon from "../../design/icons/icon.component";

const ChannelCard = ({ userId, channel, categoryId, channelDataRaw, followChannel, darkmode }) => {
    const history = useHistory();
    const { channelName, iconId, channelRef, channelId } = channel;

    const [followedChannel, setFollowedChannel] = useState(false);
    const [iconHover, setIconHover] = useState(false);
    const [cardHover, setCardHover] = useState(false);

    useLayoutEffect(() => {
        if(channelDataRaw?.[channelId]) setFollowedChannel(true)
        else setFollowedChannel(false)
    }, [channelDataRaw, channelId])

    const handleIconClick = (event) => {
        event.stopPropagation();
        followChannel(userId, channelId)
    }
    const handleIconHover = (event) => {
        event.stopPropagation();
        setIconHover(true);
        setCardHover(true);
    }
    const handleIconHoverLeave = (event) => {
        event.stopPropagation();
        setIconHover(false);
        setCardHover(false);
    }
    const handleCardHover = (event) => {
        event.stopPropagation();
        setCardHover(true);
    }
    const handleCardHoverLeave = (event) => {
        event.stopPropagation();
        setCardHover(false);
    }

    let timer;
    const handleChannelCardClick = (event) => {
        event.stopPropagation();
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => history.push(`/channel/${encodeURI(channelRef).toLowerCase()}`), 200);
    }
    const handleChannelCardDoubleClick = (/*event*/) => {
        // event.stopPropagation();
        clearTimeout(timer);
        followChannel(userId, channelId)
    }

    return (
        <ChannelCardWrapper
            onClick={(event) => handleChannelCardClick(event)}
            onDoubleClick={(event) => handleChannelCardDoubleClick(event)}
            onMouseOver={(event) => handleCardHover(event)}
            onMouseLeave={(event) => handleCardHoverLeave(event)}
            categoryId={categoryId}
            active={followedChannel}
            darkmode={darkmode}
            hover={cardHover}
        >
            <ChannelCardContainer active={followedChannel} categoryId={categoryId} darkmode={darkmode}>
                <ChannelCardActionIconContainer
                    onClick={(event) => handleIconClick(event)}
                    onMouseOver={(event) => handleIconHover(event)}
                    onMouseLeave={(event) => handleIconHoverLeave(event)}
                    categoryId={categoryId}
                >
                    <Icon
                        name={(followedChannel && iconHover) ? "remove" : followedChannel ? "check" : "add"}
                        size={30}
                        color={categoryContentColor(categoryId, darkmode)}
                        hover={followedChannel ? false : iconHover}
                    />
                </ChannelCardActionIconContainer>
                <ChannelCardContent>
                    <ChannelCardTopicIcon
                        name={iconId}
                        size={25}
                        color={categoryContentColor(categoryId, darkmode)}
                    />
                    <ChannelCardTopicContent>
                        <span>{ channelName }</span>
                    </ChannelCardTopicContent>
                </ChannelCardContent>
            </ChannelCardContainer>
        </ChannelCardWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    darkmode: selectDarkmode,
    channelDataRaw: selectChannelDataRaw
})

const mapDispatchToProps = (dispatch) => ({
    followChannel: (userId, channelId) => dispatch(followChannel(userId, channelId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelCard);