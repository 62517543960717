import styled from "styled-components";
import { textColor } from "../general.utils";
import { color, radius, font } from "../../design/theme";

export const AccountHighlightContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    border-radius: ${radius.largeRadius};
    text-align: left;
    position: relative;
    font-family: ${font.interBold};
    padding: ${props => props.reduced ? "15px 15px 15px 20px" : "20px 20px 20px 25px"};
    overflow: hidden;
`;

export const AccountHighlightTitleContainer = styled.div`
    color: ${props => textColor(props.darkmode)};
    font-size: ${props => props.reduced ? "18px" : "25px"};
    line-height: ${props => props.reduced ? "22px" : "24px"};
`;

export const AccountHighlightNumContainer = styled.div`
    color: ${props => props.color};
    position: absolute;
    margin: 20px 20px 16px 25px;
    white-space: nowrap;
    bottom: 0;
    left: 0;
`

export const AccountHighlightNumTrend = styled.span`
    display: inline-block;
    line-height: 40px;
    font-size: ${props => (props.length >= 3) ? (props.length >= 5) ? "30px" : "40px" : "60px"};
`

export const AccountHighlightNumStandard = styled.span`
    display: inline-block;
    line-height: 40px;
    font-size: ${props => props.reduced ? (props.length >= 4) ? (props.length >= 5) ? "25px" : "35px" : "45px" : (props.length >= 4) ? (props.length >= 5) ? "40px" : "50px" : "60px"};
`

export const AccountHighlightNumExtra = styled.span`
    font-size: ${props => (props.length >= 4) ? "30px" : "38px"};
    line-height: 40px;
    display: inline-block;
`;