import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const NoticeContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: ${props => props.auto ? "auto" : props.size ? "34px" : "50px"};
    border-radius: ${props => props.size ? "10px" : radius.smallRadius};
    justify-content: center;
    padding: ${props => props.auto ? "10px 20px 10px 15px" : "7px 8px 7px 15px"};
    text-align: center;
    align-items: center;
    background-color: ${props => props.type === "error" ? props.darkmode ? color.redActiveDark : color.redActiveLight : props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    color: ${props => props.type === "error" ? color.red : color.blue};
    // box-shadow: inset 0 0 0 ${props => props.size ? "2px" : "3px"} ${props => props.type === "error" ? color.red : color.blue};
    font-family: ${props => props.size ? font.pulpDisplayBold : font.pulpDisplayMedium};
    font-size: ${props => props.size ? "16px" : "20px"};
    line-height: 20px;
    letter-spacing: 0.015em;
    display: flex;
    flex-direction: row;
    margin: ${props => props.feed ? "-10px 0 20px 0" : "15px 0"};
  
    &>button {
        margin-left: auto;
    }

    @media only screen and (max-width: 750px) {
      font-size: ${props => props.size ? "16px" : "18px"};
    }
`;

export const NoticeContentContainer = styled.div`
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    text-align: left;
    padding-right: 10px;
`

export const NoticeIcon = styled(Icon)`
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
`