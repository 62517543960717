import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { publishedDate } from "../general.utils";
import { miscData } from "../lang.data";

import {
    ArticleInfoWrapper,
    ArticleInfoContainer,
    ArticleInfoIconContainer,
    ArticleInfoIcon,
    ArticleInfoTextWrapper,
    ArticleInfoTextContainer,
    ArticleInfoTextAuthor,
    ArticleInfoHighlight
} from "./articleInfo.styles";
import { extractAuthors } from "./articleInfo.utils";

const ArticleInfo = ({ infoProps, darkmode, lang }) => {
    const { publisher, author, originalUrl, publishedAt, updatedAt, readingTime, type, numUpdates } = infoProps || {};

    const usedDate = updatedAt ? updatedAt : publishedAt;
    const authorArr = extractAuthors(author, lang);

    const getReadingTime = (readingTime) => {
        if(readingTime <= 1) return "< 1"
        let baseTime = (readingTime % 1);
        if(baseTime >= 0.7) return `< ${readingTime - (readingTime % 1) + 1}`
        if(baseTime > 0.3) return `> ${readingTime - (readingTime % 1)}`
        return `${readingTime - (readingTime % 1)}`
    }

    return (
        <ArticleInfoWrapper>
            <ArticleInfoContainer>
                <ArticleInfoIconContainer>
                    <a
                        href={`${originalUrl}`}
                        target={"_blank"}
                        rel={"external noopener"}
                        type={"text/html"}
                        media={"screen"}
                    >
                        <ArticleInfoIcon
                            name={type === "preview" ? "about" : "info"}
                            size={25}
                            color={color.blue}
                            link={"true"}
                        />
                    </a>
                </ArticleInfoIconContainer>
                <ArticleInfoTextWrapper>
                    <ArticleInfoTextContainer darkmode={darkmode}>
                        <span>
                            {
                                publisher ? (
                                    <Fragment>
                                        <span>{`${publisher}`}</span>
                                        <ArticleInfoHighlight>{" // "}</ArticleInfoHighlight>
                                    </Fragment>
                                ) : null
                            }
                            {
                                authorArr.map(({ display, type, search, excludePreSpace }, index) => (
                                    type === "author" ? (
                                        <ArticleInfoTextAuthor
                                            key={index}
                                            to={`/search?q=${search}&a=author`}
                                            darkmode={darkmode}
                                        >
                                            {display}
                                        </ArticleInfoTextAuthor>
                                    ) : (
                                        <span key={index}>
                                            {(excludePreSpace ? "" : " ") + display + (index < authorArr.length - 1 ? " " : "")}
                                        </span>
                                    )
                                ))
                            }
                            <ArticleInfoHighlight> &#8212; </ArticleInfoHighlight>
                            <time dateTime={usedDate}>
                                {`${publishedDate(usedDate, lang)}${updatedAt ? ` (${miscData.updated[lang]})` : ""}`}
                            </time>
                        </span>
                    </ArticleInfoTextContainer>
                </ArticleInfoTextWrapper>
            </ArticleInfoContainer>
            {
                type !== "preview" ? (
                    <ArticleInfoContainer>
                        <ArticleInfoIcon
                            name={type === "ticker" ? "updates" : "timer"}
                            size={25}
                            color={color.blue}
                        />
                        <ArticleInfoTextWrapper>
                            <ArticleInfoTextContainer
                                darkmode={darkmode}
                                round={!(getReadingTime(readingTime).includes("<") || getReadingTime(readingTime).includes(">"))}
                            >
                                {
                                    type === "ticker" ? (
                                        <Fragment>
                                            <span>{miscData.constUpdated[lang]}</span>
                                            {
                                                numUpdates ? (
                                                    <span>{`${numUpdates} ${miscData.updates[lang]}`}</span>
                                                ) : null
                                            }
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <span>{`${miscData.readingTime[lang]}:`}</span>
                                            <span>{`${getReadingTime(readingTime)} min`}</span>
                                        </Fragment>
                                    )
                                }
                            </ArticleInfoTextContainer>
                        </ArticleInfoTextWrapper>
                    </ArticleInfoContainer>
                ) : null
            }
        </ArticleInfoWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(ArticleInfo);