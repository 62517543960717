const UserEventsActionTypes = {
    BOOKMARK_ARTICLE: "BOOKMARK_ARTICLE",
    LIST_ARTICLE: "LIST_ARTICLE",
    FOLLOW_CHANNEL: "FOLLOW_CHANNEL",
    SET_LIST_PIN: "SET_LIST_PIN",
    LOG_ARTICLE_READ: "LOG_ARTICLE_READ",
    SET_TARGET_READS: "SET_TARGET_READS",
    LOG_SHARED_CONTENT: "LOG_SHARED_CONTENT",
    SEND_FEEDBACK: "SEND_FEEDBACK",
    DELETE_SEARCH: "DELETE_SEARCH",

    UPLOAD_IMAGE_START: "UPLOAD_IMAGE_START",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

    DELETE_IMAGE_START: "DELETE_IMAGE_START",
    DELETE_IMAGE_SUCCESS: "DELETE_IMAGE_SUCCESS",
    DELETE_IMAGE_FAILURE: "DELETE_IMAGE_FAILURE",

    SET_LIST_START: "SET_LIST_START",
    SET_LIST_SUCCESS: "SET_LIST_SUCCESS",
    SET_LIST_FAILURE: "SET_LIST_FAILURE",

    DELETE_LIST_START: "DELETE_LIST_START",
    DELETE_LIST_SUCCESS: "DELETE_LIST_SUCCESS",
    DELETE_LIST_FAILURE: "DELETE_LIST_FAILURE",

    LOG_RECENT_ACCESS_START: "LOG_RECENT_ACCESS_START",
    LOG_RECENT_ACCESS_SUCCESS: "LOG_RECENT_ACCESS_SUCCESS",
    LOG_RECENT_ACCESS_FAILURE: "LOG_RECENT_ACCESS_FAILURE",

    PERFORM_SEARCH_START: "PERFORM_SEARCH_START",
    PERFORM_SEARCH_SUCCESS: "PERFORM_SEARCH_SUCCESS",
    PERFORM_SEARCH_FAILURE: "PERFORM_SEARCH_FAILURE",

    PERFORM_URL_SEARCH_START: "PERFORM_URL_SEARCH_START",
    PERFORM_URL_SEARCH_SUCCESS: "PERFORM_URL_SEARCH_SUCCESS",
    PERFORM_URL_SEARCH_FAILURE: "PERFORM_URL_SEARCH_FAILURE"
}

export default UserEventsActionTypes;