import React from "react";

import {
    ArticleListWrapper,
    ArticleListContainer,
} from "./articleList.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleList = ({ content }) => {
    const { body, type } = content || {};

    return (
        <ArticleListWrapper>
            {
                body.length ? body.map(({ text }, index) => (
                    <ArticleListContainer key={index} type={type} index={(index + 1)}>
                        <ArticleText text={text} />
                    </ArticleListContainer>
                )) : null
            }
        </ArticleListWrapper>
    )
}

export default ArticleList;