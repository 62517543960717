import ArticleRefActionTypes from "./articleRef.types";

export const fetchArticleRefStart = (reqHeaders, refKey) => ({
    type: ArticleRefActionTypes.FETCH_ARTICLE_REF_START,
    payload: reqHeaders,
    refKey: refKey
})
export const fetchArticleRefSuccess = (articleRefList, articleRefPage, refKey) => ({
    type: ArticleRefActionTypes.FETCH_ARTICLE_REF_SUCCESS,
    payload: { articleRefList, articleRefPage },
    refKey: refKey
})
export const fetchArticleRefFailure = (errorMessage, refKey) => ({
    type: ArticleRefActionTypes.FETCH_ARTICLE_REF_FAILURE,
    payload: errorMessage,
    refKey: refKey
})