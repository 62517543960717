import React, { createContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectRecentAccess } from "../redux/userData/userData.selectors";
import { selectUserId, selectListsDataRaw, selectHistoryData, selectHistoryDataFetchKey, selectUserDataFetching } from "../redux/userData/userData.selectors";
import { selectArticleList, selectArticleFetching } from "../redux/articleData/articleData.selectors";
import { fetchUserAccessedStart, fetchUserHistoryStart, fetchUserListsStart } from "../redux/userData/userData.actions";
import { fetchArticleDataStart } from "../redux/articleData/articleData.actions";
import { uniqBy, pick } from "lodash";

export const RecentContext = createContext(null);

const RecentContextProvider = ({ userId, recentAccess, fetchUserAccessedStart, listsData, articleList,
                                   fetchUserListsStart, fetchUserHistoryStart, fetchArticleDataStart,
                                     fetchKey, historyData, children, listsLoading, articlesLoading }) => {

    const [initialized, setInitialized] = useState(false);
    const [recentList, setRecentList] = useState(null);

    const handleRecentList = (uniqAccess, uniqHistory) => {
        let uniqRefHistory = uniqHistory.map(historyItem => {
            return Object.assign({}, historyItem, { type: "article" })
        })
        let uniqRefAccess = uniqAccess.map(accessItem => {
            return Object.assign({}, accessItem, { type: "list" })
        })
        let recentRefList = [...uniqRefHistory, ...uniqRefAccess];
        let resultList = [];
        recentRefList.forEach(recentItem => {
            if(recentItem.type === "article" && articleList?.[recentItem.articleId]) {
                resultList.push(recentItem)
            } else if(recentItem.type === "list" && listsData?.[recentItem.listId]) {
                resultList.push(Object.assign({}, recentItem, listsData?.[recentItem.listId]))
            }
        })
        if(resultList.length === recentRefList.length) setRecentList(resultList);

        console.log("CALC RECENT CONTEXT :: ", resultList, "?==", recentRefList, "__from: ", uniqAccess, uniqHistory)
    }

    const handleContentRefs = (uniqAccess, uniqHistory, accessCut, historyCut) => {
        let uniqFetchAccess = uniqAccess.slice(0, accessCut)
        let uniqFetchHistory = uniqHistory.slice(0, historyCut);

        let uniqFilteredAccess = uniqFetchAccess.filter(listRef => !listsData?.[listRef.listId]);
        let uniqFilteredHistory = uniqFetchHistory.filter(histRef => !articleList?.[histRef.articleId])

        if(uniqFilteredAccess.length && !listsLoading) {
            fetchUserListsStart(userId, uniqFilteredAccess.map(listRef => listRef.listId), "recent");
        } else console.log("ACCESS PREVENTED :: LISTS", uniqFilteredAccess.length, listsLoading)
        if(uniqFilteredHistory.length && !articlesLoading && (window.location.pathname !== "/history")) {
            fetchArticleDataStart("", uniqFilteredHistory.map(articleRef => pick(articleRef, "articleId")));
        } else console.log("ACCESS PREVENTED :: ARTICLES", uniqFilteredHistory.length, articlesLoading)
        handleRecentList(uniqFetchAccess, uniqFetchHistory)
    }

    const recentInitialize = () => {
        setInitialized(true);
    }

    useEffect(() => {
        if (recentAccess && historyData) {
            let uniqAccess = uniqBy(recentAccess, "listId");
            let uniqHistory = uniqBy(historyData, "articleId");

            console.log("RECENT ACCESS", recentAccess, historyData)
            console.log("UNIQ ACCESS", uniqAccess, uniqHistory)

            if (uniqHistory.length >= 2 && uniqAccess.length >= 2) {
                handleContentRefs(uniqAccess, uniqHistory, 2, 2)
            } else if (uniqHistory.length >= 1 && uniqAccess.length >= 1 && (uniqHistory.length + uniqAccess.length >= 4)) {
                if (uniqHistory.length === 1) handleContentRefs(uniqAccess, uniqHistory, 3, 1)
                else if (uniqAccess.length === 1) handleContentRefs(uniqAccess, uniqHistory, 1, 3)
            }
        }
    }, [articleList, recentAccess, historyData, listsData])

    useEffect(() => {
        if(initialized && !recentAccess) fetchUserAccessedStart(userId)
    }, [recentAccess, userId, initialized])
    useEffect(() => {
        if(initialized && !historyData && !fetchKey) fetchUserHistoryStart(userId, "recent")
    }, [fetchKey, userId, historyData, initialized])

    return (
        <RecentContext.Provider value={{ recentList, recentInitialize }}>
            { children }
        </RecentContext.Provider>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    recentAccess: selectRecentAccess,
    articleList: selectArticleList,
    listsData: selectListsDataRaw,
    historyData: selectHistoryData,
    fetchKey: selectHistoryDataFetchKey,
    articlesLoading: selectArticleFetching,
    listsLoading: selectUserDataFetching("listsData")
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserAccessedStart: (userId) => dispatch(fetchUserAccessedStart(userId)),
    fetchUserListsStart: (userId, listId, fetchKey) => dispatch(fetchUserListsStart(userId, listId, fetchKey)),
    fetchUserHistoryStart: (userId, fetchKey) => dispatch(fetchUserHistoryStart(userId, fetchKey)),
    fetchArticleDataStart: (collectionKey, articleArr) => dispatch(fetchArticleDataStart(collectionKey, articleArr))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecentContextProvider);