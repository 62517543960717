import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { titleData } from "../../../components/lang.data";

import { LibraryPageContainer } from "./library.styles";
import Indicator from "../../../components/indicator/indicator.component";
import BookmarksSubPage from "../../sub/bookmarksSubPage/bookmarks.subPage";
import ListsSubPage from "../../sub/listsSubPage/lists.subPage";

const LibraryPage = ({ }) => {
    return (
        <LibraryPageContainer>
            <Indicator pageLabel={titleData.library} subNav />
            <Switch>
                <Redirect exact from={"/library"} to={"/library/lists"} />
                <Route exact path={"/library/lists"} component={ListsSubPage} />
                <Route exact path={"/library/bookmarks"} component={BookmarksSubPage} />

                <Redirect to={"/home?origin=404"} />
            </Switch>
        </LibraryPageContainer>
    )
}

export default LibraryPage;