import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
    selectBookmarkDataForPreview,
    selectBookmarkDataRaw,
    selectUserDataFetching,
    selectUserDataError,
    selectUserLanguage,
    selectUserId
} from "../../../redux/userData/userData.selectors";
import { selectArticleArrayForPreview } from "../../../redux/articleData/articleData.selectors";
import { fetchUserBookmarksStart } from "../../../redux/userData/userData.actions";
import { fetchArticleDataStart } from "../../../redux/articleData/articleData.actions";
import { titleData } from "../../../components/lang.data";

import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import InteractionBar from "../../../components/interactionBar/interactionBar.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const BookmarksSubPage = ({ articlePreviewList, loading, bookmarkData, fetchArticleDataStart, error,
                              fetchUserBookmarksStart, userId, bookmarkPreview, articleRefList, lang }) => {

    const [reload, setReload] = useState(false);
    const [orderedList, setOrderedList] = useState(articlePreviewList);

    useEffect(() => {
        const reloadReset = reload ? setTimeout(() => setReload(false), 500) : null
        if(!bookmarkData) fetchUserBookmarksStart(userId);
        if(!loading && bookmarkData) fetchArticleDataStart("bookmarks");

        return () => clearTimeout(reloadReset)
    }, [fetchArticleDataStart, reload, bookmarkData])

    return (
        <Fragment>
            <SiteHead title={titleData.library.bookmarks.desc[lang]} />
            {
                (!error) ? (
                    <Fragment>
                        <InteractionBar
                            type={"articles"}
                            subType={"bookmarks"}
                            orderCallback={setOrderedList}
                            orderData={articlePreviewList}
                        />
                        {
                            (bookmarkPreview?.length) ? (
                                <ArticleFeed
                                    articlePreviewList={orderedList}
                                    articleRefList={articleRefList}
                                    infiniteLoader={() => fetchArticleDataStart("bookmarks")}
                                    refType={"bookmarks"}
                                    loading={loading}
                                    type={"image"}
                                />
                            ) : (loading !== undefined && !loading && bookmarkData) ? (
                                <ErrorDisplay type={"noBookmarks"} link={"popular"} icon={"110015"} />
                            ) : null
                        }
                    </Fragment>
                ) : <ErrorDisplay type={"contentError"} callback={setReload} />
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    articlePreviewList: selectArticleArrayForPreview(selectBookmarkDataForPreview(state))(state),
    articleRefList: selectBookmarkDataForPreview(state),
    loading: selectUserDataFetching("bookmarkData")(state),
    bookmarkData: selectBookmarkDataRaw(state),
    bookmarkPreview: selectBookmarkDataForPreview(state),
    error: selectUserDataError("bookmarkData")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchArticleDataStart: (refKey) => dispatch(fetchArticleDataStart(refKey)),
    fetchUserBookmarksStart: (userId) => dispatch(fetchUserBookmarksStart(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksSubPage);