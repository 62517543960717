export const calcNumCatchlines = (titleHeight, mainWidth) => {
    const expanded = mainWidth > 600;
    const lineHeight = expanded ? 24 : 18
    const numTitleLines = Math.floor(titleHeight / lineHeight)

    if(numTitleLines >= (expanded ? 6 : 8)) return 0
    return 7 - numTitleLines

    // EXPAND (at height 300) || COLLAPSE (at height 270)
    // t: 5 --> catch: 2
    // t: 4 --> catch: 3
    // t: 3 --> catch: 4
    // t: 2 --> catch: 5
    // t: 1 --> catch: 6

    // COLLAPSE (at height 300)
    // t: 7 --> catch: 2
    // t: 6 --> catch: 3
    // t: 5 --> catch: 4
    // t: 4 --> catch: 5
    // t: 3 --> catch: 6
    // t: 2 --> catch: 7
    // t: 1 --> catch: 8
}