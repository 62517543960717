import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleImageWrapper,
    ArticleImageContainer,
    ArticleImagePictureContainer,
    ArticleImageDescTextContainer,
    ArticleImageZoomIconContainer,
    ArticleImageZoomIcon,
    ArticleImageZoomDescContainer
} from "./articleImage.styles";
import ArticleCaption from "../articleCaption/articleCaption.content";
import DynamicImage from "../../dynamicImage/dynamicImage.component";
import ArticleText from "../articleText/articleText.content";
import RenderController from "../../renderController/renderController.component";

const ArticleImage = ({ content, darkmode, gallery, setEnv, removed, children }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [active, setActive] = useState(false);
    const [imgContext, setImgContext] = useState({ error: false, widthOrient: false });
    const ref = useRef(null);

    const { caption, copyright, imageUrl, imageId, type } = content || {};

    const handleOnClick = (event, type) => {
        event.stopPropagation();
        if((type === "scroll" && active) || !type) setActive(!active);
    }

    useEffect(() => {
        if(gallery) setEnv({ mediaAction: () => setActive(!active), mediaZoom: active });
        return gallery ? () => setEnv(null) : null
    }, [active])

    return (
        <Fragment>
            <ArticleImageWrapper darkmode={darkmode} active={active} mainWidth={mainWidth} gallery={gallery}>
                <ArticleImageContainer
                    active={active}
                    darkmode={darkmode}
                    onClick={(!imgContext.error && imgContext.success && !removed && active) ? (event) => handleOnClick(event) : null}
                    onWheel={(!imgContext.error && imgContext.success && !removed) ? (event) => handleOnClick(event, "scroll") : null}
                    gallery={gallery}
                    ref={ref}
                >
                    <ArticleImagePictureContainer
                        onClick={(!imgContext.error && imgContext.success && !removed) ? (event) => handleOnClick(event) : null}
                        title={(copyright && !active && !imgContext.error) ? `© ${copyright}` : null}
                        active={active}
                        darkmode={darkmode}
                        widthOrient={imgContext.widthOrient}
                        error={imgContext.error || !imgContext.success || removed}
                    >
                        <RenderController onlyOnce={"true"}>
                            <DynamicImage
                                imageId={imageId}
                                fallbackUrl={imageUrl}
                                fullscreen={active}
                                imagetype={type}
                                callback={(state) => setImgContext({ ...imgContext, ...state })}
                                removed={removed}
                                // resize={"true"}
                            />
                        </RenderController>
                        {
                            active ? (
                                <Fragment>
                                    <ArticleImageZoomIconContainer darkmode={darkmode}>
                                        <ArticleImageZoomIcon
                                            name={"add"}
                                            color={darkmode ? color.gray4 : color.gray2}
                                            size={30}
                                            darkmode={darkmode}
                                        />
                                    </ArticleImageZoomIconContainer>
                                    {
                                        copyright ? (
                                            <ArticleImageZoomDescContainer
                                                onClick={(event) => event.stopPropagation()}
                                                darkmode={darkmode}
                                            >
                                                <ArticleImageDescTextContainer>
                                                    <ArticleText size={16} text={`© ${copyright}`} narrow />
                                                </ArticleImageDescTextContainer>
                                            </ArticleImageZoomDescContainer>
                                        ) : null
                                    }
                                </Fragment>
                            ) : null
                        }
                    </ArticleImagePictureContainer>
                    { children }
                </ArticleImageContainer>
            </ArticleImageWrapper>
            {
                (caption && !gallery) ? (
                    <ArticleCaption
                        caption={caption}
                        callback={(!imgContext.error && imgContext.success && !removed) ? () => setActive(!active) : null}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleImage);