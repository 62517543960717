export const extractAuthors = (string, lang) => {
    const initialArr = [];
    const resultArr = [];
    let numAuthors = 0;
    
    const conjunctions = ["und", "and", "et"];
    const conjunctionsMap = { de: "und", en: "and", fr: "et" };
    const returnObj = (display, type, excludePreSpace, search) => ({ search, display, type, excludePreSpace })

    if(!string || typeof string !== "string") return [];
    if(!/[(),\/]/g.test(string) && !conjunctions.some(conjunction => string.includes(conjunction))) {
        return [returnObj(string, "author", true, string)]
    }
    let splitArr = string.replace(/<[^>]*>?/gm, "").split(/\b(?:und|and|et)\b|[,\/]/g).filter(e => e);

    const scanForCity = (trimSplitToScan, trimSplit) => {
        if(trimSplitToScan.includes(" ") || trimSplitToScan.includes(".")) {
            numAuthors++;
            initialArr.push(returnObj(trimSplit, "author", true, trimSplitToScan))
        }
        else initialArr.push(returnObj(trimSplit, "city", true))
    }

    try {
        splitArr.forEach(splitPart => {
            let trimSplit = splitPart.trim();
            if (trimSplit.includes("(")) {
                let trimSplitPure = trimSplit.split("(")[0].trim();
                scanForCity(trimSplitPure, trimSplit)
            } else {
                scanForCity(trimSplit, trimSplit)
            }
        })

        let numAuthorsInserted = 0;
        let splitReserve = [];
        initialArr.forEach(initialSplit => {
            const { type, display } = initialSplit || {};
            if (type === "author") {
                if (!display.includes(".") || numAuthors === 1) {
                    if (numAuthors > 1 && numAuthorsInserted !== 0) {
                        const conjunction = numAuthorsInserted + 1 !== numAuthors ? "," : conjunctionsMap[lang]
                        resultArr.push(returnObj(conjunction, "conjunction", conjunction === ","));
                    }
                    resultArr.push(initialSplit);
                    if (splitReserve.length) {
                        resultArr[resultArr.length - 1].display += ` (${splitReserve[0]})`
                        splitReserve = [];
                    }
                    numAuthorsInserted++;
                } else {
                    numAuthors--;
                    if (resultArr.length) resultArr[resultArr.length - 1].display += ` (${display})`
                    else splitReserve[0] = (display);
                }
            } else if (type === "city") {
                resultArr.push(returnObj(",", "conjunction", true));
                resultArr.push(initialSplit);
            }
        })
    } catch (error) {
        resultArr.push(returnObj(string, "conjunction", true))
    }
    return resultArr;
}


/*
// SIMPLE V1

export const extractAuthors = (string, lang) => {
    const returnArr = [];
    if(!string || typeof string !== "string") return [];

    const conjunctions = ["und", "and", "et"];
    const conjunctionsMap = { de: "und", en: "and", fr: "et" };
    const returnObj = (display, type, excludePreSpace, search) => ({ search, display, type, excludePreSpace })

    if(!/[(),]/g.test(string) && !conjunctions.some(conjunction => string.includes(conjunction))) {
        return [returnObj(string, "author", true, string)]
    }
    let splitArr = string.replace(/<[^>]*>?/gm, "").split(/\b(und|and|et)\b|(,)/g).filter(e => e);

    const scanForCity = (trimSplitToScan, trimSplit) => {
        if(trimSplitToScan.includes(" ") || trimSplitToScan.includes(".")) {
            returnArr.push(returnObj(trimSplit, "author", true, trimSplitToScan))
        }
        else returnArr.push(returnObj(trimSplit, "city", true))
    }

    splitArr.forEach((splitPart) => {
        let trimSplit = splitPart.trim();
        if(conjunctions.some(conjunction => conjunction === trimSplit) || trimSplit === ",") {
            returnArr.push(returnObj(trimSplit, "conjunction", trimSplit === ","))
        } else if(trimSplit.includes("(")) {
            let trimSplitPure = trimSplit.split("(")[0].trim();
            scanForCity(trimSplitPure, trimSplit)
        } else {
            scanForCity(trimSplit, trimSplit)
        }
    })
    return returnArr;
}
*/