import React, { useState, useLayoutEffect, useRef, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import smartcrop from "smartcrop";

import { selectImageRefObj } from "../../redux/sessionData/sessionData.selectors";
import { setImageRef } from "../../redux/sessionData/sessionData.actions";
import { generateImageUrl, canUseWebP } from "../general.utils";
import { color } from "../../design/theme";

import {
    DynamicImageWrapper,
    DynamicImagePicture,
    DynamicImageStatusIcon
} from "./dynamicImage.styles";

const DynamicImage = ({ imageId, fallbackUrl, fullscreen, resize, callback, imageRefObj, setImageRef, removed, imageType }) => {

    const [validUrl, setValidUrl] = useState("");
    const [dataUrl, setDataUrl] = useState("");
    const [onLoadDefErr, setOnLoadDefErr] = useState(false);
    const [onLoadSuccess, setOnLoadSuccess] = useState(false);
    const [onLoadUnknown, setOnLoadUnknown] = useState(false);
    const [crossOriginErr, setCrossOriginErr] = useState(false);
    const [imgDimensions, setImgDimensions] = useState([0, 0]);
    const [widthOrient, setWidthOrient] = useState(null);
    const [fullscreenSwitch, setFullscreenSwitch] = useState(null);
    const containRef = useRef(null);
    const imageRef = useRef(null);

    const getWidthOrient = (aspectRatio) => {
        if(imageRef.current && widthOrient === null) {
            const calcNode = imageRef.current;
            const windowAspectRatio = (window.innerWidth / window.innerHeight);
            const imageAspectRatio = aspectRatio ?? (calcNode.naturalWidth / calcNode.naturalHeight);

            if (windowAspectRatio < imageAspectRatio) {
                setWidthOrient(true);
                if (callback) callback({ widthOrient: true })
            } else {
                setWidthOrient(false);
                if (callback) callback({ widthOrient: false })
            }
        }
    }

    useMemo(() => {
        if(!imageId && !fallbackUrl) setOnLoadUnknown(true);
        setValidUrl((imageId || fallbackUrl) ? generateImageUrl(imageId, "image", fallbackUrl) : "")
    }, [imageId, fallbackUrl])

    useMemo(() => {
        if(resize && containRef.current && !fullscreen && validUrl && !dataUrl) {
            let img = new Image();
            try {
                img.onerror = () => setCrossOriginErr(true)
                img.onload = () => {
                    getWidthOrient(img.naturalWidth / img.naturalHeight);
                    smartcrop.crop(img, {
                        width: imgDimensions[0],
                        height: imgDimensions[1]
                    }).then(({ topCrop }) => {
                        const { x, y, width, height } = topCrop || {};

                        const canvas = document.createElement("canvas")
                        canvas.width = imgDimensions[0];
                        canvas.height = imgDimensions[1];

                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, x, y, width, height, 0, 0, imgDimensions[0], imgDimensions[1]);

                        const dataType = canUseWebP() ? "image/webp" : "image/png"
                        setDataUrl(canvas.toDataURL(dataType, 1));
                        console.log("DATA URL RECEIVED", widthOrient, canvas.toDataURL(dataType, 1), new Date())
                    })
                }
                img.crossOrigin = "Anonymous";
                img.referrerPolicy = "strict-origin-when-cross-origin";
                img.src = validUrl;
            } catch (error) {
                console.log("TRY_CATCH_DYNAMIC_IMAGE_CROP_ERROR", error)
                setCrossOriginErr(true)
            }
        }
    }, [validUrl, resize, fullscreen, containRef.current, setDataUrl, setCrossOriginErr])

    useLayoutEffect(() => {
        if(containRef.current && resize) setImgDimensions([2*containRef.current.clientWidth, 2*containRef.current.clientHeight])
        if(imageRef.current && fullscreen && widthOrient === null) getWidthOrient()
        if(fullscreen) setTimeout(() => setFullscreenSwitch(fullscreen), 0)
        else setFullscreenSwitch(fullscreen)

    }, [containRef, fullscreen, imageRef, setWidthOrient, resize])

    useEffect(() => {
        if(imageRef.current && fullscreen) window.addEventListener("resize", getWidthOrient, true);
        return () => window.removeEventListener("resize", getWidthOrient, true);
    }, [fullscreen])

    return (
        <DynamicImageWrapper
            onContextMenu={(event) => event.preventDefault()}
            fullscreen={fullscreen}
            widthOrient={widthOrient}
            ref={containRef}
        >
            {
                (imageRefObj[imageId] === "error" || !onLoadSuccess || onLoadDefErr || onLoadUnknown || removed) ? (
                    <DynamicImageStatusIcon
                        name={(onLoadDefErr || imageRefObj[imageId] === "error") ? "unknown" : (removed || onLoadUnknown) ? "error" : "updates"}
                        size={30}
                        color={color.gray3}
                    />
                ) : null
            }
            {
                (imageRefObj[imageId] !== "error" && !onLoadDefErr && !removed
                    && (((resize && dataUrl) || crossOriginErr) || (!resize && validUrl))) ? (
                    <DynamicImagePicture
                        onError={() => {
                            if(fallbackUrl && validUrl !== fallbackUrl) setValidUrl(fallbackUrl)
                            else {
                                setOnLoadDefErr(true)
                                if(callback) callback({ error: true })
                                if(!imageRefObj[imageId]) setImageRef("error", imageId)
                            }
                        }}
                        onLoad={() => {
                            setOnLoadSuccess(true)
                            if(callback) callback({ success: true })
                        }}
                        src={(resize && !crossOriginErr && !fullscreen) ? dataUrl : (fullscreen && !fullscreenSwitch) ? null :  validUrl}
                        fullscreen={fullscreen}
                        widthOrient={widthOrient}
                        // loading={"lazy"}
                        type={validUrl.includes(imageId) ? "image/webp" : (imageType ?? null)}
                        referrerPolicy={"no-referrer"}
                        ref={imageRef}
                    />
                ) : null
            }
        </DynamicImageWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    imageRefObj: selectImageRefObj
})

const mapDispatchToProps = (dispatch) => ({
    setImageRef: (imageState, refKey) => dispatch(setImageRef(imageState, refKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(DynamicImage);