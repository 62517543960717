import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { FacebookProvider, EmbeddedPost, EmbeddedVideo, Page } from "react-facebook";

import { selectUserLanguage } from "../../../../redux/userData/userData.selectors";
import { getLangCode, sanitizeUrl, removeScript, removeStyle } from "../../../general.utils";
import { radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    border-radius: ${props => props.radius ? props.radius : radius.largeRadius};
    overflow: hidden;
`

const EmbedFacebook = ({ embedUrl, width, lang }) => {
    const fbUrl = sanitizeUrl(embedUrl, "all");
    const type = (embedUrl.includes("photo") || embedUrl.includes("post")) ?
                    "post" : embedUrl.includes("video") ? "video" : "page";

    useEffect(() => {
        return () => {
            removeScript("facebook")
            removeStyle("fb_iframe")
        }
    }, [])

    return (
        <IFrameWrapper>
            <div id={"fb-root"} />
            <FacebookProvider
                appId={"272522984360685"}
                language={getLangCode(lang, true)}
                version={"v3.2"}
            >
                {
                    type === "post" ? (
                        <EmbeddedPost
                            href={fbUrl}
                            width={width}
                            showText={false}
                        />
                    ) : type === "video" ? (
                        <EmbeddedVideo
                            href={fbUrl}
                            width={width}
                            showText={false}
                            autoPlay={false}
                            showCaptions={true}
                            allowFullScreen={true}
                        />
                    ) : type === "page" && (
                        <Page
                            href={fbUrl}
                            tabs="timeline, events"
                            width={width}
                            hideCover={false}
                            showFacepile={false}
                            hideCTA={false}
                            smallHeader={false}
                            adaptContainerWidth={true}
                        />
                    )
                }
            </FacebookProvider>
        </IFrameWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(EmbedFacebook);