import React from "react";

import { color } from "../../../design/theme";

import {
    ArticleQuoteContainer,
    ArticleQuoteIcon,
    ArticleQuoteWrapper,
    ArticleQuoteAuthorContainer
} from "./articleQuote.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleQuote = ({ content }) => {
    const { text, author } = content || {}

    return (
        <ArticleQuoteWrapper>
            <ArticleQuoteContainer>
                <ArticleQuoteIcon
                    name={"quote"}
                    size={40}
                    color={color.blue}
                />
                <ArticleText text={text} italic auto />
                {
                    author ? (
                        <ArticleQuoteAuthorContainer>
                            <ArticleText text={author} color={color.blue} italic bold small />
                        </ArticleQuoteAuthorContainer>
                    ) : null
                }
            </ArticleQuoteContainer>
        </ArticleQuoteWrapper>
    )
}

export default ArticleQuote;