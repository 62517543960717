import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleInfoboxWrapper,
    ArticleInfoboxTitleWrapper,
    ArticleInfoboxTitleIconContainer,
    ArticleInfoboxTitleIcon,
    ArticleInfoboxBodyComponent
} from "./articleInfobox.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleInfobox = ({ content, darkmode }) => {
    const { mainWidth } = useContext(LayoutContext);
    const { title, body } = content || {};

    return (
        <ArticleInfoboxWrapper darkmode={darkmode}>
            {
                title ? (
                    <ArticleInfoboxTitleWrapper>
                        <ArticleInfoboxTitleIconContainer>
                            <ArticleInfoboxTitleIcon
                                name={"info"}
                                color={color.blue}
                                size={mainWidth > 600 ? 40 : 34}
                            />
                        </ArticleInfoboxTitleIconContainer>
                        <ArticleText text={title} size={mainWidth > 600 ? 25 : 20} contrast bold />
                    </ArticleInfoboxTitleWrapper>
                ) : null
            }
            {
                body ? body.map((component, index) => {
                    return (
                        <ArticleInfoboxBodyComponent darkmode={darkmode} key={index}>
                            <ArticleText
                                text={Object.values(component)[0]}
                                bold={Object.keys(component)[0] === "subtitle"}
                                contrast={Object.keys(component)[0] === "subtitle"}
                            />
                        </ArticleInfoboxBodyComponent>
                    )
                }) : null
            }
        </ArticleInfoboxWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleInfobox);