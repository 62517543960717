import styled from "styled-components";

export const MainContainer = styled.main`
    top: 0;
    width: 800px;
    height: inherit;
    margin: 0 0 0 0; // -100px
    display: flex;
    // overflow-y: scroll;
    position: relative;

    @media only screen and (max-width: 1540px) {
        margin: 0 0 0 45px;
        // justify-content: flex-start;
    }
    @media only screen and (max-width: 1300px) {
        // margin: 0 0 0 40px;
        // flex-grow: 100;
        flex-shrink: 100;
        width: auto;
        align-self: stretch;
        flex-basis: 100%;
        max-width: 800px;
        min-width: 0;
    }
    @media only screen and (max-width: 800px) {
        margin: 0 0 0 35px;
    }
    @media only screen and (max-width: 600px) {
        margin: 0 0 0 25px;
    }
`;

export const MainScrollContainer = styled.div`
    height: auto;
    width: 100%;
    position: relative;
    display: inline-block;
    overflow-x: hidden;
`