import React, { useState, Fragment, useRef, useEffect, useCallback, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { selectEmbedConsent } from "../../../redux/sessionData/sessionData.selectors";
import { selectUserLanguage, selectUserAllowAllEmbeds, selectUserAllowEmbeds } from "../../../redux/userData/userData.selectors";
import { setEmbedConsent } from "../../../redux/sessionData/sessionData.actions";
import { cookiesUsed, calcEmbedWidth, calcEmbedHeight, indexedEmbed } from "./articleEmbed.utils";
import { miscData, consentData } from "../../lang.data";
import { color } from "../../../design/theme";
import { capitalize, cleanStringToDbKey, generateCdnUrl } from "../../general.utils";

import {
    ArticleEmbedWrapper,
    ArticleEmbedContainer,
    ArticleEmbedConsentContainer,
    ArticleEmbedConsentImageContainer,
    ArticleEmbedConsentContentContainer,
    ArticleEmbedConsentTitleContainer,
    ArticleEmbedCookieInfoContainer,
    ArticleEmbedCookieInfoIconContainer,
    ArticleEmbedCookieInfoTextWrapper,
    ArticleEmbedCookieInfoTextContainer,
    ArticleEmbedConsentInfoContainer,
    ArticleEmbedConsentActionContainer
} from "./articleEmbed.styles";
import CustomButton from "../../customButton/customButton.component";
import ArticleCaption from "../articleCaption/articleCaption.content";
import StaticImage from "../../staticImage/staticImage.component";

import EmbedSdaLiveblog from "./embedProviders/ep_sda-liveblog.embed";
import EmbedDatawrapper from "./embedProviders/ep_datawrapper.embed";
import EmbedLivecenter from "./embedProviders/ep_livecenter.embed";
import EmbedPinterest from "./embedProviders/ep_pinterest.embed";
import EmbedInfogram from "./embedProviders/ep_infogram.embed";
import EmbedFacebook from "./embedProviders/ep_facebook.embed";
import EmbedPlaybuzz from "./embedProviders/ep_playbuzz.embed";
import EmbedModular from "./embedProviders/ep_modular.embed";
import EmbedDefault from "./embedProviders/ep_default.embed";
import EmbedTwitter from "./embedProviders/ep_twitter.embed";
import EmbedGiphy from "./embedProviders/ep_giphy.embed";

const ArticleEmbed = ({ content, contentId, darkmode, lang, embedConsent, setEmbedConsent, allEmbeds, embeds }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [consent, setConsent] = useState(false);
    const [consentHeight, setConsentHeight] = useState(0);
    const refHeight = useRef(null);

    const { caption, provider, embedUrl } = content || {};
    let embedWidth = useCallback(() => calcEmbedWidth(provider, mainWidth), [provider, mainWidth])
    let embedHeight = useCallback(() => calcEmbedHeight(provider, mainWidth), [provider, mainWidth])

    const handleConsent = () => {
        setConsent(true);
        setEmbedConsent(provider, contentId);
    }

    const embedProviders = {
        facebook: EmbedFacebook,
        giphy: EmbedGiphy,
        infogram: EmbedInfogram,
        livecenter: EmbedLivecenter,
        pinterest: EmbedPinterest,
        playbuzz: EmbedPlaybuzz,
        "sda-liveblog": EmbedSdaLiveblog,
        datawrapper: EmbedDatawrapper,
        twitter: EmbedTwitter,

        instagram: EmbedModular,
        default: EmbedDefault
    }
    const embedType = embedProviders[provider] ? provider : "default";
    const EmbedComponent = embedProviders[embedType];

    const consentGiven = allEmbeds || embeds[cleanStringToDbKey(provider)] || consent || embedConsent[provider];

    useEffect(() => {
        if(refHeight.current) setConsentHeight(refHeight.current.clientHeight)
    }, [refHeight])

    return (
        <Fragment>
            <ArticleEmbedWrapper>
                <ArticleEmbedContainer
                    darkmode={darkmode}
                    width={embedWidth()}
                    height={embedHeight()}
                    consent={consentGiven}
                >
                    {
                        consentGiven ? (
                            <EmbedComponent
                                width={embedWidth()}
                                height={embedHeight()}
                                embedUrl={embedUrl}
                                provider={provider}
                                contentId={contentId}
                            />
                        ) : (
                            <ArticleEmbedConsentContainer>
                                <ArticleEmbedConsentImageContainer height={consentHeight} darkmode={darkmode}>
                                    <StaticImage
                                        url={generateCdnUrl(`content/brands/icons/color/${provider}.svg`)}
                                        type={"logo"}
                                        rect
                                    />
                                </ArticleEmbedConsentImageContainer>
                                <ArticleEmbedConsentContentContainer ref={refHeight}>
                                    <ArticleEmbedConsentTitleContainer darkmode={darkmode}>
                                        <span>{consentData.embeddedContent[lang]}</span>
                                        <span>{`\n${consentData.providerNames[provider] ?? capitalize(provider)}`}</span>
                                    </ArticleEmbedConsentTitleContainer>
                                    {
                                        indexedEmbed(provider) ? (
                                            <ArticleEmbedCookieInfoContainer>
                                                <Link
                                                    to={{ pathname: consentData.providerPrivacy[provider][lang] }}
                                                    target="_blank"
                                                    rel={"external nofollow noreferrer noopener"}
                                                >
                                                    <ArticleEmbedCookieInfoIconContainer
                                                        name={"cookie"}
                                                        darkmode={darkmode}
                                                        size={25}
                                                        color={darkmode ? color.gray4 : color.gray3}
                                                    />
                                                </Link>
                                                <ArticleEmbedCookieInfoTextWrapper>
                                                    <ArticleEmbedCookieInfoTextContainer darkmode={darkmode}>
                                                        <span>{consentData.providerCookies[provider]}</span>
                                                        <span>{" " + cookiesUsed(provider, lang)}</span>
                                                    </ArticleEmbedCookieInfoTextContainer>
                                                </ArticleEmbedCookieInfoTextWrapper>
                                            </ArticleEmbedCookieInfoContainer>
                                        ) : null
                                    }
                                    <ArticleEmbedConsentInfoContainer darkmode={darkmode}>
                                        <span>{consentData.embeddedInfo[lang]}</span>
                                    </ArticleEmbedConsentInfoContainer>
                                    <ArticleEmbedConsentActionContainer>
                                        <CustomButton
                                            color={darkmode ? color.gray4 : color.gray3}
                                            callback={() => handleConsent()}
                                            desc={miscData.allow[lang]}
                                            disabled={!indexedEmbed(provider)}
                                            size={"true"}
                                            solid
                                        />
                                        <Link
                                            to={{ pathname: embedUrl }}
                                            target="_blank"
                                            rel={"external nofollow noreferrer noopener"}
                                        >
                                            <CustomButton
                                                desc={consentData.followLink[lang]}
                                                color={color.blue}
                                                icon={"external"}
                                                size={"true"}
                                                solid
                                            />
                                        </Link>
                                    </ArticleEmbedConsentActionContainer>
                                </ArticleEmbedConsentContentContainer>
                            </ArticleEmbedConsentContainer>
                        )
                    }
                </ArticleEmbedContainer>
            </ArticleEmbedWrapper>
            {
                (caption && (consent || embedConsent[provider])) ? (
                    <ArticleCaption
                        caption={caption}
                        type={"embed"}
                        callback={() => window.open(embedUrl, '_blank').focus()}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode,
    embedConsent: selectEmbedConsent,
    allEmbeds: selectUserAllowAllEmbeds,
    embeds: selectUserAllowEmbeds
})

const mapDispatchToProps = (dispatch) => ({
    setEmbedConsent: (provider, addData) => dispatch(setEmbedConsent(provider, addData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEmbed);