import styled from "styled-components";
import { font, color } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const HistoryBlockWrapper = styled.section`
    width: ${props => props.mainWidth + "px" ?? "100%"};
    height: auto;
    margin-bottom: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    position: relative;
    // transform: translate3d(0,0,0); // forces tap into webGPU
    // content-visibility: auto;
    // will-change: scroll-position;
  
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: ${props => props.singleitem ? "100px" : "150px"};
        background: ${
            props => props.darkmode ? 
                        `linear-gradient(rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8) 50%, ${color.gray1} 90%);` 
                        : `linear-gradient(rgba(240, 242, 245, 0), rgba(240, 242, 245, 0.8) 50%, ${color.gray5} 90%);`
        }
    }
`

export const HistoryBlockIconContainer = styled.div`
    height: ${props => props.height + "px"};
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 15px;

    &::after {
        content: "";
        height: calc(100% - 50px);
        width: 3px;
        margin-top: 10px;
        background-color: ${color.gray3};
        border-radius: 20px;
        position: relative;
        display: flex;
    }
`

export const HistoryBlockIcon = styled(Icon)`
    display: flex;
    justify-content: center;
    width: inherit;
    height: 40px;
`

export const HistoryBlockContentContainer = styled.div`
    height: auto;
    width: auto;
    flex-grow: 100;
    padding: 0 2px;
`

export const HistoryBlockTitle = styled.h2`
    font-family: ${font.pulpDisplayBold};
    font-size: 24px;
    letter-spacing: 0.02em;
    color: ${props => textColor(props.darkmode)};
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    @media only screen and (max-width: 750px) {
        font-size: 20px;
        margin-right: 15px;
    }
`

export const HistoryBlockListContainer = styled.div`
    height: auto;
    width: inherit;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`