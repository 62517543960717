import styled from "styled-components";
import { radius, font, color } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const LoginPreviewWrapper = styled.div`
    width: 100%;
    height: max-content;
    max-height: 100vh;
    min-height: 420px;
    position: relative;
    padding: 25px 30px 30px 30px;
    overflow-y: ${props => props.scrollActive ? "scroll" : "visible"};
`

export const LoginPreviewImageWrapper = styled.div`
    width: 100%;
    height: ${props => props.refHeight};
    padding: 0 25px;
    display: flex;
    justify-content: center;
    margin: -70px 0 35px 0 !important;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        width: calc(100% - 50px);
        border-radius: ${radius.smallRadius};
        height: 100%;
        box-shadow: 0 1px 10px 0 ${props => props.darkmode ? color.black : color.gray3};
        z-index: -1;
    }
`

export const LoginPreviewImageContainer = styled.div`
    width: 100%;
    height: inherit;
    border-radius: ${radius.smallRadius};
    overflow: hidden;
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    box-shadow: 0 1px 10px 0 ${props => props.darkmode ? props.wallActive ? color.gray1 + "60" : color.gray1 : props.wallActive ? color.gray5 : color.gray4};

    &::before {
        content: "";
        width: calc(100% - 50px);
        margin-left: 25px;
        height: 100%;
        display: ${props => props.wallActive ? "block" : "none"};
        background: ${props => props.darkmode ? "linear-gradient(to top, rgba(42, 42, 42, 0.96) 30%, rgba(42, 42, 42, 0.75) 70%, rgba(42, 42, 42, 0.45) 100%)" : "linear-gradient(to top, rgba(256, 256, 256, 0.96) 30%, rgba(256, 256, 256, 0.75) 70%, rgba(256, 256, 256, 0.45) 100%)"};
        position: absolute;
        border-radius: ${radius.smallRadius} ${radius.smallRadius} 0 0;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
    }
`

export const LoginPreviewContentContainer = styled.div`
    width: 100%;
    padding: 0 5px;
    height: auto;
    margin-top: 15px;
    position: relative;
  
    &::after {
        content: "";
        width: 100%;
        height: ${props => props.wallActive ? "100%" : "85px"};
        background: ${props => props.darkmode ? props.wallActive ? "linear-gradient(to top, rgba(42, 42, 42, 1) 60%, rgba(42, 42, 42, 0.99) 90%, rgba(42, 42, 42, 0.96) 100%)" : "linear-gradient(to top, rgba(42, 42, 42, 1) 5%, rgba(42, 42, 42, 0.7) 80%, rgba(42, 42, 42, 0.4) 90%, rgba(26, 26, 26, 0) 100%)" : props.wallActive ? "linear-gradient(to top, rgba(256, 256, 256, 1) 60%, rgba(256, 256, 256, 0.99) 80%, rgba(256, 256, 256, 0.96) 100%)" : "linear-gradient(to top, rgba(256, 256, 256, 1) 5%, rgba(256, 256, 256, 0.7) 80%, rgba(256, 256, 256, 0.4) 90%, rgba(256, 256, 256, 0) 100%)"};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    & > div {
        margin-top: 15px;
    }
    & > div:nth-child(3) {
        margin-left: -5px;
    }
`

export const LoginPreviewTitle = styled.div`
    font-family: ${font.interBold};
    font-size: 25px;
    color: ${props => textColor(props.darkmode)}
`

export const LoginPreviewIcon = styled(Icon)`
    width: 40px;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    box-shadow: ${props => props.darkmode ? `0 1px 3px 0 ${color.gray1}` : "none"};
    border-radius: 50px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
`