import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { loginData, miscData } from "../lang.data";
import { textColor } from "../general.utils";
import { color } from "../../design/theme";

import {
    LoginWallWrapper,
    LoginWallLogo,
    LoginWallTitle,
    LoginWallSubtitle,
    LoginWallBrandsContainer,
    LoginWallBrandBubble,
    LoginWallInfoCard,
    LoginWallInfoCardLine,
    LoginWallInfoCardIcon,
    LoginWallInfoDesc,
    LoginWallInfoDescLink
} from "./loginWall.styles";
import CustomButton from "../customButton/customButton.component";
import StaticImage from "../staticImage/staticImage.component";

const LoginWall = ({ lang, darkmode, article, callback }) => {
    const { newspaper, originalUrl } = article || {};

    const logoDistro = ["suedostschweiz", "limmattaler-zeitung", "tagblatt", "aargauer-zeitung", "bz-basel", "luzerner-zeitung"]

    return (
        <LoginWallWrapper>
            <LoginWallLogo
                name={"reavide"}
                size={40}
                icon
            />
            <LoginWallTitle darkmode={darkmode}>
                <span>{`${loginData.wallTitle[lang]}\xa0`}</span>
                <span>reavide</span>
            </LoginWallTitle>
            <LoginWallSubtitle darkmode={darkmode}>
                <span>{loginData.wallSubTitle[lang]}</span>
            </LoginWallSubtitle>
            <LoginWallBrandsContainer>
                {
                    logoDistro.map(newspaper => (
                        <LoginWallBrandBubble key={newspaper} darkmode={darkmode}>
                            <StaticImage
                                url={`https://storage.googleapis.com/reavide/content/brands/icons/color/${newspaper}.svg`}
                                type={"logo"}
                                rect
                            />
                        </LoginWallBrandBubble>
                    ))
                }
                <LoginWallInfoDescLink href={"https://reavide.com"} target={"_blank"}>
                    <span>{`+ ${loginData.andMore[lang]}...`}</span>
                </LoginWallInfoDescLink>
            </LoginWallBrandsContainer>
            <LoginWallInfoCard darkmode={darkmode}>
                <LoginWallInfoCardLine>
                    <LoginWallInfoCardIcon
                        name={"140001"}
                        color={textColor(darkmode)}
                        size={20}
                    />
                    <LoginWallInfoDesc darkmode={darkmode}>
                        <span>{`${loginData.moreInfo[lang]}\xa0`}</span>
                        <LoginWallInfoDescLink href={"https://reavide.com"} target={"_blank"}>
                            <span>{miscData.here[lang]}</span>
                        </LoginWallInfoDescLink>
                        <span>.</span>
                        <LoginWallInfoCardIcon
                            lowered={"true"}
                            name={"external"}
                            color={textColor(darkmode)}
                            size={15}
                        />
                    </LoginWallInfoDesc>
                </LoginWallInfoCardLine>
                <LoginWallInfoCardLine>
                    <LoginWallInfoCardIcon
                        name={"110014"}
                        color={textColor(darkmode)}
                        size={20}
                    />
                    <LoginWallInfoDesc darkmode={darkmode}>
                        <span>{`${loginData.readDirect(lang, newspaper)}\xa0`}</span>
                        <LoginWallInfoDescLink
                            href={originalUrl}
                            target={"_blank"}
                            rel={"external noopener"}
                            type={"text/html"}
                            media={"screen"}
                        >
                            <span>{newspaper}</span>
                            </LoginWallInfoDescLink>
                            <span>.</span>
                            <LoginWallInfoCardIcon
                                lowered={"true"}
                                name={"external"}
                                color={textColor(darkmode)}
                                size={15}
                            />
                    </LoginWallInfoDesc>
                </LoginWallInfoCardLine>
            </LoginWallInfoCard>
            <CustomButton
                desc={loginData.signup[lang]}
                callback={() => callback("action")}
                color={color.blue}
                icon={"arrow"}
                width={300}
                solid
            />
        </LoginWallWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(LoginWall)