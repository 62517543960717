import React, { Fragment, useEffect, useState, useLayoutEffect, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { HeaderContext } from "../../../context/header.context";
import { fetchUserHistoryStart } from "../../../redux/userData/userData.actions";
import { fetchArticleDataStart } from "../../../redux/articleData/articleData.actions";
import { selectUserId, selectHistoryObj, selectUserDataFetching, selectUserDataError } from "../../../redux/userData/userData.selectors";
import { selectArticleList, selectArticleFetching } from "../../../redux/articleData/articleData.selectors";
import { selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { isBlockEligible } from "./history.utils";
import { titleData } from "../../../components/lang.data";

import Indicator from "../../../components/indicator/indicator.component";
import HistoryBlock from "../../../components/historyBlock/historyBlock.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const HistoryPage = ({ userId, historyObj, fetchUserHistoryStart, fetchArticleDataStart,
                         error, historyLoading, articleList, articleLoading, lang }) => {

    const { setSimpleEnv } = useContext(HeaderContext);
    const { data, preview, fetchKey } = historyObj || {};

    const [reload, setReload] = useState(false);
    const [loadActive, setLoadActive] = useState(false);

    let observer = new IntersectionObserver((entries, observer) => {
        const [ entry ] = entries;
        if(entry.target && entry.isIntersecting) {
            if(window.requestIdleCallback) {
                window.requestIdleCallback(() => setLoadActive(true), { timeout: 250 })
            } else setLoadActive(true);
            observer.unobserve(entry.target);
        }
    }, { threshold: 0.2 });

    useLayoutEffect(() => {
        let queryList = document.querySelectorAll("article");
        queryList.forEach((articleElement, index) => {
            if(queryList.length - 4 === index) {
                observer.observe(articleElement)
                console.log("__SIDE_OPERATION :: LOADING_OBSERVER_PLANTED", articleElement)
                setLoadActive(false)
            }
        })
    }, [setSimpleEnv, articleList, data])

    useEffect(() => {
        if(loadActive && !articleLoading) fetchArticleDataStart("history");
    }, [loadActive])
    useEffect(() => {
        setSimpleEnv(true);
        const reloadReset = reload ? setTimeout(() => setReload(false), 500) : null;
        if(fetchKey !== "general") fetchUserHistoryStart(userId, "general");
        if(data && document.querySelectorAll("article").length < 4) fetchArticleDataStart("history");

        return () => {
            clearTimeout(reloadReset)
            setSimpleEnv(null)
        }
    }, [data, fetchUserHistoryStart, fetchArticleDataStart, userId])

    return (
        <Fragment>
            <SiteHead title={titleData.history[lang]} />
            <Indicator
                pageLabel={titleData.history[lang]}
                pageLabelEmoji={"🧭"}
            />
            {
                (!error) ? (
                    <Fragment>
                        {
                            (preview && preview.length) ? (
                                <Fragment>
                                    {
                                        preview.map((historyDataBlock, index) => (
                                            isBlockEligible(historyDataBlock, index, articleList, preview) ? (
                                                <HistoryBlock
                                                    key={historyDataBlock.timestamp}
                                                    historyDataBlock={historyDataBlock}
                                                />
                                            ) : null
                                        ))
                                    }
                                </Fragment>
                            ) : (historyLoading !== undefined && !historyLoading
                                    && articleLoading !== undefined && !articleLoading && !data) ? (
                                <ErrorDisplay type={"noHistory"} link={"popular"} icon={"150013"} />
                            ) : null
                        }
                    </Fragment>
                ) : <ErrorDisplay type={"contentError"} callback={setReload} />
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    lang: selectUserLanguage,
    historyObj: selectHistoryObj,
    articleList: selectArticleList,
    historyLoading: selectUserDataFetching("historyData"),
    articleLoading: selectArticleFetching,
    error: selectUserDataError("historyData")
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserHistoryStart: (userId, fetchKey) => dispatch(fetchUserHistoryStart(userId, fetchKey)),
    fetchArticleDataStart: (refKey) => dispatch(fetchArticleDataStart(refKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);