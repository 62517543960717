import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserAuthId } from "../../redux/userLogin/userLogin.selectors";
import { selectUser } from "../../redux/userData/userData.selectors";
import { fetchUserDataStart } from "../../redux/userData/userData.actions";
import { errorArtPreload } from "../errorDisplay/errorDisplay.utils";
import { textColor } from "../general.utils";

import { FullscreenLoaderContainer, FullscreenLoaderLogo } from "./fullscreenLoader.styles";

const FullscreenLoader = ({ authId, fetchUserDataStart, darkmode, userData }) => {
    useEffect(() => {
        if(!userData) fetchUserDataStart(authId);
        errorArtPreload();
    }, [fetchUserDataStart, authId])

    return (
        <FullscreenLoaderContainer>
            <FullscreenLoaderLogo
                name={"reavide"}
                color={textColor(darkmode)}
                size={70}
            />
        </FullscreenLoaderContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    authId: selectUserAuthId,
    userData: selectUser,
    darkmode: selectDarkmode
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserDataStart: (userId) => dispatch(fetchUserDataStart(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenLoader);