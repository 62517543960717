import React, { useEffect } from "react";
import styled from "styled-components";

import { addScript, capitalize, generateCdnUrl, removeScript, scriptPresent } from "../../../general.utils";
import { radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    border-radius: ${radius.largeRadius};
    overflow: hidden;
`

const EmbedPlaybuzz = ({ embedUrl, width, provider }) => {
    const dataId = embedUrl.substring(embedUrl.lastIndexOf("/") + 1);

    useEffect(() => {
        if(!scriptPresent("playbuzz")) addScript(generateCdnUrl("dist/js/embeds/playbuzz-sdk.js"))
        // if(!scriptPresent("embed.ex.co")) addScript("//embed.ex.co/sdk.js")

        return () => removeScript("playbuzz")
        // return () => removeScript("embed.ex.co")
    }, [])

    return (
        <IFrameWrapper width={width} title={`${capitalize(provider)} Iframe`}>
            <div
                className="exco"
                data-id={dataId}
                data-show-info={"false"}
                data-show-share={"false"}
                style={{ width: "inherit" }}
            > </div>
        </IFrameWrapper>
    )
}

export default EmbedPlaybuzz;