import UserLoginActionTypes from "./userLogin.types";

export const signUpEmailStart = (userCredentials) => ({
    type: UserLoginActionTypes.EMAIL_SIGN_UP_START,
    payload: userCredentials
});
export const signUpGoogleStart = () => ({
    type: UserLoginActionTypes.GOOGLE_SIGN_UP_START
})
export const signUpAppleStart = () => ({
    type: UserLoginActionTypes.APPLE_SIGN_UP_START
})

export const signUpSuccess = () => ({
    type: UserLoginActionTypes.SIGN_UP_SUCCESS
});
export const signUpFailure = (error) => ({
    type: UserLoginActionTypes.SIGN_UP_FAILURE,
    payload: error
});


export const signInEmailStart = (userCredentials) => ({
    type: UserLoginActionTypes.EMAIL_SIGN_IN_START,
    payload: userCredentials
});
export const signInGoogleStart = () => ({
    type: UserLoginActionTypes.GOOGLE_SIGN_IN_START
})

export const signInSuccess = () => ({
    type: UserLoginActionTypes.SIGN_IN_SUCCESS
});
export const signInFailure = (error) => ({
    type: UserLoginActionTypes.SIGN_IN_FAILURE,
    payload: error
});


export const checkUserSessionStart = () => ({
    type: UserLoginActionTypes.CHECK_USER_SESSION_START
})
export const checkUserSessionSuccess = (authObj) => ({
    type: UserLoginActionTypes.CHECK_USER_SESSION_SUCCESS,
    payload: authObj
})
export const checkUserSessionFailure = (error) => ({
    type: UserLoginActionTypes.CHECK_USER_SESSION_FAILURE,
    payload: error
})

export const signOutStart = () => ({
    type: UserLoginActionTypes.SIGN_OUT_START
})
export const signOutSuccess = () => ({
    type: UserLoginActionTypes.SIGN_OUT_SUCCESS
})
export const signOutFailure = (error) => ({
    type: UserLoginActionTypes.SIGN_OUT_FAILURE,
    payload: error
})