import styled from "styled-components";
import { color, font } from "../../design/theme";

export const OptInContainer = styled.section`
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin-bottom: 20px;
`;

export const OptInDescription = styled.label`
    text-align: left;
    margin-left: 12px;
    height: auto;
    color: ${color.gray3};
    font-family: ${font.pulpDisplayMedium};
    font-size: 16px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    position: relative;
    letter-spacing: 0.025em;
    text-overflow: ellipsis;
`;