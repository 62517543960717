import React, { useEffect, useState, useMemo, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { fetchUserHistoryStart } from "../../../redux/userData/userData.actions";
import { selectUserLanguage, selectUserId, selectHistoryObj, selectUserTargetReads } from "../../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { titleData, accountData, navData } from "../../../components/lang.data";
import { calcStreaks, calcArticleAvg, calcArticleTotal, calcWeeklyTrend, reducedAccView } from "./account.utils";
import { textColor } from "../../../components/general.utils";
import { color } from "../../../design/theme";

import {
    AccountContainer,
    AccountGridContainer,
    AccountGridElement,
    TargetReadContainer,
    TargetReadContentContainer,
    TargetReadTitleContainer,
    TargetReadDescContainer,
    TargetReadNumberContainer,
    TargetReadNumber
} from "./account.styles";
import Indicator from "../../../components/indicator/indicator.component";
import CustomButton from "../../../components/customButton/customButton.component";
import Modal from "../../../components/modal/modal.component";
import AccountProgress from "../../../components/accountProgress/accountProgress.component";
import AccountHighlight from "../../../components/accountHighlight/accountHighlight.component";
import AccountChart from "../../../components/accountChart/accountChart.component";
import SiteHead from "../../../components/siteHead/siteHead.component";
import ModalInput from "../../../components/modalInput/modalInput.component";

const AccountPage = ({ lang, userId, fetchUserHistoryStart, historyObj, darkmode, targetReads }) => {
    const { mainWidth } = useContext(LayoutContext);
    const reduced = reducedAccView(mainWidth);
    const [targetActive, setTargetActive] = useState(false);
    const { data, fetchKey } = historyObj || {};

    let currentStreak = useMemo(() => calcStreaks("current", data, targetReads), [data, targetReads])
    let maxStreak = useMemo(() => calcStreaks("max", data, targetReads), [data, targetReads])
    let articleTotal = useMemo(() => calcArticleTotal("", data), [data])
    let articleAvg = useMemo(() => calcArticleAvg("", data), [data])
    let weeklyTotal = useMemo(() => calcArticleTotal("week", data), [data])
    let weeklyTrend = useMemo(() => calcWeeklyTrend(data), [data])

    useEffect(() => {
        if(fetchKey !== "general") fetchUserHistoryStart(userId, "general")
    }, [userId, fetchUserHistoryStart, data])

    return (
        <AccountContainer>
            <SiteHead title={navData.account.desc[lang]} />
            <Indicator
                pageLabel={titleData.account[lang]}
                pageLabelEmoji={"🎉"}
                icon={"settings"}
                icon2={"history"}
                link={"true"}
            />
            {
                targetReads ? (
                    <AccountProgress title={accountData.targetReads.today[lang]}/>
                ) : null
            }
            <AccountGridContainer reduced={reduced} collapsed={mainWidth <= 600}>
                <AccountGridElement gridArea={"targetReadAdjustor"}>
                    <TargetReadContainer darkmode={darkmode}>
                        <TargetReadContentContainer reduced={reduced}>
                            <TargetReadTitleContainer darkmode={darkmode}>
                                <span>{accountData.targetReads.title[lang]}</span>
                            </TargetReadTitleContainer>
                            <TargetReadDescContainer darkmode={darkmode} reduced={reduced}>
                                <span>{accountData.targetReads.desc[lang]}</span>
                            </TargetReadDescContainer>
                            <CustomButton
                                callback={() => setTargetActive(!targetActive)}
                                desc={accountData.targetReads.adjust[lang]}
                                color={color.blue}
                                size={"true"}
                                solid
                            />
                            {
                                targetActive ? (
                                    <Modal
                                        title={accountData.targetReads.setTarget[lang] + " 🎯"}
                                        desc={accountData.targetReads.setTargetInfo[lang]}
                                        onDismiss={() => setTargetActive(!targetActive)}
                                    >
                                        <ModalInput
                                            type={"target"}
                                            onDismiss={() => setTargetActive(!targetActive)}
                                        />
                                    </Modal>
                                ) : null
                            }
                        </TargetReadContentContainer>
                        <TargetReadNumberContainer reduced={reduced}>
                            <TargetReadNumber reduced={reduced}>
                                <span>{(targetReads === 0) ? "?" : `${targetReads}`}</span>
                            </TargetReadNumber>
                        </TargetReadNumberContainer>
                    </TargetReadContainer>
                </AccountGridElement>
                <AccountGridElement gridArea={"currentStreak"} key={"currentStreak"}>
                    <AccountHighlight
                        title={accountData.highlights.currentStreak[lang]}
                        metric={data ? currentStreak ? currentStreak : "0" : "-"}
                        color={(maxStreak !== 0) ? (currentStreak >= maxStreak) ? color.green : ((currentStreak >= (maxStreak - 3)) && (currentStreak !== 0)) ? color.yellow : color.red : textColor(darkmode)}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"maxStreak"} key={"maxStreak"}>
                    <AccountHighlight
                        title={accountData.highlights.maxStreak[lang]}
                        metric={data ? maxStreak ? maxStreak : "0" : "-"}
                        color={textColor(darkmode)}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"timeBarChart"} key={"barChart"}>
                    <AccountChart
                        title={accountData.barChart.title[lang]}
                        type={"bar"}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"categoryPieChart"} key={"pieChart"}>
                    <AccountChart
                        title={accountData.pieChart.title[lang]}
                        type={"pie"}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"weeklyTotal"} key={"weeklyTotal"}>
                    <AccountHighlight
                        title={accountData.highlights.weeklyTotal[lang]}
                        metric={data ? weeklyTotal : "-"}
                        color={textColor(darkmode)}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"weeklyTrend"} key={"weeklyTrend"}>
                    <AccountHighlight
                        title={accountData.highlights.weeklyTrend[lang]}
                        metric={(data && !isNaN(weeklyTrend)) ? weeklyTrend >= 0 ? weeklyTrend : (weeklyTrend * -1) : "-"}
                        color={(isNaN(weeklyTrend) || weeklyTrend === 0) ? textColor(darkmode) : weeklyTrend > 0 ? color.green : color.red}
                        type={"trend"}
                        prefix={!isNaN(weeklyTrend) ? weeklyTrend > 0 ? "+" : weeklyTrend < 0 ? "-" : "±" : ""}
                        suffix={!isNaN(weeklyTrend) ? "%" : ""}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"totalArticles"} key={"totalArticles"}>
                    <AccountHighlight
                        title={accountData.highlights.totalArticles[lang]}
                        metric={data ? articleTotal ? articleTotal : "0" : "-"}
                        color={textColor(darkmode)}
                    />
                </AccountGridElement>
                <AccountGridElement gridArea={"avgArticles"} key={"avgArticles"}>
                    <AccountHighlight
                        title={accountData.highlights.avgArticles[lang]}
                        metric={data ? articleAvg ? articleAvg : "0" : "-"}
                        color={color.green}
                    />
                </AccountGridElement>
            </AccountGridContainer>
        </AccountContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    userId: selectUserId,
    historyObj: selectHistoryObj,
    darkmode: selectDarkmode,
    targetReads: selectUserTargetReads
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserHistoryStart: (userId, fetchKey) => dispatch(fetchUserHistoryStart(userId, fetchKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);