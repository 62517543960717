import React, { Fragment, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { timeStamp, categoryColor } from "../general.utils";
import { color } from "../../design/theme";
import { articleDataCC } from "../lang.data";

import {
    PreviewCardWrapper,
    PreviewCardContainer,
    PreviewCardImageWrapper,
    PreviewCardContentContainer,
    PreviewCardContentDetails,
    PreviewCardContentText,
    PreviewCardContentIcon,
    PreviewCardContentDesc
} from "./previewCard.styles";
import ArticleTime from "../articleTime/articleTime.component";
import ArticleText from "../articleContent/articleText/articleText.content";
import DynamicImage from "../dynamicImage/dynamicImage.component";

const PreviewCard = ({ article, articleLog, lang, darkmode, minimal, extended, modal }) => {
    const { mainWidth } = useContext(LayoutContext);
    const minimalState = minimal || mainWidth <= 600;

    const { timestamp, articleCount, userMobile } = articleLog || {};
    const { title, catchline, image, articleId, category, newspaper, publishedAt, updatedAt, type } = article || {};
    const { copyright, imageUrl, imageId } = image || {};

    const deletedState = type === "deleted";
    const undefinedState = type === "undefined";

    return (
        <PreviewCardWrapper
            darkmode={darkmode}
            minimal={minimalState}
            extended={extended}
            modal={modal}
        >
            <PreviewCardContainer to={`/${articleId}`}>
                <PreviewCardImageWrapper
                    title={copyright ? `© ${copyright}` : null}
                    minimal={minimalState}
                >
                    <DynamicImage
                        imageId={imageId}
                        fallbackUrl={imageUrl}
                        removed={(deletedState || undefinedState)}
                    />
                </PreviewCardImageWrapper>
                <PreviewCardContentContainer>
                    <PreviewCardContentDetails>
                        {
                            !minimalState && !extended ? (
                                <Fragment>
                                    {
                                        articleCount > 1 ? (
                                            <PreviewCardContentText
                                                type={"specification count"}
                                                darkmode={darkmode}
                                                color={color.gray3}
                                            >
                                                <span>{articleCount}</span>
                                            </PreviewCardContentText>
                                        ) : null
                                    }
                                </Fragment>
                            ) : null
                        }
                        <PreviewCardContentText
                            type={"newspaper"}
                            darkmode={darkmode}
                            color={categoryColor(category, darkmode)}
                        >
                            <span>{(deletedState || undefinedState) ? "//" : newspaper?.toUpperCase()}</span>
                        </PreviewCardContentText>
                        {
                            !minimalState && !extended ? (
                                <PreviewCardContentText darkmode={darkmode}>
                                    <span>{`\xa0 @${timeStamp(timestamp, lang, "simple")}`}</span>
                                </PreviewCardContentText>
                            ) : null
                        }
                        {
                            !extended && !undefinedState ? (
                                <PreviewCardContentIcon
                                    name={userMobile ? "mobile" : "desktop"}
                                    size={20}
                                    color={darkmode ? color.gray4 : color.gray3}
                                    minimal={minimalState ? 1 : 0}
                                />
                            ) : null
                        }
                        {
                            !minimalState && !undefinedState ? (
                                <PreviewCardContentText
                                    type={"specification articleId"}
                                    darkmode={darkmode}
                                    color={color.gray3}
                                >
                                    <span>{articleId}</span>
                                </PreviewCardContentText>
                            ) : null
                        }
                    </PreviewCardContentDetails>
                    <PreviewCardContentDesc>
                        <ArticleText text={deletedState ? articleDataCC.deletedTitle[lang] : undefinedState ? articleDataCC.undefinedTitle[lang] : title} size={18} bold narrow contrast />
                        <ArticleText text={deletedState ? articleDataCC.deletedDesc[lang] : undefinedState ? articleDataCC.undefinedDesc[lang] : catchline} small narrow />
                    </PreviewCardContentDesc>
                    {
                        extended && !undefinedState ? (
                            <ArticleTime
                                publishedAt={publishedAt}
                                updatedAt={updatedAt}
                                live={type === "ticker"}
                                contrast
                            />
                        ) : null
                    }
                </PreviewCardContentContainer>
            </PreviewCardContainer>
        </PreviewCardWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(PreviewCard);