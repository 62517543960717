import React from "react";

import { ArticleParagraphContainer } from "./articleParagraph.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleParagraph = ({ content }) => {
    const { text, type } = content || {};

    return(
        <ArticleParagraphContainer>
            <ArticleText
                text={text}
                bold={type === "bold"}
                contrast={type === "bold"}
                italic={(type === "italic" || type === "source")}
            />
        </ArticleParagraphContainer>
    )
}

export default ArticleParagraph;