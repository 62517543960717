import UserLoginActionTypes from "./userLogin.types";

const INITIAL_STATE = {
    userAuth: null,
    userAuthFetching: false,
    errorMessage: null
}

const errorMessageSetter = (error) => {
    console.log("FIREBASE ERROR", error)
    switch (error.code) {
        case "auth/email-already-in-use":
            return "405";
        case "auth/wrong-password":
            return "406";
        case "auth/user-not-found":
            return "407";
        default:
            return "404";
    }
}

const userLoginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserLoginActionTypes.EMAIL_SIGN_UP_START:
        case UserLoginActionTypes.EMAIL_SIGN_IN_START:
        case UserLoginActionTypes.GOOGLE_SIGN_UP_START:
        case UserLoginActionTypes.GOOGLE_SIGN_IN_START:
        case UserLoginActionTypes.APPLE_SIGN_UP_START:
        case UserLoginActionTypes.APPLE_SIGN_IN_START:
            return {
                ...state,
                userAuthFetching: true,
                errorMessage: null
            }
        case UserLoginActionTypes.SIGN_UP_SUCCESS:
        case UserLoginActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                userAuthFetching: false,
                errorMessage: null
            }
        case UserLoginActionTypes.CHECK_USER_SESSION_SUCCESS:
            return {
                ...state,
                userAuth: action.payload,
                errorMessage: null
            }
        case UserLoginActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                userAuth: null,
                errorMessage: null
            }
        case UserLoginActionTypes.SIGN_UP_FAILURE:
        case UserLoginActionTypes.SIGN_IN_FAILURE:
        case UserLoginActionTypes.CHECK_USER_SESSION_FAILURE:
        case UserLoginActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                userAuth: null,
                userAuthFetching: false,
                errorMessage: errorMessageSetter(action.payload)
            }
        default:
            return state;
    }
}

export default userLoginReducer;