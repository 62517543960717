import React, { Fragment } from "react";
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { color } from "../../design/theme";

import {
    LinearProgressBar,
    CircularProgressContainer,
    CircularProgressBar
} from "./progress.styles";

const Progress = ({ type, value, darkmode }) => {
    const diameter = 20;
    const strokeWidth = 3;
    const radius = diameter / 2 - (strokeWidth - 1);
    const circumference = Math.PI * radius * 2;
    const position = Math.max(1 - value, 0);

    return (
        <Fragment>
        {
            type === "circular" ? (
                <CircularProgressContainer>
                    <CircularProgressBar
                        viewBox="0 0 20 20"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        baseProfile="full"
                    >
                        <circle
                            cx={diameter / 2}
                            cy={diameter / 2}
                            r={radius}
                            stroke={value > 1 ? color.red : color.blue}
                            strokeLinecap="round"
                            strokeDasharray={circumference}
                            strokeDashoffset={circumference * position}
                            strokeWidth={strokeWidth}
                            fill="transparent"
                        />
                    </CircularProgressBar>
                    <CircularProgressBar
                        viewBox="0 0 20 20"
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        baseProfile="full"
                    >
                        <circle
                            cx={diameter / 2}
                            cy={diameter / 2}
                            r={radius}
                            stroke={darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"}
                            strokeLinecap="round"
                            strokeDasharray={circumference}
                            strokeWidth={strokeWidth}
                            fill="transparent"
                        />
                    </CircularProgressBar>
                </CircularProgressContainer>
            ) : (
                <LinearProgressBar
                    value={value}
                    darkmode={darkmode}
                    max={"100"}
                />
            )
        }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(Progress);