import BreakingDataActionTypes from "./breakingData.types";

export const fetchBreakingDataStart = (reqHeader) => ({
    type: BreakingDataActionTypes.FETCH_BREAKING_DATA_START,
    payload: reqHeader
})

export const fetchBreakingDataSuccess = (breakingList, refKey) => ({
    type: BreakingDataActionTypes.FETCH_BREAKING_DATA_SUCCESS,
    payload: breakingList,
    refKey: refKey
})

export const fetchBreakingDataFailure = (errorMessage) => ({
    type: BreakingDataActionTypes.FETCH_BREAKING_DATA_FAILURE,
    payload: errorMessage
})