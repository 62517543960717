import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { createStructuredSelector } from "reselect";

import { setTargetReads, setListStart, deleteImageStart } from "../../redux/userEvents/userEvents.actions";
import { selectUserId, selectUserLanguage, selectUserTargetReads } from "../../redux/userData/userData.selectors";
import { selectUploadedImage, selectDeletedImage } from "../../redux/userEvents/userEvents.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserAuthId } from "../../redux/userLogin/userLogin.selectors";
import { miscData, accountData } from "../lang.data";

import InputField from "../inputField/inputField.component";
import ModalAction from "../modalAction/modalAction.component";
import Notice from "../notice/notice.component";

const ModalInput = ({ userId, darkmode, onDismiss, type, lang, targetReads, setTargetReads, imageId,
                      refValues, uploadedImage, deletedImage, setListStart, deleteImageStart }) => {

    const { listId, title, desc } = refValues || {};
    const [targetWarning, setTargetWarning] = useState(false);

    const required = (value) => ((!value || value.length === 0) ? "required" : undefined)
    const typeOfTarget = (value) => {
        setTargetWarning(parseInt(value) === parseInt(targetReads));
        return (
            ((value.length > 2) || ((parseInt(value) === 0) && (value.length === 2)) || isNaN(parseInt(value))
                || isNaN(value - 0)) || (parseInt(value) === parseInt(targetReads))
                ? "targetError" : undefined
        )
    }
    const typeOfTitle = (value) => (value.length > 30) ? "titleError" : undefined;
    const typeOfDesc = (value) => (value && value.length > 140) ? "descError" : undefined;

    const composeValidators = (...validators) => value =>
        validators.reduce((error, validator) => error || validator(value), undefined)

    const invalidSubmission = (invalid, submitting, pristine, values) => {
        if (refValues) {
            return (invalid || submitting || pristine)
                    || ((values.title ? values.title === title : true)
                    && (values.description === desc || (!values.description && !desc))
                    && (((values.image === "delete") && !imageId) || !values.image))
        } else {
            return (invalid || submitting || pristine)
        }
    }
    const onSubmit = async (values) => {
        if(type === "target") {
            await setTargetReads(userId, values);
        } else if(type === "list") {
            await setListStart(userId, listId, values)
        }
        onDismiss()
    }

    return(
        <>
            <Form
                onSubmit={onSubmit}
                subscription={{ submitting: true, invalid: true, pristine: true, values: true }}
                initialValues={{ image: deletedImage ? "delete" : uploadedImage }}
                keepDirtyOnReinitialize={true}
                render={({ handleSubmit, submitting, pristine, invalid, values }) => (
                    <form onSubmit={handleSubmit}>
                        {
                            type === "target" ? (
                                <Field
                                    validate={composeValidators(required, typeOfTarget)}
                                    subscription={{ touched: true, dirty: true, error: true, value: true, valid: true }}
                                    name={"target"}
                                    component={InputField}
                                    placeholder={"max. 99"}
                                    minValue={"1"}
                                    maxValue={"99"}
                                    darkmode={darkmode}
                                    type={"number"}
                                    checkType={true}
                                    modal
                                />
                            ) : (
                                <Fragment>
                                    <Field
                                        validate={composeValidators(required, typeOfTitle)}
                                        subscription={{ touched: true, dirty: true, error: true, value: true, valid: true }}
                                        name={"title"}
                                        component={InputField}
                                        placeholder={miscData.title[lang]}
                                        defaultValue={title ? title : ""}
                                        insertedValue={title ? title : ""}
                                        max={"30"}
                                        darkmode={darkmode}
                                        type={"text"}
                                        checkType={true}
                                        modal
                                    />
                                    <Field
                                        validate={composeValidators(typeOfDesc)}
                                        subscription={{ touched: true, error: true, value: true, valid: true, dirty: true }}
                                        name={"description"}
                                        component={InputField}
                                        placeholder={miscData.desc[lang]}
                                        defaultValue={desc ? desc : ""}
                                        insertedValue={desc ? desc : ""}
                                        darkmode={darkmode}
                                        type={"text"}
                                        max={"140"}
                                        checkType={true}
                                        area={"true"}
                                        modal
                                    />
                                </Fragment>
                            )
                        }
                        {
                            type === "target" && targetWarning ? (
                                <Notice
                                    type={"warning"}
                                    desc={accountData.targetReads.newCantBeOld[lang]}
                                    size={"small"}
                                />
                            ) : null
                        }
                        <ModalAction
                            type={"submit"}
                            disabled={invalidSubmission(invalid, submitting, pristine, values)}
                            // loading={loading}
                            secondaryCallback={() => (uploadedImage || deletedImage) && deleteImageStart(userId, "reset")}
                            onDismiss={onDismiss}
                        />
                    </form>
                )}
            />
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    lang: selectUserLanguage,
    authId: selectUserAuthId,
    darkmode: selectDarkmode,
    targetReads: selectUserTargetReads,
    uploadedImage: selectUploadedImage,
    deletedImage: selectDeletedImage
})

const mapDispatchToProps = (dispatch) => ({
    setTargetReads: (userId, target) => dispatch(setTargetReads(userId, target)),
    setListStart: (userId, listId, listDetails) => dispatch(setListStart(userId, listId, listDetails)),
    deleteImageStart: (userId, refType) => dispatch(deleteImageStart(userId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalInput);