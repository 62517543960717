const UserSettingsActionTypes = {
    SET_USER_LANGUAGE_START: "SET_USER_LANGUAGE_START",
    SET_USER_LANGUAGE_SUCCESS: "SET_USER_LANGUAGE_SUCCESS",
    SET_USER_LANGUAGE_FAILURE: "SET_USER_LANGUAGE_FAILURE",

    SET_USER_EMBED_CONSENT_START: "SET_USER_EMBED_CONSENT_START",
    SET_USER_EMBED_CONSENT_SUCCESS: "SET_USER_EMBED_CONSENT_SUCCESS",
    SET_USER_EMBED_CONSENT_FAILURE: "SET_USER_EMBED_CONSENT_FAILURE",
}

export default UserSettingsActionTypes;