import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState, createContext } from 'react';
import { Redirect, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { resizeApp, loadTopBar, getCurrentPath } from "./components/general.utils";
import { selectUserAuth } from "./redux/userLogin/userLogin.selectors";
import { selectUser } from "./redux/userData/userData.selectors";
import { selectDarkmode } from "./redux/persistData/persistData.selectors";
import { selectScrollState } from "./redux/sessionData/sessionData.selectors";
import { checkUserSessionStart } from "./redux/userLogin/userLogin.actions";
import { setScrollState } from "./redux/sessionData/sessionData.actions";

import { GlobalStyle, GlobalContainer } from "./global.styles";
import Navigation from "./components/navigation/navigation.component";
import MainWrapper from "./pages/wrapper/main.wrapper";
import TimelinePage from "./pages/main/timelinePage/timeline.page";
import LoginPage from "./pages/main/loginPage/login.page";
import FullscreenLoader from "./components/fullscreenLoader/fullscreenLoader.component";
import ErrorBoundary from "./components/errorBoundary/errorBoundary.component";

import BreakingContextProvider from "./context/breaking.context";
import RecentContextProvider from "./context/recent.context";
import SearchContextProvider from "./context/search.context";
import HeaderContextProvider from "./context/header.context";

export const LayoutContext = createContext(null);

const App = ({ checkUserSession, userAuth, userData, darkmode, scrollState, setScrollState }) => {
    const[appSize, setAppSize] = useState(resizeApp(window.innerWidth));
    const[scrollPosition, setScrollPosition] = useState(0);
    const[currentPage, setCurrentPage] = useState("");
    const[mainWidth, setMainWidth] = useState(800);

    const location = useLocation();

    const handleMainWidthAdjustment = () => {
        let mainFrame = document.getElementById("#main");
        if(mainFrame) setMainWidth(mainFrame.scrollWidth)
    }

    useMemo(() => { setScrollPosition(document.body.scrollTop) }, [location])
    useLayoutEffect(() => {
        if(getCurrentPath(location) !== currentPage) {
            let currentPath = getCurrentPath(window.location);
            let evergreenPaths = ["/home", "/popular", "/history", "/channel/"];
            let excludedPaths = ["/settings", "/login", "/signup"];
            if(((scrollState[currentPath] === undefined && !excludedPaths.some(path => currentPath.includes(path))) || evergreenPaths.some(path => currentPath.includes(path))) && userAuth && userData) {
                loadTopBar(evergreenPaths.includes(currentPath), darkmode);
            }
            if(scrollState[currentPath]) {
                document.body.scrollTo(0, scrollState[currentPath])
            } else document.body.scrollTo(0, 0)
        }

        const excludedPaths = ["/settings", "/account", "/login", "/signup"];
        const excludedPages = ["/library", "/library/lists", "/", ""];
        let validPath = !excludedPaths.some(path => currentPage.includes(path))
        let validPage = !excludedPages.some(page => page === currentPage)
        if((getCurrentPath(location) !== currentPage) && validPath && validPage) {
            setScrollState(scrollPosition, currentPage)
        }
        setCurrentPage(getCurrentPath(location))
    }, [location])

    useEffect(() => {
        checkUserSession()
        window.addEventListener("resize", event => {
            setAppSize(resizeApp(event, appSize))
            handleMainWidthAdjustment()
        }, true)
        if(window.history.scrollRestoration !== "manual") window.history.scrollRestoration = "manual";

        return () => window.removeEventListener("resize", setAppSize, true);
    }, [userData, userAuth])

    return (
        <GlobalContainer about={"Hello there! Go check out reavi.de/bio 🍀"}>
            <GlobalStyle darkmode={darkmode} />
            <ErrorBoundary type={"global"}>
                {
                    (userAuth && userData) ? (
                        <LayoutContext.Provider value={{ appSize, mainWidth, handleMainWidthAdjustment }}>
                            <BreakingContextProvider>
                                <Navigation />
                                <HeaderContextProvider>
                                    <RecentContextProvider>
                                        <SearchContextProvider>
                                            <MainWrapper />
                                        </SearchContextProvider>
                                    </RecentContextProvider>
                                </HeaderContextProvider>
                                {
                                    (appSize < 3) ? <TimelinePage /> : null
                                }
                            </BreakingContextProvider>
                        </LayoutContext.Provider>
                    ) : (userAuth) ? (
                        <FullscreenLoader />
                    ) : (
                        <Fragment>
                            <Redirect from={"/"} to={"/login"} />
                            <Route path={"/:login"} component={LoginPage} />
                        </Fragment>
                    )
                }
            </ErrorBoundary>
        </GlobalContainer>
    );
}

const mapStateToProps = createStructuredSelector({
    userAuth: selectUserAuth,
    userData: selectUser,
    darkmode: selectDarkmode,
    scrollState: selectScrollState
})

const mapDispatchToProps = (dispatch) => ({
    checkUserSession: () => dispatch(checkUserSessionStart()),
    setScrollState: (scrollState, pathName) => dispatch(setScrollState(scrollState, pathName))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);