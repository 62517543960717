import React, { useState, useLayoutEffect, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { color } from "../../../design/theme";
import { HeaderContext } from "../../../context/header.context";
import { settingsData, loginData, geoData, titleData, miscData } from "../../../components/lang.data";
import { selectUserEmail, selectUserLanguage, selectUserSubscriptionType, selectUserId } from "../../../redux/userData/userData.selectors";
import { selectUserAuthId } from "../../../redux/userLogin/userLogin.selectors";
import { signOutStart } from "../../../redux/userLogin/userLogin.actions";
import { selectTheme } from "../../../redux/persistData/persistData.selectors";
import { capitalize } from "../../../components/general.utils";

import { SettingsContainer } from "./settings.styles";
import Indicator from "../../../components/indicator/indicator.component";
import SettingsCard from "../../../components/settingsCard/settingsCard.component";
import CustomButton from "../../../components/customButton/customButton.component";
import ContextMenu from "../../../components/contextMenu/contextMenu.component";
import ModeSelector from "../../../components/modeSelector/modeSelector.component";
import Modal from "../../../components/modal/modal.component";
import SiteHead from "../../../components/siteHead/siteHead.component";
import ModalAction from "../../../components/modalAction/modalAction.component";

// reavide

const SettingsPage = ({ email, subscriptionType, lang, theme, userId, authId, signOutStart }) => {
    const { setSimpleEnv } = useContext(HeaderContext)
    const [logoutActive, setLogoutActive] = useState(false);
    const history = useHistory();

    useLayoutEffect(() => {
        setSimpleEnv(true);
        return () => setSimpleEnv(null)
    }, [setSimpleEnv])

    return (
        <SettingsContainer>
            <SiteHead title={titleData.settings[lang]} />
            <Indicator
                pageLabel={titleData.settings[lang]}
                pageLabelEmoji={"🔐"}
                subLabel={`©${(new Date()).getFullYear()} reavide KlG. ${miscData.copyright[lang]}`}
                type={"settings"}
            />
            <SettingsCard
                title={settingsData.titles.account[lang]}
                contentData={[
                    {
                        desc: settingsData.account.email,
                        label: email, // rename to label, reserve info for additional disclaimers that expand upon the provided functionality options
                        icon: "email",
                        type: "locked",
                        actionIcon: "lock"
                    },{
                        desc: settingsData.account.password,
                        label: "************",
                        icon: "password",
                        type: "locked",
                        actionIcon: "lock"
                    },{
                        desc: settingsData.account.subscription,
                        label: capitalize(subscriptionType),
                        icon: "subscription",
                        type: "locked",
                        actionIcon: "lock"
                    },
                ]}
            />
            <SettingsCard
                title={settingsData.titles.experience[lang]}
                contentData={[
                    {
                        desc: settingsData.experience.theme,
                        icon: "theme",
                        type: "action",
                        action: (
                            <ModeSelector
                                data={settingsData.theme}
                                current={theme}
                                type={"theme"}
                            />
                        )
                    },{
                        desc: settingsData.experience.language,
                        icon: "language",
                        type: "action",
                        action: (
                            <ContextMenu
                                data={geoData.language}
                                current={lang}
                                type={"lang"}
                            />
                        )
                    },{
                        desc: settingsData.experience.region,
                        icon: "region",
                        label: "Basel",
                        type: "locked",
                        actionIcon: "lock"
                    }, {
                        desc: settingsData.experience.embeds,
                        icon: "embeds",
                        type: "internal",
                        link: "/settings/embeds",
                        actionIcon: "next"
                    }
                ]}
            />
            <SettingsCard
                title={settingsData.titles.misc[lang]}
                contentData={settingsData.misc}
            />
            <CustomButton
                callback={() => setLogoutActive(!logoutActive)}
                desc={loginData.logout[lang]}
                color={color.blue}
                width={200}
                center
            />
            {
                logoutActive ? (
                    <Modal
                        type={"prompt"}
                        icon={"logout"}
                        title={loginData.logout[lang]}
                        desc={miscData.confirmAction[lang]}
                        onDismiss={() => setLogoutActive(!logoutActive)}
                    >
                        <ModalAction
                            callback={() => {
                                history.go(0); // Forces refresh and clears all data, ideally handled via native redux approach
                                if(userId === authId) signOutStart();
                            }}
                            onDismiss={() => setLogoutActive(!logoutActive)}
                        />
                    </Modal>
                ) : null
            }
        </SettingsContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    email: selectUserEmail,
    theme: selectTheme,
    subscriptionType: selectUserSubscriptionType,
    userId: selectUserId,
    authId: selectUserAuthId
})

const mapDispatchToProps = (dispatch) => ({
    signOutStart: () => dispatch(signOutStart())
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);