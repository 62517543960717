import ChannelRefActionTypes from "./channelRef.types";

export const fetchChannelRefStart = (reqHeaders, refKey = "") => ({
    type: ChannelRefActionTypes.FETCH_CHANNEL_REF_START,
    payload: reqHeaders,
    refKey: refKey
})

export const fetchChannelRefSuccess = (channelRefList, refKey = "") => ({
    type: ChannelRefActionTypes.FETCH_CHANNEL_REF_SUCCESS,
    payload: channelRefList,
    refKey: refKey
})

export const fetchChannelRefFailure = (errorMessage, refKey = "") => ({
    type: ChannelRefActionTypes.FETCH_CHANNEL_REF_FAILURE,
    payload: errorMessage,
    refKey: refKey
})