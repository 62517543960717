import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleFallbackContainer,
    ArticleFallbackIcon
} from "./articleFallback.styles";
import ArticleText from "../articleText/articleText.content";

const ArticleFallback = ({ darkmode }) => {
    return (
        <ArticleFallbackContainer darkmode={darkmode}>
            <ArticleFallbackIcon
                name={"warning"}
                size={34}
                color={color.yellow}
            />
            <ArticleText text={"This content is currently unavailable."} bold auto />
        </ArticleFallbackContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleFallback);