import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import articleDataReducer from "./articleData/articleData.reducer";
import articleRefReducer from "./articleRef/articleRef.reducer";
import channelRefReducer from "./channelRef/channelRef.reducer";
import breakingDataReducer from "./breakingData/breakingData.reducer";
import userLoginReducer from "./userLogin/userLogin.reducer";
import userDataReducer from "./userData/userData.reducer";
import persistDataReducer from "./persistData/persistData.reducer";
import userEventsReducer from "./userEvents/userEvents.reducer";
import sessionDataReducer from "./sessionData/sessionData.reducer";
import userSettingsReducer from "./userSettings/userSettings.reducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userLogin", "persistData"]
}

const appReducer = combineReducers({
    articleData: articleDataReducer,
    articleRef: articleRefReducer,
    breakingData: breakingDataReducer,
    channelRef: channelRefReducer,
    persistData: persistDataReducer,
    sessionData: sessionDataReducer,
    userLogin: userLoginReducer,
    userData: userDataReducer,
    userEvents: userEventsReducer,
    userSettings: userSettingsReducer
})

const rootReducer = (state, action) => {
    if(action.type === "SIGN_OUT_SUCCESS") return appReducer(undefined, action)
    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer);