import { reverse } from "lodash";
import { compareDates, calcDayDiff } from "../../../components/general.utils";

export const reducedAccView = (mainWidth) => {
    return mainWidth < 800 && mainWidth > 600
}

export const calcDailyReads = (option, historyData) => {
    let dailyReadArr = [];
    let ctrlDateObj = new Date();
    let triggerDateObj = calcDateUntil(option);
    let triggerDate = triggerDateObj.setDate(triggerDateObj.getDate() + 6) // .getDate();
    let dailyNum = 0;
    let untilDate = calcDateUntil(option);
    let counterTrigger = (option !== "lastWeek");

    for(let i = 0; i < historyData.length; i++) {
        let readAtISO = historyData[i].timestamp;
        let readAt = new Date(readAtISO);
        // let readAtDate = readAt.getDate();

        if(compareDates(readAt, ctrlDateObj)) { // readAtDate === ctrlDate
            dailyNum += 1;
            if(i === historyData.length - 1) dailyReadArr.push(dailyNum);
        } else {
            ctrlDateObj.setDate(ctrlDateObj.getDate() - 1);
            if(counterTrigger) dailyReadArr.push(dailyNum);
            dailyNum = 0;

            if(option === "lastWeek") {
                if(compareDates(triggerDate, ctrlDateObj)) counterTrigger = true; // ctrlDate === triggerDate
                // if(compareDates(untilDate, historyData[historyData.length - 1].timestamp, 0, "smaller")) break;
            }
            if(option && compareDates(ctrlDateObj, untilDate, option === "week" ? 0 : 1, "smaller")) break; // ctrlDateObj - untilDate < 0

            if(compareDates(readAt, ctrlDateObj)) { // readAtDate === ctrlDate
                dailyNum += 1;
                if(i === historyData.length - 1) dailyReadArr.push(dailyNum);
            } else if(compareDates(readAt, ctrlDateObj, 0, "smaller")) i--; // readAt - triggerDateObj <= 0
        }
    }

    if(!dailyReadArr.length) dailyReadArr.push(0);
    return dailyReadArr;
}

export const calcArticleTotal = (option, historyData) => {
    if(!historyData) return 0;
    let dailyReadArr = calcDailyReads(option, historyData);
    let total = 0;

    for(let i = 0; i < dailyReadArr.length; i++) {
        total += dailyReadArr[i]
    }
    return total;
}

export const calcArticleAvg = (option, historyData) => {
    if(!historyData) return 0;
    let numerator = calcArticleTotal(option, historyData);
    let denominator = calcDailyReads(option, historyData).length;
    if((option === "week" && new Date().getDay() === 1) || !option) denominator -= 1;

    console.log("ARTICLE AVERAGE ::", option, numerator, calcDailyReads(option, historyData), denominator)

    if(denominator <= 0) return 0;
    const result = Number(Math.round(parseFloat((numerator / denominator) + 'e' + 3)) + 'e-' + 3);

    if(!!option) return result;
    if(Number((result % 1.00).toFixed(1)) === 0.0) {
        return result.toFixed(0);
    } else {
        return result.toFixed(1);
    }
}

export const calcWeeklyTrend = (historyData) => {
    if(!historyData) return "none";
    let currentWeekAvg = calcArticleAvg("week", historyData);
    let lastWeekAvg = calcArticleAvg("lastWeek", historyData);

    console.log("CURRENT WEEK AVG", currentWeekAvg)
    console.log("LAST WEEK AVG", lastWeekAvg)

    if(lastWeekAvg === 0 || currentWeekAvg === 0) return "none";
    let resultRaw = Number(Math.round(parseFloat((currentWeekAvg / lastWeekAvg)*100 + 'e' + 3)) + 'e-' + 3)

    return resultRaw.toFixed(0) - 100;
}

export const calcStreaks = (option, historyData, target) => {
    if(!historyData || !target) return 0;
    let dailyReadArr = calcDailyReads("", historyData);

    if(option === "current") {
        let i = 0;
        let result = 0;
        if(dailyReadArr[0] < target) i = 1;
        while(i < dailyReadArr.length) {
            if(dailyReadArr[i] >= target) {
                result++
                i++
            } else { break }
        }
        return result;
    } else {
        let maxResult = 0;
        let result = 0;
        for(let i = 0; i < dailyReadArr.length; i++) {
            if(dailyReadArr[i] >= target) {
                result++
                if((i === dailyReadArr.length - 1) && (result > maxResult)) maxResult = result;
            } else {
                if(result > maxResult) maxResult = result;
                result = 0;
            }
        }
        return maxResult;
    }
}

export const calcDateUntil = (option) => {
    const resultDateObj = new Date();
    if(!option) return resultDateObj;

    if(option === "week") {
        while(resultDateObj.getDay() !== 1) {
            resultDateObj.setDate(resultDateObj.getDate() - 1);
        }
    } else if(option === "lastWeek") {
        for(let i = 0; i < 2; i++) {
            while(resultDateObj.getDay() !== 1) {
                resultDateObj.setDate(resultDateObj.getDate() - 1);
            }
            if(i === 0) resultDateObj.setDate(resultDateObj.getDate() - 1);
        }
    } else if(option === "30days") {
        resultDateObj.setDate(resultDateObj.getDate() - 30);
    }
    return resultDateObj
}

const calcChartCategories = (option, historyData) => {
    let groupedHistArr = [[]];
    let ctrlDateISO = new Date().toISOString();
    let ctrlDateObj = new Date(ctrlDateISO);
    let ctrlDate = ctrlDateObj.getDate();
    let untilDate = option ? calcDateUntil(option) : new Date();

    for(let i = 0; i < historyData.length; i++) {
        let readAtISO = historyData[i].timestamp;
        let readAt = new Date(readAtISO);
        // let readAtDate = readAt.getDate();

        if(compareDates(readAt, ctrlDateObj)) { // readAtDate === ctrlDate
            groupedHistArr[(groupedHistArr.length - 1)].push(historyData[i])
        } else {
            ctrlDateObj.setDate(ctrlDateObj.getDate() - 1);
            ctrlDate = ctrlDateObj.getDate()
            if(option && compareDates(ctrlDateObj, untilDate, option === "week" ? 0 : 1, "smaller")) break; // ctrlDateObj.setHours(0,0,0,0) - untilDate.setHours(0,0,0,0) < 1
            groupedHistArr.push([]);

            if(compareDates(readAt, ctrlDateObj)) { // readAtDate === ctrlDate
                groupedHistArr[(groupedHistArr.length - 1)].push(historyData[i])
            } else {
                i--;
            }
        }
    }
    if(compareDates(untilDate, ctrlDateObj, 0, "smaller")) {
        console.log("CALC DAY DIFF", option, calcDayDiff(ctrlDateObj, untilDate) - 1);
        [...Array((calcDayDiff(ctrlDateObj, untilDate) - 1) || 0)].forEach(() => groupedHistArr.push([]))
    }
    console.log("GROUPED HIST ARR", option, groupedHistArr)
    let categorizedHistArr = []

    for(let i = 0; i < groupedHistArr.length; i++) {
        categorizedHistArr.push([0, 0, 0, 0, 0, 0, 0])
        for(let j = 0; j < groupedHistArr[i].length; j++) {
            if(groupedHistArr[i][j].category === "politics") {
                categorizedHistArr[i][0] += 1;
            } else if(groupedHistArr[i][j].category === "society") {
                categorizedHistArr[i][1] += 1;
            } else if(groupedHistArr[i][j].category === "sports") {
                categorizedHistArr[i][2] += 1;
            } else if(groupedHistArr[i][j].category === "science") {
                categorizedHistArr[i][3] += 1;
            } else if(groupedHistArr[i][j].category === "technology") {
                categorizedHistArr[i][4] += 1;
            } else if(groupedHistArr[i][j].category === "economy") {
                categorizedHistArr[i][5] += 1;
            } else if(groupedHistArr[i][j].category === "special") {
                categorizedHistArr[i][6] += 1;
            }
        }
    }
    console.log("CATEGORIZED HIST ARR", option, categorizedHistArr)
    let catChartArr = [[], [], [], [], [], [], []];

    for(let i = 0; i < categorizedHistArr.length; i++) {
        for(let j = 0; j < categorizedHistArr[i].length; j++) {
            catChartArr[j].push(categorizedHistArr[i][j]);
        }
    }
    console.log("CAT CHART ARR", option, JSON.stringify(catChartArr))
    return catChartArr;
}

export const calcBarChart = (option, historyData, loading) => {
    if(!historyData?.length) {
        let arrContentLength = option === "week" ? 7 : 30;
        let returnArray = [[], [], [], [], [], [], []];
        for(let i = 0; i < returnArray.length; i++) {
            for(let j = 0; j < arrContentLength; j++) {
                let generatedNum = loading ? 0 : (Math.floor((Math.random() * 18) + 1))
                if(!i) returnArray[i].push(generatedNum);
                else returnArray[i].push(0);
            }
        }
        return returnArray;
    }
    let ctrlLength;
    if(option === "week") ctrlLength = 7;
    if(option === "30days") ctrlLength = 30;

    let barChartArr = calcChartCategories(option, historyData);

    // if(option === "week") barChartArr.forEach(arr => reverse(arr))
    const lengthDiff = ctrlLength - barChartArr[0].length;
    if(lengthDiff > 0) {
        console.log("BAR CHART ARR LENGTH DIFF", option, lengthDiff)
        if(option === "week") for(let i = 0; i < lengthDiff; i++) barChartArr.forEach(arr => arr.unshift(0))
        else for(let i = 0; i < lengthDiff; i++) barChartArr.forEach(arr => arr.push(0))
    }
    barChartArr.forEach(arr => reverse(arr))
    console.log("BAR CHART ARR", option, barChartArr)
    return barChartArr;
}

export const calcPieChart = (option, historyData) => {
    if(!historyData?.length) return [1, 0, 0, 0, 0, 0, 0];
    let pieChartArrRaw = calcChartCategories(option, historyData);

    let pieChartArr = [];
    for(let i = 0; i < pieChartArrRaw.length; i++) {
        let result = 0;
        for(let j = 0; j < pieChartArrRaw[i].length; j++) {
            result += pieChartArrRaw[i][j]
        }
        pieChartArr.push(result)
    }
    console.log("PIE CHART ARR", option, pieChartArr)
    return pieChartArr;
}

export const calcXAxisLabels = (option, variant) => {
    const untilObj = calcDateUntil(option);
    let ctrlDateISO = new Date().toISOString();
    let ctrlDateObj = new Date(ctrlDateISO);
    let resultArray = [];

    while(!compareDates(ctrlDateObj, untilObj)) {
        let ctrlDate = ctrlDateObj.getDate().toString();
        let ctrlMonth = (ctrlDateObj.getMonth() + 1).toString();
        let ctrlYear = (variant === 2) ? (ctrlDateObj.getFullYear()).toString() : "";
        if(ctrlDate.length <= 1) ctrlDate = "0" + ctrlDate
        if(ctrlMonth.length <= 1) ctrlMonth = "0" + ctrlMonth
        resultArray.push(ctrlDate + "." + ctrlMonth + "." + ctrlYear);
        ctrlDateObj.setDate(ctrlDateObj.getDate() - 1);
    }
    return reverse(resultArray);
}