import styled from "styled-components";
import { color, radius, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const BreakingCardContainer = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    padding-left: 45px;
    transform: translate3d(0,0,0); // forces tap into webGPU
    content-visibility: auto;
    will-change: scroll-position;
    
    &::before {
        content: "";
        height: 34px;
        width: 34px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
        box-shadow: inset 0 0 0 8px ${color.blue};
        margin: 32px 0 0 1px;
    } 
`;

export const BreakingCardDetailsContainer = styled.div`
    width: inherit;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 8px 15px;
`

export const BreakingCardTimestamp = styled.div`
    font-size: 16px;
    font-family: ${font.interMedium};
    bottom: 5px;
    color: ${props => props.darkmode ? color.white : color.black};
    display: flex;
    user-select: none;
`;

export const BreakingCardButtonContainer = styled.a`
    display: flex;
    height: auto;
    width: auto;
    margin-left: 10px;

    &:hover {
        background-color: ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
        box-shadow: 0 0 0 4px ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
        border-radius: 50%;
    }
`

export const BreakingCardIcon = styled(Icon)`
    height: auto;
    width: auto;
    justify-content: center;
`

export const BreakingCardNewsContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: ${radius.largeRadius};
    background-color: ${props => props.highlight ? props.darkmode ? color.blueActiveDark : color.blueActiveLight : props.darkmode ? color.gray2 : color.white};
    padding: 25px 25px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &::-moz-focus-inner {
        border: 0;
    }
  
    &>div:first-child {
        margin-bottom: 15px;
    }
`;