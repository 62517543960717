import noChannelsError from "../../design/assets/errorArt-noChannels.png";
import noBookmarksError from "../../design/assets/errorArt-noBookmarks.png";
import noListsError from "../../design/assets/errorArt-noLists.png";
import contentMissingError from "../../design/assets/errorArt-404.png";
import noSearchError from "../../design/assets/errorArt-noSearch.png"

export const errorArtOrigin = (type) => {
    if (type === "noChannels" || type === "noChannelsHome") {
        return noChannelsError;
    } else if (type === "noBookmarks" || type === "noHistory") {
        return noBookmarksError;
    } else if(type === "noLists" || type === "noCollection") {
        return noListsError;
    } else if(type === "noSearch") {
        return noSearchError;
    } else {
        return contentMissingError;
    }
}

export const errorArtPreload = () => {
    const preloadImages = [noChannelsError, noBookmarksError, noListsError, contentMissingError];
    preloadImages.forEach(async (image) => new Image().src = await image)
}