import styled from "styled-components";
import { radius, color, font } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const ModalListImageSetter = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    margin: 0 0 15px 0;
`

export const ModalListImageContainer = styled.div`
    height: 140px;
    width: 140px;
    margin: 0 15px 0 0;
    flex-direction: row;
    border-radius: ${radius.smallRadius};
    overflow: hidden;
    position: relative;
`

export const ModalListImagePreview = styled.canvas`
    width: inherit;
    height: inherit;
    display: ${props => props.visible ? "inherit" : "none"};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

export const ModalListActionContainer = styled.div`
    flex-grow: 100;
    flex-direction: row;
    display: flex;
    justify-content: center;
    position: relative;
`

export const ModalListButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
  
    &>button:first-child {
        margin-bottom: 10px;
    }
`

export const ModalListDropzoneWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 25px;
    top: 0;
    left: 0;
    background-color: ${props => props.dropzoneactive ? props.darkmode ? color.blueActiveDark : color.blueActiveLight : (props.darkmode ? color.gray2 : color.white) + "F2"};
    z-index: 10;
`

export const ModalListDropzoneInput = styled.input`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    background-color: transparent;
    color: transparent;
    opacity: 0;
    font-size: 0;
    z-index: 1001;
`

export const ModalListDropzoneContainer = styled.div`
    height: 100%;
    width: 100%;
    border: 5px ${color.blue} dashed;
    border-radius: ${radius.smallRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    user-input: none;
    user-focus: none;
`

export const ModalListDropzoneContent = styled.div`
    height: max-content;
    width: auto;
    padding: 0 40px;  
    opacity: 1;
`

export const ModalListDropzoneIcon = styled(Icon)`
    height: auto;
    width: auto;
    justify-content: center;
`

export const ModalListDropzoneDesc = styled.div`
    margin-top: 10px;
    font-family: ${font.pulpDisplayBold};
    font-size: 30px;
    color: ${color.blue}
`
