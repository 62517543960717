import styled from "styled-components";
import { radius, color } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleFallbackContainer = styled.div`
    height: auto;
    width: inherit;
    padding: 35px 45px;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
`

export const ArticleFallbackIcon = styled(Icon)`
    position: relative;
    margin-right: 10px;
`