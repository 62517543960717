import styled from "styled-components";
import { color, font, radius } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleCaptionWrapper = styled.div`
    width: auto;
    height: auto;
    min-height: 44px;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    padding: ${props => props.gallery ? "9px 10px 9px 15px" : "10px 20px 10px 15px"};
    margin: 15px 0 30px 0;
`;

export const ArticleCaptionIconContainer = styled.div`
    height: inherit;
    width: auto;
    // justify-content: center;
    margin: ${props => props.gallery ? "3px" : "2px"} 15px 0 0;
    display: inline-flex;
`;

export const ArticleCaptionIcon = styled(Icon)`
    width: auto;
    justify-content: center;
    cursor: ${props => props.callback ? "pointer" : "default"};
`;

export const ArticleCaptionDescContainer = styled.div`
    display: inline-flex;
    margin-top: ${props => props.gallery ? "1px" : "0"};
    text-align: left;
    flex-grow: 100;
    height: 100%;
    width: auto;
`;

export const ArticleCaptionCounterWrapper = styled.div`
    width: auto;
    height: 26px;
    background-color: ${props => props.darkmode ? color.gray1 : color.gray5};
    border-radius: 6px;
    padding: 0 16px;
    margin-left: 10px;
`

export const ArticleCaptionCounterContainer = styled.div`
    font-family: ${font.pulpDisplayBold};
    font-size: 16px;
    line-height: 26px;
    color: ${props => props.darkmode ? color.gray4 : color.gray2};
    display: block;
    width: max-content;
  
    & > span:first-child {
        color: ${color.blue};
    }
`