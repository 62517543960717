import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Interweave from "interweave";
import { HashtagMatcher, EmailMatcher } from "interweave-autolink";

import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { sanitizeUrl, isValidEmail } from "../../general.utils";

import { ArticleTextContainer, ArticleTextLink, ArticleTextIcon } from "./articleText.styles";

const ArticleText = ({ text, darkmode, bold, italic, small, color, contrast, auto, narrow, size }) => {
    const transform = (node, children) => {
        if (node.tagName.toLowerCase() === "a") {
            let internalLink = (node.href.includes("reavi.de") && node.href.split("reavi.de/")[1]?.length === 9) || node.href.includes("news.reavide.com")
            let internalPath = node.href.split(node.href.substring(0, node.href?.lastIndexOf("/")))[1] || "";
            let potentialEmail = node.href.split("mailto:")[1] || node.href.split("//")[1]?.replace(/\//g, "");
            let potentialEmailVerified = isValidEmail(potentialEmail);
            let externalPath = !internalLink ? potentialEmailVerified ? `mailto:${potentialEmail}` : sanitizeUrl(node.getAttribute("href")) : "";

            return (
                <ArticleTextLink
                    to={internalLink ? `${internalPath}` : { pathname: externalPath }}
                    href={internalLink ? `${internalPath}` : potentialEmailVerified ? `mailto:${potentialEmail}` : sanitizeUrl(node.getAttribute("href"))}
                    target={(internalLink || potentialEmailVerified) ? null : "_blank"}
                    rel={internalLink ? null : "external nofollow noreferrer noopener"}
                    type={potentialEmailVerified ? "email" : "text/html"}
                    media={potentialEmailVerified ? null : "all"}
                    internal={internalLink ? "true" : null}
                >
                    {children}
                    { !internalLink && <ArticleTextIcon name={"external"} /> }
                </ArticleTextLink>
            );
        }
        if ((node.tagName.toLowerCase() === "b" || node.tagName.toLowerCase() === "strong") && italic) {
            return (
                <strong><em>{children}</em></strong>
            );
        }
        if ((node.tagName.toLowerCase() === "i" || node.tagName.toLowerCase() === "em") && bold) {
            return (
                <em><strong>{children}</strong></em>
            );
        }
    }

    return (
        <ArticleTextContainer
            darkmode={darkmode}
            bold={bold}
            italic={italic}
            small={small}
            narrow={narrow}
            color={color}
            contrast={contrast}
            size={size}
            auto={auto}
        >
            <Interweave
                content={text}
                newWindow={true}
                hashtagUrl={"https://twitter.com/hashtag/{{hashtag}}"}
                matchers={[new HashtagMatcher("hashtag"), new EmailMatcher("email")]}
                transform={transform}
            />
        </ArticleTextContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleText);