import React from "react";

import { SeparatorContainer } from "./separator.styles";

const Separator = ({ desc }) => {
    return (
        <SeparatorContainer>
            <span>{desc ? desc : ""}</span>
        </SeparatorContainer>
    )
}

export default Separator;