import styled from "styled-components";
import { color, font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const ContextMenuContainer = styled.div`
    width: auto;
    padding: ${props => props.type === "options" ? "0" : "0 15px"};
    margin-top: ${props => props.type === "filter" || props.type === "options" || props.type === "lang" ? "0" : "7px"};
    height: ${props => props.type === "options" ? "inherit" : "34px"};
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.045em;
    border-radius: 7px !important;
    background-color: ${props => props.type !== "options" ? props.type === "filter" ? props.darkmode ? color.gray2 : color.white : props.darkmode ? color.gray1 : color.gray5 : "transparent"};
    color: ${props => props.type === "filter" ? color.gray3 : color.blue};
    line-height: 100%;
    user-select: none;
    font-size: 16px;
    font-family: ${font.pulpDisplayMedium};
    cursor: pointer;
  
    &:hover {
        background-color: ${props => props.type !== "options" ? props.type === "filter" ?  props.darkmode ? color.blueActiveDark : color.blueActiveLight : props.darkmode ? color.gray1 : color.gray5 : "transparent"};
    }
    &:active {
        transform: scale(0.98);
        outline: none;
    }

    ${props => props.type === "options" ? "&>svg:first-child { margin: 0 !important; }" : null};
`;

export const ContextMenuRefGhostContainer = styled.div`
    position: absolute;
    height: 50px;
    width: 50px;
`;

export const ContextMenuSelectionContainer = styled.div`
    max-height: 70vh;
    width: max-content;
    border: 1px solid ${props => props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    border-radius: ${props => props.share ? "10px" : "7px !important"};
    color: ${props => props.darkmode ? props.variant ? color.gray4 : color.gray4 : color.gray3};
    position: absolute;
    box-shadow: 0 1px 5px 0 ${props => props.darkmode ? color.black : color.gray3};
    margin-top: ${props => props.noOffset ? "0" : props.offsetTop ? "-50px" : "-35px"};
    overflow-y: scroll;
    z-index: 99 !important;
    cursor: default;
    user-select: none;
  
    div:first-child {
        border-radius: ${props => props.variant ? "10px" : "6px"} ${props => props.variant ? "10px" : "6px"} 0 0 !important;
        margin-top: ${props => props.variant ? "5px" : 0};
    }
    div:last-child {
        border-radius: 0 0 ${props => props.variant ? 0 : "6px"} ${props => props.variant ? "0" : "6px"} !important;
        margin-bottom: ${props => props.variant ? "8px" : 0};
    }
    
    ${props => props.offset ? props.share ? `right: 0; top: 60px;` : `right: ${props.offset}px` : 0};
`;

export const ContextMenuSelectionCell = styled.div`
    line-height: 40px;
    width: 100%;
    display: flex;
    position: relative;
    margin-left: 0 !important;
    margin-top: ${props => props.separator ? "8px" : "0"};
    border-top: ${props => props.separator ? `1px solid ${props.darkmode ? "rgba(255, 255, 255, 0.12)" : "rgba(0, 0, 0, 0.1)"}` : "0"};
    justify-content: ${props => props.variant ? "left" : "center"};
    letter-spacing: 0.045em;
    font-size: 16px;
    text-align: ${props => props.variant ? "left" : "center"};
    height: ${props => props.separator ? "8px" : "40px"};
    font-family: ${props => props.desc ? font.pulpDisplayBold : font.pulpDisplayMedium};
    cursor: ${props => props.copy ? "copy" : (props.active || props.desc || props.separator) ? "default" : "pointer"};
    padding: ${props => props.variant ? "0 20px" : "0 15px"};
    color: ${props => props.active ? color.blue + " !important" : props.desc ? textColor(props.darkmode) : props.danger ? color.red : "inherit"};
    background-color: ${props => props.active ? props.darkmode ? color.blueActiveDark : color.blueActiveLight : "inherit"};
    
    &:hover {
        background-color: ${props => (props.desc || props.separator) ? "transparent" : props.danger ? props.darkmode ? color.redActiveDark : color.redActiveLight : props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    }
`;

export const ContextMenuIcon = styled(Icon)`
    height: inherit;
    display: flex;
    justify-content: center;
    margin: ${props => props.left ? "0 10px 0 0" : "0 0 0 5px"};
`;