import styled from "styled-components";
import { radius, color, font } from "../../../design/theme";
import { textColor } from "../../../components/general.utils";

export const AccountContainer = styled.section`
    width: inherit;
    height: auto;
    padding-bottom: 80px;
`

export const AccountGridContainer = styled.section`
    width: inherit;
    height: auto;
    display: grid;
    grid-template-columns: repeat(${props => props.collapsed ? "2" : "4"}, minmax(0, 1fr));
    grid-template-rows: repeat(${props => props.collapsed ? "8" : "5"}, ${props => props.reduced ? "150px" : "170px"});
  
    ${
        props => props.collapsed ? (
            `grid-template-areas:  
                "targetReadAdjustor targetReadAdjustor"
                "currentStreak maxStreak"
                "timeBarChart timeBarChart"
                "timeBarChart timeBarChart"
                "weeklyTotal weeklyTrend"
                "categoryPieChart categoryPieChart"
                "categoryPieChart categoryPieChart"
                "totalArticles avgArticles";
            `
        ) : (
            `grid-template-areas:  
                "targetReadAdjustor targetReadAdjustor currentStreak maxStreak"
                "timeBarChart timeBarChart timeBarChart timeBarChart"
                "timeBarChart timeBarChart timeBarChart timeBarChart"
                "categoryPieChart categoryPieChart weeklyTotal totalArticles"
                "categoryPieChart categoryPieChart weeklyTrend avgArticles";
            `
        )
    }
    gap: 20px;
`

export const AccountGridElement = styled.div`
    grid-area: ${props => props.gridArea};
    height: auto;
    width: auto;
`;

export const TargetReadContainer = styled.div`
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    border-radius: ${radius.largeRadius};
    text-align: left;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
`

export const TargetReadContentContainer = styled.div`
    width: ${props => props.reduced ? "70%" : "60%"}; 
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 20px 20px 20px 25px;
`

export const TargetReadNumberContainer = styled.div`
    right: 0;
    width: ${props => props.reduced ? "30%" : "40%"};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 25px;
`

export const TargetReadNumber = styled.div`
    color: ${color.blue};
    font-size: ${props => props.reduced ? "70px" : "90px"};
    font-family: ${font.interBold};
`

export const TargetReadTitleContainer = styled.div`
    color: ${props => textColor(props.darkmode)};
    font-size: 25px;
    font-family: ${font.interBold};
    margin-bottom: 10px;
`

export const TargetReadDescContainer = styled.div`
    font-size: 16px;
    font-family: ${font.interMedium};
    color: ${props => props.darkmode ? color.gray4 : color.gray3};
    text-overflow: ellipsis;
    word-break: break-word;
    line-height: 20px;
    letter-spacing: 0.01em; 
    margin-bottom: auto;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.reduced ? "1" : "2"};
    -webkit-box-orient: vertical;
    overflow: hidden;
`