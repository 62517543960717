import UserEventsActionTypes from "./userEvents.types";

export const bookmarkArticle = (userId, articleId) => ({
    type: UserEventsActionTypes.BOOKMARK_ARTICLE,
    payload: userId,
    articleId: articleId
});

export const listArticle = (userId, articleId, listId, deleteLength) => ({
    type: UserEventsActionTypes.LIST_ARTICLE,
    payload: userId,
    identifiers: { articleId, listId, deleteLength }
});

export const followChannel = (userId, channelId) => ({
    type: UserEventsActionTypes.FOLLOW_CHANNEL,
    payload: userId,
    channelId: channelId
});

export const setListPin = (userId, listId) => ({
    type: UserEventsActionTypes.SET_LIST_PIN,
    payload: userId,
    listId: listId
})

export const logArticleRead = (userId, { articleId, category, lang }) => ({
    type: UserEventsActionTypes.LOG_ARTICLE_READ,
    payload: userId,
    articleDetails: { articleId, category, lang }
})

export const sendFeedback = (userId, feedbackValues) => ({
    type: UserEventsActionTypes.SEND_FEEDBACK,
    payload: userId,
    feedback: feedbackValues
})

export const deleteSearch = (userId, searchDetails) => ({
    type: UserEventsActionTypes.DELETE_SEARCH,
    payload: userId,
    searchDetails: searchDetails
})


export const logRecentAccessStart = (userId, listId, refType) => ({
    type: UserEventsActionTypes.LOG_RECENT_ACCESS_START,
    payload: userId,
    listId: listId,
    refType: refType,
    refKey: "userRecentAccess"
})
export const logRecentAccessSuccess = (listId) => ({
    type: UserEventsActionTypes.LOG_RECENT_ACCESS_SUCCESS,
    payload: listId,
    refKey: "userRecentAccess"
})
export const logRecentAccessFailure = (errorMessage) => ({
    type: UserEventsActionTypes.LOG_RECENT_ACCESS_FAILURE,
    payload: errorMessage,
    refKey: "userRecentAccess"
})

export const setTargetReads = (userId, target) => ({
    type: UserEventsActionTypes.SET_TARGET_READS,
    payload: userId,
    target: target
})

export const logSharedContent = (userId, { type, shareId, value }) => ({
    type: UserEventsActionTypes.LOG_SHARED_CONTENT,
    payload: userId,
    shareDetails: { type, shareId, value }
})

export const uploadImageStart = (userId, fileRef, refType) => ({
    type: UserEventsActionTypes.UPLOAD_IMAGE_START,
    payload: { userId, fileRef },
    refType: refType,
    refKey: "userUploadedImage"
})
export const uploadImageSuccess = (imgDetails, fallbackDetails) => ({
    type: UserEventsActionTypes.UPLOAD_IMAGE_SUCCESS,
    payload: imgDetails,
    fallback: fallbackDetails,
    refKey: "userUploadedImage"
})
export const uploadImageFailure = (errorMessage) => ({
    type: UserEventsActionTypes.UPLOAD_IMAGE_FAILURE,
    payload: errorMessage,
    refKey: "userUploadedImage"
})

export const deleteImageStart = (userId, refType) => ({
    type: UserEventsActionTypes.DELETE_IMAGE_START,
    payload: userId,
    refType: refType,
    refKey: "userUploadedImage"
})
export const deleteImageSuccess = (target, refType) => ({
    type: UserEventsActionTypes.DELETE_IMAGE_SUCCESS,
    payload: target,
    refType: refType,
    refKey: "userUploadedImage"
})
export const deleteImageFailure = (errorMessage) => ({
    type: UserEventsActionTypes.DELETE_IMAGE_FAILURE,
    payload: errorMessage,
    refKey: "userUploadedImage"
})

export const setListStart = (userId, listId, listDetails) => ({
    type: UserEventsActionTypes.SET_LIST_START,
    payload: { userId, listId },
    listDetails: listDetails,
    refKey: "userList"
})
export const setListSuccess = (target) => ({
    type: UserEventsActionTypes.SET_LIST_SUCCESS,
    payload: target,
    refKey: "userList"
})
export const setListFailure = (errorMessage) => ({
    type: UserEventsActionTypes.SET_LIST_FAILURE,
    payload: errorMessage,
    refKey: "userList"
})

export const deleteListStart = (userId, listId) => ({
    type: UserEventsActionTypes.DELETE_LIST_START,
    payload: userId,
    listId: listId,
    refKey: "userList"
})
export const deleteListSuccess = (target) => ({
    type: UserEventsActionTypes.DELETE_LIST_SUCCESS,
    payload: target,
    refKey: "userList"
})
export const deleteListFailure = (errorMessage) => ({
    type: UserEventsActionTypes.DELETE_LIST_FAILURE,
    payload: errorMessage,
    refKey: "userList"
})

export const performSearchStart = (userId, query, attribute, searchType) => ({
    type: UserEventsActionTypes.PERFORM_SEARCH_START,
    payload: userId,
    query: query,
    attribute: attribute,
    searchType: searchType,
    refKey: "userSearchResults"
})
export const performSearchSuccess = (results) => ({
    type: UserEventsActionTypes.PERFORM_SEARCH_SUCCESS,
    payload: results,
    refKey: "userSearchResults"
})
export const performSearchFailure = (errorMessage) => ({
    type: UserEventsActionTypes.PERFORM_SEARCH_FAILURE,
    payload: errorMessage,
    refKey: "userSearchResults"
})

export const performUrlSearchStart = (reqHeaders, urlQuery) => ({
    type: UserEventsActionTypes.PERFORM_URL_SEARCH_START,
    payload: reqHeaders,
    urlQuery: urlQuery,
    refKey: "userUrlSearchResults"
})
export const performUrlSearchSuccess = (results) => ({
    type: UserEventsActionTypes.PERFORM_URL_SEARCH_SUCCESS,
    payload: results,
    refKey: "userUrlSearchResults"
})
export const performUrlSearchFailure = (errorMessage) => ({
    type: UserEventsActionTypes.PERFORM_URL_SEARCH_FAILURE,
    payload: errorMessage,
    refKey: "userUrlSearchResults"
})