import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { timeSince, textColor } from "../general.utils";

import { ArticleTimeContainer, ArticleTimeLive } from "./articleTime.styles";
import Icon from "../../design/icons/icon.component";

const ArticleTime = ({ publishedAt, updatedAt, darkmode, live, lang, contrast }) => {
    const { mainWidth } = useContext(LayoutContext);

    return (
        <ArticleTimeContainer darkmode={darkmode} contrast={contrast}>
            <Icon
                name={(mainWidth <= 600 && live) ? "updates" : "clock"}
                size={25}
                color={(mainWidth <= 600 && live) ? color.blue : contrast ? textColor(darkmode) : darkmode ? color.gray4 : color.gray2}
            />
            <span>{ timeSince(updatedAt || publishedAt, lang) }</span>
            {
                mainWidth > 600 && live ? (
                    <ArticleTimeLive>
                        <span>LIVE</span>
                    </ArticleTimeLive>
                ) : null
            }
        </ArticleTimeContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(ArticleTime);