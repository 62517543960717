export const color = {
    blue: "#3873F7",
    blueActiveDark: "#253969", // #263D72 og:#223051
    blueActiveLight: "#B8CBF6", // #D7E3FD

    purple: "#791FE7", // #5E23BF
    purpleActiveDark: "#351E5C",
    purpleActiveLight: "#C6A8F0",

    red: "#FF1157",
    redActiveDark: "#481826",
    redActiveLight: "#F5A3BE", // #FFCEDD

    orange: "#FE6B27",
    orangeActiveDark: "#753A1F",
    orangeActiveLight: "#F6BCA3",

    yellow: "#FDB400",
    yellowActiveDark: "#81600E",
    yellowActiveLight: "#F4DFAB",

    green: "#22F89A", // #16FFBE
    greenActiveDark: "#1D734D", // #18765C
    greenActiveLight: "#BDF3DF", // #B9F5E7

    white: "#FFFFFF",
    whiteActive: "#818181",

    black: "#000000",
    blackActive: "#A8A9AB",

    gray1: "#1A1A1A",
    gray2: "#2A2A2A",
    gray3: "#737373",
    gray4: "#BBBBBB",
    gray5: "#F0F2F5",

    lightBlue: "#16FFFA"
}

export const radius = {
    smallRadius: "14px",
    largeRadius: "24px"
}

export const font = {
    pulpDisplayBold: '"Pulp Display Bold", "PulpDisplay-Bold", Helvetica, Arial, sans-serif',
    pulpDisplayMedium: '"Pulp Display Medium", "PulpDisplay-Medium", Helvetica, Arial, sans-serif',
    interExtraBold: '"Inter Extra Bold", "Inter-ExtraBold", Helvetica, Arial, sans-serif',
    interBold: '"Inter Bold", "Inter-Bold", Helvetica, Arial, sans-serif',
    interBoldItalic: '"Inter Bold Italic", "Inter-BoldItalic", Helvetica, Arial, sans-serif',
    interSemiBold: '"Inter Semi Bold", "Inter-SemiBold", Helvetica, Arial, sans-serif',
    interMedium: '"Inter Medium", "Inter-Medium", Helvetica, Arial, sans-serif',
    interMediumItalic: '"Inter Medium Italic", "Inter-MediumItalic", Helvetica, Arial, sans-serif',
    interBlackItalic: '"Inter Black Italic", "Inter-BlackItalic", Helvetica, Arial, sans-serif',
}