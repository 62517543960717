import React, { Fragment, useContext, useEffect, useRef, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { LayoutContext } from "../../App";
import { SearchContext } from "../../context/search.context";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserId, selectUserLanguage, selectRecentSearches } from "../../redux/userData/userData.selectors";
import { selectSearchResults } from "../../redux/userEvents/userEvents.selectors";
import { performSearchStart } from "../../redux/userEvents/userEvents.actions";
import { fetchUserSearchesStart } from "../../redux/userData/userData.actions";
import { encodeURIQuery, textColor, capitalize, isMobile, getUserPlatform, getEnvPermissionsHelpUrl, getURIQueryResultKey } from "../general.utils";
import { recognizeSpeech } from "./searchbar.utils";
import { color } from "../../design/theme";
import { uniqBy } from "lodash";
import { searchData as searchDataCC, miscData } from "../lang.data";

import {
    SearchbarWrapper,
    SearchbarBackdrop,
    SearchbarContainer,
    SearchbarContent,
    SearchbarIconContainer,
    SearchbarIcon,
    SearchbarInputContainer,
    SearchbarInput,
    SearchbarInputCancelContainer,
    SearchbarInputCancelIcon,
    SearchbarMethodGroup,
    SearchbarAutocompleteWrapper,
    SearchbarAutocompleteContainer,
    SearchbarAttribute
} from "./searchbar.styles";
import SearchElement from "../searchElement/searchElement.component";
import Modal from "../modal/modal.component";
import ModalUrlSearch from "../modalUrlSearch/modalUrlSearch.component";
import ModalAction from "../modalAction/modalAction.component";


const Searchbar = ({ userId, darkmode, main, lang, type, performSearchStart, searchResults, recentSearches,
                       fetchUserSearchesStart, localValue, setLocalValue, reduced }) => {

    const { mainWidth } = useContext(LayoutContext);
    const { value, setValue, attribute, handleAttribute, setClearInputAndFocus } = useContext(SearchContext);
    const voiceStatusIconMap = { request: "faq", pending: "clock", granted: "check", denied: "warning" };

    const [focus, setFocus] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [voiceActive, setVoiceActive] = useState(false);
    const [voiceHover, setVoiceHover] = useState(false);
    const [voiceInstance, setVoiceInstance] = useState(false);
    const [voiceStatus, setVoiceStatus] = useState("");
    const [urlSearchActive, setUrlSearchActive] = useState(false);
    const [urlSearchHover, setUrlSearchHover] = useState(false);
    const [inputWidth, setInputWidth] = useState(null);
    const [autocompleteHeight, setAutocompleteHeight] = useState(null);

    const inputRef = useRef(null);
    const searchRef = useRef(null);
    const cancelRef = useRef(null);
    const containerRef = useRef(null);
    const autocompleteRef = useRef(null);

    const history = useHistory();
    let recursionDepth = 0;

    const handleValue = (query) => {
        if(main) setValue(query)
        else setLocalValue(query);
    }
    const searchResultState = (query, queryAttribute, isRecent) => {
        let searchAttribute = isRecent ? queryAttribute : attribute;
        let searchQuery = encodeURIQuery(query);
        let queryParam = getURIQueryResultKey(searchQuery);
        let queryRef = getURIQueryResultKey(searchQuery, searchAttribute);
        let falseArticleId = !isRecent && attribute === "articleId" && query.includes(" ");
        let searchResultPresent = (falseArticleId) ? searchResults[queryParam] : searchResults[queryRef];
        let searchParams = `?q=${searchQuery}${searchAttribute && !falseArticleId ? `&a=${searchAttribute}` : ""}`;
        let paramsEqualLocation = (searchParams === history.location?.search);

        return { searchParams, searchResultPresent, paramsEqualLocation }
    }
    const performSearch = (query, queryAttribute, type = "standard", isRecent = false) => {
        const searchAttribute = isRecent ? queryAttribute : attribute;
        if(query) {
            if(attribute === "articleId" && !query.includes(" ")) history.push(`/${query}`)
            else {
                if(attribute !== searchAttribute || attribute === "articleId") handleAttribute(searchAttribute);
                const { searchParams, searchResultPresent, paramsEqualLocation } = searchResultState(query, searchAttribute, isRecent);
                if(!searchResultPresent) performSearchStart(userId, query, searchAttribute, type)
                if(!paramsEqualLocation) history.push("/search" + searchParams)
            }
            handleValue(query);
        } else console.log("PREVENTED SEARCH")
        setDisabled(true);
        if(inputRef.current) inputRef.current.blur()
    }
    const handleFocusSetting = (clear = false) => {
        const focusAction = () => {
            inputRef.current.focus();
            let tempVal = inputRef.current?.value || "";
            inputRef.current.value = "";
            if(!clear) inputRef.current.value = tempVal;
            else setValue("");
            setFocus(true);
            setTimeout(() => {
                if(document.activeElement !== inputRef.current && recursionDepth < 20) {
                    handleFocusSetting(clear);
                    recursionDepth++;
                } else recursionDepth = 0;
            }, 20);
        }
        if(inputRef.current) focusAction();
        else if(recursionDepth < 30) {
            setTimeout(() => handleFocusSetting(clear), 20);
            recursionDepth++;
        }
    }
    const handleAttributeClick = (event, newAttribute) => {
        event.stopPropagation();
        if(newAttribute) handleAttribute(newAttribute);
        handleFocusSetting();
    }
    const handleSearchClick = (event) => {
        event.stopPropagation();
        const { searchResultPresent, paramsEqualLocation } = searchResultState(inputRef.current.value);
        if(inputRef.current.value && (!searchResultPresent || !paramsEqualLocation)) {
            performSearch(inputRef.current.value);
        } else handleFocusSetting();
    }
    const handleVoiceClick = (event) => {
        event?.stopPropagation();
        const handleResults = (transcript, isFinal) => {
            handleValue(capitalize(transcript));
            if(isFinal) {
                if(main) performSearch(transcript, "", "voice");
                setFocus(false);
            }
        }

        if(voiceActive) {
            setVoiceActive(false);
            voiceInstance?.stop();
        } else {
            navigator?.mediaDevices.enumerateDevices().then(devices => {
                if(devices.length) {
                    if (!devices[0].label) {
                        if(!voiceStatus) setVoiceStatus("request");
                        else {
                            navigator.mediaDevices.getUserMedia({ audio: true })
                                .then((stream) => {
                                    getUserPlatform()
                                    const tracks = stream.getTracks();
                                    tracks.forEach((track) => track.stop());
                                    setVoiceStatus("granted")
                                })
                                .catch(() => setVoiceStatus("denied"));
                        }
                    } else {
                        if(!focus) handleFocusSetting();
                        recognizeSpeech(lang, setVoiceActive, handleResults, setVoiceInstance);
                    }
                } else setVoiceStatus("denied")
            })
        }
    }

    const handleUrlSearchClick = (event) => {
        event.stopPropagation();
        setUrlSearchActive(true);
    }
    const handleInputTrigger = (event, query, queryAttribute = "", isRecent) => {
        if(query || event.code === "Enter" || event.code === "NumpadEnter") {
            performSearch(query || event.target.value, queryAttribute, "standard", isRecent)
        }
    }
    const handleAutocompleteResize = () => {
        if((window.innerHeight - 40) >= (autocompleteRef.current?.clientHeight + 60)) {
            setAutocompleteHeight(null)
        } else if(searchRef?.current.clientHeight < (autocompleteRef.current?.clientHeight + 60)) {
            setAutocompleteHeight(searchRef.current?.clientHeight - 60);
        }
    }

    useLayoutEffect(() => {
        if(inputRef) setClearInputAndFocus({ trigger: () => {
                handleValue("");
                handleFocusSetting(true);
                handleAttribute(attribute);
            } });
        if(containerRef.current) setInputWidth(containerRef.current?.clientWidth)
    }, [containerRef, inputRef])
    useEffect(() => {
        if(!recentSearches && main && focus) fetchUserSearchesStart(userId);
        if(focus) handleAutocompleteResize();
    }, [focus, recentSearches])
    useEffect(() => {
        const handleSearchFieldClicks = (event) => {
            event.stopPropagation();
            if(
                (!(searchRef.current?.contains(event.target) && (event.target.id.toLowerCase().includes("attribute") || event.target?.ownerSVGElement?.id?.toLowerCase()?.includes("delete_search") || event.target?.id?.toLowerCase()?.includes("delete_search")))
                 && !cancelRef.current?.contains(event.target) && !inputRef.current?.contains(event.target)) || ((event.code === "Enter" || event.code === "NumpadEnter") && inputRef.current?.contains(event.target))
                // !searchRef.current.contains(event.target)
            ) {
                setFocus(false);
                // setDisabled(true)
            }
        }

        document.addEventListener("mousedown", handleSearchFieldClicks);
        document.addEventListener("keydown", handleSearchFieldClicks);
        window.addEventListener("resize", handleAutocompleteResize);

        return () => {
            document.removeEventListener("mousedown", handleSearchFieldClicks);
            document.removeEventListener("keydown", handleSearchFieldClicks);
            window.removeEventListener("resize", handleAutocompleteResize)
        }
    }, [searchRef])

    return (
        <Fragment>
            <SearchbarWrapper
                onMouseOver={() => setDisabled(false)}
                onMouseLeave={() => !focus && setDisabled(true)}
                main={main}
                darkmode={darkmode}
                width={inputWidth}
                focused={focus}
                ref={searchRef}
            >
                <SearchbarContainer main={main} darkmode={darkmode} ref={containerRef}>
                    <SearchbarContent main={main} darkmode={darkmode} focused={(main && focus) ? 1 : 0}>
                        <SearchbarIcon
                            onClick={(event) => handleSearchClick(event)}
                            focused={(!main && focus) ? 1 : 0}
                            name={main ? "search" : "scan"}
                            main={main}
                            size={main ? 34 : 22}
                            color={(main && value) || localValue ? textColor(darkmode) : darkmode ? color.gray3 : color.gray4}
                        />
                        {
                            main && attribute ? (
                                <SearchbarAttribute
                                    onClick={(event) => handleAttributeClick(event, attribute)}
                                    id={"currentAttribute"}
                                    darkmode={darkmode}
                                >
                                    {searchDataCC.attributes.find(attributeCC => attributeCC.uriQuery === attribute).desc[lang]}
                                </SearchbarAttribute>
                            ) : null
                        }
                        <SearchbarInputContainer main={main}>
                            <SearchbarInput
                                onChange={(event) => handleValue(event.target.value)}
                                onFocus={() => setFocus(true)}
                                // onBlur={() => { setDisabled(true); setFocus(false); }}
                                onKeyDown={(event) => handleInputTrigger(event)}
                                main={main}
                                maxLength={"50"}
                                type={"search"}
                                placeholder={`${main ? mainWidth > 500 ? searchDataCC.placeholder.main[lang] : miscData.search[lang] : searchDataCC.placeholder.filter[type][lang]}...`}
                                aria-label={main ? searchDataCC.placeholder.main[lang] : searchDataCC.placeholder.filter[type][lang]}
                                rel={"search"}
                                tabindex={"0"}
                                value={main ? value : localValue}
                                darkmode={darkmode}
                                autoCapitalize={"none"}
                                autoComplete={"off"}
                                autoCorrect={"off"}
                                spellCheck={"false"}
                                disabled={disabled}
                                ref={inputRef}
                                multiple
                            />
                            {
                                inputRef.current?.value ? (
                                    <SearchbarInputCancelContainer main={main} ref={cancelRef}>
                                        <SearchbarInputCancelIcon
                                            onClick={(event) => {
                                                handleValue("")
                                                handleFocusSetting(true)
                                                handleAttributeClick(event, attribute)
                                            }}
                                            name={"close"}
                                            color={color.blue}
                                            size={20}
                                        />
                                    </SearchbarInputCancelContainer>
                                ) : null
                            }
                        </SearchbarInputContainer>
                        {
                            !reduced && !(!main && !(window.SpeechRecognition || window.webkitSpeechRecognition)) ? (
                                <SearchbarMethodGroup darkmode={darkmode} main={main}>
                                    {
                                        (window.SpeechRecognition || window.webkitSpeechRecognition) ? (
                                            <Fragment>
                                                <SearchbarIconContainer active={voiceActive} main={main}>
                                                    <SearchbarIcon
                                                        onClick={(event) => handleVoiceClick(event)}
                                                        onMouseEnter={() => setVoiceHover(true)}
                                                        onMouseLeave={() => setVoiceHover(false)}
                                                        focused={focus ? 1 : 0}
                                                        name={"voiceSearch"}
                                                        main={main}
                                                        size={main ? 30 : 22}
                                                        color={(voiceHover || voiceActive) ? textColor(darkmode) : darkmode ? color.gray3 : color.gray4}
                                                    />
                                                </SearchbarIconContainer>
                                                {
                                                    voiceStatus ? (
                                                        <Modal
                                                            type={"prompt"}
                                                            refType={voiceStatus === "denied" ? "warning": null}
                                                            icon={"voiceSearch"}
                                                            secondaryIcon={voiceStatusIconMap[voiceStatus]}
                                                            title={searchDataCC.voiceSearch[voiceStatus].title[lang]}
                                                            desc={searchDataCC.voiceSearch[voiceStatus].desc[lang]}
                                                            onDismiss={() => setVoiceStatus("")}
                                                        >
                                                            <ModalAction
                                                                actionDesc={searchDataCC.voiceSearch[voiceStatus].action?.[lang]}
                                                                actionColor={voiceStatus === "denied" ? color.red : null}
                                                                callback={() => {
                                                                    if(voiceStatus === "request") {
                                                                        setVoiceStatus("pending")
                                                                        setTimeout(() => handleVoiceClick(), 1000)
                                                                    } else if(voiceStatus === "granted") {
                                                                        setVoiceStatus("")
                                                                        setTimeout(() => handleVoiceClick(), 0)
                                                                    } else if(voiceStatus === "denied") {
                                                                        window.open(getEnvPermissionsHelpUrl(lang, darkmode), "_blank", "noreferrer")
                                                                    }
                                                                }}
                                                                singular={voiceStatus !== "request"}
                                                                onDismiss={() => setVoiceStatus("")}
                                                                loading={voiceStatus === "pending"}
                                                                icon={voiceStatus === "denied" ? "external" : null}
                                                            />
                                                        </Modal>
                                                    ) : null
                                                }
                                            </Fragment>
                                        ) : null
                                    }
                                    {
                                        main ? (
                                            <Fragment>
                                                <SearchbarIcon
                                                    onClick={(event) => handleUrlSearchClick(event)}
                                                    onMouseEnter={() => setUrlSearchHover(true)}
                                                    onMouseLeave={() => setUrlSearchHover(false)}
                                                    focused={focus ? 1 : 0}
                                                    name={"urlSearch"}
                                                    main={main}
                                                    size={main ? 30 : 22}
                                                    color={(urlSearchHover || urlSearchActive) ? textColor(darkmode) : darkmode ? color.gray3 : color.gray4}
                                                />
                                                {
                                                    urlSearchActive ? (
                                                        <Modal
                                                            onDismiss={() => setUrlSearchActive(false)}
                                                            title={`${searchDataCC.urlSearchTitle[lang]} 🔗`}
                                                            desc={searchDataCC.urlSearchDesc[lang]}
                                                        >
                                                            <ModalUrlSearch
                                                                onDismiss={() => setUrlSearchActive(false)}
                                                            />
                                                        </Modal>
                                                    ) : null
                                                }
                                            </Fragment>
                                        ) : null
                                    }
                                </SearchbarMethodGroup>
                            ) : null
                        }
                    </SearchbarContent>
                </SearchbarContainer>
                {
                    main && focus ? (
                        <SearchbarAutocompleteWrapper height={autocompleteHeight}>
                            <SearchbarAutocompleteContainer ref={autocompleteRef}>
                                {
                                    value && value !== recentSearches?.[0]?.query ? (
                                        <SearchElement
                                            key={value}
                                            type={"search"}
                                            details={{ query: value, attribute }}
                                            callback={(event, query, attribute) => handleInputTrigger(event, query, attribute)}
                                        />
                                    ) : null
                                }
                                {
                                    recentSearches && uniqBy(recentSearches, search => [search.query, search.attribute].join()).slice(0, 7).map(({ query, key, attribute }) => (
                                        <SearchElement
                                            key={key}
                                            type={"history"}
                                            details={{ query, key, attribute }}
                                            callback={(event, query, attribute) => handleInputTrigger(event, query, attribute, true)}
                                            focusCallback={handleFocusSetting}
                                        />
                                    ))
                                }
                                {
                                    !isMobile() ? (
                                        <SearchElement
                                            type={"attributes"}
                                            callback={(event, attribute) => handleAttributeClick(event, attribute)}
                                            activeAttribute={attribute}
                                        />
                                    ) : null
                                }
                            </SearchbarAutocompleteContainer>
                        </SearchbarAutocompleteWrapper>
                    ) : null
                }
            </SearchbarWrapper>
            {
                main && focus ? (
                    <SearchbarBackdrop darkmode={darkmode} focused={focus} onWheel={() => {
                        inputRef.current && inputRef.current.blur();
                        setFocus(false)
                    }} />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    darkmode: selectDarkmode,
    lang: selectUserLanguage,
    searchResults: selectSearchResults,
    recentSearches: selectRecentSearches
})

const mapDispatchToProps = (dispatch) => ({
    performSearchStart: (userId, query, attribute, type) => dispatch(performSearchStart(userId, query, attribute, type)),
    fetchUserSearchesStart: (userId) => dispatch(fetchUserSearchesStart(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);