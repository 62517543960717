import styled from "styled-components";
import { color, font, radius } from "../../design/theme";

import Icon from "../../design/icons/icon.component";
import { Link } from "react-router-dom";
import { textColor } from "../general.utils";

export const SettingsCardWrapper = styled.section`
    width: 100%;
    height: auto;
    position: relative;
    justify-content: left;
    text-align: left;
    padding-bottom: 40px;
`;

export const SettingsCardTitle = styled.h1`
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    font-size: 30px;
    margin: 0 0 15px 25px;
`;

export const SettingsCardContainer = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.darkmode ? color.gray2 : color.white};
    font-family: ${font.pulpDisplayMedium};
    // overflow: hidden;

    div:first-child {
        border-top: none !important;
        border-radius: ${radius.smallRadius} ${radius.smallRadius} 0 0;

        &::after {
            border-top: none !important;
        }
        &:hover, &:active {
            &::after {
                margin-top: 0;
            }
        }
    }
    div:last-child {
        border-radius: 0 0 ${radius.smallRadius} ${radius.smallRadius};
    }
`;

export const SettingsCardLinkExternal = styled(Link)`
    position: absolute;
    height: inherit;
    width: inherit;
    display: block;
    user-select: auto;
    pointer-events: auto;
    z-index: 3;
`

export const SettingsCardItemContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    position: relative;
    justify-content: left;
    border-radius: ${props => props.single ? radius.smallRadius + " !important" : null};
    border-top: 1px solid ${props => props.darkmode ? color.gray3 : color.gray4};
    cursor: ${props => props.type === "locked" ? "not-allowed" : props.type === "action" ? "default" : "pointer"};
    padding: 0 25px;
    
    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 25px;
        height: inherit;
        background-color: inherit;
        border-radius: inherit;
        border-bottom: 3px solid ${props => props.darkmode ? color.gray2 : color.white};
        border-top: 1px solid ${props => props.darkmode ? color.gray2 : color.white};
        margin-top: -1px;
    }
    
    &:hover, &:active {
        background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
        border-top: 1px solid transparent;
                
        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 25px;
            height: inherit;
            background-color: inherit;
            border-radius: inherit;
            border-bottom: none;
            border-top: none;
            margin-top: -1px;
        }
        
        + div {
            border-top: 1px solid transparent;
        }
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &::-moz-focus-inner {
        border: 0;
    }
`;

export const SettingsCardItemIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    margin: 0 12px 0 0;
`;

export const SettingsCardItemImage = styled.div`
    height: 30px;
    width: 30px;
    justify-content: center;
    margin: 0 12px 0 0;
    padding: 10px 0;

    & > picture {
        border-radius: 50px;
        box-shadow: 0 0 0 1px ${props => !props.darkmode ? color.gray4 : "transparent"};
        overflow: hidden;
    }
`;

export const SettingsCardItemTitle = styled.div`
    font-size: 20px;
    margin-left: 5px;
    height: 100%;
    display: flex;
    color: ${props => textColor(props.darkmode)};
    justify-content: center;
    flex-direction: column;
    letter-spacing: 0.045em;
`

export const SettingsCardActionContainer = styled.div`
    height: inherit;
    width: auto;
    display: flex;
    position: relative;
    flex-direction: row;
    vertical-align: center;
    margin-left: auto;
    justify-content: right;
    align-items: center;
`

export const SettingsCardActionLabel = styled.div`
    height: inherit;
    width: auto;
    margin-right: 10px;
    line-height: 100%;
    display: flex;
    letter-spacing: 0.045em;
    font-size: 16px;
    align-items: center;
    user-select: none;
    z-index: 0;
    color: ${props => props.darkmode ? color.gray3 : color.gray4}
`

export const SettingsCardActionIcon = styled(Icon)`
    justify-content: center;
    height: inherit;
    flex-shrink: 0;
`;

export const SettingsCardInfo = styled.div`
    font-family: ${font.pulpDisplayMedium};
    font-size: 18px;
    color: ${color.gray3};
    width: 100%;
    padding: 0 25px;
    margin-top: 15px;
`