import { takeLatest, call, put, all } from "redux-saga/effects";
import { createUserWithEmailAndPassword, signInWithPopup, signOut, getRedirectResult, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { ref, set, push, onValue } from "firebase/database";
import UserLoginActionTypes from "./userLogin.types";

import { locationAPI } from "../backendAPI";
import { auth, database, googleProvider, appleProvider } from "../../config/firebase.config";
import { createUserProfileDocument } from "./userLogin.utils";
import {
    signUpSuccess, signUpFailure,
    signInSuccess, signInFailure,
    checkUserSessionSuccess, checkUserSessionFailure,
    signOutSuccess, signOutFailure
} from "./userLogin.actions";
import { fetchUserDataStart } from "../userData/userData.actions";
import { isMobile, getUserPlatform, getUserAgent, defaultLanguage } from "../../components/general.utils";
import { store } from "../store";


//////////////////// SIGN UP SECTION ////////////////////

export function* createUserOnSignUp(userId, email, firstName, lastName, userLang, imageUrl = "") {
    try {
        const locationRef = yield locationAPI.get("/");
        const location = yield locationRef.data.city;
        const userRef =  ref(database, "users/" + userId)
        yield set(userRef, createUserProfileDocument(userId, email, firstName, lastName, userLang, location, imageUrl));
        yield call(logUserOnSignIn, userId)
        yield put(signUpSuccess());
        yield put(fetchUserDataStart(userId));
    } catch (error) {
        yield put(signUpFailure(error))
    }
}

// ----------------- Sign Up with Email ----------------- //

export function* signUpWithEmail({ payload: { firstName, lastName, email, password } }) {
    try {
        const { user } = yield createUserWithEmailAndPassword(auth, email, password);
        yield call(createUserOnSignUp, user.uid, email, firstName, lastName, defaultLanguage())
    } catch (error) {
        yield put(signUpFailure(error))
    }
}

export function* onEmailSignUpStart() {
    yield takeLatest(
        UserLoginActionTypes.EMAIL_SIGN_UP_START,
        signUpWithEmail
    )
}

// ----------------- Sign Up with Google ----------------- //

export function* signUpWithGoogle() {
    try {
        const data = yield signInWithPopup(auth, googleProvider);
        console.log("USER", data)
        const { uid, email, photoURL } = data.user;
        const { locale, family_name, given_name } = data.additionalUserInfo.profile;
        const isNewUser = data.additionalUserInfo.isNewUser;
        if (isNewUser) {
            yield call(createUserOnSignUp, uid, email, given_name, family_name, locale, photoURL);
        } else {
            yield signOut(auth);
            yield put(signUpFailure({ code: "auth/email-already-in-use" }));
        }
    } catch (error) {
        yield put(signUpFailure(error))
    }
}

export function* onGoogleSignUpStart() {
    yield takeLatest(
        UserLoginActionTypes.GOOGLE_SIGN_UP_START,
        signUpWithGoogle
    )
}

// ----------------- Sign Up with Apple ----------------- //

export function* signUpWithApple() {
    try {
        const data = yield signInWithPopup(auth, appleProvider);
        console.log("DATA", data)
        const result = yield getRedirectResult(auth)
        console.log("RESULT", result)
        /*const { uid, email, photoURL } = data.user
        const { locale, family_name, given_name } = data.additionalUserInfo.profile
        const isNewUser = data.additionalUserInfo.isNewUser
        if (isNewUser) {
            yield call(createUserOnSignUp, uid, email, given_name, family_name, locale, photoURL)
        } else {
            yield auth.signOut();
            yield put(signUpFailure({ code: "auth/email-already-in-use" }))
        }*/
    } catch (error) {
        yield put(signUpFailure(error))
    }
}

export function* onAppleSignUpStart() {
    yield takeLatest(
        UserLoginActionTypes.APPLE_SIGN_UP_START,
        signUpWithApple
    )
}

//////////////////// SIGN IN SECTION ////////////////////

export function* logUserOnSignIn(userId) {
    try {
        const { data } = yield locationAPI.get("/");
        const { region, region_code, country_name, country, ip, city } = data || {};
        const signInDetailsRef = ref(database, "users/" + userId + "/details/signInDetails");
        yield push(signInDetailsRef, {
                signInAt: new Date().toISOString(),
                signInLocation: {
                    city: city || "Basel",
                    region: (region && region_code) ? `${region}, ${region_code}` : "Basel-Stadt, BS",
                    country: (country_name && country) ? `${country_name}, ${country}` : "Switzerland, CH"
                },
                userPlatform: getUserPlatform(),
                userAgent: getUserAgent(),
                userMobile: isMobile(),
                ip: ip || "00.000.000.000",
                client: "webApp",
                service: "News"
            })
        yield put(signInSuccess());
        yield put(fetchUserDataStart(userId));
    } catch (error) {
        yield put(signUpFailure(error))
    }
}

// ----------------- Sign In with Email ----------------- //

export function* signInWithEmail({ payload: { email, password } }) {
    try {
        const { user } = yield signInWithEmailAndPassword(auth, email, password);
        yield call(logUserOnSignIn, user.uid)
    } catch (error) {
        yield put(signInFailure(error))
    }
}

export function* onEmailSignInStart() {
    yield takeLatest(
        UserLoginActionTypes.EMAIL_SIGN_IN_START,
        signInWithEmail
    )
}

// ----------------- Sign In with Google ----------------- //

export function* signInWithGoogle() {
    try {
        const data = yield signInWithPopup(auth, googleProvider);
        const isNewUser = data.additionalUserInfo.isNewUser;
        const userId = data.user.uid;
        const userRef = yield database.ref("users/" + userId);
        if (isNewUser) {
            yield onValue(userRef, async snapshot => {
                if (snapshot.exists()) {
                    await auth.signOut()
                    store.dispatch(signInFailure({ code: "auth/generic-error" }))
                } else {
                    await auth.currentUser.delete()
                    store.dispatch(signInFailure({ code: "auth/user-not-found" }))
                }
            }, { onlyOnce: true })
        } else {
            yield call(logUserOnSignIn, userId)
        }
    } catch (error) {
        yield put(signInFailure(error))
    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(
        UserLoginActionTypes.GOOGLE_SIGN_IN_START,
        signInWithGoogle
    )
}

//////////////////// CHECK USER SESSION SECTION ////////////////////

export function* checkUserSession() {
    try {
        const userAuth = yield new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
                unsubscribe();
                resolve(userAuth);
            }, reject)
        })
        yield put(checkUserSessionSuccess(userAuth))
    } catch (error) {
        yield put(checkUserSessionFailure(error))
    }
}

export function* onCheckUserSessionStart() {
    yield takeLatest(
        UserLoginActionTypes.CHECK_USER_SESSION_START,
        checkUserSession
    )
}

//////////////////// SIGN OUT SECTION ////////////////////

export function* signOutStart() {
    try {
        yield signOut(auth);
        yield put(signOutSuccess())
    } catch (error) {
        yield put(signOutFailure(error))
    }
}

export function* onSignOutStart() {
    yield takeLatest(
        UserLoginActionTypes.SIGN_OUT_START,
        signOutStart
    )
}

//////////////////// GENERAL EXPORTS ////////////////////

export function* userLoginSagas() {
    yield all([
        call(onEmailSignUpStart),
        call(onGoogleSignUpStart),
        call(onAppleSignUpStart),
        call(onEmailSignInStart),
        call(onGoogleSignInStart),
        call(onCheckUserSessionStart),
        call(onSignOutStart)
    ])
}