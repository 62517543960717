import React from "react";

import { OptInContainer, OptInDescription } from "./optIn.styles";
import Checkbox from "../checkbox/checkbox.component";

const OptIn = ({ desc, type, ...input }) => {
    return (
        <OptInContainer>
            <Checkbox
                type={type}
                input={input}
                label={"Check to accept the Terms of Service and Privacy Policy."}
                required
            />
            <OptInDescription htmlFor={"toggle"}>
                { desc }
            </OptInDescription>
        </OptInContainer>
    )
}

export default OptIn;