import styled from "styled-components";
import { color } from "../../../design/theme";
import Icon from "../../../design/icons/icon.component";

export const ArticleGalleryWrapper = styled.div`
    height:  ${props => (props.mainWidth * 0.5625) + "px"};
    width:  100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    user-select: none;
`

export const ArticleGalleryAction = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: inherit;
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    backdrop-filter: blur(8px);
    background-color: ${props => CSS.supports("backdrop-filter", "blur(8px)") ? props.darkmode ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)" : props.darkmode ? color.gray2 : color.gray5};
    visibility: ${props => props.hidden ? "hidden" : "visible"};
    ${props => props.direction ?  "right: 0;" : "left: 0; transform: scaleX(-1);"}

    &:active {
        transform: ${props => props.direction ?  "" : "scaleX(-1)"} scale(0.94);
        transition: 0.1s;
        outline: none;
    }
`

export const ArticleGalleryActionIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 5;
`;

export const ArticleGalleryCounter = styled.div`
    width: max-content;
    height: 25px;
    border-radius: 50px;
    padding: 0 15px;
    margin: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    background: inherit;
    cursor: default;
    left: 50%;
    bottom: 0;
    backdrop-filter: blur(8px);
    background-color: ${props => props.backDropEnabled ? props.darkmode ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.3)" : props.darkmode ? color.gray2 : color.gray5};
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    
    & > div:first-child {
        margin-left: 0;
    }
`

export const ArticleGalleryCounterPoint = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 50px;
    margin-left: 14px;
    background-color: ${props => props.activeKey ? color.blue : props.darkmode ? color.gray4 : color.gray2};
    box-shadow: 0 0 0 ${props => props.activeKey ? "3px" : "0"} ${color.blue};
    cursor: ${props => props.activeKey ? "default" : "pointer"};

    &:hover {
        box-shadow: ${props => props.activeKey ? `0 0 0 3px ${color.blue}` : `0 0 0 2px ${props.darkmode ? color.gray4 : color.gray2}`} ;
    }
`