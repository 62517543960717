import React, { StrictMode } from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistedStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker.js";

if(process.env.NODE_ENV !== "development") {
    console.log = () => {}; // disable console.log in production
    if(!window.location?.hostname?.endsWith("reavide.com")) {
        window.location.replace(`https://news.reavide.com${window.location.pathname}`);
    }
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <PersistGate persistor={persistedStore}>
                <StrictMode>
                    <App />
                </StrictMode>
            </PersistGate>
        </BrowserRouter>
    </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();