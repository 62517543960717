import React, { Fragment, useLayoutEffect, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import { selectArticlePreview, selectArticleError } from "../../../redux/articleData/articleData.selectors";
import { fetchArticlePreviewStart } from "../../../redux/articleData/articleData.actions";
import { loginData, miscData } from "../../../components/lang.data";
import { selectUserLanguage } from "../../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    LoginContainer,
    LoginFormContainer,
    LoginArtContainer,
    LoginFormContentWrapper,
    LoginFormContentContainer,
    LoginFormTitleContainer,
    LoginFormHeader,
    LoginHomeButtonContainer,
    LoginFormLogo,
    LoginHomeButton,
    LoginFormGreeting,
    LoginFormArrow
} from "./login.styles";
import CustomButton from "../../../components/customButton/customButton.component";
import Separator from "../../../components/separator/separator.component";
import LoginForm from "../../../components/loginForm/loginForm.component";
import LoginServices from "../../../components/loginServices/loginServices.component";
import LoginPreview from "../../../components/loginPreview/loginPreview.component";
import Modal from "../../../components/modal/modal.component";
import Notice from "../../../components/notice/notice.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

import loginArt from "../../../design/assets/login-wp.svg";
import loginArrow from "../../../design/assets/login-arrow.svg";

const LoginPage = ({ lang, darkmode, param, preview, fetchArticlePreviewStart, previewError }) => {
    const [previewActive, setPreviewActive] = useState(false);
    const history = useHistory();
    const { search } = useLocation();
    const location = history.location;
    const pathEnd = history.location.pathname.slice(-5);

    useLayoutEffect(() => {
        const isValidPreview = (path) => {
            if(path?.length === 9 && /[a-zA-Z0-9\-_]/g.test(path)) {
                fetchArticlePreviewStart(path, lang)
                return true
            } else return false
        };
        const replaceUrl = (location, preview) => {
            const cases = ["login", "login/email", "signup", "signup/email"]
            const caseIndex = cases.indexOf(location);
            if(caseIndex + 1) return cases[caseIndex] + (isValidPreview(preview) ? `?preview=${preview}` : "")
            else return "login"
        }
        const modLocation = location.pathname.replace("/", "");
        const preview = new URLSearchParams(search).get("preview");
        if(isValidPreview(modLocation)) history.replace(`login?preview=${modLocation}`)
        else history.replace(replaceUrl(modLocation, preview))
    }, [])

    useEffect(() => {
        new Image().src = loginArrow;
        if(!isEmpty(preview)) setPreviewActive(true);
        if(previewError) handlePreviewDismiss();
    }, [preview, previewError])

    const handleSwitchClick = () => {
        if (param === "signup") {
            history.push("/login")
        } else if (param === "login") {
            history.push("/signup")
        }
    }
    const handlePreviewDismiss = (type) => {
        setPreviewActive(false);
        history.replace({ search: "" })
        if(type === "action") history.push("signup")
    }

    return (
        <LoginContainer darkmode={darkmode}>
            <SiteHead title={param === "signup" ? "Sign Up" : "Login"} />
            <LoginFormContainer>
                <LoginFormContentWrapper>
                    {
                        (param === "signup" && pathEnd !== "email") ? (
                            <Fragment>
                                <LoginFormGreeting darkmode={darkmode}>
                                    <span>{loginData.greeting[lang]}</span>
                                </LoginFormGreeting>
                                <LoginFormArrow
                                    src={loginArrow}
                                    darkmode={darkmode}
                                    alt={"Login Arrow"}
                                />
                            </Fragment>
                        ) : null
                    }
                    <LoginFormContentContainer>
                        <LoginFormHeader>
                            <LoginFormLogo
                                name={"reavide"}
                                size={60}
                                icon
                            />
                            <LoginFormTitleContainer darkmode={darkmode}>
                                <span>{param === "signup" ? "Sign Up 🤗" : "Login 👋"}</span>
                            </LoginFormTitleContainer>
                        </LoginFormHeader>
                        {
                            pathEnd !== "email" ? (
                                <LoginServices
                                    data={loginData.services}
                                    param={param}
                                />
                            ) : (
                                <LoginForm param={param} />
                            )
                        }
                        <Separator
                            desc={miscData.or[lang]}
                        />
                        <CustomButton
                            callback={() => handleSwitchClick()}
                            desc={param === "signup" ? loginData.hasAccQ[lang] : loginData.hasNoAccQ[lang]}
                            color={color.blue}
                            solid={(pathEnd !== "email") ? 1 : 0}
                        />
                        {
                            param === "signup" ? (
                                <Notice
                                    size={1}
                                    type={"warning"}
                                    desc={"Derzeit in Alpha. Anmeldungen werden zu einem späteren Zeitpunkt freigeschaltet."}
                                    auto
                                />
                            ) : null
                        }
                        <LoginHomeButtonContainer>
                            <LoginHomeButton darkmode={darkmode} href={"https://reavi.de"} target={"_blank"}>
                                <span>{miscData.more[lang]}</span>
                            </LoginHomeButton>
                        </LoginHomeButtonContainer>
                    </LoginFormContentContainer>
                </LoginFormContentWrapper>
            </LoginFormContainer>
            <LoginArtContainer>
                <img src={loginArt} alt={"Login Art"} />
            </LoginArtContainer>
            {
                previewActive ? (
                    <Modal
                        type={"preview"}
                        onDismiss={() => handlePreviewDismiss()}
                    >
                        <LoginPreview
                            article={preview}
                            callback={(type) => handlePreviewDismiss(type)}
                        />
                    </Modal>
                ) : null
            }
        </LoginContainer>
    )
}

const mapStateToProps = (state, ownProps) => ({
    lang: selectUserLanguage(state),
    darkmode: selectDarkmode(state),
    param: ownProps.match.params.login,
    preview: selectArticlePreview(state),
    previewError: selectArticleError(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchArticlePreviewStart: (articleId, lang) => dispatch(fetchArticlePreviewStart(articleId, lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);