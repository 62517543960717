import React, { useContext } from "react";

import { LayoutContext } from "../../App";
import { BreakingContext } from "../../context/breaking.context";
import { navColor } from "../navigation/navigation.utils";
import { capitalize } from "../general.utils";

import {
    NavigationItemContainer,
    NavigationItemLink,
    NavigationItemIcon,
    NavigationItemDesc
} from "./navigationItem.styles";

const NavigationItem = ({ properties, active, darkmode, type, callback }) => {

    const { appSize } = useContext(LayoutContext);
    const { alertUpdate } = useContext(BreakingContext)
    const { name, desc, icon } = properties;

    const handleOnClick = (event) => {
        event.stopPropagation();
        if(callback) callback();
        if(type !== "action" && active) {
            document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    }

    return (
        <NavigationItemContainer
            onClick={(event) => handleOnClick(event)}
            darkmode={darkmode}
            active={active}
            aria-label={`${capitalize(name)} Menu Button`}
        >
            {
                type !== "action" ? (
                    <NavigationItemLink to={`/${name}`} />
                ) : null
            }
            <NavigationItemIcon
                name={icon}
                size={34}
                color={navColor({darkmode, active})}
                alert={name === "timeline" && alertUpdate}
            />
            {
                appSize < 1 ? (
                    <NavigationItemDesc>
                        <span>{desc}</span>
                    </NavigationItemDesc>
                ) : null
            }
        </NavigationItemContainer>
    )
}

export default NavigationItem;