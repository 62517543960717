import styled from "styled-components";
import { radius, color } from "../../design/theme";

export const ModalSelectionContainer = styled.div`
    width: 100%;
    height: auto;
    border-radius: ${radius.smallRadius};
    overflow-y: scroll;
  
    &>div {
        // border-bottom: 2px solid ${props => props.darkmode ? color.gray2 : color.white};
        margin-bottom: 10px;
    }
    &>div:last-child {
        border: 0;
        margin-bottom: 0;
    }
`