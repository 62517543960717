import { all, call } from "redux-saga/effects";

import { articleDataSagas } from "./articleData/articleData.sagas";
import { articleRefSagas } from "./articleRef/articleRef.sagas";
import { channelRefSagas } from "./channelRef/channelRef.sagas";
import { breakingDataSagas } from "./breakingData/breakingData.sagas";
import { userLoginSagas } from "./userLogin/userLogin.sagas";
import { userDataSagas } from "./userData/userData.sagas";
import { userEventsSagas } from "./userEvents/userEvents.sagas";
import { userSettingsSagas } from "./userSettings/userSettings.sagas";

export default function* rootSaga() {
    yield all([
        call(articleDataSagas),
        call(articleRefSagas),
        call(channelRefSagas),
        call(breakingDataSagas),
        call(userLoginSagas),
        call(userDataSagas),
        call(userEventsSagas),
        call(userSettingsSagas)
    ])
}