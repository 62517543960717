import styled, { createGlobalStyle } from "styled-components";
import { color } from "./design/theme";

import PulpDisplayBoldWoff2 from "./design/fonts/PulpDisplay-Bold.woff2";
import PulpDisplayBoldWoff from "./design/fonts/PulpDisplay-Bold.woff";
import PulpDisplayBoldTtf from "./design/fonts/PulpDisplay-Bold.ttf";
import PulpDisplayBoldOtf from "./design/fonts/PulpDisplay-Bold.otf";
import PulpDisplayBoldSvg from "./design/fonts/PulpDisplay-Bold.svg";

import PulpDisplayMediumWoff2 from "./design/fonts/PulpDisplay-Medium.woff2";
import PulpDisplayMediumWoff from "./design/fonts/PulpDisplay-Medium.woff";
import PulpDisplayMediumTtf from "./design/fonts/PulpDisplay-Medium.ttf";
import PulpDisplayMediumOtf from "./design/fonts/PulpDisplay-Medium.otf";
import PulpDisplayMediumSvg from "./design/fonts/PulpDisplay-Medium.svg";

import InterExtraBoldWoff2 from "./design/fonts/Inter-ExtraBold.woff2";
import InterExtraBoldWoff from "./design/fonts/Inter-ExtraBold.woff";
import InterExtraBoldTtf from "./design/fonts/Inter-ExtraBold.ttf";

import InterBoldWoff2 from "./design/fonts/Inter-Bold.woff2";
import InterBoldWoff from "./design/fonts/Inter-Bold.woff";
import InterBoldTtf from "./design/fonts/Inter-Bold.ttf";

import InterBoldItalicWoff2 from "./design/fonts/Inter-BoldItalic.woff2";
import InterBoldItalicWoff from "./design/fonts/Inter-BoldItalic.woff";
import InterBoldItalicTtf from "./design/fonts/Inter-BoldItalic.ttf";

import InterSemiBoldWoff2 from "./design/fonts/Inter-SemiBold.woff2";
import InterSemiBoldWoff from "./design/fonts/Inter-SemiBold.woff";
import InterSemiBoldTtf from "./design/fonts/Inter-SemiBold.ttf";

import InterMediumWoff2 from "./design/fonts/Inter-Medium.woff2";
import InterMediumWoff from "./design/fonts/Inter-Medium.woff";
import InterMediumTtf from "./design/fonts/Inter-Medium.ttf";

import InterMediumItalicWoff2 from "./design/fonts/Inter-MediumItalic.woff2";
import InterMediumItalicWoff from "./design/fonts/Inter-MediumItalic.woff";
import InterMediumItalicTtf from "./design/fonts/Inter-MediumItalic.ttf";

import InterBlackItalicWoff2 from "./design/fonts/Inter-BlackItalic.woff2";
import InterBlackItalicWoff from "./design/fonts/Inter-BlackItalic.woff";
import InterBlackItalicTtf from "./design/fonts/Inter-BlackItalic.ttf";

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        scrollbar-width: none;
        margin: 0;
        padding: 0;
    }
    *, *::before, *::after {
        box-sizing: border-box;
    }
    *:fullscreen {
        position: fixed;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    html {
      overflow-y: hidden;
      overflow-x: hidden;
    }
    body {
        // position: fixed;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        background: ${props => props.darkmode ? color.gray1 : color.gray5};
        overflow-scrolling: touch;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        text-rendering: optimizeLegibility;
        overflow-clip: auto;
        text-align: center;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -moz-osx-font-smoothing: auto;
        -webkit-font-smoothing: subpixel-antialiased;
    }
    #root {
        width: inherit;
        height: inherit;
        // overflow: scroll;
    }
    
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        display: none;
        background-color: transparent;
    }
    ::selection {
        background: ${color.blue};
        color: ${color.white};
        border-radius: 10px;
    }
    ::placeholder, ::-webkit-input-placeholder {
        overflow: visible;
        color: ${props => props.darkmode ? `${color.gray3} !important` : `${color.gray4} !important`};
        opacity: 1;
    }
    ::-moz-placeholder, :-moz-placeholder, :-ms-input-placeholder {
        overflow: visible;
        color: ${props => props.darkmode ? `${color.gray3} !important` : `${color.gray4} !important`};
        opacity: 1;
    }
    a {
        text-decoration: none;
        color: ${color.blue};
    }
    span {
        height: auto;
        width: auto;
    }
    input::placeholder {
        overflow: visible;
    }
    
    @font-face {
        font-family: "Pulp Display Bold";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: local("Pulp Display Bold"), local("PulpDisplay-Bold"), 
             url(${PulpDisplayBoldWoff2}) format("woff2"),
             url(${PulpDisplayBoldWoff}) format("woff"),
             url(${PulpDisplayBoldTtf}) format("truetype"),
             url(${PulpDisplayBoldOtf}) format("opentype"),
             url(${PulpDisplayBoldSvg}) format("svg");
    }
    @font-face {
        font-family: "Pulp Display Medium";
        font-style: normal;
        font-weight: 500;
        font-display: block;
        src: local("Pulp Display Medium"), local("PulpDisplay-Medium"),
             url(${PulpDisplayMediumWoff2}) format("woff2"),
             url(${PulpDisplayMediumWoff}) format("woff"),
             url(${PulpDisplayMediumTtf}) format("truetype"),
             url(${PulpDisplayMediumOtf}) format("opentype"),
             url(${PulpDisplayMediumSvg}) format("svg");
    }
    @font-face {
        font-family: "Inter Extra Bold";
        font-style: normal;
        font-weight: 800;
        font-display: block;
        src: local("Inter Extra Bold"), local("Inter-ExtraBold"),
             url(${InterExtraBoldWoff2}) format("woff2"),
             url(${InterExtraBoldWoff}) format("woff"),
             url(${InterExtraBoldTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Bold";
        font-style: normal;
        font-weight: 700;
        font-display: block;
        src: local("Inter Bold"), local("Inter-Bold"),
             url(${InterBoldWoff2}) format("woff2"),
             url(${InterBoldWoff}) format("woff"),
             url(${InterBoldTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Bold Italic";
        font-style: italic;
        font-weight: 700;
        font-display: block;
        src: local("Inter Bold Italic"), local("Inter-BoldItalic"),
             url(${InterBoldItalicWoff2}) format("woff2"),
             url(${InterBoldItalicWoff}) format("woff"),
             url(${InterBoldItalicTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Semi Bold";
        font-style: normal;
        font-weight: 600;
        font-display: block;
        src: local("Inter Semi Bold"), local("Inter-SemiBold"),
             url(${InterSemiBoldWoff2}) format("woff2"),
             url(${InterSemiBoldWoff}) format("woff"),
             url(${InterSemiBoldTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Medium";
        font-style: normal;
        font-weight: 500;
        font-display: block;
        src: local("Inter Medium"), local("Inter-Medium"),
             url(${InterMediumWoff2}) format("woff2"),
             url(${InterMediumWoff}) format("woff"),
             url(${InterMediumTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Medium Italic";
        font-style: italic;
        font-weight: 500;
        font-display: block;
        src: local("Inter Medium Italic"), local("Inter-MediumItalic"),
             url(${InterMediumItalicWoff2}) format("woff2"),
             url(${InterMediumItalicWoff}) format("woff"),
             url(${InterMediumItalicTtf}) format("truetype");
    }
    @font-face {
        font-family: "Inter Black Italic";
        font-style: italic;
        font-weight: 900;
        font-display: block;
        unicode-range: U+0030-0039;
        src: local("Inter Black Italic"), local("Inter-BlackItalic"),
             url(${InterBlackItalicWoff2}) format("woff2"),
             url(${InterBlackItalicWoff}) format("woff"),
             url(${InterBlackItalicTtf}) format("truetype");
    }
`

export const GlobalContainer = styled.div`
    padding-top: 20px;
    margin: 0 auto;
    width: inherit;
    height: auto;
    display: flex;
    justify-content: space-between;
    // position: static;

    @media only screen and (max-width: 1540px) {
        justify-content: center;
        padding: 20px 30px 0 30px;
    }
    @media only screen and (max-width: 600px) {
        padding: 20px 20px 0 20px;
    }
`