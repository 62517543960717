import React, { Fragment, useState, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserId, selectUserLanguage, selectCollectionsDataPreview } from "../../redux/userData/userData.selectors";
import { selectArticleList } from "../../redux/articleData/articleData.selectors";
import { setListPin, deleteListStart, logRecentAccessStart } from "../../redux/userEvents/userEvents.actions";
import { listsData, miscData } from "../lang.data";
import { timeSince, capitalize } from "../general.utils";
import { color } from "../../design/theme";

import {
    ListCardContainer,
    ListCardPictureContainer,
    ListCardContentContainer,
    ListCardContentOptions,
    ListCardContentPinned,
    ListCardActionsContainer,
    ListCardTitleContainer,
    ListCardDetailsContainer,
    ListCardCreationDetails,
    ListCardStateDetails,
    ListCardStateDownload,
    ListCardLink
} from "./listCard.styles";
import StaticImage from "../staticImage/staticImage.component";
import ContextMenu from "../contextMenu/contextMenu.component";
import Icon from "../../design/icons/icon.component";
import Modal from "../modal/modal.component";
import ListSelection from "../listSelection/listSelection.component";
import ModalAction from "../modalAction/modalAction.component";
import ModalList from "../modalList/modalList.component";
import ModalInput from "../modalInput/modalInput.component";

const ListCard = ({ darkmode, placeholder, list, userId, setListPin, deleteListStart, lang, preview,
                      logRecentAccessStart, collectionsPreview, articleList }) => {

    const [deleteActive, setDeleteActive] = useState(false);
    const [editActive, setEditActive] = useState(false);
    const [contextActive, setContextActive] = useState(false);
    const contextRef = useRef(null);

    const history = useHistory();
    const { listId, title, desc, imageId, length, timestamp, creator, pinned } = list || {};

    let timer;
    const handleDownloadState = () => {
        if(collectionsPreview?.[listId]) {
            return !(collectionsPreview[listId].some(({ articleId }) => !articleList[articleId]))
        } else return false
    }
    const handleListContextMenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setContextActive(!contextActive)
    }
    const handleListCardClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => history.push(`/list/${listId}`), 200);
    }
    const handleListCardDoubleClick = (/*event*/) => {
        // event.stopPropagation();
        clearTimeout(timer);
        setListPin(userId, listId);
    }

    return (
        <ListCardContainer darkmode={darkmode} pinned={pinned} ref={contextRef} preview={preview}>
            {
                preview ? (
                    <ListCardLink to={`/list/${listId}`} />
                ) : !placeholder ? (
                    <ListCardLink
                        onContextMenu={(event) => handleListContextMenu(event)}
                        onClick={(event) => handleListCardClick(event)}
                        onDoubleClick={(event) => handleListCardDoubleClick(event)}
                        to={`/list/${listId}`}
                    />
                ) : null
            }
            <ListCardPictureContainer darkmode={darkmode}>
                <StaticImage
                    placeholder={placeholder}
                    imageId={imageId}
                />
            </ListCardPictureContainer>
            <ListCardContentContainer>
                {
                    placeholder ? (
                        <ListCardTitleContainer placeholder={placeholder}>
                            <span>{listsData.addNewList[lang]}</span>
                        </ListCardTitleContainer>
                    ) : (
                        <Fragment>
                            <ListCardTitleContainer>
                                <span>{title}</span>
                            </ListCardTitleContainer>
                            <ListCardDetailsContainer>
                                <ListCardCreationDetails>
                                    <span>{capitalize(miscData.by[lang])} </span>
                                    <span>{creator === userId ? miscData.you[lang] : "reavide"}</span>
                                    <span>{` • `}</span>
                                    <span>{timeSince(timestamp, lang)}</span>
                                </ListCardCreationDetails>
                                <ListCardStateDetails>
                                    <span>{length} {miscData.articles[lang]}</span>
                                    {
                                        !preview ? (
                                            <ListCardStateDownload
                                                name={"download"}
                                                size={20}
                                                color={handleDownloadState() ? color.blue : color.gray3}
                                            />
                                        ) : null
                                    }
                                </ListCardStateDetails>
                            </ListCardDetailsContainer>
                            {
                                !preview ? (
                                    <ListCardActionsContainer>
                                        {
                                            pinned ? (
                                                <ListCardContentPinned>
                                                    <Icon
                                                        name={"pinned"}
                                                        size={25}
                                                        color={color.white}
                                                    />
                                                </ListCardContentPinned>
                                            ) : null
                                        }
                                        <ListCardContentOptions darkmode={darkmode}>
                                            <ContextMenu
                                                type={"options"}
                                                title={listsData.title}
                                                data={listsData.options}
                                                state={{ pin: pinned }}
                                                contextState={contextActive}
                                                contextCallback={() => setContextActive(!contextActive)}
                                                contextRef={contextRef}
                                                callbacks={{
                                                    pin: () => setListPin(userId, listId),
                                                    delete: () => setDeleteActive(!deleteActive),
                                                    edit: () => setEditActive(!editActive)
                                                }}
                                            />
                                            {
                                                editActive ? (
                                                    <Modal
                                                        type={"list"}
                                                        title={listsData.editList[lang]}
                                                        onDismiss={() => setEditActive(!editActive)}
                                                    >
                                                        <ModalList imageId={imageId} />
                                                        <ModalInput
                                                            type={"list"}
                                                            onDismiss={() => setEditActive(!editActive)}
                                                            imageId={imageId}
                                                            refValues={{
                                                                title: title,
                                                                desc: desc,
                                                                listId: listId
                                                            }}
                                                        />
                                                    </Modal>
                                                ) : null
                                            }
                                            {
                                                deleteActive ? (
                                                    <Modal
                                                        title={`${listsData.deleteList[lang]} ⚠️`}
                                                        desc={miscData.confirmIrreversible[lang]}
                                                        onDismiss={() => setDeleteActive(!deleteActive)}
                                                    >
                                                        <ListSelection
                                                            type={"delete"}
                                                            list={list}
                                                        />
                                                        <ModalAction
                                                            callback={() => {
                                                                logRecentAccessStart(userId, listId, "delete")
                                                                deleteListStart(userId, listId)
                                                            }}
                                                            onDismiss={() => setDeleteActive(!deleteActive)}
                                                            actionColor={color.red}
                                                        />
                                                    </Modal>
                                                ) : null
                                            }
                                        </ListCardContentOptions>
                                    </ListCardActionsContainer>
                                ) : null
                            }
                        </Fragment>
                    )
                }
            </ListCardContentContainer>
        </ListCardContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    userId: selectUserId,
    lang: selectUserLanguage,
    collectionsPreview: selectCollectionsDataPreview,
    articleList: selectArticleList
})

const mapDispatchToProps = (dispatch) => ({
    setListPin: (userId, listId) => dispatch(setListPin(userId, listId)),
    deleteListStart: (userId, listId) => dispatch(deleteListStart(userId, listId)),
    logRecentAccessStart: (userId, listId, refType) => dispatch(logRecentAccessStart(userId, listId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListCard);