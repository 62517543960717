import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { titleData, navData } from "../../../components/lang.data";
import {
    selectChannelDataRaw,
    selectChannelDataForPreview,
    selectUserDataFetching,
    selectUserLanguage,
    selectUserDataError,
    selectUserId
} from "../../../redux/userData/userData.selectors";
import { fetchUserChannelsStart } from "../../../redux/userData/userData.actions";

import { ChannelSubscribedContainer } from "./channelSubscribed.styles";
import Indicator from "../../../components/indicator/indicator.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";
import ChannelCategory from "../../../components/channelCategory/channelCategory.component";
import SiteHead from "../../../components/siteHead/siteHead.component";

const ChannelSubscribed = ({ channelData, loading, previewData, lang, error, userId, fetchUserChannelsStart }) => {
    const[reload, setReload] = useState(false);

    useEffect(() => {
        const reloadReset = reload ? setTimeout(() => setReload(false), 500) : null
        if(!loading && !channelData) fetchUserChannelsStart({ userId, lang });

        return () => clearTimeout(reloadReset)
    }, [fetchUserChannelsStart, userId, lang, reload])

    return (
        <ChannelSubscribedContainer>
            <SiteHead title={navData.channels.desc[lang]} />
            {
                (!error) ? (
                    <Fragment>
                        <Indicator
                            pageLabel={titleData.channels[lang]}
                            pageLabelEmoji={"📬"}
                        />
                        {
                            !isEmpty(previewData) ? Object.keys(previewData).map(key => (
                                previewData[key]?.channels.length ? (
                                        <ChannelCategory
                                            type={"subbedChannels"}
                                            key={key}
                                            categoryId={key}
                                            categoryContent={previewData[key]}
                                        />
                                ) : null
                            )) : (loading !== undefined && !loading && previewData) ? (
                                <ErrorDisplay type={"noChannels"} link={"explore"} icon={"110015"} />
                            ) : null
                        }
                    </Fragment>
                ) : <ErrorDisplay type={"contentError"} callback={setReload} />
            }
        </ChannelSubscribedContainer>
    )
}

const mapStateToProps = (state) => ({
    lang: selectUserLanguage(state),
    userId: selectUserId(state),
    channelData: selectChannelDataRaw(state),
    previewData: selectChannelDataForPreview(state),
    loading: selectUserDataFetching("channelData")(state),
    error: selectUserDataError("channelData")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserChannelsStart: (reqHeaders) => dispatch(fetchUserChannelsStart(reqHeaders))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSubscribed);