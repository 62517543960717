import React, { useLayoutEffect, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { color } from "../../../design/theme";
import { HeaderContext } from "../../../context/header.context";
import { settingsData, titleData, consentData } from "../../../components/lang.data";
import { selectUserLanguage, selectUserAllowAllEmbeds, selectUserAllowEmbeds, selectUserId } from "../../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { indexedEmbedProviders } from "../../../components/articleContent/articleEmbed/articleEmbed.utils";
import { setUserEmbedConsentStart } from "../../../redux/userSettings/userSettings.actions";
import { capitalize, cleanStringToDbKey, generateCdnUrl } from "../../../components/general.utils";

import {
    EmbedsSettingsContainer,
    EmbedsSettingsCookieContainer,
    EmbedsSettingsCookieNum,
    EmbedsSettingsCookieIcon
} from "./embeds.styles";
import SettingsCard from "../../../components/settingsCard/settingsCard.component";
import SiteHead from "../../../components/siteHead/siteHead.component";
import ToggleSwitch from "../../../components/toggleSwitch/toggleSwitch.component";

const EmbedsSettingsPage = ({ userId, lang, darkmode, setUserEmbedConsent, allEmbeds, embeds }) => {
    const { setSimpleEnv } = useContext(HeaderContext)

    useLayoutEffect(() => {
        setSimpleEnv(true);
        return () => setSimpleEnv(null)
    }, [setSimpleEnv])

    return (
        <EmbedsSettingsContainer>
            <SiteHead title={titleData.settings[lang]} />
            <SettingsCard
                title={settingsData.experience.embeds[lang]}
                info={settingsData.embeds.general.info[lang]}
                contentData={[
                    {
                        desc: settingsData.embeds.general.allowAll,
                        icon: "embeds",
                        type: "action",
                        action: (
                            <ToggleSwitch
                                active={allEmbeds}
                                disabled={false}
                                callback={() => setUserEmbedConsent(userId, "all", !allEmbeds)}
                            />
                        )
                    }
                ]}
            />
            <SettingsCard
                title={settingsData.embeds.specific.embedProviders[lang]}
                info={settingsData.embeds.specific.info[lang]}
                contentData={
                    indexedEmbedProviders.map(provider => ({
                        desc: (consentData.providerNames[provider] ?? capitalize(provider)),
                        image: generateCdnUrl(`content/brands/icons/color/${provider}.svg`),
                        type: "action",
                        action: (
                            <ToggleSwitch
                                active={allEmbeds || embeds[cleanStringToDbKey(provider)]}
                                disabled={allEmbeds}
                                callback={() => setUserEmbedConsent(userId, cleanStringToDbKey(provider), !embeds[cleanStringToDbKey(provider)])}
                            />
                        ),
                        actionLabel: (
                            <EmbedsSettingsCookieContainer
                                href={consentData.providerPrivacy[provider][lang]}
                                target="_blank"
                                rel={"external nofollow noreferrer noopener"}
                                darkmode={darkmode}
                            >
                                <EmbedsSettingsCookieNum darkmode={darkmode}>
                                    <span>{consentData.providerCookies[provider]}</span>
                                </EmbedsSettingsCookieNum>
                                <EmbedsSettingsCookieIcon
                                    name={"cookie"}
                                    size={25}
                                    color={darkmode ? color.gray4 : color.gray3}
                                />
                            </EmbedsSettingsCookieContainer>
                        )
                    }))
                }
            />
            {

            }
        </EmbedsSettingsContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode,
    userId: selectUserId,
    allEmbeds: selectUserAllowAllEmbeds,
    embeds: selectUserAllowEmbeds
})

const mapDispatchToProps = (dispatch) => ({
    setUserEmbedConsent: (userId, embedVariant, embedValue) => dispatch(setUserEmbedConsentStart(userId, embedVariant, embedValue))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmbedsSettingsPage);