import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { addScript, removeScript, /*removeStyle,*/ scriptPresent, generateCdnUrl } from "../../../general.utils";
import { radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
`

const EmbedPinterest = ({ embedUrl, width, contentId }) => {
    const ref = useRef(null);
    const modUrl = "https://www.pinterest.com/pin/" + embedUrl.substring(embedUrl.lastIndexOf("?id=") + 4)

    const observer = new MutationObserver(mutations => {
        mutations.forEach(({ target }) => {
            if (target.id === "pinterestWrapper_" + contentId) {
                target.firstChild.style.width = width + "px";
                target.firstChild.style.maxWidth = width + "px";
                target.firstChild.style.borderRadius = radius.largeRadius;
            }
        });
    });

    useEffect(() => {
        if(ref.current) {
            observer.observe(ref.current, { attributes: true, childList: true, subtree: true });
        }

        if(!scriptPresent("pinterest") && !window.doBuild) {
            addScript(generateCdnUrl("dist/js/embeds/pinterest-init-sdk.js"), "data-pin-build", "doBuild")
            // addScript("//assets.pinterest.com/js/pinit.js", "data-pin-build", "doBuild")
        } else if(window.doBuild){
            window.doBuild(ref.current);
        }

        return () => {
            removeScript("pinterest")
            // removeStyle("span.PIN_")
        }
    }, [ref])

    return (
        <IFrameWrapper
            id={"pinterestWrapper_" + contentId}
            width={width}
            ref={ref}
        >
            <a
                data-pin-do={"embedPin"}
                data-pin-terse={"true"}
                href={modUrl}
            > </a>
        </IFrameWrapper>
    )
}

export default EmbedPinterest;