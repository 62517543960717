import UserDataActionTypes from "./userData.types";
import { isPlainObject, sortBy, values, omit, remove, pull, isEmpty } from "lodash";
import { configureHistoryPreview } from "./userData.utils";

const INITIAL_STATE = {
    user: null,
    accessedData: null,
    searchData: {
        data: null,
        preview: null
    },
    channelData: {
        data: null,
        preview: null,
        fetchKey: null
    },
    bookmarkData: {
        data: null,
        preview: null,
        fetchKey: null
    },
    listsData: {
        data: null,
        preview: null,
        fetchKey: null
    },
    collectionsData: {
        data: null,
        preview: null,
        fetchKey: null
    },
    historyData: {
        data: null,
        preview: null,
        fetchKey: null
    },
    userDataFetching: {},
    userDataError: {}
}

const userDataSetter = (action, stateObj) => {
    const { refKey, payload, addData, fetchKey } = action || {}
    // userDataSetter(action.refKey, action.payload, action.addData, action.fetchKey, state),
    if(refKey === "bookmarkData" || refKey === "listsData") {
        let stateData = stateObj[refKey].data || {};
        let statePreview = stateObj[refKey].preview || {};

        if(addData === "delete") {
            let itemId = refKey === "listsData" ? payload.listId : payload.articleId
            stateData = omit(stateData, [itemId,]);
        } else if(isPlainObject(payload)) {
            stateData = Object.assign({}, stateData, payload);
        }

        return {
            data: stateData,
            preview: pull((Array.isArray(addData) && !statePreview ? addData : sortBy(values(stateData), "timestamp").reverse()), "undefined"),
            fetchKey: fetchKey || stateObj[refKey].fetchKey
        }
    } else if(refKey === "historyData") {
        let stateData = fetchKey === "general" ? payload : stateObj[refKey].data;
        let stateFetchKey = stateObj[refKey].fetchKey;

        if(addData === "delete") {
            stateData = remove(stateData, (item) => item.timestamp !== payload.timestamp)
        } else if(addData === "add" && isPlainObject(payload)) {
            stateData = [payload, ...(stateData || [])];
        }

        return {
            data: stateData || payload,
            preview: configureHistoryPreview(stateData || payload),
            fetchKey: stateFetchKey !== "general" ? (fetchKey ?? stateFetchKey) : "general"
        }
    } else if(refKey === "channelData") {
        let stateData = stateObj[refKey].data;
        let previewData = stateObj[refKey].preview;

        const deleteFromPreviewObj = (previewData) => {
            Object.values(previewData).forEach(({ channels }) => {
                remove(channels, (item) => item.channelId === payload.channelId)
            })
            return previewData;
        }
        const addToPreviewObj = (previewData) => {
            if(isEmpty(previewData)) return addData;
            let modCategory = Object.keys(addData)[0];
            let channelArr = [...(previewData[modCategory]?.channels || []), ...addData[modCategory].channels];

            if(!previewData[modCategory]) Object.assign(previewData, addData)
            else Object.assign(previewData[modCategory], { channels: channelArr });

            return previewData;
        }

        if(addData === "delete") {
            stateData = omit(stateData, [payload.channelId,]);
            previewData = deleteFromPreviewObj(previewData);
        } else if(stateData && isPlainObject(payload)) {
            stateData = Object.assign({}, stateData, payload);
            previewData = addToPreviewObj(previewData);
        }

        return {
            data: stateData || payload,
            preview: previewData || addData,
            fetchKey: fetchKey
        }
    } else if(refKey === "collectionsData") {
        let stateData = stateObj[refKey].data;
        let previewData = stateObj[refKey].preview;

        if(addData === "delete") {
            if(fetchKey === "general") {
                stateData = omit(stateData, [Object.keys(payload)[0],]);
            } else {
                let listId = Object.keys(payload)[0];
                delete stateData[listId][payload[listId].articleId];
                stateData = Object.assign({}, stateData);
            }
        } else if(addData === "add") {
            if(fetchKey === "general") {
                stateData = Object.assign({}, stateData, payload)
            } else {
                let listId = Object.keys(payload)[0];
                let updatedList = Object.assign(stateData[listId], { [payload[listId].articleId]: payload[listId] });
                stateData = Object.assign({}, stateData, { [listId]: updatedList })
            }
        } else if(stateData && isPlainObject(payload)) {
            if(fetchKey === "general") {
                stateData = payload;
            } else {
                stateData = Object.assign({}, stateData, payload);
            }
        }

        const generatePreview = (stateData) => {
            let snapshotPreview = {};
            Object.entries(stateData).forEach(entry => {
                let values = sortBy(Object.values(entry[1]).map(article => (article)), "timestamp").reverse();
                Object.assign(snapshotPreview, { [entry[0]]: values })
            });
            return snapshotPreview;
        }

        return {
            data: stateData || payload,
            preview: previewData ? generatePreview(stateData) : addData,
            fetchKey: stateObj[refKey].fetchKey !== "general" ? fetchKey : "general"
        }
    } else if(refKey === "searchData") {
        let stateData = fetchKey === "general" ? payload : stateObj[refKey].data;

        if(fetchKey === "delete") delete stateData[payload.key];
        else if(fetchKey === "add" && payload) stateData = { ...(stateData || {}), ...payload }

        return {
            data: stateData || payload,
            preview: typeof stateData === "object" ? sortBy(Object.entries(stateData).map(([key, value]) => ({ key, ...value })), "timestamp").reverse() : []
        }
    } else if(refKey === "accessedData") {
        let stateData = stateObj[refKey];

         if(fetchKey === "delete" || fetchKey === "change") {
            remove(stateData, list => list.listId === payload.listId)
         }
         if(fetchKey === "add" || fetchKey === "change") {
             if(stateData?.length) stateData.unshift(payload)
             else stateData = [payload]
         }

        return sortBy([...(stateData || payload)], "timestamp").reverse()
    }
}

const userDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserDataActionTypes.FETCH_USER_BOOKMARKS_START:
        case UserDataActionTypes.FETCH_USER_CHANNELS_START:
        case UserDataActionTypes.FETCH_USER_ACCESSED_START:
        case UserDataActionTypes.FETCH_USER_SEARCHES_START:
        case UserDataActionTypes.FETCH_USER_HISTORY_START:
        case UserDataActionTypes.FETCH_USER_LISTS_START:
        case UserDataActionTypes.FETCH_USER_DATA_START:
            return {
                ...state,
                userDataFetching: Object.assign(state.userDataFetching, { [action.refKey]: true })
            }
        case UserDataActionTypes.FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                user: isPlainObject(state.user) ? { ...state.user, ...action.payload } : action.payload,
                userDataError: Object.assign(state.userDataError, { "userData": null }),
                userDataFetching: Object.assign(state.userDataFetching, { "userData": false })
            }
        case UserDataActionTypes.FETCH_USER_COLLECTIONS_SUCCESS:
        case UserDataActionTypes.FETCH_USER_BOOKMARKS_SUCCESS:
        case UserDataActionTypes.FETCH_USER_CHANNELS_SUCCESS:
        case UserDataActionTypes.FETCH_USER_ACCESSED_SUCCESS:
        case UserDataActionTypes.FETCH_USER_SEARCHES_SUCCESS:
        case UserDataActionTypes.FETCH_USER_HISTORY_SUCCESS:
        case UserDataActionTypes.FETCH_USER_LISTS_SUCCESS:
            return {
                ...state,
                [action.refKey]: userDataSetter(action, state),
                userDataError: Object.assign(state.userDataError, { [action.refKey]: null }),
                userDataFetching: Object.assign(state.userDataFetching, { [action.refKey]: false })
            }
        case UserDataActionTypes.FETCH_USER_COLLECTIONS_FAILURE:
        case UserDataActionTypes.FETCH_USER_BOOKMARKS_FAILURE:
        case UserDataActionTypes.FETCH_USER_CHANNELS_FAILURE:
        case UserDataActionTypes.FETCH_USER_ACCESSED_FAILURE:
        case UserDataActionTypes.FETCH_USER_SEARCHES_FAILURE:
        case UserDataActionTypes.FETCH_USER_HISTORY_FAILURE:
        case UserDataActionTypes.FETCH_USER_LISTS_FAILURE:
        case UserDataActionTypes.FETCH_USER_DATA_FAILURE:
            return {
                ...state,
                userDataError: Object.assign(state.userDataError, { [action.refKey]: action.payload }),
                userDataFetching: Object.assign(state.userDataFetching, { [action.refKey]: false })
            }
        default:
            return state;
    }
}

export default userDataReducer;