import { createSelector } from "reselect";

const selectArticleRef = state => state.articleRef;

export const selectArticleRefList = createSelector(
    [selectArticleRef],
    articleRef => articleRef.articleRefObj
)

export const selectArticleRefArray = (refKey) =>
    createSelector(
        [selectArticleRefList],
        articleRefObj => articleRefObj[refKey]?.list || null
    )

export const selectArticleRefPage = (refKey) =>
    createSelector(
        [selectArticleRefList],
        articleRefObj => articleRefObj[refKey]?.page
    )

export const selectArticleRefError = (refKey) =>
    createSelector(
        [selectArticleRef],
        articleRef => articleRef.articleRefError[refKey]
    )

export const selectArticleRefFetching = createSelector(
    [selectArticleRef],
    articleRef => articleRef.articleRefIsFetching
)