import { consentData } from "../../lang.data";

const narrowProviders = ["facebook", "instagram", "pinterest"];

export const cookiesUsed = (provider, lang) => {
    if(parseInt(consentData.providerCookies[provider]) === 1) return consentData.cookiesSingular[lang]
    else return consentData.cookiesPlural[lang];
}

export const calcEmbedWidth = (provider, mainWidth) => {
    if(provider === "twitter" && mainWidth >= 550) return "550"
    if(narrowProviders.includes(provider) && mainWidth >= 600 || !indexedEmbed(provider)) return "600"
    else return mainWidth;
}

export const calcEmbedHeight = (provider, mainWidth) => {
    if(!indexedEmbed(provider) || narrowProviders.includes(provider)) return mainWidth * 0.5625 * (2/3);
    else return mainWidth * 0.5625
}

export const indexedEmbedProviders = ["admin.ch", "bbvms", "datawrapper", "facebook", "flourish", "genially", "giphy", "google-maps", "gr.ch", "infogram", "instagram", "keystone-video", "knightlab", "livecenter", "nhl", "pageflow", "pinterest", "playbuzz", "sda-liveblog", "sg.ch", "sketchfab", "sportresultate", "spotify", "srf-player", "srgssr", "twitter", "wilmaa"];
export const indexedEmbed = (provider) => indexedEmbedProviders.includes(provider);