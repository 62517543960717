import ChannelRefActionTypes from "./channelRef.types";

const INITIAL_STATE = {
    channelRefList: null,
    channelRefIsFetching: false,
    channelRefData: null,
    channelRefError: {}
}

const channelRefReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ChannelRefActionTypes.FETCH_CHANNEL_REF_START:
            return {
                ...state,
                channelRefIsFetching: true
            }
        case ChannelRefActionTypes.FETCH_CHANNEL_REF_SUCCESS:
            if (Object.keys(action.payload).length <= 1) {
                return {
                    ...state,
                    channelRefIsFetching: false,
                    channelRefData: { ...(state.channelRefData || {}), ...action.payload },
                    channelRefError: Object.assign(state.channelRefError, { [action.refKey]: null })
                }
            } else {
                return {
                    ...state,
                    channelRefIsFetching: false,
                    channelRefList: action.payload,
                    channelRefError: Object.assign(state.channelRefError, { "general": null })
                }
            }
        case ChannelRefActionTypes.FETCH_CHANNEL_REF_FAILURE:
            const refKey = action.refKey ? action.refKey : "general";

            return {
                ...state,
                channelRefIsFetching: false,
                channelRefError: Object.assign(state.channelRefError, { [refKey]: action.payload })
            }
        default:
            return state
    }
}

export default channelRefReducer;