import React, { useState, useMemo, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { calcCategoryHeight, calcCategoryWidth, calcNumColumns, calcSubCategoryHeight } from "./channelCategory.utils";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectChannelDataFetchKey } from "../../redux/userData/userData.selectors";

import {
    ChannelCategoryContainer,
    ChannelCategoryContainerTitle,
    ChannelCategoryCardsContainer,
    ChannelCategoryListContainer
} from "./channelCategory.styles";
import ChannelCard from "../channelCard/channelCard.component";
import ChannelList from "../channelList/channelList.component";

const ChannelCategory = ({ categoryId, categoryContent, type = "", darkmode, fetchKey }) => {
    const { mainWidth } = useContext(LayoutContext);

    const { categoryName, channels } = categoryContent;
    const [categoryChannels, setCategoryChannels] = useState(channels);

    useMemo(() => setCategoryChannels(channels), [fetchKey])

    return (
        <ChannelCategoryContainer
            darkmode={darkmode}
            height={type ? calcSubCategoryHeight(channels.length, mainWidth) : calcCategoryHeight(channels.length, mainWidth)}
            width={type ? null : calcCategoryWidth(channels.length, mainWidth)}
            type={type}
        >
            <ChannelCategoryContainerTitle
                darkmode={darkmode}
                type={type}
            >
                <span>{ categoryName }</span>
            </ChannelCategoryContainerTitle>
            {
                type ? (
                    <ChannelCategoryListContainer>
                        {
                            categoryChannels.map(channel => (
                                <ChannelList
                                    key={channel.channelId}
                                    channel={channel}
                                    categoryId={categoryId}
                                />
                            ))
                        }
                    </ChannelCategoryListContainer>
                ) : (
                    <ChannelCategoryCardsContainer numColumns={calcNumColumns(mainWidth)}>
                        {
                            channels.map(channel => (
                                <ChannelCard
                                    key={channel.channelId}
                                    channel={channel}
                                    categoryId={categoryId}
                                />
                            ))
                        }
                    </ChannelCategoryCardsContainer>
                )
            }
        </ChannelCategoryContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    fetchKey: selectChannelDataFetchKey
})

export default connect(mapStateToProps)(ChannelCategory);