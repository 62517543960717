import React, { createContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserId, selectUserLanguage } from "../redux/userData/userData.selectors";
import { selectBreakingList, selectBreakingUpdate, selectBreakingListFetching } from "../redux/breakingData/breakingData.selectors";
import { fetchBreakingDataStart } from "../redux/breakingData/breakingData.actions";

export const BreakingContext = createContext(null);

const BreakingContextProvider = ({ children, breakingList, breakingUpdate, userId, lang, loading, fetchBreakingDataStart }) => {
    const [renderList, setRenderList] = useState(null);
    const [latestUpdate, setLatestUpdate] = useState("");
    const [highlightUpdate, setHighlightUpdate] = useState(false);
    const [highlightTicker, setHighlightTicker] = useState(setTimeout(() => {}, 0));
    const [alertUpdate, setAlertUpdate] = useState(false);
    const [alertTicker, setAlertTicker] = useState(setTimeout(() => {}, 0));

    useEffect(() => {
        setRenderList(breakingList);
        if(!breakingList && !loading) fetchBreakingDataStart({ userId, lang })
        if((breakingUpdate !== latestUpdate) && !breakingUpdate.includes("removed")) {
            setLatestUpdate(breakingUpdate);
            if(highlightUpdate) clearTimeout(highlightTicker);
            else setHighlightUpdate(true);
            setHighlightTicker(setTimeout(() => setHighlightUpdate(false), 1500));
            if(alertUpdate) clearTimeout(alertTicker);
            else setAlertUpdate(true);
            setAlertTicker(setTimeout(() => setAlertUpdate(false), 40000));
        }
    }, [breakingList, breakingUpdate, userId, lang, fetchBreakingDataStart])

    return (
        <BreakingContext.Provider value={{ renderList, highlightUpdate, alertUpdate }}>
            { children }
        </BreakingContext.Provider>
    )
}

const mapStateToProps = createStructuredSelector({
    userId: selectUserId,
    lang: selectUserLanguage,
    breakingList: selectBreakingList,
    breakingUpdate: selectBreakingUpdate,
    loading: selectBreakingListFetching
})

const mapDispatchToProps = (dispatch) => ({
    fetchBreakingDataStart: (payload) => dispatch(fetchBreakingDataStart(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(BreakingContextProvider);