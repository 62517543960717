import styled from "styled-components";

export const ReadingProgressContainer = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    padding: 0 22px 0 22px;
    z-index: 5;
    display: inline-flex;
    flex-direction: column;
`