import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { update, set, onValue, ref, remove, push } from "firebase/database";
import { updateMetadata, ref as storageRef, uploadString, deleteObject } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import smartcrop from "smartcrop";

import { store } from "../store";
import { version } from "../../../package.json";
import { locationAPI, newsAPI } from "../backendAPI";
import { searchIndex } from "../../config/algolia.config";
import { database, storage, feedback } from "../../config/firebase.config";
import { isDataURL, calcBytes, isMobile, encodeURIQuery, validateSearchAttribute, getUserPlatform, getUserAgent, getURIQueryResultKey } from "../../components/general.utils";
import { selectListsDataDetails, selectUserLanguage, selectRecentSearches } from "../userData/userData.selectors";
import { selectUploadedImageFallback, selectSearchResults } from "./userEvents.selectors";
import { selectArticleList } from "../articleData/articleData.selectors";
import { selectTraceLog } from "../sessionData/sessionData.selectors";
import UserEventsActionTypes from "./userEvents.types";
import {
    uploadImageSuccess, uploadImageFailure,
    deleteImageSuccess, deleteImageFailure,
    setListSuccess, setListFailure,
    deleteListSuccess, deleteListFailure,
    logRecentAccessSuccess, logRecentAccessFailure,
    performSearchSuccess, performSearchFailure,
    performUrlSearchSuccess, performUrlSearchFailure,
    deleteSearch
} from "./userEvents.actions";
import { fetchArticleDataStart } from "../articleData/articleData.actions";


export function* articleBookmarkStartAsync(action) {
    try {
        const userId = action.payload;
        const articleId = action.articleId;
        let userBookmark = yield ref(database, "users/" + userId + "/news/bookmarks/" + articleId);
        yield onValue(userBookmark, async snapshot => {
            if (!snapshot.exists()) {
                await set(userBookmark, {
                    articleId: articleId,
                    timestamp: new Date().toISOString(),
                    userMobile: isMobile(),
                    client: "webApp"
                })
            } else {
                await remove(userBookmark)
            }
        }, { onlyOnce: true })
    } catch (error) {
        console.log("ARTICLE_BOOKMARK_ERROR", error.message)
    }
}

export function* articleBookmarkStart() {
    yield takeLatest(
        UserEventsActionTypes.BOOKMARK_ARTICLE,
        articleBookmarkStartAsync
    )
}


export function* followChannelStartAsync(action) {
    try {
        const userId = action.payload;
        const channelId = action.channelId;
        let userChannel = yield ref(database, "users/" + userId + "/news/channels/" + channelId);
        yield onValue(userChannel, async snapshot => {
            if (!snapshot.exists()) {
                await set(userChannel, {
                    channelId: channelId,
                    timestamp: new Date().toISOString()
                })
            } else {
                await remove(userChannel)
            }
        }, { onlyOnce: true })
    } catch (error) {
        console.log("CHANNEL_FOLLOW_ERROR", error.message)
    }
}

export function* followChannelStart() {
    yield takeLatest(
        UserEventsActionTypes.FOLLOW_CHANNEL,
        followChannelStartAsync
    )
}


export function* listArticleStartAsync(action) {
    try {
        const userId = action.payload;
        const { articleId, listId, deleteLength } = action.identifiers;
        const { length } = yield select(selectListsDataDetails(listId));

        let collectionArticle = yield ref(database, "users/" + userId + "/news/lists/collections/" + listId + "/" + articleId);
        let userList = yield ref(database, "users/" + userId + "/news/lists/details/" + listId);

        yield onValue(collectionArticle, async snapshot => {
            let newISODate = new Date().toISOString()
            if (!snapshot.exists()) {
                await set(collectionArticle, {
                    articleId: articleId,
                    timestamp: newISODate
                })
                await update(userList, {
                    length: length + 1,
                    timestamp: newISODate
                })
            } else {
                await remove(collectionArticle)
                await update(userList, {
                    length: length - (deleteLength || 1),
                    timestamp: newISODate
                })
            }
        }, { onlyOnce: true })
    } catch (error) {
        console.log("LIST_ARTICLE_ERROR", error.message)
    }
}

export function* listArticleStart() {
    yield takeLatest(
        UserEventsActionTypes.LIST_ARTICLE,
        listArticleStartAsync
    )
}


export function* setListPinStartAsync(action) {
    try {
        const userId = action.payload;
        const listId = action.listId;
        let userList = yield ref(database, "users/" + userId + "/news/lists/details/" + listId);
        let userListPin = yield ref(database, "users/" + userId + "/news/lists/details/" + listId + "/pinned");

        yield onValue(userListPin, async snapshot => {
            await update(userList, { pinned: !snapshot.val() })
        }, { onlyOnce: true })
    } catch (error) {
        console.log("SET_LIST_PIN_ERROR", error.message);
    }
}

export function* setListPinStart() {
    yield takeLatest(
        UserEventsActionTypes.SET_LIST_PIN,
        setListPinStartAsync
    )
}


export function* deleteSearchStartAsync(action) {
    try {
        const userId = action.payload;
        const { query, attribute } = action.searchDetails;
        const recentSearches = yield select(selectRecentSearches);

        let updateObj = {};
        let itemsToRemove = recentSearches.filter(search => search.attribute === attribute && search.query === query);
        itemsToRemove?.forEach(({ key }) => Object.assign(updateObj, { [key]: null }));

        // yield remove(ref(database, "users/" + userId + "/news/search/" + searchKey))
        const searchRef = ref(database, "users/" + userId + "/news/search/");
        if(itemsToRemove.length) yield update(searchRef, updateObj);
    } catch (error) {
        console.log("DELETE_SEARCH_ERROR", error.message);
    }
}

export function* deleteSearchStart() {
    yield takeLatest(
        UserEventsActionTypes.DELETE_SEARCH,
        deleteSearchStartAsync
    )
}


export function* logArticleReadStartAsync(action) {
    try {
        const userId = action.payload;
        const { articleId, category, lang } = action.articleDetails;

        yield push(ref(database, "users/" + userId + "/news/history"), {
            articleId: articleId,
            category: category,
            articleLang: lang,
            timestamp: new Date().toISOString(),
            userAgent: getUserAgent(),
            userMobile: isMobile(),
            userPlatform: getUserPlatform(),
            client: "webApp"
        })
    } catch (error) {
        console.log("LOG_ARTICLE_READ_ERROR", error.message);
    }
}

export function* logArticleReadStart() {
    yield takeLatest(
        UserEventsActionTypes.LOG_ARTICLE_READ,
        logArticleReadStartAsync
    )
}


export function* setTargetReadsStartAsync(action) {
    try {
        const userId = action.payload;
        const { target } = action.target;

        yield update(ref(database, "users/" + userId + "/news/general"), {
            targetReads: target
        })
    } catch (error) {
        console.log("SET_TARGET_READS_ERROR", error.message);
    }
}

export function* setTargetReadsStart() {
    yield takeLatest(
        UserEventsActionTypes.SET_TARGET_READS,
        setTargetReadsStartAsync
    )
}


export function* logSharedContentStartAsync(action) {
    try {
        const userId = action.payload;
        const { type, shareId, value } = action.shareDetails;

        yield push(ref(database, "users/" + userId + `/news/shared/${type}s`), {
            [`${type}Id`]: shareId,
            medium: value,
            timestamp: new Date().toISOString()
        })
    } catch (error) {
        console.log("LOG_CONTENT_SHARE_ERROR", error.message);
    }
}

export function* logSharedContentStart() {
    yield takeLatest(
        UserEventsActionTypes.LOG_SHARED_CONTENT,
        logSharedContentStartAsync
    )
}


export function* sendFeedbackStartAsync(action) {
    try {
        const userId = action.payload;
        const { type, desc, screenshot, attachTraceLog } = action.feedback;
        const traceLog = yield select(selectTraceLog);
        const validImage = screenshot ? isDataURL(screenshot) : null;
        console.log("RECEIVED ON FEEDBACK", validImage, screenshot);
        const screenshotIdSize = 30 + Math.floor(Math.random() * 10.5);
        const screenshotId = nanoid(screenshotIdSize);
        const reportId = nanoid(6);

        const depositDoc = {
            id: reportId,
            userId: userId,
            service: "news",
            client: "webApp",
            clientVersion: version,
            timestamp: new Date().toISOString(),
            type: type,
            desc: desc,
            status: "open",
            userPlatform: getUserPlatform(),
            userAgent: getUserAgent(true),
            userMobile: isMobile(),
            screenshotId: validImage ? screenshotId : ""
        }
        const addIfBugReport = {
            traceLog: attachTraceLog ? JSON.stringify(traceLog) : "",
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            route: window.location?.pathname,
            routeParams: window.location?.search || ""
        }

        const setFeedbackDoc = async () => {
            await setDoc(
                doc(feedback, "feedback", "news", type + "s", reportId),
                { ...Object.assign(depositDoc, type === "bugReport" ? addIfBugReport : {}) }
            )
        }
        if(validImage) {
            let newImagePath = "reports/bugReports/" + screenshotId + ".webp";
            let cdnReports = yield storageRef(storage, newImagePath);

            yield uploadString(cdnReports, screenshot, "data_url").then(async (snapshot) => {
                console.log("CDN SCREENSHOT UPLOAD --WEBP", snapshot)
                await setFeedbackDoc()
            }).catch((error) => console.log("CDN SCREENSHOT ERROR", error))
        } else {
            yield setFeedbackDoc()
        }
    } catch (error) {
        console.log("SEND_FEEDBACK_ERROR", error.message, error);
    }
}

export function* sendFeedbackStart() {
    yield takeLatest(
        UserEventsActionTypes.SEND_FEEDBACK,
        sendFeedbackStartAsync
    )
}


export function* uploadImageStartAsync(action) {
    try {
        const { userId, fileRef } = action.payload;
        const { name, size, type } = fileRef || {};
        const refType = action.refType;

        if(type !== "image/png" && type !== "image/jpeg" &&
           type !== "image/jpg" && type !== "image/webp") throw "408";

        let reader = new FileReader();

        reader.onload = () => {
            let img = new Image();
            img.onload = () => {
                try {
                    const imgWidth = img.naturalWidth;
                    const imgHeight = img.naturalHeight;
                    const outputImageAspectRatio = 1;

                    console.log("SAGA TRIGGER", userId, fileRef, name, size, calcBytes(size, 3), type, refType, imgHeight, imgWidth, reader);

                    if(imgWidth < 256 || imgHeight < 256) throw "409";
                    if(size > 5200000) throw "410";

                    smartcrop.crop(img, { height: 1000, width: 1000 }).then(({ topCrop }) => {
                        console.log("SMARTCROP ASYNC CROP RESULT", topCrop)
                        const { x, y, width, height } = topCrop || {};

                        const inputImageAspectRatio = imgWidth / imgHeight;

                        let outputWidth = imgWidth;
                        let outputHeight = imgHeight;
                        if (inputImageAspectRatio > outputImageAspectRatio) {
                            outputWidth = imgHeight * outputImageAspectRatio;
                        } else if (inputImageAspectRatio < outputImageAspectRatio) {
                            outputHeight = imgWidth / outputImageAspectRatio;
                        }

                        const canvas = document.getElementById("canvas-list-preview")
                        canvas.width = outputWidth;
                        canvas.height = outputHeight;

                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, x, y, width, height, 0, 0, canvas.width, canvas.height);

                        const result = canvas.toDataURL("image/webp", 0.7);
                        const fallback = canvas.toDataURL("image/jpeg", 0.6);

                        store.dispatch(uploadImageSuccess(result, fallback))
                    })
                } catch (error) {
                    store.dispatch(uploadImageFailure(error))
                }
            };
            img.src = reader.result.toString();
        };
        reader.readAsDataURL(fileRef);

    } catch (error) {
        yield put(uploadImageFailure(error))
    }
}

export function* uploadImageStart() {
    yield takeLatest(
        UserEventsActionTypes.UPLOAD_IMAGE_START,
        uploadImageStartAsync
    )
}


export function* deleteImageStartAsync(action) {
    try {
        const userId = action.payload;
        const refType = action.refType;

        console.log("USER ID", userId, "REF TYPE", refType)
        yield put(deleteImageSuccess(null, refType))
    } catch (error) {
        yield put(deleteImageFailure(error))
    }
}

export function* deleteImageStart() {
    yield takeLatest(
        UserEventsActionTypes.DELETE_IMAGE_START,
        deleteImageStartAsync
    )
}


export function* setListStartAsync(action) {
    try {
        const { userId, listId } = action.payload;
        const { title, description, image } = action.listDetails;
        const imageFallback = yield select(selectUploadedImageFallback)
        const validImage = image ? (isDataURL(image) && isDataURL(imageFallback)) : null;

        const updateImageMetaData = async (cdnList, cdnListFallback, listId) => {
            const { data } = await locationAPI.get("/");
            const { country_name, country, city } = data || {};
            const customMetadata = {
                "userId": userId,
                "listId": listId,
                "location": (country_name && country && city) ? `${city}, ${country_name}, ${country}` : "Basel, Switzerland, CH",
            }
            await updateMetadata(cdnList, { customMetadata })
            await updateMetadata(cdnListFallback, { customMetadata })
        }

        if(listId) {
            console.log("SAGA UPDATE LIST")
            const currentList = yield select(selectListsDataDetails(listId));
            const currentTitle = currentList.title;
            const currentDesc = currentList.desc;
            const currentImgId = currentList.imageId;

            let userList = yield ref(database, "users/" + userId + "/news/lists/details/" + listId);

            const updateListWithImage = async (imagePath, imageId) => {
                if ((title !== currentTitle) && (description !== currentDesc) && image) {
                    await update(userList, {
                        title: title,
                        desc: description ? description : "",
                        // imageUrl: validImage ? "https://storage.googleapis.com/reavide/" + imagePath : "",
                        imageId: imageId,
                        timestamp: new Date().toISOString()
                    })
                }  else if ((title !== currentTitle) && image) {
                    await update(userList, {
                        title: title,
                        // imageUrl: validImage ? "https://storage.googleapis.com/reavide/" + imagePath : "",
                        imageId: imageId,
                        timestamp: new Date().toISOString()
                    })
                } else if ((description !== currentDesc) && image) {
                    await update(userList, {
                        desc: description ? description : "",
                        // imageUrl: validImage ? "https://storage.googleapis.com/reavide/" + imagePath : "",
                        imageId: imageId,
                        timestamp: new Date().toISOString()
                    })
                } else {
                    await update(userList, {
                        // imageUrl: validImage ? "https://storage.googleapis.com/reavide/" + imagePath : "",
                        imageId: imageId,
                        timestamp: new Date().toISOString()
                    })
                }
            }
            const updateList = async () => {
                if ((title !== currentTitle) && (description !== currentDesc)) {
                    await update(userList, {
                        title: title,
                        desc: description ? description : "",
                        timestamp: new Date().toISOString()
                    })
                } else {
                    if (title !== currentTitle) {
                        await update(userList, {
                            title: title,
                            timestamp: new Date().toISOString()
                        })
                    }
                    if (description !== currentDesc) {
                        await update(userList, {
                            desc: description ? description : "",
                            timestamp: new Date().toISOString()
                        })
                    }
                }
            }

            if(validImage || image === "delete") {
                if(validImage) {
                    const newImageId = nanoid(24);
                    const newImagePath = "news/lists/" + newImageId + ".webp";
                    const newImagePathFallback = "news/lists/" + newImageId + ".png";
                    let cdnList = yield storageRef(storage, newImagePath);
                    let cdnListFallback = yield storageRef(storage, newImagePathFallback);

                    yield uploadString(cdnList, image, "data_url").then((snapshot) => {
                        console.log("CDN LIST UPLOAD --WEBP", snapshot)
                        uploadString(cdnListFallback, imageFallback, "data_url").then(async (snapshot) => {
                            console.log("CDN LIST UPLOAD --PNG", snapshot)
                            await updateListWithImage(newImagePath, newImageId)
                            await updateImageMetaData(cdnList, cdnListFallback, listId)
                        }).catch((error) => console.log("CDN LIST ERROR", error))
                    }).catch((error) => console.log("CDN LIST ERROR", error))

                    // yield updateImageMetaData(cdnList, cdnListFallback)
                } else {
                    yield updateListWithImage("", "");
                }

                if(image === "delete") {
                    const imagePath = "news/lists/" + currentImgId + ".webp";
                    const imagePathFallback = "news/lists/" + currentImgId + ".png";
                    let cdnList = yield storageRef(storage, imagePath)
                    let cdnListFallback = yield storageRef(storage, imagePathFallback)
                    if(currentImgId) yield deleteObject(cdnList); yield deleteObject(cdnListFallback);
                }
            } else  {
                yield updateList()
            }

        } else {
            console.log("SAGA CREATE LIST", validImage)
            const newListId = nanoid(11);
            const newImageId = nanoid(24);
            const imagePath = "news/lists/" + newImageId + ".webp";
            const imagePathFallback = "news/lists/" + newImageId + ".png";

            const setListUpload = async (listRef) => {
                await set(listRef, {
                    listId: newListId,
                    imageId: validImage ? newImageId : "",
                    title: title,
                    desc: description ? description : "",
                    length: 0,
                    creator: userId,
                    restricted: true,
                    pinned: false,
                    timestamp: new Date().toISOString()
                })
            }

            let userList = yield ref(database, "users/" + userId + "/news/lists/details/" + newListId);
            if(validImage) {
                let cdnList = yield storageRef(storage, imagePath)
                let cdnListFallback = yield storageRef(storage, imagePathFallback)
                yield uploadString(cdnList, image, "data_url").then((snapshot) => {
                    console.log("CDN LIST UPLOAD --WEBP", snapshot)
                    uploadString(cdnListFallback, imageFallback, "data_url").then(async (snapshot) => {
                        console.log("CDN LIST UPLOAD --PNG", snapshot)
                        await setListUpload(userList);
                        await updateImageMetaData(cdnList, cdnListFallback, newListId);
                    }).catch((error) => console.log("CDN LIST ERROR", error))
                }).catch((error) => console.log("CDN LIST ERROR", error))

                // yield updateImageMetaData(cdnList, cdnListFallback)
            } else {
                yield setListUpload(userList);
            }
        }

        yield put(setListSuccess(null))
    } catch (error) {
        yield put(setListFailure(error.message))
    }
}

export function* setListStart() {
    yield takeLatest(
        UserEventsActionTypes.SET_LIST_START,
        setListStartAsync
    )
}


export function* deleteListStartAsync(action) {
    try {
        const userId = action.payload;
        const listId = action.listId;
        const { imageId } = yield select(selectListsDataDetails(listId));

        let userList = yield ref(database, "users/" + userId + "/news/lists/details/" + listId);
        let collection = yield ref(database, "users/" + userId + "/news/lists/collections/" + listId);

        yield remove(userList);
        yield onValue(collection, async snapshot => {
            if (snapshot.exists()) {
                await remove(collection)
            }
        }, { onlyOnce: true })

        if(imageId) {
            let cdnList = yield storageRef(storage, "news/lists/" + imageId + ".webp")
            let cdnListFallback = yield storageRef(storage, "news/lists/" + imageId + ".png")
            yield deleteObject(cdnList)
            yield deleteObject(cdnListFallback)
        }

        yield put(deleteListSuccess(null))
    } catch (error) {
        yield put(deleteListFailure(error.message))
    }
}

export function* deleteListStart() {
    yield takeLatest(
        UserEventsActionTypes.DELETE_LIST_START,
        deleteListStartAsync
    )
}


export function* logRecentAccessStartAsync(action) {
    try {
        const userId = action.payload;
        const listId = action.listId;
        const refType = action.refType;

        let userRecent = yield ref(database, "users/" + userId + "/news/recent/" + listId);
        yield onValue(userRecent, async snapshot => {
            if(!snapshot.exists() && refType === "add") {
                await set(userRecent, {
                    listId: listId,
                    type: "list",
                    timestamp: new Date().toISOString(),
                    userMobile: isMobile(),
                    client: "webApp"
                })
            } else if(refType === "add") {
                await update(userRecent, { timestamp: new Date().toISOString() })
            } else if(refType === "delete") {
                await remove(userRecent)
            }
        }, { onlyOnce: true })
        yield put(logRecentAccessSuccess(listId))
    } catch (error) {
        yield put(logRecentAccessFailure(error.message))
    }
}

export function* logRecentAccessStart() {
    yield takeLatest(
        UserEventsActionTypes.LOG_RECENT_ACCESS_START,
        logRecentAccessStartAsync
    )
}


export function* performSearchStartAsync(action) {
    try {
        const userId = action.payload;
        const query = action.query;
        const type = action.searchType;
        const lang = yield select(selectUserLanguage);
        const attribute = validateSearchAttribute(action.attribute, lang);
        const attributeAlgolia = validateSearchAttribute(action.attribute, lang, true);
        const resultKey = getURIQueryResultKey(query, attribute);
        const { currentPage, processingTime } = (yield select(selectSearchResults))[resultKey] || {};

        let searchRef = yield ref(database, "users/" + userId + "/news/search");

        if(query) {
            if(!processingTime) {
                yield put(deleteSearch(userId, { query, attribute }))
                yield push(searchRef, {
                    query: query,
                    attribute: attribute,
                    timestamp: new Date().toISOString(),
                    userMobile: isMobile()
                })
            }
            const requestOptions = {
                // enablePersonalization: true, --Premium Feature
                analyticsTags: [
                    "webApp",
                    (type === "voice" ? "voice" : "standard"),
                    (isMobile() ? "mobile" : "desktop"),
                    getUserPlatform(),
                    getUserAgent(),
                    lang
                ],
                userToken: userId,
                attributesToHighlight: [],
                hitsPerPage: 10,
                page: (currentPage || currentPage === 0) ? currentPage + 1 : 0,

                ...(type === "voice" && { naturalLanguages: [lang] }),
                ...(attribute && { restrictSearchableAttributes: [attributeAlgolia] })
            }
            searchIndex.search(query, requestOptions).then((response) => {
                console.log("SAGA SEARCH RESPONSE", requestOptions, response);
                store.dispatch(performSearchSuccess({
                    resultKey: resultKey,
                    query: encodeURIQuery(query),
                    processingTime: response.processingTimeMS,
                    results: response.hits,
                    totalResults: response.nbHits,
                    currentPage: response.page,
                    maxPage: response.nbPages,
                    attribute: attribute
                }))
                store.dispatch(fetchArticleDataStart("", response.hits))
            }).catch(error => store.dispatch(performSearchFailure(error.message)))
        }
    } catch (error) {
        yield put(performSearchFailure(error.message))
    }
}

export function* performSearchStart() {
    yield takeLatest(
        UserEventsActionTypes.PERFORM_SEARCH_START,
        performSearchStartAsync
    )
}


export function* performUrlSearchStartAsync(action) {
    try {
        const { userId, lang } = action.payload;
        const { url } = action.urlQuery;
        const articleList = yield select(selectArticleList)

        yield newsAPI.get(`/search/url?u=${url}`, {
            headers: {
                user: userId,
                lang: lang
            }
        }).then(response => {
            console.log("RESPONSE", response)
            const { articleId } = response?.data?.content
            if(articleId && !articleList?.[articleId]) {
                store.dispatch(fetchArticleDataStart("", articleId))
            }
            store.dispatch(performUrlSearchSuccess({
                query: url,
                articleId: articleId
            }))
        }).catch(() => store.dispatch(performUrlSearchSuccess({
            query: url,
            articleId: "unavailable"
        })))
    } catch (error) {
        yield put(performUrlSearchFailure(error.message))
    }
}

export function* performUrlSearchStart() {
    yield takeLatest(
        UserEventsActionTypes.PERFORM_URL_SEARCH_START,
        performUrlSearchStartAsync
    )
}


export function* userEventsSagas() {
    yield all([
        call(articleBookmarkStart),
        call(followChannelStart),
        call(setListPinStart),
        call(listArticleStart),
        call(deleteSearchStart),
        call(logArticleReadStart),
        call(setTargetReadsStart),
        call(sendFeedbackStart),
        call(logSharedContentStart),
        call(uploadImageStart),
        call(deleteImageStart),
        call(setListStart),
        call(deleteListStart),
        call(logRecentAccessStart),
        call(performSearchStart),
        call(performUrlSearchStart)
    ])
}