import React, { useEffect } from "react";
import styled from "styled-components";

import { color, radius } from "../../../../design/theme";
import { capitalize, addScript, removeScript, generateCdnUrl } from "../../../general.utils";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    background-color: ${color.white};
    padding: 20px 15px 0 15px;
    border-radius: ${radius.largeRadius};
    overflow: hidden;
`

const EmbedSdaLiveblog = ({ embedUrl, width, provider, height }) => {
    const actuateResize = (iframe) => {
        addScript(generateCdnUrl("dist/js/libs/iframeResizer.min.js"), "", "", () => {
        // addScript("//cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js", "", "", () => {
            window.iFrameResize({ heightCalculationMethod: "lowestElement", log: false }, iframe)
            let reached = false;
            const detectEndOfBlog = () => {
                if (window.pageYOffset > iframe.height() + iframe.offset().top - 50 - window.innerHeight) {
                    if (reached) return;
                    iframe.get(0).contentWindow.postMessage("loadMore", "*");
                    reached = true;
                } else {
                    reached = false;
                }
            };
            window.scrollTo(detectEndOfBlog);
        })
    }

    useEffect(() => {
        return () => removeScript("iframeResizer", "iFrameResize")
    }, [])

    return (
        <IFrameWrapper
            width={width}
        >
            <iframe
                title={`${capitalize(provider)} Iframe`}
                onLoad={() => actuateResize(this)}
                id={"liveblog-iframe"}
                width={width - 2*15}
                height={height}
                allowFullScreen
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                loading={"lazy"}
                referrerPolicy={"no-referrer"}
                sandbox={"allow-popups allow-scripts allow-same-origin allow-presentation"}
                src={embedUrl}
            > </iframe>
        </IFrameWrapper>
    )
}

export default EmbedSdaLiveblog;