import React, { useEffect, useState, Fragment, useContext } from "react";
import { connect } from "react-redux";

import { HeaderContext } from "../../../context/header.context";
import { selectUserId, selectListsDataRaw, selectCollectionsDataPreview, selectUserLanguage, selectCollectionsDataRefArray, selectUserDataFetching, selectUserDataError } from "../../../redux/userData/userData.selectors";
import { selectLocalRecentAccess } from "../../../redux/userEvents/userEvents.selectors";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { fetchUserListsStart, fetchUserCollectionsStart } from "../../../redux/userData/userData.actions";
import { fetchArticleDataStart } from "../../../redux/articleData/articleData.actions";
import { logRecentAccessStart } from "../../../redux/userEvents/userEvents.actions";
import { selectArticleArrayForPreview, selectArticleFetching } from "../../../redux/articleData/articleData.selectors";
import { color } from "../../../design/theme";
import { capitalize, timeSince } from "../../../components/general.utils";
import { listsData as listsDataCC, miscData } from "../../../components/lang.data";

import {
    ListViewHeaderContainer,
    ListViewHeaderImage,
    ListViewHeaderContentContainer,
    ListViewHeaderTitle,
    ListViewHeaderSubtitle,
    ListViewHeaderInfoWrapper,
    ListViewHeaderInfoContainer,
    ListViewHeaderInfoIconContainer,
    ListViewHeaderInfoTextWrapper,
    ListViewHeaderInfoTextContainer
} from "./listView.styles";
import StaticImage from "../../../components/staticImage/staticImage.component";
import ArticleFeed from "../../../components/articleFeed/articleFeed.component";
import InteractionBar from "../../../components/interactionBar/interactionBar.component";
import SiteHead from "../../../components/siteHead/siteHead.component";
import ErrorDisplay from "../../../components/errorDisplay/errorDisplay.component";

const ListView = ({ userId, listId, darkmode, listsData, fetchUserListsStart, fetchUserCollectionsStart, articleRefList,
                      fetchArticleDataStart, articlePreviewList, collectionsData, lang, logRecentAccessStart, recentAccess,
                        listLoading, collectionLoading, articlesLoading, listError, collectionError }) => {

    const { setListEnv, setSimpleEnv } = useContext(HeaderContext);
    const [orderedList, setOrderedList] = useState(articlePreviewList);
    const [initialContext, setInitialContext] = useState(false);
    const [timeout, logTimeout] = useState(true);
    const { creator, desc, imageId, length, title, timestamp } = listsData?.[listId] || {};
    const accessLog = setTimeout(() => logTimeout(false), 5000);

    useEffect( () => {
        if(!listsData?.[listId]) fetchUserListsStart(userId, listId, "single");
    }, [listsData, listId, userId])
    useEffect(() => {
        if(!collectionsData?.[listId]) fetchUserCollectionsStart(userId, listId, "single");
        else fetchArticleDataStart("collections", listId);
    }, [collectionsData, listId, userId])
    useEffect(() => {
        if(listsData?.[listId] && collectionsData?.[listId]) {
            if(listsData?.[listId] !== "undefined") setListEnv({ list: listsData[listId], listId: listId });
            else setSimpleEnv(true)

            if(!initialContext && !timeout) {
                if(recentAccess[0] !== listId && listsData?.[listId] !== "undefined") {
                    logRecentAccessStart(userId, listId, "add")
                }
                setInitialContext(true);
            }
        }

        return () => {
            clearTimeout(accessLog);
            setListEnv(null);
            setSimpleEnv(null);
        }
    }, [listsData, collectionsData, listId, userId, setListEnv, timeout])

    return (
        <Fragment>
            <SiteHead title={title + " List"} />
            <ListViewHeaderContainer>
                <ListViewHeaderImage>
                    <StaticImage imageId={imageId} main />
                </ListViewHeaderImage>
                <ListViewHeaderContentContainer>
                    <ListViewHeaderSubtitle darkmode={darkmode}>
                        <span>{`${listsDataCC.readingList[lang].toUpperCase()} //`}</span>
                    </ListViewHeaderSubtitle>
                    <ListViewHeaderTitle darkmode={darkmode}>
                        <span>{title}</span>
                    </ListViewHeaderTitle>
                    {
                        desc ? (
                            <ListViewHeaderInfoTextContainer darkmode={darkmode} desc>
                                <span>{desc}</span>
                            </ListViewHeaderInfoTextContainer>
                        ) : null
                    }
                    <ListViewHeaderInfoWrapper>
                        <ListViewHeaderInfoContainer>
                            <ListViewHeaderInfoIconContainer
                                name={"account"}
                                size={25}
                                color={color.blue}
                            />
                            <ListViewHeaderInfoTextWrapper>
                                <ListViewHeaderInfoTextContainer darkmode={darkmode}>
                                    <span>{capitalize(miscData.by[lang])} </span>
                                    <span>{creator === userId ? miscData.you[lang] : "reavide"}</span>
                                    <span>{` • `}</span>
                                    <span>{timeSince(timestamp, lang)}</span>
                                </ListViewHeaderInfoTextContainer>
                            </ListViewHeaderInfoTextWrapper>
                        </ListViewHeaderInfoContainer>
                        <ListViewHeaderInfoContainer>
                            <ListViewHeaderInfoIconContainer
                                name={"download"}
                                size={25}
                                color={color.blue}
                            />
                            <ListViewHeaderInfoTextWrapper>
                                <ListViewHeaderInfoTextContainer darkmode={darkmode}>
                                    <span>{listsData?.[listId] !== "undefined" ? length : "0"} {miscData.articles[lang]}</span>
                                </ListViewHeaderInfoTextContainer>
                            </ListViewHeaderInfoTextWrapper>
                        </ListViewHeaderInfoContainer>
                    </ListViewHeaderInfoWrapper>
                </ListViewHeaderContentContainer>
            </ListViewHeaderContainer>
            <InteractionBar
                type={"articles"}
                orderCallback={setOrderedList}
                orderData={articlePreviewList}
            />
            {
                orderedList?.length ? (
                    <ArticleFeed
                        type={"standard"}
                        articlePreviewList={orderedList}
                        infiniteLoader={() => fetchArticleDataStart("collections", listId)}
                        articleRefList={articleRefList}
                        error={listError || collectionError}
                        refType={listId}
                    />
                ) : (!listLoading && listLoading !== undefined && !collectionLoading && collectionLoading !== undefined && !articlesLoading) ? (
                    <ErrorDisplay
                        type={"noCollection"}
                        link={"popular"}
                        icon={"110015"}
                    />
                ) : null
            }
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    listsData: selectListsDataRaw(state),
    collectionsData: selectCollectionsDataPreview(state),
    articlePreviewList: selectArticleArrayForPreview(selectCollectionsDataRefArray(ownProps.match.params.listId)(state))(state),
    articleRefList: selectCollectionsDataRefArray(ownProps.match.params.listId)(state),
    userId: selectUserId(state),
    lang: selectUserLanguage(state),
    listId: ownProps.match.params.listId,
    recentAccess: selectLocalRecentAccess(state),
    darkmode: selectDarkmode(state),
    articlesLoading: selectArticleFetching(state),
    listLoading: selectUserDataFetching("listsData")(state),
    collectionLoading: selectUserDataFetching("collectionsData")(state),
    listError: selectUserDataError("listsData")(state),
    collectionError: selectUserDataError("collectionsData")(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserListsStart: (userId, listId, fetchKey) => dispatch(fetchUserListsStart(userId, listId, fetchKey)),
    fetchUserCollectionsStart: (userId, listId, fetchKey) => dispatch(fetchUserCollectionsStart(userId, listId, fetchKey)),
    fetchArticleDataStart: (collRef, collKey) => dispatch(fetchArticleDataStart(collRef, collKey)),
    logRecentAccessStart: (userId, listId, refType) => dispatch(logRecentAccessStart(userId, listId, refType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListView);