import React, { useRef, useLayoutEffect, useState, useMemo, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { color } from "../../design/theme";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { generateImageUrl } from "../general.utils";

import {
    StaticImageContainer,
    StaticImagePicture,
    StaticImageDefaultIcon,
    StaticImageStatusIcon
} from "./staticImage.styles";

const StaticImage = ({ placeholder, imageId, darkmode, type, largeDefault, main, rect, url = "", deleted }) => {
    const ref = useRef(null);
    const [validUrl, setValidUrl] = useState("");
    const [onLoadDefErr, setOnLoadDefErr] = useState(false);
    const [onLoadSuccess, setOnLoadSuccess] = useState(false);
    const [imageDimensions, setImageDimensions] = useState([0, 0]);
    const defaultModifier = largeDefault ? 0.5 : 0.6;

    useEffect(() => {
        if(!placeholder) setValidUrl(imageId ? generateImageUrl(imageId, "list") : url)
        setOnLoadDefErr(false);
    }, [imageId])

    useLayoutEffect(() => {
        if(ref.current) setImageDimensions([ref.current.clientWidth, ref.current.clientHeight])
    }, [ref])

    return (
        <StaticImageContainer
            onContextMenu={(event) => event.preventDefault()}
            darkmode={darkmode}
            success={onLoadSuccess && validUrl}
            deleted={deleted}
            placeholder={placeholder}
            main={main}
            ref={ref}
            height={imageDimensions[0]}
            rect={rect}
        >
            {
                useMemo(() => (
                    <Fragment>
                        {
                            (placeholder || !validUrl || deleted) ? (
                                <StaticImageDefaultIcon
                                    name={type === "profile" ? "account" : type === "logo" ? "faq" : placeholder ? "listsAdd" : "lists"}
                                    size={defaultModifier * (rect ? imageDimensions[0] : imageDimensions[1])}
                                    color={color.gray3}
                                />
                            ) : (
                                <Fragment>
                                    {
                                        (!onLoadSuccess || onLoadDefErr) ? (
                                            <StaticImageStatusIcon
                                                name={onLoadDefErr ? "unknown" : "updates"}
                                                size={largeDefault ? 0.3 * (rect ? imageDimensions[0] : imageDimensions[1]) : 25}
                                                color={color.gray3}
                                            />
                                        ) : null
                                    }
                                    {
                                        !onLoadDefErr ? (
                                            <StaticImagePicture
                                                onError={() => setOnLoadDefErr(true)}
                                                onLoad={() => setOnLoadSuccess(true)}
                                                src={validUrl}
                                                // loading={"lazy"}
                                            />
                                        ) : null
                                    }
                                </Fragment>
                            )
                        }
                    </Fragment>
                ), [validUrl, placeholder, onLoadSuccess, onLoadDefErr, imageDimensions])
            }

        </StaticImageContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(StaticImage);