import styled from "styled-components";
import Icon from "../../design/icons/icon.component";
import { radius, font } from "../../design/theme";
import { categoryColor, categoryContentColor } from "../general.utils";

export const ChannelListContainer = styled.div`
    width: 100%;
    padding: 0 0 0 20px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: ${radius.smallRadius};
    background-color: ${props => categoryColor(props.categoryId, props.darkmode)};
    color: ${props => categoryContentColor(props.categoryId, props.darkmode)};
    position: relative;
    cursor: pointer;
    font-family: ${font.pulpDisplayBold};
`;

export const ChannelListIconContainer = styled(Icon)`
    height: 100%;
    justify-content: center;
    user-select: none;
`;

export const ChannelListDescriptionContainer = styled.div`
    font-size: 20px;
    // margin-left: 5px;
    margin-right: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
`

export const ChannelListButtonContainer = styled.div`
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    vertical-align: center;
    justify-content: right;
    margin: 0 15px 0 auto;
  
    &>button {
        margin-left: 10px;
    }
`;

export const ChannelListActionContainer = styled.div`
    height: 35px;
    width: auto;
    margin-right: 5px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    user-select: none;
    letter-spacing: 0.045em;
    font-size: 16px;
    text-align: center;
    flex-direction: column;
`