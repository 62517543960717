import styled from "styled-components";
import { color, font, radius } from "../../design/theme";
import { Link } from "react-router-dom";
import Icon from "../../design/icons/icon.component";

export const PreviewCardWrapper = styled.div`
    width: auto;
    height: ${props => props.extended ? "130px" : "100px"};
    border-radius: ${radius.smallRadius};
    background-color: ${props => props.modal ? props.darkmode ? color.gray1 : color.gray5 : props.darkmode ? color.gray2 : color.white};
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    transform: translate3d(0,0,0); // forces tap into webGPU
    content-visibility: auto;
    // will-change: scroll-position;
    
    &:hover  {
        box-shadow: 0 0 0 2px ${color.blue};
        outline: none;
    }
    &:active {
        transform: scale(0.98);
        // transition: 0.1s;
        outline: none;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &:focus-within {
        outline: none !important;
    }
    &::-moz-focus-inner {
        border: 0;
    }
`

export const PreviewCardContainer = styled(Link)`
    width: inherit;
    height: inherit;
    min-width: 0;
    display: flex;
`

export const PreviewCardImageWrapper = styled.div`
    width: ${props => props.minimal ? "87.5px" : "120px"};
    height: inherit;
    display: flex;
`

export const PreviewCardContentContainer = styled.div`
    height: inherit;
    width: inherit;
    display: flex;
    flex-grow: 100;
    min-width: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 12px 20px 12px 20px;
`

export const PreviewCardContentDetails = styled.div`
    height: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`

export const PreviewCardContentText = styled.div`
    font-family: ${props => props.type === "newspaper" ? font.pulpDisplayBold : font.pulpDisplayMedium};
    height: auto;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.color ? props.color : props.darkmode ? color.gray4 : color.gray2};
    letter-spacing: 0.01em;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  
    ${
        props => props.type && props.type.includes("specification") ?
            `background-color: ${props.darkmode ? color.gray1 : color.gray5};
             border-radius: 20px;
             padding: 0 15px;
             box-shadow: 0 0 0 1px ${props.darkmode ? color.gray1 : color.gray5};
             flex-shrink: 0;` : null
    }
    ${ props => props.type && props.type.includes("count") ? "margin-right: 10px;" : null }
`

export const PreviewCardContentIcon = styled(Icon)`
    height: inherit;
    width: auto;
    margin-right: ${props => props.minimal ? "0" : "10px"};
    margin-left: auto;
    flex-shrink: 0;
`

export const PreviewCardContentDesc = styled.div`
    height: auto;
    width: inherit;
    display: flex;
    flex-shrink: 100;
    flex-grow: 0;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
  
    & > div > span {
        // flex: 1;
        // min-width: 0;
        // white-space: nowrap;
        // white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`