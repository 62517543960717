import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { miscData } from "../lang.data";
import { color } from "../../design/theme";

import { ModalActionButtonContainer } from "./modalAction.styles";
import CustomButton from "../customButton/customButton.component";

const ModalAction = ({ darkmode, onDismiss, lang, callback, loading, actionDesc, actionColor,
                         secondaryCallback, disabled, type, singular, icon }) => {
    return(
        <ModalActionButtonContainer singular={singular}>
            {
                !singular ? (
                    <CustomButton
                        callback={() => {
                            secondaryCallback?.()
                            onDismiss()
                        }}
                        desc={miscData.cancel[lang]}
                        color={darkmode ? color.gray4 : color.gray3}
                    />
                ) : null
            }
            <CustomButton
                callback={() => callback?.()}
                desc={actionDesc ?? miscData.confirm[lang]}
                color={actionColor ?? color.blue}
                disabled={disabled}
                width={singular ? 250 : null}
                loading={loading}
                type={type}
                icon={icon ?? "arrow"}
                solid
            />
        </ModalActionButtonContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ModalAction);