import { createSelector } from "reselect";

const selectBreakingData = state => state.breakingData

export const selectBreakingList = createSelector(
    [selectBreakingData],
    breakingData => breakingData.breakingList
)

export const selectBreakingUpdate = createSelector(
    [selectBreakingData],
    breakingData => breakingData.breakingUpdate
)

export const selectBreakingListFetching = createSelector(
    [selectBreakingData],
    breakingData => breakingData.breakingListFetching
)