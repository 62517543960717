import React, { useRef, /*useLayoutEffect,*/ useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../App";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectArticleList } from "../../redux/articleData/articleData.selectors";
import { textColor, date, capitalize, compactArticleData } from "../general.utils";

import {
    HistoryBlockWrapper,
    HistoryBlockIconContainer,
    HistoryBlockIcon,
    HistoryBlockContentContainer,
    HistoryBlockTitle,
    HistoryBlockListContainer
} from "./historyBlock.styles";
import PreviewCard from "../previewCard/previewCard.component";
import RenderController from "../renderController/renderController.component";

const HistoryBlock = ({ historyDataBlock, darkmode, lang, articleList }) => {
    const { mainWidth } = useContext(LayoutContext);

    const [refHeight, setRefHeight] = useState(0);
    const [articleRefList, setArticleRefList] = useState(articleList);
    const ref = useRef(null);
    const { timestamp, articleLogList } = historyDataBlock;

    /*useLayoutEffect(() => {
        if(ref.current) setRefHeight(ref.current.clientHeight)
        setArticleRefList(articleList);
    }, [ref, articleLogList, articleList])*/
    useEffect(() => {
        setArticleRefList(articleList);
        if(ref.current) setTimeout(() => setRefHeight(ref.current?.clientHeight), 0)
    }, [ref, articleLogList, articleList])

    return (
        <HistoryBlockWrapper
            darkmode={darkmode}
            singleitem={articleLogList.length === 1}
            mainWidth={mainWidth}
        >
            <HistoryBlockIconContainer height={refHeight}>
                <HistoryBlockIcon
                    name={"history"}
                    size={40}
                    color={textColor(darkmode)}
                />
            </HistoryBlockIconContainer>
            <HistoryBlockContentContainer ref={ref}>
                <HistoryBlockTitle darkmode={darkmode}>
                    <span>{capitalize(date(lang, timestamp))}</span>
                </HistoryBlockTitle>
                <HistoryBlockListContainer>
                    {
                        articleLogList.map(articleLog => {
                            const { articleId, timestamp, articleCount } = articleLog || {};
                            return articleRefList[articleId] ? (
                                <RenderController
                                    key={timestamp}
                                    id={articleId + articleCount + new Date(timestamp).getDay() + new Date(timestamp).getHours() + new Date(timestamp).getMinutes()}
                                    defaultHeight={100}
                                    darkmode={darkmode}
                                    visibleOffset={400}
                                >
                                    <PreviewCard
                                        article={compactArticleData(articleList[articleId])}
                                        articleLog={articleLog}
                                    />
                                </RenderController>
                            ) : null
                        })
                    }
                </HistoryBlockListContainer>
            </HistoryBlockContentContainer>
        </HistoryBlockWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage,
    articleList: selectArticleList
})

export default connect(mapStateToProps)(HistoryBlock);