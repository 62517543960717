import React, { createContext, useState } from "react";

export const HeaderContext = createContext(null);

const HeaderContextProvider = ({ children }) => {
    const [articleEnv, setArticleEnv] = useState(null);
    const [channelEnv, setChannelEnv] = useState(null);
    const [simpleEnv, setSimpleEnv] = useState(null);
    const [listEnv, setListEnv] = useState(null);

    return (
        <HeaderContext.Provider value={{ articleEnv, setArticleEnv, channelEnv, setChannelEnv, simpleEnv, setSimpleEnv, listEnv, setListEnv }}>
            { children }
        </HeaderContext.Provider>
    )
}

export default HeaderContextProvider;