import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { categoryColor } from "../general.utils";
import { color } from "../../design/theme";

import {
    LoginPreviewWrapper,
    LoginPreviewImageWrapper,
    LoginPreviewImageContainer,
    LoginPreviewContentContainer,
    LoginPreviewTitle,
    LoginPreviewIcon
} from "./loginPreview.styles";
import DynamicImage from "../dynamicImage/dynamicImage.component";
import ArticleText from "../articleContent/articleText/articleText.content";
import ArticleInfo from "../articleInfo/articleInfo.component";
import LoginWall from "../loginWall/loginWall.component";
import Logo from "../../design/logos";
import SiteHead from "../siteHead/siteHead.component";

const LoginPreview = ({ article, darkmode, callback }) => {
    const [refHeight, setRefHeight] = useState("auto");
    const [scrollActive, setScrollActive] = useState(false);
    const [wallActive, setWallActive] = useState(false);
    const { title, author, catchline, category, newspaper, originalUrl, teaserImage, publishedAt, type, updatedAt, articleId } = article || {};
    const { copyright, imageId, imageUrl, caption } = teaserImage || {};

    const ref = useRef(null);

    const handleWallActivation = (event) => {
        event.stopPropagation();
        setWallActive(true);
    }
    console.log("WALL IS ACTIVE", wallActive)
    useLayoutEffect(() => {
        if(ref.current) setRefHeight(`${(ref.current.clientWidth - 90) * 0.5625}px`)
    }, [ref])
    useEffect(() => {
        const handleResize = (event) => {
            event.stopPropagation();
            if(ref.current) setRefHeight(`${(ref.current.clientWidth - 90) * 0.5625}px`)
            if(window.innerHeight === ref.current.clientHeight) setScrollActive(true)
            else setScrollActive(false)
        }
        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [ref])

    return (
        <LoginPreviewWrapper
            ref={ref}
            scrollActive={scrollActive}
            onWheel={(event) => handleWallActivation(event)}
        >
            <SiteHead title={title} desc={catchline} type={"article"}
                data={{
                    shareUrl: `https://reavi.de/${articleId}`,
                    imageUrl: imageUrl,
                    imageDesc: caption,
                    imageType: type,
                    publishedAt,
                    updatedAt,
                    category,
                    author
                }}
            />
            <LoginPreviewImageWrapper refHeight={refHeight} darkmode={darkmode}>
                <LoginPreviewImageContainer
                    title={copyright ? `© ${copyright}` : null}
                    darkmode={darkmode}
                    wallActive={wallActive}
                >
                    <DynamicImage
                        imageId={imageId}
                        fallbackUrl={imageUrl}
                    />
                </LoginPreviewImageContainer>
            </LoginPreviewImageWrapper>
            <LoginPreviewContentContainer wallActive={wallActive} darkmode={darkmode}>
                <Logo
                    name={newspaper}
                    size={25}
                    color={categoryColor(category, darkmode)}
                />
                <LoginPreviewTitle darkmode={darkmode}>
                    <span>{title}</span>
                </LoginPreviewTitle>
                <ArticleInfo infoProps={{ author, newspaper, originalUrl, publishedAt, type, updatedAt }} />
                <ArticleText text={catchline} size={18} narrow />
                {
                    !wallActive ? (
                        <LoginPreviewIcon
                            onClick={(event) => handleWallActivation(event)}
                            name={"download"}
                            size={40}
                            color={color.blue}
                            darkmode={darkmode}
                        />
                    ) : null
                }
                {
                    wallActive ? (
                        <LoginWall
                            article={article}
                            callback={callback}
                        />
                    ) : null
                }
            </LoginPreviewContentContainer>
        </LoginPreviewWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(LoginPreview);