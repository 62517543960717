import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isEmpty } from "lodash";

import { fetchUserListsStart, fetchUserCollectionsStart } from "../../redux/userData/userData.actions";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserId, selectListsDataForPreview, selectCollectionsDataRaw, selectListsDataFetchKey, selectCollectionsDataFetchKey, selectUserDataFetching } from "../../redux/userData/userData.selectors";

import { ModalSelectionContainer } from "./modalSelection.styles";
import ListSelection from "../listSelection/listSelection.component";
import ErrorDisplay from "../errorDisplay/errorDisplay.component";

const ModalSelection = ({ type, listsData, refValue, darkmode, onDismiss, userId, fetchUserListsStart, listLoading,
                          collectionsData, fetchUserCollectionsStart, listFetchKey, collFetchKey, collLoading }) => {

    useEffect(() => {
        if(type === "list" && listFetchKey !== "general") fetchUserListsStart(userId, "", "general");
    }, [fetchUserListsStart, userId, listFetchKey])
    useEffect(() => {
        if(type === "list" && collFetchKey !== "general") fetchUserCollectionsStart(userId, "", "general");
    }, [fetchUserCollectionsStart, userId, collFetchKey])

    console.log("LOADING STATE", listLoading, collLoading)
    return (
        <ModalSelectionContainer darkmode={darkmode}>
            {
                type === "list" ? (
                    (listsData && !isEmpty(listsData) && collectionsData) ? listsData.map(list => (
                        <ListSelection
                            key={list.listId}
                            list={list}
                            onDismiss={onDismiss}
                            articleId={refValue}
                            // minimal={"true"}
                            mapped={"true"}
                        />
                    )) : (isEmpty(listsData) && !listLoading && !collLoading) ? (
                        <ErrorDisplay
                            type={"noLists"}
                            link={"library/lists"}
                            icon={"add"}
                            modal
                        />
                    ) : null
                ) : null
            }
        </ModalSelectionContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    listsData: selectListsDataForPreview,
    collectionsData: selectCollectionsDataRaw,
    listFetchKey: selectListsDataFetchKey,
    collFetchKey: selectCollectionsDataFetchKey,
    listLoading: selectUserDataFetching("listsData"),
    collLoading: selectUserDataFetching("collectionsData"),
    darkmode: selectDarkmode,
    userId: selectUserId
})

const mapDispatchToProps = (dispatch) => ({
    fetchUserListsStart: (userId, listId, fetchKey) => dispatch(fetchUserListsStart(userId, listId, fetchKey)),
    fetchUserCollectionsStart: (userId, listId, fetchKey) => dispatch(fetchUserCollectionsStart(userId, listId, fetchKey))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelection);