import React, { useContext } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { LayoutContext } from "../../../App";
import { selectDarkmode } from "../../../redux/persistData/persistData.selectors";
import { color } from "../../../design/theme";

import {
    ArticleTickerWrapper,
    ArticleTickerIcon,
    ArticleTickerContainer,
    ArticleTickerSeparator
} from "./articleTicker.styles";

const ArticleTicker = ({ content, darkmode }) => {
    const { mainWidth } = useContext(LayoutContext);
    const { text, tickerNum } = content || {}

    return (
        <ArticleTickerWrapper tickerNum={tickerNum}>
            <ArticleTickerIcon
                name={"clock"}
                size={mainWidth > 600 ? 40 : 30}
                color={color.blue}
            />
            <ArticleTickerContainer darkmode={darkmode}>
                <span>{text}</span>
            </ArticleTickerContainer>
            <ArticleTickerSeparator />
        </ArticleTickerWrapper>
    )
}

// Possibly replace Article Separator with pseudo element

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ArticleTicker);