import styled from "styled-components";
import { Link } from "react-router-dom";
import { navBackground, navColor } from "../navigation/navigation.utils";
import { color, font, radius } from "../../design/theme";
import Icon from "../../design/icons/icon.component";

export const NavigationItemContainer = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${props => navBackground(props)};
    color: ${props => navColor(props)};
    border-radius: ${radius.smallRadius};
    display: flex;
    position: relative;
    justify-content: left;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;
    
    &:hover, &:active {
        background-color: ${props => props.darkmode ? color.blueActiveDark : color.blueActiveLight};
    }
    &:active {
        transform: scale(0.98);
        transition: 0.1s;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: outset 0 0 0 2px ${color.blue};
    }
    &::-moz-focus-inner {
        border: 0;
    }
`;

export const NavigationItemLink = styled(Link)`
    height: inherit;
    width: inherit;
    position: absolute;
    top: 0;
    left: 0;
`

export const NavigationItemIcon = styled(Icon)`
    height: 100%;
    justify-content: center;
    margin: 0 10px 0 10px;
`;

export const NavigationItemDesc = styled.div`
    font-size: 20px;
    font-family: ${font.pulpDisplayMedium};
    margin-left: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 0.045em;
`