import ArticleDataActionTypes from "./articleData.types";

const INITIAL_STATE = {
    articleList: {},
    articleDataFetching: false,
    articlePreview: null,
    articleDataError: null
}

const articleDataSetter = (articleArray, stateObject) => {
    if (articleArray && articleArray.length) {
        let resultObj = {};
        articleArray.forEach(article => {
            if(article.content?.components?.length) {
                const { teaserImage, components } = article.content;
                const { type } = article.metadata;
                let showTeaser = false;
                if(components && teaserImage) {
                    let imgDuplicate = false;
                    let relevantTypes = ["image", "video", "gallery"];
                    for(let i = 0; i < components.length; i++) {
                        if(relevantTypes.includes(components[i].component)) {
                            if(i === 0 || type === "ticker") break;
                            if(components[i].content?.imageUrl === teaserImage.imageUrl) imgDuplicate = true;
                            if(components[i].component === "gallery") {
                                for(const j in components[i].content?.body) {
                                    if(j.content?.imageUrl === teaserImage.imageUrl) {
                                        imgDuplicate = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if(i === components.length - 1 && !imgDuplicate) showTeaser = true;
                    }
                }
                let numUpdates = 0;
                if(type  === "ticker") {
                    components.forEach((component) => {
                        if(component.component === "ticker") {
                            numUpdates++
                            Object.assign(component.content, { tickerNum: numUpdates })
                        }
                    })
                }
                Object.assign(article.content, { showTeaser: showTeaser })
                Object.assign(article.metadata, { numUpdates: numUpdates })
            }
            Object.assign(resultObj, stateObject.articleList, { [article.articleId]: article })
        })
        return resultObj;
    } else return stateObject.articleList;
}

const articleDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ArticleDataActionTypes.FETCH_ARTICLE_DATA_START:
        case ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_START:
            return {
                ...state,
                articleDataFetching: true
            }
        case ArticleDataActionTypes.FETCH_ARTICLE_DATA_SUCCESS:
            return {
                ...state,
                articleList: articleDataSetter(action.payload, state),
                articleDataFetching: false
            }
        case ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_SUCCESS:
            return {
                ...state,
                articlePreview: action.payload,
                articleDataFetching: false
            }
        case ArticleDataActionTypes.FETCH_ARTICLE_DATA_FAILURE:
        case ArticleDataActionTypes.FETCH_ARTICLE_PREVIEW_FAILURE:
            return {
                ...state,
                articleDataFetching: false,
                articleDataError: action.payload
            }
        default:
            return state;
    }
}

export default articleDataReducer;