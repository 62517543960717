import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { addScript, removeScript, scriptPresent, generateCdnUrl } from "../../../general.utils";
import { radius } from "../../../../design/theme";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    border-radius: ${radius.largeRadius};
    overflow: hidden;
`

const EmbedInfogram = ({ embedUrl, width }) => {
    const ref = useRef(null);
    const dataId = embedUrl.substring(embedUrl.lastIndexOf("/") + 1);

    const observer = new MutationObserver(mutations => {
        mutations.forEach(({ type, target }) => {
            if(type === "attributes" && target.tagName.toLowerCase() === "iframe") {
                target.parentElement.style.height = (parseInt(target.style.height.split("px")[0]) - 14) + "px"
            }
        });
    });

    useEffect(() => {
        if(ref.current) {
            observer.observe(ref.current, { attributes: true, childList: true, subtree: true });
        }
        if(!scriptPresent("infogram")) {
            addScript(generateCdnUrl("dist/js/embeds/infogram-sdk.js"), "id", "infogram-async")
            // addScript("//e.infogram.com/js/dist/embed-loader-min.js", "id", "infogram-async")
        }

        return () => removeScript("infogram")
    }, [ref])

    return (
        <IFrameWrapper
            width={width}
            ref={ref}
        >
            <div
                className={"infogram-embed"}
                data-id={dataId}
                data-type={"interactive"}
                data-title={"infogram-embed"}
                style={{ width: "inherit" }}
            >
            </div>
        </IFrameWrapper>
    )
}

export default EmbedInfogram;