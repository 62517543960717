import { createSelector } from "reselect";

const selectUserEvents = state => state.userEvents

export const selectUploadedImage = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userUploadedImage?.webp
)
export const selectUploadedImageFallback = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userUploadedImage?.fallback
)

export const selectDeletedImage = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userDeletedImage
)

export const selectLocalRecentAccess = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userRecentAccess
)

export const selectSearchResults = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userSearchResults
)
export const selectSearchResultsArr = (resultKey) =>
    createSelector(
        [selectSearchResults],
        userSearchData => userSearchData[resultKey]?.results || []
    )

export const selectUrlSearchResults = createSelector(
    [selectUserEvents],
    userEvents => userEvents.userUrlSearchResults
)

export const selectUserEventsLoading = (refKey) =>
    createSelector(
        [selectUserEvents],
        userEvents => userEvents.userEventsLoading[refKey]
    )

export const selectUserEventsError = (refKey) =>
    createSelector(
        [selectUserEvents],
        userEvents => userEvents.userEventsError[refKey]
    )