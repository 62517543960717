import styled from "styled-components";
import { font } from "../../design/theme";
import { textColor } from "../general.utils";

export const ErrorDisplayContainer = styled.div`
    width: ${props => props.fullscreen ? "100vw" : "inherit"};
    height: ${props => props.fullscreen ? "100vh" : "inherit"};
    margin-top: ${props => props.fullscreen ? "-50px" : "5%"};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const ErrorDisplayImage = styled.img`
    width: ${props => props.modal ? "180px" : "250px"};
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`;

export const ErrorDisplayText = styled.h1`
    width: clamp(80%, 480px, 60%);
    font-size: ${props => props.modal ? "22px" : "30px"};
    font-family: ${font.pulpDisplayBold};
    display: flex;
    justify-content: center;
    flex-direction: column;
    white-space: pre-wrap;
    color: ${props => textColor(props.darkmode)};
    margin: 20px 0 40px 0;
`;