import ArticleRefActionTypes from "./articleRef.types";

const INITIAL_STATE = {
    articleRefObj: {},
    articleRefIsFetching: false,
    articleRefError: {}
}

const refDataSetter = (refKey, payload, stateObj) => {
    const { articleRefList, articleRefPage } = payload || {};
    const stateData = stateObj.articleRefObj;

    return stateData[refKey] ?
        refDataUpdater(refKey, articleRefList, articleRefPage, stateData)
        : Object.assign(stateObj.articleRefObj,{
            [refKey]: { list: articleRefList, page: articleRefPage }
        })
}
const refDataUpdater = (refKey, articleRefList, articleRefPage, stateData) => {
    let updateArray = [];
    articleRefList.forEach(ref => {
        console.log("ARTICLE REF SAGA UPDATE REF", ref)
        if (!stateData[refKey].list.some(refObj => refObj.articleId === ref.articleId)) {
            updateArray.push(ref)
            console.log("ARTICLE REF SAGA UPDATE REF :: PUSH", ref);
        }
    })
    return Object.assign(stateData,{
        [refKey]: { list: [...stateData[refKey].list, ...updateArray], page: articleRefPage }
    })
}

const articleRefReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ArticleRefActionTypes.FETCH_ARTICLE_REF_START:
            return {
                ...state,
                articleRefIsFetching: true
            }
        case ArticleRefActionTypes.FETCH_ARTICLE_REF_SUCCESS:
            return {
                ...state,
                articleRefIsFetching: false,
                articleRefObj: refDataSetter(action.refKey, action.payload, state),
                articleRefError: Object.assign(state.articleRefError, { [action.refKey]: null })
            }
        case ArticleRefActionTypes.FETCH_ARTICLE_REF_FAILURE:
            return {
                ...state,
                articleRefIsFetching: false,
                articleRefError: Object.assign(state.articleRefError, { [action.refKey]: action.payload })
            }
        default:
            return state;
    }
}

export default articleRefReducer;