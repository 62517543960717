import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import { color, radius } from "../../../../design/theme";
import { capitalize, addScript, removeScript, scriptPresent, generateCdnUrl } from "../../../general.utils";
import { selectUserLanguage } from "../../../../redux/userData/userData.selectors";

const IFrameWrapper = styled.div`
    width: ${props => props.width + "px"};
    background-color: ${props => props.background ? color.white : "transparent"};
    padding: ${props => props.padding ? props.padding + "px" : "0"};
    border-radius: ${props => props.border ? props.border : radius.largeRadius};
    overflow: hidden;
`

const EmbedModular = ({ embedUrl, width, provider, contentId }) => {
    const ref = useRef(null);

    const providerProperties = {
        instagram: {
            sandbox: "allow-popups allow-scripts allow-same-origin",
            background: true,
            modUrl: embedUrl.includes("embed") ? embedUrl : embedUrl + "embed",
            border: radius.smallRadius
        }
    }
    const { background, sandbox, modUrl, border, padding } = providerProperties[provider] || {};

    const observer = new MutationObserver(mutations => {
        mutations.forEach(({ type, target }) => {
            if (type === "attributes" && target.tagName.toLowerCase() === "iframe") {
                target.style.height = target.height + "px";
            }
        });
    });

    useEffect(() => {
        if(ref.current) {
            observer.observe(ref.current, { attributes: true, childList: true, subtree: true });
        }

        if(provider === "instagram" && !scriptPresent("instagram") && !window.instgrm) {
            addScript(generateCdnUrl("dist/js/embeds/instagram-sdk.js"))
        } else if(window.instgrm) window.instgrm.Embeds.process()

        return () => {
            if(provider === "instagram") removeScript("instagram", "instgrm")
        }
    }, [ref])

    return (
        <IFrameWrapper
            width={width}
            background={background}
            padding={padding}
            border={border}
            ref={ref}
        >
            <iframe
                title={`${capitalize(provider)} Iframe`}
                id={"modularIframe_" + contentId}
                width={padding ? (width - 2*padding) : width}
                allowFullScreen
                marginHeight={0}
                marginWidth={0}
                frameBorder={0}
                referrerPolicy={"no-referrer"}
                sandbox={sandbox || "allow-popups"}
                src={modUrl || embedUrl}
                style={{ display: "block" }}
            > </iframe>
        </IFrameWrapper>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(EmbedModular);