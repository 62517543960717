import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from "react";
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect";
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";

import { LayoutContext } from "../../App";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectHistoryData, selectUserLanguage, selectUserDataFetching } from "../../redux/userData/userData.selectors";
import { generateChart } from "./accountChart.utils";
import { accountData } from "../lang.data";

import {
    AccountChartContainer,
    AccountChartTitleContainer,
    AccountChartCanvasWrapper,
    AccountChartCanvasContainer,
    AccountChartCanvasBox,
    AccountChartOptionsContainer
} from "./accountChart.styles";
import ModeSelector from "../modeSelector/modeSelector.component";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip)

const AccountChart = ({ darkmode, title, historyData, type, lang, loading }) => {
    const { mainWidth } = useContext(LayoutContext);

    const[option, setOption] = useState("week");
    const[pieWidth, setPieWidth] = useState(0);
    const[reducedSize, setReducedSize] = useState(false);
    const chartNode = useRef(null);
    const chartWrapper = useRef(null);

    useLayoutEffect(() => {
        if(chartWrapper.current) {
            if(chartWrapper.current.clientWidth > chartWrapper.current.clientHeight) {
                setPieWidth(chartWrapper.current.clientHeight)
            } else setPieWidth(chartWrapper.current.clientWidth)
        }
        if(mainWidth <= 600) setReducedSize(true)
        else setReducedSize(false)
    }, [mainWidth, chartWrapper])
    useEffect(() => {
        // let chartNode = document.getElementById(`${type}Ref`).getContext("2d");
        let chart = generateChart(type, historyData, loading, chartNode.current, option, darkmode, lang, reducedSize)
        console.log("CHART", type, chart)

        return () => {
            if(chart) {
                console.log("CHART IS DESTROYED")
                chart.destroy()
            }
        }
    }, [historyData, option, darkmode, reducedSize, loading, lang])

    return(
        <AccountChartContainer
            darkmode={darkmode}
            type={type}
        >
            <AccountChartTitleContainer darkmode={darkmode}>
                <span>{title}</span>
            </AccountChartTitleContainer>
            {
                type === "bar" ? (
                    <AccountChartOptionsContainer mobileView={mainWidth <= 600}>
                        <ModeSelector
                            data={accountData.barChart.options}
                            current={option}
                            type={"barChart"}
                            onClick={(newOption) => setOption(newOption)}
                            adapt
                        />
                    </AccountChartOptionsContainer>
                ) : null
            }
            <AccountChartCanvasWrapper mobileView={mainWidth <= 600 && type === "bar"}>
                <AccountChartCanvasContainer ref={chartWrapper}>
                    <AccountChartCanvasBox type={type} pieWidth={pieWidth}>
                        <canvas id={`${type}Ref`} ref={chartNode} />
                    </AccountChartCanvasBox>
                </AccountChartCanvasContainer>
            </AccountChartCanvasWrapper>
        </AccountChartContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    historyData: selectHistoryData,
    lang: selectUserLanguage,
    loading: selectUserDataFetching("historyData")
})

export default connect(mapStateToProps)(AccountChart);