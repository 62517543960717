import styled from "styled-components";

export const InteractionContainer = styled.section`
    width: ${props => props.fullWidth ? "100%" : "max-content"};
    min-width: calc(50% - ${props => props.type === "articles" ? "0px" : "10px"});
    max-width: 100%;
    height: 34px;
    padding-left: 2px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    flex-grow: 0;
    z-index: 1;
`

export const SearchbarContainer = styled.div`
    height: 100%;
    width: auto;
    margin-right: 10px;
    flex-grow: 100;
    flex-direction: row;
    position: relative;
`

export const FilterContainer = styled.div`
    height: inherit;
    width: max-content;
    flex-direction: row;
`