import { createSelector } from "reselect";

const selectChannelRef = state => state.channelRef;

export const selectChannelRefList = createSelector(
    [selectChannelRef],
    channelRef => channelRef.channelRefList
)

export const selectChannelRefFetching = createSelector(
    [selectChannelRef],
    channelRef => channelRef.channelRefIsFetching
)

export const selectChannelRefData = createSelector(
    [selectChannelRef],
    channelRef => channelRef.channelRefData
)

export const selectChannelRefError = (refKey) =>
    createSelector(
    [selectChannelRef],
    channelRef => channelRef.channelRefError[refKey]
)