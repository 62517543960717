import styled from "styled-components";

export const ArticleParagraphContainer = styled.div`
    width: inherit;
    position: relative;
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin: 30px 0;
`;