import styled from "styled-components";
import { font } from "../../design/theme";
import { textColor } from "../general.utils";
import Icon from "../../design/icons/icon.component";

export const SubIndicatorContainer = styled.title`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    margin-bottom: 25px;
`

export const SubIndicatorIcon = styled(Icon)`
    height: 34px;
    width: 34px;
    justify-content: center;
    margin-right: 15px;
`

export const SubIndicatorTitle = styled.h2`
    font-family: ${font.pulpDisplayBold};
    color: ${props => textColor(props.darkmode)};
    font-size: 24px;
    white-space: nowrap;

    @media only screen and (max-width: 750px) {
        font-size: 22px;
    }
`