import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectDarkmode } from "../../redux/persistData/persistData.selectors";
import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { color } from "../../design/theme";
import { miscData } from "../lang.data";

import { NoticeContainer, NoticeContentContainer, NoticeIcon } from "./notice.styles";
import CustomButton from "../customButton/customButton.component";

const Notice = ({ desc, darkmode, type, size, callback, feed, auto, lang }) => {
    return (
        <NoticeContainer darkmode={darkmode} type={type} size={size} feed={feed} auto={auto}>
            <NoticeContentContainer>
                <NoticeIcon
                    name={type}
                    size={size ? 20 : 30}
                    color={type === "error" ? color.red : color.blue}
                />
                <span>{desc}</span>
            </NoticeContentContainer>
            {
                callback ? (
                    <CustomButton
                        desc={miscData.remove[lang]}
                        color={color.blue}
                        callback={callback}
                        size={"small"}
                        width={100}
                        solid
                    />
                ) : null
            }
        </NoticeContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    darkmode: selectDarkmode,
    lang: selectUserLanguage
})

export default connect(mapStateToProps)(Notice);