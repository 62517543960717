import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserLanguage } from "../../redux/userData/userData.selectors";
import { selectDarkmode } from "../../redux/persistData/persistData.selectors";

import {
    ImageCardContainer,
    ImageCardLink,
    ImageCardPictureContainer,
    ImageCardContentContainer,
    ImageCardNewspaperContainer,
    ImageCardTitleContainer
} from "./imageCard.styles";
import ArticleTime from "../articleTime/articleTime.component";
import ArticleAction from "../articleAction/articleAction.component";
import DynamicImage from "../dynamicImage/dynamicImage.component";

const ImageCard = ({ articlePreview, lang, darkmode }) => {

    const { articleId, newspaper, title, image, category, publishedAt, updatedAt, type } = articlePreview || {};
    const { imageUrl, copyright, imageId } = image || {};

    return (
        <ImageCardContainer
            title={copyright ? `© ${copyright}` : null}
            tabIndex={"0"}
            aria-label={"Article Image"}
            darkmode={darkmode}
        >
            <ImageCardPictureContainer darkmode={darkmode}>
                <DynamicImage
                    imageId={imageId} fallbackUrl={imageUrl}
                />
            </ImageCardPictureContainer>
            <ArticleAction articleId={articleId} type={"bookmark"} />
            <ArticleAction articleId={articleId} type={"list"} beside={1} />
            <ImageCardContentContainer>
                <ImageCardNewspaperContainer
                    categoryId={category}
                    darkmode={darkmode}
                    content={newspaper}
                >
                    <span>{newspaper.toUpperCase()}</span>
                </ImageCardNewspaperContainer>
                <ImageCardTitleContainer>
                    <span>{title}</span>
                </ImageCardTitleContainer>
                <ArticleTime
                    publishedAt={publishedAt}
                    updatedAt={updatedAt}
                    live={type === "ticker"}
                />
            </ImageCardContentContainer>
            <ImageCardLink to={`/${articleId}`} />
        </ImageCardContainer>
    )
}

const mapStateToProps = createStructuredSelector({
    lang: selectUserLanguage,
    darkmode: selectDarkmode
})

export default connect(mapStateToProps)(ImageCard);