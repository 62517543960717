import UserDataActionTypes from "./userData.types";

export const fetchUserDataStart = (userId) => ({
    type: UserDataActionTypes.FETCH_USER_DATA_START,
    payload: userId,
    refKey: "userData"
})
export const fetchUserDataSuccess = (userData) =>  ({
    type: UserDataActionTypes.FETCH_USER_DATA_SUCCESS,
    payload: userData
})
export const fetchUserDataFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_DATA_FAILURE,
    payload: errorMessage,
    refKey: "userData"
})

export const fetchUserBookmarksStart = (userId) => ({
    type: UserDataActionTypes.FETCH_USER_BOOKMARKS_START,
    payload: userId,
    refKey: "bookmarkData"
})
export const fetchUserBookmarksSuccess = (bookmarkObj, addData, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_BOOKMARKS_SUCCESS,
    payload: bookmarkObj,
    addData: addData,
    fetchKey: fetchKey,
    refKey: "bookmarkData"
})
export const fetchUserBookmarksFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_BOOKMARKS_FAILURE,
    payload: errorMessage,
    refKey: "bookmarkData"
})

export const fetchUserChannelsStart = (reqHeaders) => ({
    type: UserDataActionTypes.FETCH_USER_CHANNELS_START,
    payload: reqHeaders,
    refKey: "channelData"
})
export const fetchUserChannelsSuccess = (channelObj, addData, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_CHANNELS_SUCCESS,
    payload: channelObj,
    addData: addData,
    fetchKey: fetchKey,
    refKey: "channelData"
})
export const fetchUserChannelsFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_CHANNELS_FAILURE,
    payload: errorMessage,
    refKey: "channelData"
})

export const fetchUserHistoryStart = (userId, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_HISTORY_START,
    payload: userId,
    fetchKey: fetchKey,
    refKey: "historyData"
})
export const fetchUserHistorySuccess = (userData, addData, fetchKey) =>  ({
    type: UserDataActionTypes.FETCH_USER_HISTORY_SUCCESS,
    payload: userData,
    addData: addData,
    fetchKey: fetchKey,
    refKey: "historyData"
})
export const fetchUserHistoryFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_HISTORY_FAILURE,
    payload: errorMessage,
    refKey: "historyData"
})

export const fetchUserListsStart = (userId, listId, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_LISTS_START,
    payload: userId,
    listId: listId,
    fetchKey: fetchKey,
    refKey: "listsData"
})
export const fetchUserListsSuccess = (listsObj, addData, fetchKey) =>  ({
    type: UserDataActionTypes.FETCH_USER_LISTS_SUCCESS,
    payload: listsObj,
    addData: addData,
    fetchKey: fetchKey,
    refKey: "listsData"
})
export const fetchUserListsFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_LISTS_FAILURE,
    payload: errorMessage,
    refKey: "listsData"
})

export const fetchUserCollectionsStart = (userId, listId, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_COLLECTIONS_START,
    payload: userId,
    listId: listId,
    fetchKey: fetchKey,
    refKey: "collectionsData"
})
export const fetchUserCollectionsSuccess = (collectionsObj, addData, fetchKey) =>  ({
    type: UserDataActionTypes.FETCH_USER_COLLECTIONS_SUCCESS,
    payload: collectionsObj,
    addData: addData,
    fetchKey: fetchKey,
    refKey: "collectionsData"
})
export const fetchUserCollectionsFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_COLLECTIONS_FAILURE,
    payload: errorMessage,
    refKey: "collectionsData"
})

export const fetchUserAccessedStart = (userId) => ({
    type: UserDataActionTypes.FETCH_USER_ACCESSED_START,
    payload: userId,
    refKey: "accessedData"
})
export const fetchUserAccessedSuccess = (recentList, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_ACCESSED_SUCCESS,
    payload: recentList,
    fetchKey: fetchKey,
    refKey: "accessedData"
})
export const fetchUserAccessedFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_ACCESSED_SUCCESS,
    payload: errorMessage,
    refKey: "accessedData"
})

export const fetchUserSearchesStart = (userId) => ({
    type: UserDataActionTypes.FETCH_USER_SEARCHES_START,
    payload: userId,
    refKey: "searchData"
})
export const fetchUserSearchesSuccess = (searchData, fetchKey) => ({
    type: UserDataActionTypes.FETCH_USER_SEARCHES_SUCCESS,
    payload: searchData,
    fetchKey: fetchKey,
    refKey: "searchData"
})
export const fetchUserSearchesFailure = (errorMessage) => ({
    type: UserDataActionTypes.FETCH_USER_SEARCHES_FAILURE,
    payload: errorMessage,
    refKey: "searchData"
})