import React from "react";

import { CheckboxOptIn } from "./checkbox.styles";

const Checkbox = ({ type, required, label, input }) => {
    return (
        <CheckboxOptIn
            type={type}
            aria-label={label ?? null}
            required={required}
            { ...input }
        />
    )
}

export default Checkbox;